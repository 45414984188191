export enum EVisitRecordType {
  START_CHECKIN = 'START_CHECKIN',
  BADGE_GENERATION = 'BADGE_GENERATION',
  DAILY_CHECKIN = 'DAILY_CHECKIN',
  DAILY_CHECKOUT = 'DAILY_CHECKOUT',
  MAXXESS_CHECKIN = 'MAXXESS_CHECKIN',
  MAXXESS_CHECKOUT = 'MAXXESS_CHECKOUT',
  COVID_ACKNOWLEDGEMENT = 'COVID_ACKNOWLEDGEMENT',
  REPRINT_BADGE = 'REPRINT_BADGE',
  CHECKIN_EMAIL_CONFIRMATION = 'CHECKIN_EMAIL_CONFIRMATION',
}
