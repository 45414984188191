import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Step } from '@core/model/entity/step.model';
import { BaseComponent } from '@shared/base/base-component.component';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.less'],
})
export class StepperComponent extends BaseComponent implements OnInit {
  @Input() steps$: Observable<Step[]>;
  steps: Step[];

  activeStep: Step;
  constructor(private readonly router: Router) {
    super();
  }

  ngOnInit(): void {
    this.initState();
  }

  initState() {
    if (this.steps$) {
      this.steps$.pipe(take(1)).subscribe((steps) => {
        this.steps = steps;
        this.loadSteps();
      });
    }
  }

  loadSteps() {
    if (this.steps) {
      let url = this.router.url;
      if (url.startsWith(`/`)) {
        url = url.substr(1);
      }
      this.activeStep = this.steps.find((x) => x.routes.includes(url));
    }
  }

  get displayStepper(): boolean {
    return this.activeStep !== undefined;
  }

  isActiveStep(step: Step): boolean {
    return step === this.activeStep;
  }

  isCompletedStep(step: Step): boolean {
    return this.steps.indexOf(step) < this.steps.indexOf(this.activeStep);
  }

  isFutureStep(step: Step): boolean {
    return this.steps.indexOf(step) > this.steps.indexOf(this.activeStep);
  }
}
