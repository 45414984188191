import { lastValueFrom, take } from 'rxjs';

import { ApplicationStateService } from '@core/services/application-state.service';
import { CamundaProcessInstanceWithVariables } from '@data/models/camunda/camunda-process-instance-with-variables.model';
import { CamundaTask } from '@data/models/camunda/camunda-task.model';
import { CamundaTaskCustomProperties } from '@data/models/camunda/camunda-task-custom-properties.model';
import { Injectable } from '@angular/core';
import { LogService } from '@core/services/log.service';
import { PageKeyRoutes } from './../constants';
import { WorkflowInformation } from '@core/model/checkin-process/workflow-information.model';
import { WorkflowService } from '@data/services/workflow.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  camundaTaskCustomPropertiesMap: Map<string, CamundaTaskCustomProperties>;

  constructor(
    private applicationStateService: ApplicationStateService,
    private workflowService: WorkflowService,
    private logService: LogService
  ) {
    this.init();
  }

  private async init() {
    this.applicationStateService.camundaTaskCustomPropertiesMap$.subscribe((value) => {
      this.camundaTaskCustomPropertiesMap = value;
    });
  }

  async resetActiveTask() {
    const camundaProcessInstanceWithVariables = await lastValueFrom(
      this.applicationStateService.camundaProcessInstanceWithVariables$.pipe(take(1))
    );

    if (!camundaProcessInstanceWithVariables || !camundaProcessInstanceWithVariables.businessKey) {
      this.logService.error(
        `[NavigationService]resetActiveTask()`,
        `camundaProcessInstanceWithVariables/not found in state`
      );
      return;
    }

    const activeTask = await this.workflowService.getActiveTask(
      camundaProcessInstanceWithVariables.businessKey
    );
    await this.applicationStateService.setCamundaTask(activeTask);
  }

  async start(
    processDefinitionKey: string,
    workflowInformation?: WorkflowInformation
  ): Promise<CamundaTask> {
    const camundaProcessInstanceWithVariables = await this.workflowService.createProcessInstance(
      processDefinitionKey,
      workflowInformation
    );
    const nextCamundaTask = await this.workflowService.getActiveTask(
      camundaProcessInstanceWithVariables.businessKey
    );

    this.applicationStateService.setCamundaProcessInstanceWithVariables(
      camundaProcessInstanceWithVariables
    );

    try {
      await this.applicationStateService.setCamundaTask(nextCamundaTask);
    } catch (error) {
      this.logService.error(`[NavigationService]start ${error}`, error);
    }

    return nextCamundaTask;
  }

  async next(
    camundaProcessInstanceWithVariables: CamundaProcessInstanceWithVariables,
    camundaTask: CamundaTask,
    workflowInformation: WorkflowInformation
  ): Promise<CamundaTask> {
    this.logService.debug(`[NavigationService]next()`, workflowInformation);
    if (!camundaTask || !camundaProcessInstanceWithVariables) {
      this.logService.error(
        `[NavigationService]next()`,
        `Invalid camundaTask/camundaProcessInstanceWithVariables`
      );
      return;
    }

    await this.workflowService.completeTask(camundaTask.id, workflowInformation);

    const nextCamundaTask = await this.workflowService.getActiveTask(
      camundaProcessInstanceWithVariables.businessKey
    );

    this.applicationStateService.setCamundaTask(nextCamundaTask);

    return nextCamundaTask;
  }

  async back(
    camundaProcessInstanceWithVariables: CamundaProcessInstanceWithVariables,
    camundaTask: CamundaTask,
    workflowInformation: WorkflowInformation
  ): Promise<CamundaTask> {
    this.logService.debug(`[NavigationService]back()`, workflowInformation);
    if (!camundaTask || !camundaProcessInstanceWithVariables) {
      this.logService.error(
        `[NavigationService]back()`,
        `Invalid camundaTask/camundaProcessInstanceWithVariables`
      );
      return;
    }
    await this.workflowService.previousTask(camundaTask.id, workflowInformation);

    const nextCamundaTask = await this.workflowService.getActiveTask(
      camundaProcessInstanceWithVariables.businessKey
    );

    this.applicationStateService.setCamundaTask(nextCamundaTask);

    return nextCamundaTask;
  }

  async exit(
    camundaProcessInstanceWithVariables: CamundaProcessInstanceWithVariables,
    camundaTask: CamundaTask
  ): Promise<CamundaTask> {
    this.logService.debug(`[NavigationService]exit()`);
    if (!camundaTask || !camundaProcessInstanceWithVariables) {
      this.logService.error(
        `[NavigationService]exit()`,
        `Invalid camundaTask/camundaProcessInstanceWithVariables`
      );
      return;
    }
    await this.workflowService.exitTask(camundaTask.id);

    const nextCamundaTask = await this.workflowService.getActiveTask(
      camundaProcessInstanceWithVariables.businessKey
    );

    this.applicationStateService.setCamundaTask(nextCamundaTask);

    return nextCamundaTask;
  }

  async bypass(
    camundaProcessInstanceWithVariables: CamundaProcessInstanceWithVariables,
    camundaTask: CamundaTask
  ): Promise<CamundaTask> {
    this.logService.debug(`[NavigationService]bypass()`);
    if (!camundaTask || !camundaProcessInstanceWithVariables) {
      this.logService.error(
        `[NavigationService]bypass()`,
        `Invalid camundaTask/camundaProcessInstanceWithVariables`
      );
      return;
    }

    await this.workflowService.bypassTask(camundaTask.id);

    const nextCamundaTask = await this.workflowService.getActiveTask(
      camundaProcessInstanceWithVariables.businessKey
    );

    this.applicationStateService.setCamundaTask(nextCamundaTask);

    return nextCamundaTask;
  }

  async getRoutePath(camundaTask: CamundaTask): Promise<string> {
    if (!this.camundaTaskCustomPropertiesMap) {
      return null;
    }
    const camundaTaskCustomProperty = this.camundaTaskCustomPropertiesMap.get(
      camundaTask.taskDefinitionKey
    );
    if (!camundaTaskCustomProperty) {
      return null;
    }

    return PageKeyRoutes[camundaTaskCustomProperty.pageKey];
  }
}
