import { Injectable } from '@angular/core';
import {
  DoFaceCompare,
  ResetScan,
  ResetSession,
  SetAircraft,
  SetAppLoadedDateTime,
  SetBadgePrinted,
  SetBadgePrintRequest,
  SetBadgeValidity,
  SetByPasses,
  SetCamundaTask,
  SetCamundaTaskCustomPropertiesMap,
  SetCheckinFlow,
  SetCustomer,
  SetDeviceConfiguration,
  SetDeviceId,
  SetDeviceRegistration,
  SetExportControl,
  SetFacialComparisonResult,
  SetIDCardCameraSelection,
  SetIdentityDocumentType,
  SetIsAdministratorAuthenticated,
  SetPrinter,
  SetPrinters,
  SetRecordingType,
  SetBackButtonDeactivated,
  SetSkipBadgePrinting,
  SetSkipTraining,
  SetSteps,
  SetTechnicalTrainingCenters,
  SetTrainingCenter,
  SetTrainingCenterConfiguration,
  SetVisitorFacePhoto,
  SetVisitorIdFacePhoto,
  SetDeviceSerialNumber,
  SetAppVersionNumber,
  SetFeedbackInformation,
  ResetFeedbackInformation,
  SetCheckinFormFields,
} from '@core/state/application.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { first, lastValueFrom, Observable, take, timeout } from 'rxjs';

import { CheckinCameraType } from '@core/enums/checkin-camera-type.enum';
import { FlowType } from '@core/enums/flow-type.enum';
import { EIdentityDocumentType } from '@core/enums/identity-document-type.enum';
import { RecordingType } from '@core/enums/recording-type.enum';
import { IosBridgeService } from '@core/ios-bridge/ios-bridge.service';
import { checkinApp, isHostedInIPad } from '@core/ios-bridge/ios.utils';
import { BadgeValidity } from '@core/model/badge/badge-validity.model';
import { ByPassInformation } from '@core/model/bypass-information.model';
import { Customer } from '@core/model/customer/customer.model';
import { DeviceConfiguration } from '@core/model/devices-configuration/device-configuration.model';
import { Aircraft } from '@core/model/entity/aircraft.model';
import { ExportControl } from '@core/model/entity/export-control.model';
import { FacesCompareResult } from '@core/model/entity/FacesCompareResult';
import { Step } from '@core/model/entity/step.model';
import { TrainingCenter } from '@core/model/entity/training-center.model';
import { Printer } from '@core/model/printers/printer.model';
import { CheckinProcessConfiguration } from '@core/model/training-center/checkin-process-configuration.model';
import { TrainingCenterConfiguration } from '@core/model/training-center/training-center-configuration.model';
import { CamundaProcessInstanceWithVariables } from '@data/models/camunda/camunda-process-instance-with-variables.model';
import { CamundaTaskCustomProperties } from '@data/models/camunda/camunda-task-custom-properties.model';
import { CamundaTask } from '@data/models/camunda/camunda-task.model';
import { TrainingCentersService } from '@data/services/training-centers.service';
import { ApplicationStateModel } from '../state/application-state.model';
import { ApplicationState } from '../state/application.state';
import { FlowInformation } from '../state/flow-information.model';
import { TechnicalInformation } from '../state/technical-information.model';
import { VisitorInformation } from '../state/visitor-information.model';
import { DeviceRegistrationService } from './../../data/services/device-registration.service';
import { DeviceConfigurationsService } from './../../data/services/devices-configuration.service';
import { TrainingCentersConfigurationsService } from './../../data/services/training-centers-configurations.service';
import { DeviceRegistration } from './../model/device-registration/device-registration.model';
import {
  SetCamundaProcessInstanceWithVariables,
  SetPassportScannerIp,
  SetPassportScannerConnected,
  SetDeviceInventoryNumber
} from './../state/application.actions';
import { VisitStateService } from './../state/visit-state.service';
import { AuthenticationService } from './authentication.service';
import { WinBridgeService } from '@core/win-bridge/win-bridge.service';
import { isHostedInWin } from '@core/win-bridge/win.utils';
import { FeedbackInformation } from '@core/state/feedback-information.model';
import { CheckinFormFields } from '@core/model/form/checkin-form-fields.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService {
  @Select(ApplicationState.getVisitorInformation)
  public visitorInformation$: Observable<VisitorInformation>;

  @Select(ApplicationState.getFlowInformation)
  public flowInformation$: Observable<FlowInformation>;

  @Select(ApplicationState.getTechnicalInformation)
  public technicalInformation$: Observable<TechnicalInformation>;

  @Select(ApplicationState.getDeviceConfiguration)
  public deviceConfiguration$: Observable<DeviceConfiguration>;

  @Select(ApplicationState.getAppVersion)
  public appVersion$: Observable<string>;

  @Select(ApplicationState.getDeviceRegistration)
  public deviceRegistration$: Observable<DeviceRegistration>;

  @Select(ApplicationState.getState)
  public applicationState$: Observable<ApplicationStateModel>;

  @Select(ApplicationState.getDeviceId)
  public deviceId$: Observable<string>;

  @Select(ApplicationState.getFeedbackInformation)
  public feedbackInformation$: Observable<FeedbackInformation>;

  @Select(ApplicationState.getIsScannerConnected)
  public isScannerConnected$: Observable<boolean>;

  @Select(ApplicationState.getPrinter)
  public printer$: Observable<Printer>;

  @Select(ApplicationState.getFacialComparisonResult)
  public facialComparisonResult$: Observable<FacesCompareResult>;

  @Select(ApplicationState.getVisitorIdFacePhoto)
  public visitorIdFacePhoto$: Observable<string>;

  @Select(ApplicationState.getVisitorFacePhoto)
  public visitorFacePhoto$: Observable<string>;

  @Select(ApplicationState.getTrainingCenter)
  public trainingCenter$: Observable<TrainingCenter>;

  @Select(ApplicationState.getTechnicalTrainingCenters)
  public technicalTrainingCenters$: Observable<TrainingCenter[]>;

  @Select(ApplicationState.getTrainingCenterConfiguration)
  public trainingCenterConfiguration$: Observable<TrainingCenterConfiguration>;

  @Select(ApplicationState.getPrinters)
  public printers$: Observable<Printer[]>;

  @Select(ApplicationState.getBadgePrinted)
  public badgePrinted$: Observable<boolean>;

  @Select(ApplicationState.getSessionId)
  public sessionId$: Observable<Guid>;

  @Select(ApplicationState.getBadgePrintRequest)
  public badgePrintRequest$: Observable<boolean>;

  @Select(ApplicationState.getIDCardCameraSelection)
  public idCardCameraSelection$: Observable<CheckinCameraType>;

  @Select(ApplicationState.getSkipBadgePrinting)
  public skipBadgePrinting$: Observable<boolean>;

  @Select(ApplicationState.getSkipTraining)
  public skipTraining$: Observable<boolean>;

  @Select(ApplicationState.getappLoadedDateTime)
  public appLoadedDateTime$: Observable<Date>;

  @Select(ApplicationState.getFlowType)
  public checkinFlow$: Observable<FlowType>;

  @Select(ApplicationState.getRecordingType)
  public recordingType$: Observable<RecordingType>;

  @Select(ApplicationState.getByPasses)
  public byPasses$: Observable<ByPassInformation[]>;

  @Select(ApplicationState.getCamundaTask)
  public camundaTask$: Observable<CamundaTask>;

  @Select(ApplicationState.getCamundaProcessInstanceWithVariables)
  public camundaProcessInstanceWithVariables$: Observable<CamundaProcessInstanceWithVariables>;

  @Select(ApplicationState.getBadgeValidity)
  public badgeValidity$: Observable<BadgeValidity>;

  @Select(ApplicationState.getCamundaTaskCustomPropertiesMap)
  public camundaTaskCustomPropertiesMap$: Observable<Map<string, CamundaTaskCustomProperties>>;

  @Select(ApplicationState.getCheckinProcessConfiguration)
  public checkinProcessConfiguration$: Observable<CheckinProcessConfiguration>;

  @Select(ApplicationState.getExportControl)
  public exportControl$: Observable<ExportControl>;

  @Select(ApplicationState.getAircraft)
  public aircraft$: Observable<Aircraft>;

  @Select(ApplicationState.getCustomer)
  public customer$: Observable<Customer>;

  @Select(ApplicationState.getDrk)
  public drk$: Observable<string>;

  @Select(ApplicationState.getSteps)
  public steps$: Observable<Step[]>;

  @Select(ApplicationState.getIdentityDocumentType)
  public identityDocumentType$: Observable<EIdentityDocumentType>;

  @Select(ApplicationState.isAdministratorAuthenticated)
  public isAdministratorAuthenticated$: Observable<boolean>;

  @Select(ApplicationState.getBackButtonDeactivated)
  public backButtonDeactivated$: Observable<boolean>;

  @Select(ApplicationState.getCheckinFormFieldsState)
  public checkinFormFields$: Observable<CheckinFormFields>;

  constructor(
    private store: Store,
    private trainingCentersService: TrainingCentersService,
    private trainingCentersConfigurationsService: TrainingCentersConfigurationsService,
    private deviceConfigurationsService: DeviceConfigurationsService,
    private deviceRegistrationService: DeviceRegistrationService,
    private authenticationService: AuthenticationService,
    private iosBridgeService: IosBridgeService,
    private visitStateService: VisitStateService,
    private winBridgeService: WinBridgeService
  ) { }

  @Dispatch()
  public setCheckinFacialComparisonResult(same: FacesCompareResult) {
    return new SetFacialComparisonResult(same);
  }

  @Dispatch()
  public resetFeedbackInformation() {
    return new ResetFeedbackInformation();
  }

  @Dispatch()
  public resetScan() {
    return new ResetScan();
  }

  @Dispatch()
  public resetSession() {
    return new ResetSession();
  }

  @Dispatch()
  public doFaceCompare() {
    return new DoFaceCompare();
  }

  @Dispatch()
  public async setTrainingCenter(trainingCenter: TrainingCenter, deviceConfigurationId: string) {
    try {
      if (trainingCenter) {
        const trainingCentersConfiguration =
          await this.trainingCentersConfigurationsService.getByDevice(
            trainingCenter.geminiSiteId,
            deviceConfigurationId
          );
        this.setTrainingCenterConfiguration(trainingCentersConfiguration);

        const technicalTrainingCenters: TrainingCenter[] =
          await this.trainingCentersService.getTechnicalTrainingCenters(
            trainingCenter.geminiSiteId
          );

        const hasMainTrainingCenter = technicalTrainingCenters.some(
          (technicalTrainingCenter) =>
            technicalTrainingCenter.geminiSiteId === trainingCenter.geminiSiteId
        );
        if (!hasMainTrainingCenter) {
          technicalTrainingCenters.push(trainingCenter);
        }
        this.setTechnicalTrainingCenters(technicalTrainingCenters);
      } else {
        this.setTechnicalTrainingCenters([trainingCenter]);
      }
    } catch (error) {
      this.setTechnicalTrainingCenters([trainingCenter]);
      console.error(`[ApplicationState]setTrainingCenter : ${error}`, error);
    }

    return new SetTrainingCenter(trainingCenter);
  }

  @Dispatch()
  public async setTrainingCenterConfiguration(
    trainingCenterConfiguration: TrainingCenterConfiguration
  ) {
    return new SetTrainingCenterConfiguration(trainingCenterConfiguration);
  }

  @Dispatch()
  public async setDeviceId(deviceId: string) {
    if (!deviceId) {
      await this.setDeviceConfiguration(null);
      await this.setDeviceRegistration(null);
      return;
    }
    try {
      let deviceConfiguration = await this.deviceConfigurationsService.getByDeviceId(deviceId);

      if (!deviceConfiguration) {
        deviceConfiguration = new DeviceConfiguration({ deviceId });
      }

      const deviceRegistration = await this.deviceRegistrationService.getByDeviceId(deviceId);

      await this.setDeviceConfiguration(deviceConfiguration);
      await this.setDeviceRegistration(deviceRegistration);
    } catch (error) {
      console.error(`[ApplicationState]setDeviceId : ${error}`, error);
    }

    return new SetDeviceId(deviceId);
  }

  @Dispatch()
  public setFeedbackInformation(feedbackInformation: FeedbackInformation) {
    return new SetFeedbackInformation(feedbackInformation);
  }

  @Dispatch()
  public setPrinter(printer: Printer) {
    return new SetPrinter(printer);
  }

  @Dispatch()
  public setTechnicalTrainingCenters(trainingCenters: TrainingCenter[]) {
    return new SetTechnicalTrainingCenters(trainingCenters);
  }

  @Dispatch()
  public setPrinters(printers: Printer[]) {
    return new SetPrinters(printers);
  }

  @Dispatch()
  public setBadgePrinted(badgePrinted: boolean) {
    return new SetBadgePrinted(badgePrinted);
  }

  @Dispatch()
  public setBadgePrintRequest(request: boolean) {
    return new SetBadgePrintRequest(request);
  }

  @Dispatch()
  public setIDCardCameraSelection(idCardCameraSelection: CheckinCameraType) {
    return new SetIDCardCameraSelection(idCardCameraSelection);
  }

  @Dispatch()
  public setSkipBadgePrinting(setSkipBadgePrinting: boolean) {
    return new SetSkipBadgePrinting(setSkipBadgePrinting);
  }

  @Dispatch()
  public setSkipTraining(setSkipTraining: boolean) {
    return new SetSkipTraining(setSkipTraining);
  }

  @Dispatch()
  public setAppLoadedDateTime(appLoadedDateTime) {
    return new SetAppLoadedDateTime(appLoadedDateTime);
  }

  @Dispatch()
  public setCheckinFlow(checkinFlow: FlowType) {
    return new SetCheckinFlow(checkinFlow);
  }

  @Dispatch()
  public setRecordingType(recordingType: RecordingType) {
    return new SetRecordingType(recordingType);
  }

  @Dispatch()
  public setVisitorIdFacePhoto(idScan: string) {
    return new SetVisitorIdFacePhoto(idScan);
  }

  @Dispatch()
  public setVisitorFacePhoto(face: string) {
    return new SetVisitorFacePhoto(face);
  }

  @Dispatch()
  public setByPasses(byPasses: ByPassInformation[]) {
    return new SetByPasses(byPasses);
  }

  @Dispatch()
  public async setPassportScannerIp(passportScannerIp: string) {
    return new SetPassportScannerIp(passportScannerIp);
  }

  @Dispatch()
  public async setPassportScannerConnected(isConnected: boolean) {
    return new SetPassportScannerConnected(isConnected);
  }

  @Dispatch()
  public async setDeviceInventoryNumber(inventoryNumber: string) {
    return new SetDeviceInventoryNumber(inventoryNumber);
  }

  @Dispatch()
  public async setDeviceSerialNumber(serialNumber: string) {
    return new SetDeviceSerialNumber(serialNumber);
  }

  @Dispatch()
  public async setAppVersionNumber(serialNumber: string) {
    return new SetAppVersionNumber(serialNumber);
  }

  @Dispatch()
  public async setCamundaTask(value: CamundaTask): Promise<SetCamundaTask> {
    return new SetCamundaTask(value);
  }

  @Dispatch()
  public setCamundaProcessInstanceWithVariables(value: CamundaProcessInstanceWithVariables) {
    return new SetCamundaProcessInstanceWithVariables(value);
  }

  @Dispatch()
  public setBadgeValidity(badgeValidity: BadgeValidity) {
    return new SetBadgeValidity(badgeValidity);
  }

  @Dispatch()
  public setCamundaTaskCustomPropertiesMap(
    camundaTaskCustomPropertiesMap: Map<string, CamundaTaskCustomProperties>
  ) {
    return new SetCamundaTaskCustomPropertiesMap(camundaTaskCustomPropertiesMap);
  }

  @Dispatch()
  public async setDeviceConfiguration(deviceConfiguration: DeviceConfiguration) {
    let trainingCenter = null;
    if (deviceConfiguration) {
      if (deviceConfiguration.geminiSiteId) {
        const currentTrainingCenter = await lastValueFrom(this.trainingCenter$.pipe(first()));
        if (deviceConfiguration.geminiSiteId != currentTrainingCenter?.geminiSiteId) {
          trainingCenter = await this.trainingCentersService.getTrainingCenter(
            deviceConfiguration.geminiSiteId
          );
        } else {
          trainingCenter = currentTrainingCenter;
        }
      }
    }
    await this.setTrainingCenter(trainingCenter, deviceConfiguration?.id);

    return new SetDeviceConfiguration(deviceConfiguration);
  }

  @Dispatch()
  public async setDeviceRegistration(deviceRegistration: DeviceRegistration) {
    if (isHostedInIPad()) {
      if (deviceRegistration) {
        await lastValueFrom(this.iosBridgeService.setDrk(deviceRegistration?.drk).pipe(first()));
      }
    }
    if (isHostedInWin()) {
      if (deviceRegistration) {
        await this.winBridgeService.setRegistrationKey(deviceRegistration?.drk);
      }
    }
    if (isHostedInWin() || isHostedInIPad()) {
      this.authenticationService.drk = deviceRegistration?.drk;
    }
    return new SetDeviceRegistration(deviceRegistration);
  }

  @Dispatch()
  public setExportControl(exportControl: ExportControl) {
    return new SetExportControl(exportControl);
  }

  @Dispatch()
  public setAircraft(aircraft: Aircraft) {
    return new SetAircraft(aircraft);
  }

  @Dispatch()
  public setCustomer(customer: Customer) {
    return new SetCustomer(customer);
  }

  @Dispatch()
  public setSteps(steps: Step[]) {
    return new SetSteps(steps);
  }

  @Dispatch()
  public setIdentityDocumentType(identityDocumentType: EIdentityDocumentType) {
    return new SetIdentityDocumentType(identityDocumentType);
  }

  @Dispatch()
  public setIsAdministratorAuthenticated(isAdministratorAuthenticated: boolean) {
    return new SetIsAdministratorAuthenticated(isAdministratorAuthenticated);
  }

  public async initializeApplication() {
    try {
      if (isHostedInIPad()) {
        await this.initializeIosBridgeSubscription();
      }
      else if (isHostedInWin()) {
        await this.initializeWinBridgeSubscription();
      }
      const isAuthenticated = await lastValueFrom(
        this.authenticationService.isAuthenticated.pipe(first())
      );
      if (isAuthenticated) {
        if (isHostedInWin()) {
          const deviceId = await lastValueFrom(this.winBridgeService.getDeviceId().pipe(first()));
          const registrationKey = await lastValueFrom(this.winBridgeService.getRegistrationKey().pipe(take(1), timeout(3000)), { defaultValue: '' }).catch(() => '');
          const appVersion = await lastValueFrom(this.winBridgeService.getAppVersionNUmber().pipe(first(), timeout(1000)), { defaultValue: '' }).catch(() => '');
          this.authenticationService.drk = registrationKey;
          await this.setAppVersionNumber(appVersion);
          await this.setDeviceId(deviceId);
        } else
          if (isHostedInIPad()) {
            const appValue = checkinApp();
            this.authenticationService.drk = appValue.registrationKey;
            await this.setDeviceId(appValue?.deviceId);
          } else {
            await this.setDeviceId(this.authenticationService.userId);
          }
      }
    } catch (error) {
      console.error(`[ApplicationState]initializeApplication : ${error}`, error);
    }
  }

  public async initializeIosBridgeSubscription() {
    this.iosBridgeService.onBadgeNumberReceived.subscribe(async (badgeNumber) => {
      await this.visitStateService.setVisitBadgeNumber(badgeNumber);
    });
    this.iosBridgeService.onBadgePrintedReceived.subscribe(async (isPrinted) => {
      await this.setBadgePrintRequest(isPrinted);
    });
    this.iosBridgeService.onIdCardCameraSelectionReceived.subscribe(async (cameraSelection) => {
      await this.setIDCardCameraSelection(cameraSelection);
    });
  }

  public async initializeWinBridgeSubscription() {
    this.winBridgeService.onBadgePrintedReceived.subscribe(async (isPrinted) => {
      await this.setBadgePrintRequest(isPrinted);
    });
  }

  public getCurrentState(): ApplicationStateModel {
    const session = this.store.selectSnapshot((st) => st.session) as ApplicationStateModel;
    return session;
  }


  @Dispatch()
  public setBackButtonDeactivated(setBackButtonDeactivated: boolean) {
    return new SetBackButtonDeactivated(setBackButtonDeactivated);
  }

  @Dispatch()
  public async setCheckinFormFields(trainingCenterId: string) {
    const checkinFormFields = await this.visitStateService.getCheckinFormFields(trainingCenterId);
    return new SetCheckinFormFields(checkinFormFields);
  }
}
