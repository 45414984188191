import { Component, Input } from '@angular/core';

import { Reservation } from '@core/model/reservation/reservation.model';
import { Training } from '@core/model/training/training.model';
import { TrainingCenter } from '@core/model/entity/training-center.model';

@Component({
  selector: 'app-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.less'],
})
export class TrainingCardComponent {
  @Input() public training: Training;
  @Input() public reservations: Reservation[];
  @Input() public trainingCenter: TrainingCenter;

  dateRange: string;
}
