export enum WinBridgeCommand {
  GetConnected = 'GET_CONNECTED',
  Shutdown = 'SHUTDOWN',
  GetConnectedPrinters = 'GET_CONNECTED_PRINTERS',
  ReloadApplication = 'RELOAD_APPLICATION',
  GetDeviceId = 'GET_DEVICE_ID',
  GetDeviceRegistrationKey = 'GET_DEVICE_REGISTRATION',
  GetDeviceSerialNumber = 'GET_DEVICE_SERIALNUMBER',
  GetAppVersion = 'GET_APP_VERSION',
}

export enum WinBridgeInvokedMethod {
  Print = 'PrintBadge',
  ScanDocument = 'ScanDocument',
  SetDeviceRegistrationKey = 'SetDeviceRegistrationKey'
}