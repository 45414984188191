import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '@core/model/customer/customer.model';
import { Aircraft } from '@core/model/entity/aircraft.model';
import { TrainingCenter } from '@core/model/entity/training-center.model';
import { Training } from '@core/model/training/training.model';
import { stateToHttpParams } from '@core/utils/http-params.util';
import { ITrainingOdata } from '@data/interfaces/training/training-odata.interface';
import { ITraining } from '@data/interfaces/training/training.interface';
import { ApiService } from '@data/services/api.service';
import moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ETrainingStatus } from './../../core/enums/training-status.enum';

@Injectable({
  providedIn: 'root',
})
export class TrainingsService {
  private endpoint = `/trainings`;

  constructor(private apiService: ApiService) {}

  public get(
    trainingCenters: TrainingCenter[],
    trainingStartDate: Date,
    aircraft: Aircraft,
    customer: Customer
  ): Promise<Training> {
    const params = stateToHttpParams(trainingCenters, trainingStartDate, aircraft, customer);

    return lastValueFrom(
      this.apiService.get<ITraining>(`${this.endpoint}`, { params }).pipe(
        map((response) => {
          return new Training(response);
        })
      )
    );
  }

  public getTraining(id: string): Promise<Training> {
    if (!id) {
      return null;
    }
    return lastValueFrom(
      this.apiService
        .get<ITraining>(`${this.endpoint}/${id}`)
        .pipe(map((response) => new Training(response)))
    );
  }

  public async getTrainingsOdata(
    firstName: string,
    lastName: string,
    trainingCenter: TrainingCenter,
    trainingStartDate: Date,
    customer: Customer,
    trainingStatus: ETrainingStatus,
    odataOptions?: {
      skip?: number;
      top?: number;
      orderBy?: string;
      includeReservation?: boolean;
    }
  ): Promise<ITrainingOdata> {
    const odataFilters = [];

    if (firstName) {
      odataFilters.push(`contains(tolower(client/firstName), '${firstName.toLowerCase()}')`);
    }
    if (lastName) {
      odataFilters.push(`contains(tolower(client/lastName), '${lastName.toLowerCase()}')`);
    }

    if (trainingCenter) {
      odataFilters.push(`trainingCenterId eq '${trainingCenter.geminiSiteId}'`);
    }

    if (trainingStartDate) {
      odataFilters.push(
        `startDate ge ${moment(trainingStartDate)
          .subtract(5, 'day')
          .startOf('day')
          .toISOString()} and startDate le ${moment(trainingStartDate).endOf('day').toISOString()}`
      );
    }

    if (customer) {
      odataFilters.push(`customer/salesForceId eq '${customer.salesForceId}'`);
    }

    if (trainingStatus) {
      odataFilters.push(`status eq '${trainingStatus}'`);
    }

    let params = new HttpParams();

    if (odataFilters.length > 0) {
      params = params.append('$filter', odataFilters.join(' and '));
    }

    return lastValueFrom(this.apiService.get<ITrainingOdata>(`${this.endpoint}/odata`, { params }));
  }

  public async searchTraining(
    firstName: string,
    lastName: string,
    trainingCenter: TrainingCenter,
    trainingStartDate: Date,
    customer: Customer,
    trainingStatus: ETrainingStatus,
    odataOptions?: {
      skip?: number;
      top?: number;
      orderBy?: string;
      includeReservation?: boolean;
    }
  ): Promise<Training> {
    const result = await this.getTrainingsOdata(
      firstName,
      lastName,
      trainingCenter,
      trainingStartDate,
      customer,
      trainingStatus
    );

    if (result.total === 1) {
      return new Training(result.results.shift());
    }
  }
}
