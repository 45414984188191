import { Component, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

import { AnalyticService } from '@data/services/analytic.service';
import { BaseComponent } from '@shared/base/base-component.component';
import { LoadingService } from '@core/services/loading.service';
import { Meta } from '@angular/platform-browser';
import { MonitoringService } from '@core/services/monitoring.service';
import { environment } from '@env';
import { isHostedInIPad } from '@core/ios-bridge/ios.utils';
import { isHostedInWin } from '@core/win-bridge/win.utils';
import { WatchdogService } from '@core/services/watchdog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  providers: [LoadingService],
})
export class AppComponent extends BaseComponent implements OnInit {
  isIframe = window !== window.parent && !window.opener;
  constructor(
    public appIdle: Idle,
    private monitoringService: MonitoringService,
    private analyticService: AnalyticService,
    private watchdogService: WatchdogService,
    private metaService: Meta
  ) {
    super();
  }

  isLoading = false;
  ngOnInit(): void {
    try {
      this.initIdling();
      this.analyticService.init();
      this.monitoringService.startTracking();
      this.watchdogService.initialize();
      this.initLoader();
      if (isHostedInIPad() || isHostedInWin()) {
        this.metaService.updateTag({
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
        });
      }
    } catch (error) {
      this.analyticService.trackException(error);
    }
  }

  async initIdling() {
    try {
      if (environment.idleTime && environment.idleTimeout) {
        // sets an idle timeout of x seconds, for testing purposes.
        this.appIdle.setIdle(environment.idleTime);
        // sets a timeout period of x seconds. after x seconds of inactivity, the user will be considered timed out.
        this.appIdle.setTimeout(environment.idleTimeout);
      }

      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this.appIdle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    } catch (error) {
      this.analyticService.trackException(error);
    }
  }

  initLoader() {
    this._loadingService
      .isLoadingObservable()
      .subscribe((isLoading) => (this.isLoading = isLoading));
  }
}
