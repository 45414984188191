import { HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addOptionsToHttpHeaders, addToHttpParams } from '@core/utils/http-params.util';
import { CamundaHistoryTimeToLive } from '@data/models/camunda/camuda-history-time-to-live.model';
import { CamundaActivityStatisticsResult } from '@data/models/camunda/camunda-activity-statistics-result.model';
import { CamundaBatch } from '@data/models/camunda/camunda-batch.model';
import { CamundaCountResult } from '@data/models/camunda/camunda-count-result.model';
import { CamundaForm } from '@data/models/camunda/camunda-form.model';
import { CamundaProcessDefinitionDiagram } from '@data/models/camunda/camunda-process-definition-diagram.model';
import { CamundaProcessDefinitionStatisticsResult } from '@data/models/camunda/camunda-process-definition-statistics-result.model';
import { CamundaProcessDefinitionSuspensionState } from '@data/models/camunda/camunda-process-definition-suspension-state.model';
import { CamundaProcessDefinition } from '@data/models/camunda/camunda-process-definition.model';
import { CamundaProcessInstanceWithVariables } from '@data/models/camunda/camunda-process-instance-with-variables.model';
import { CamundaProcessInstance } from '@data/models/camunda/camunda-process-instance.model';
import { CamundaStartProcessInstanceForm } from '@data/models/camunda/camunda-start-process-instance-form.model';
import { CamundaStartProcessInstance } from '@data/models/camunda/camunda-start-process-instance.model';
import { CamundaVariableValue } from '@data/models/camunda/camunda-variable-value.model';
import { ApiService } from '@data/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProcessDefinitionService {
  protected endpoint = '/camunda/process-definition';
  public defaultHeaders = new HttpHeaders();

  constructor(private apiService: ApiService) {}

  /**
   * Delete
   * Deletes a running process instance by id.
   * @param id The id of the process definition to be deleted.
   * @param cascade &#x60;true&#x60;, if all process instances, historic process instances and jobs for this process definition should be deleted.
   * @param skipCustomListeners &#x60;true&#x60;, if only the built-in ExecutionListeners should be notified with the end event.
   * @param skipIoMappings A boolean value to control whether input/output mappings should be executed during deletion. &#x60;true&#x60;, if input/output mappings should not be invoked.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProcessDefinition(
    id: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public deleteProcessDefinition(
    id: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public deleteProcessDefinition(
    id: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public deleteProcessDefinition(
    id: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteProcessDefinition.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let queryParameters = new HttpParams();
    if (cascade !== undefined && cascade !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>cascade, 'cascade');
    }
    if (skipCustomListeners !== undefined && skipCustomListeners !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>skipCustomListeners,
        'skipCustomListeners'
      );
    }
    if (skipIoMappings !== undefined && skipIoMappings !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>skipIoMappings, 'skipIoMappings');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.delete(`${this.endpoint}/${encodeURIComponent(String(id))}`, {
      params: queryParameters,
      responseType: <any>responseType,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Delete By Key
   * Deletes process definitions by a given key which belong to no tenant id.
   * @param key The key of the process definitions to be deleted.
   * @param cascade &#x60;true&#x60;, if all process instances, historic process instances and jobs for this process definition should be deleted.
   * @param skipCustomListeners &#x60;true&#x60;, if only the built-in ExecutionListeners should be notified with the end event.
   * @param skipIoMappings A boolean value to control whether input/output mappings should be executed during deletion. &#x60;true&#x60;, if input/output mappings should not be invoked.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProcessDefinitionsByKey(
    key: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public deleteProcessDefinitionsByKey(
    key: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public deleteProcessDefinitionsByKey(
    key: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public deleteProcessDefinitionsByKey(
    key: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling deleteProcessDefinitionsByKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let queryParameters = new HttpParams();
    if (cascade !== undefined && cascade !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>cascade, 'cascade');
    }
    if (skipCustomListeners !== undefined && skipCustomListeners !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>skipCustomListeners,
        'skipCustomListeners'
      );
    }
    if (skipIoMappings !== undefined && skipIoMappings !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>skipIoMappings, 'skipIoMappings');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.delete(`${this.endpoint}/key/${encodeURIComponent(String(key))}`, {
      params: queryParameters,
      responseType: <any>responseType,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Delete By Key
   * Deletes process definitions by a given key and which belong to a tenant id.
   * @param key The key of the process definitions to be deleted.
   * @param tenantId The id of the tenant the process definitions belong to.
   * @param cascade &#x60;true&#x60;, if all process instances, historic process instances and jobs for this process definition should be deleted.
   * @param skipCustomListeners &#x60;true&#x60;, if only the built-in ExecutionListeners should be notified with the end event.
   * @param skipIoMappings A boolean value to control whether input/output mappings should be executed during deletion. &#x60;true&#x60;, if input/output mappings should not be invoked.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProcessDefinitionsByKeyAndTenantId(
    key: string,
    tenantId: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public deleteProcessDefinitionsByKeyAndTenantId(
    key: string,
    tenantId: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public deleteProcessDefinitionsByKeyAndTenantId(
    key: string,
    tenantId: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public deleteProcessDefinitionsByKeyAndTenantId(
    key: string,
    tenantId: string,
    cascade?: boolean,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling deleteProcessDefinitionsByKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling deleteProcessDefinitionsByKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let queryParameters = new HttpParams();
    if (cascade !== undefined && cascade !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>cascade, 'cascade');
    }
    if (skipCustomListeners !== undefined && skipCustomListeners !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>skipCustomListeners,
        'skipCustomListeners'
      );
    }
    if (skipIoMappings !== undefined && skipIoMappings !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>skipIoMappings, 'skipIoMappings');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.delete(
      `${this.endpoint}/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Activity Instance Statistics
   * Retrieves runtime statistics of a given process definition, grouped by activities. These statistics include the number of running activity instances, optionally the number of failed jobs and also optionally the number of incidents either grouped by incident types or for a specific incident type. **Note**: This does not include historic data.
   * @param id The id of the process definition.
   * @param failedJobs Whether to include the number of failed jobs in the result or not. Valid values are &#x60;true&#x60; or &#x60;false&#x60;.
   * @param incidents Valid values for this property are &#x60;true&#x60; or &#x60;false&#x60;. If this property has been set to &#x60;true&#x60; the result will include the corresponding number of incidents for each occurred incident type. If it is set to &#x60;false&#x60;, the incidents will not be included in the result. Cannot be used in combination with &#x60;incidentsForType&#x60;.
   * @param incidentsForType If this property has been set with any incident type (i.e., a string value) the result will only include the number of incidents for the assigned incident type. Cannot be used in combination with &#x60;incidents&#x60;. See the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/incidents/#incident-types) for a list of incident types.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getActivityStatistics(
    id: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<Array<CamundaActivityStatisticsResult>>;
  public getActivityStatistics(
    id: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<Array<CamundaActivityStatisticsResult>>>;
  public getActivityStatistics(
    id: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<Array<CamundaActivityStatisticsResult>>>;
  public getActivityStatistics(
    id: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getActivityStatistics.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let queryParameters = new HttpParams();
    if (failedJobs !== undefined && failedJobs !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>failedJobs, 'failedJobs');
    }
    if (incidents !== undefined && incidents !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidents, 'incidents');
    }
    if (incidentsForType !== undefined && incidentsForType !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentsForType, 'incidentsForType');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<Array<CamundaActivityStatisticsResult>>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/statistics`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Activity Instance Statistics
   * Retrieves runtime statistics of the latest version of the given process definition which belongs to no tenant, grouped by activities. These statistics include the number of running activity instances, optionally the number of failed jobs and also optionally the number of incidents either grouped by incident types or for a specific incident type. **Note**: This does not include historic data.
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param failedJobs Whether to include the number of failed jobs in the result or not. Valid values are &#x60;true&#x60; or &#x60;false&#x60;.
   * @param incidents Valid values for this property are &#x60;true&#x60; or &#x60;false&#x60;. If this property has been set to &#x60;true&#x60; the result will include the corresponding number of incidents for each occurred incident type. If it is set to &#x60;false&#x60;, the incidents will not be included in the result. Cannot be used in combination with &#x60;incidentsForType&#x60;.
   * @param incidentsForType If this property has been set with any incident type (i.e., a string value) the result will only include the number of incidents for the assigned incident type. Cannot be used in combination with &#x60;incidents&#x60;. See the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/incidents/#incident-types) for a list of incident types.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getActivityStatisticsByProcessDefinitionKey(
    key: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<Array<CamundaActivityStatisticsResult>>;
  public getActivityStatisticsByProcessDefinitionKey(
    key: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<Array<CamundaActivityStatisticsResult>>>;
  public getActivityStatisticsByProcessDefinitionKey(
    key: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<Array<CamundaActivityStatisticsResult>>>;
  public getActivityStatisticsByProcessDefinitionKey(
    key: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getActivityStatisticsByProcessDefinitionKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let queryParameters = new HttpParams();
    if (failedJobs !== undefined && failedJobs !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>failedJobs, 'failedJobs');
    }
    if (incidents !== undefined && incidents !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidents, 'incidents');
    }
    if (incidentsForType !== undefined && incidentsForType !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentsForType, 'incidentsForType');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<Array<CamundaActivityStatisticsResult>>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/statistics`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Activity Instance Statistics
   * Retrieves runtime statistics of the latest version of the given process definition for a tenant, grouped by activities. These statistics include the number of running activity instances, optionally the number of failed jobs and also optionally the number of incidents either grouped by incident types or for a specific incident type. **Note**: This does not include historic data.
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param failedJobs Whether to include the number of failed jobs in the result or not. Valid values are &#x60;true&#x60; or &#x60;false&#x60;.
   * @param incidents Valid values for this property are &#x60;true&#x60; or &#x60;false&#x60;. If this property has been set to &#x60;true&#x60; the result will include the corresponding number of incidents for each occurred incident type. If it is set to &#x60;false&#x60;, the incidents will not be included in the result. Cannot be used in combination with &#x60;incidentsForType&#x60;.
   * @param incidentsForType If this property has been set with any incident type (i.e., a string value) the result will only include the number of incidents for the assigned incident type. Cannot be used in combination with &#x60;incidents&#x60;. See the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/incidents/#incident-types) for a list of incident types.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getActivityStatisticsByProcessDefinitionKeyAndTenantId(
    key: string,
    tenantId: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<Array<CamundaActivityStatisticsResult>>;
  public getActivityStatisticsByProcessDefinitionKeyAndTenantId(
    key: string,
    tenantId: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<Array<CamundaActivityStatisticsResult>>>;
  public getActivityStatisticsByProcessDefinitionKeyAndTenantId(
    key: string,
    tenantId: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<Array<CamundaActivityStatisticsResult>>>;
  public getActivityStatisticsByProcessDefinitionKeyAndTenantId(
    key: string,
    tenantId: string,
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getActivityStatisticsByProcessDefinitionKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling getActivityStatisticsByProcessDefinitionKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let queryParameters = new HttpParams();
    if (failedJobs !== undefined && failedJobs !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>failedJobs, 'failedJobs');
    }
    if (incidents !== undefined && incidents !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidents, 'incidents');
    }
    if (incidentsForType !== undefined && incidentsForType !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentsForType, 'incidentsForType');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<Array<CamundaActivityStatisticsResult>>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/statistics`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Deployed Start Form
   * Retrieves the deployed form that can be referenced from a start event. For further information please refer to [User Guide](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#embedded-task-forms).
   * @param id The id of the process definition to get the deployed start form for.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeployedStartForm(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<Blob>;
  public getDeployedStartForm(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getDeployedStartForm(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getDeployedStartForm(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getDeployedStartForm.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(
      `${this.endpoint}/${encodeURIComponent(String(id))}/deployed-start-form`,
      {
        responseType: 'blob',
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Deployed Start Form
   * Retrieves the deployed form that can be referenced from a start event. For further information please refer to [User Guide](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#embedded-task-forms).
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeployedStartFormByKey(
    key: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<Blob>;
  public getDeployedStartFormByKey(
    key: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getDeployedStartFormByKey(
    key: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getDeployedStartFormByKey(
    key: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getDeployedStartFormByKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/deployed-start-form`,
      {
        responseType: 'blob',
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Deployed Start Form
   * Retrieves the deployed form that can be referenced from a start event. For further information please refer to [User Guide](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#embedded-task-forms).
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param tenantId The id of the tenant the process definitions belong to.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeployedStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<Blob>;
  public getDeployedStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getDeployedStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getDeployedStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getDeployedStartFormByKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling getDeployedStartFormByKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/deployed-start-form`,
      {
        responseType: 'blob',

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get
   * Retrieves the latest version of the process definition for tenant according to the &#x60;ProcessDefinition&#x60; interface in the engine.
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLatestProcessDefinitionByTenantId(
    key: string,
    tenantId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessDefinition>;
  public getLatestProcessDefinitionByTenantId(
    key: string,
    tenantId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessDefinition>>;
  public getLatestProcessDefinitionByTenantId(
    key: string,
    tenantId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessDefinition>>;
  public getLatestProcessDefinitionByTenantId(
    key: string,
    tenantId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getLatestProcessDefinitionByTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling getLatestProcessDefinitionByTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaProcessDefinition>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}`,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get
   * Retrieves a process definition according to the &#x60;ProcessDefinition&#x60; interface in the engine.
   * @param id The id of the process definition to be retrieved.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinition(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessDefinition>;
  public getProcessDefinition(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessDefinition>>;
  public getProcessDefinition(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessDefinition>>;
  public getProcessDefinition(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProcessDefinition.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaProcessDefinition>(
      `${this.endpoint}/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get XML
   * Retrieves the BPMN 2.0 XML of a process definition.
   * @param id The id of the process definition.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinitionBpmn20Xml(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessDefinitionDiagram>;
  public getProcessDefinitionBpmn20Xml(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessDefinitionDiagram>>;
  public getProcessDefinitionBpmn20Xml(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessDefinitionDiagram>>;
  public getProcessDefinitionBpmn20Xml(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProcessDefinitionBpmn20Xml.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaProcessDefinitionDiagram>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/xml`,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get XML
   * Retrieves latest version the BPMN 2.0 XML of a process definition.
   * @param key The key of the process definition (the latest version thereof) whose XML should be retrieved.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinitionBpmn20XmlByKey(
    key: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessDefinitionDiagram>;
  public getProcessDefinitionBpmn20XmlByKey(
    key: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessDefinitionDiagram>>;
  public getProcessDefinitionBpmn20XmlByKey(
    key: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessDefinitionDiagram>>;
  public getProcessDefinitionBpmn20XmlByKey(
    key: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getProcessDefinitionBpmn20XmlByKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaProcessDefinitionDiagram>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/xml`,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get XML
   * Retrieves latest version the BPMN 2.0 XML of a process definition. Returns the XML for the latest version of the process definition for tenant.
   * @param key The key of the process definition (the latest version thereof) whose XML should be retrieved.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinitionBpmn20XmlByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessDefinitionDiagram>;
  public getProcessDefinitionBpmn20XmlByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessDefinitionDiagram>>;
  public getProcessDefinitionBpmn20XmlByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessDefinitionDiagram>>;
  public getProcessDefinitionBpmn20XmlByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getProcessDefinitionBpmn20XmlByKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling getProcessDefinitionBpmn20XmlByKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaProcessDefinitionDiagram>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/xml`,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get
   * Retrieves the latest version of the process definition which belongs to no tenant according to the &#x60;ProcessDefinition&#x60; interface in the engine.
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinitionByKey(
    key: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessDefinition>;
  public getProcessDefinitionByKey(
    key: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessDefinition>>;
  public getProcessDefinitionByKey(
    key: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessDefinition>>;
  public getProcessDefinitionByKey(
    key: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getProcessDefinitionByKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaProcessDefinition>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}`,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Diagram
   * Retrieves the diagram of a process definition.  If the process definition\&#39;s deployment contains an image resource with the same file name as the process definition, the deployed image will be returned by the Get Diagram endpoint. Example: &#x60;someProcess.bpmn&#x60; and &#x60;someProcess.png&#x60;. Supported file extentions for the image are: &#x60;svg&#x60;, &#x60;png&#x60;, &#x60;jpg&#x60;, and &#x60;gif&#x60;.
   * @param id The id of the process definition.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinitionDiagram(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<Blob>;
  public getProcessDefinitionDiagram(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getProcessDefinitionDiagram(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getProcessDefinitionDiagram(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProcessDefinitionDiagram.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(`${this.endpoint}/${encodeURIComponent(String(id))}/diagram`, {
      responseType: 'blob',
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Diagram
   * Retrieves the diagram for the latest version of the process definition which belongs to no tenant.  If the process definition\&#39;s deployment contains an image resource with the same file name as the process definition, the deployed image will be returned by the Get Diagram endpoint. Example: &#x60;someProcess.bpmn&#x60; and &#x60;someProcess.png&#x60;. Supported file extentions for the image are: &#x60;svg&#x60;, &#x60;png&#x60;, &#x60;jpg&#x60;, and &#x60;gif&#x60;.
   * @param key The key of the process definition.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinitionDiagramByKey(
    key: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<Blob>;
  public getProcessDefinitionDiagramByKey(
    key: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getProcessDefinitionDiagramByKey(
    key: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getProcessDefinitionDiagramByKey(
    key: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getProcessDefinitionDiagramByKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(`${this.endpoint}/key/${encodeURIComponent(String(key))}/diagram`, {
      responseType: 'blob',

      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Diagram
   * Retrieves the diagram for the latest version of the process definition for tenant.  If the process definition\&#39;s deployment contains an image resource with the same file name as the process definition, the deployed image will be returned by the Get Diagram endpoint. Example: &#x60;someProcess.bpmn&#x60; and &#x60;someProcess.png&#x60;. Supported file extentions for the image are: &#x60;svg&#x60;, &#x60;png&#x60;, &#x60;jpg&#x60;, and &#x60;gif&#x60;.
   * @param key The key of the process definition.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinitionDiagramByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<Blob>;
  public getProcessDefinitionDiagramByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getProcessDefinitionDiagramByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getProcessDefinitionDiagramByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/octet-stream' | '*/*' | 'application/json';
      headerContentType?: 'application/octet-stream' | '*/*' | 'application/json';
    }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getProcessDefinitionDiagramByKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling getProcessDefinitionDiagramByKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/diagram`,
      {
        responseType: 'blob',

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Process Instance Statistics
   * Retrieves runtime statistics of the process engine, grouped by process definitions. These statistics include the number of running process instances, optionally the number of failed jobs and also optionally the number of incidents either grouped by incident types or for a specific incident type. **Note**: This does not include historic data.
   * @param failedJobs Whether to include the number of failed jobs in the result or not. Valid values are &#x60;true&#x60; or &#x60;false&#x60;.
   * @param incidents Valid values for this property are &#x60;true&#x60; or &#x60;false&#x60;. If this property has been set to &#x60;true&#x60; the result will include the corresponding number of incidents for each occurred incident type. If it is set to &#x60;false&#x60;, the incidents will not be included in the result. Cannot be used in combination with &#x60;incidentsForType&#x60;.
   * @param incidentsForType If this property has been set with any incident type (i.e., a string value) the result will only include the number of incidents for the assigned incident type. Cannot be used in combination with &#x60;incidents&#x60;. See the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/incidents/#incident-types) for a list of incident types.
   * @param rootIncidents Valid values for this property are &#x60;true&#x60; or &#x60;false&#x60;. If this property has been set to &#x60;true&#x60; the result will include the corresponding number of root incidents for each occurred incident type. If it is set to &#x60;false&#x60;, the incidents will not be included in the result. Cannot be used in combination with &#x60;incidentsForType&#x60; or &#x60;incidents&#x60;.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinitionStatistics(
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    rootIncidents?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<Array<CamundaProcessDefinitionStatisticsResult>>;
  public getProcessDefinitionStatistics(
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    rootIncidents?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<Array<CamundaProcessDefinitionStatisticsResult>>>;
  public getProcessDefinitionStatistics(
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    rootIncidents?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<Array<CamundaProcessDefinitionStatisticsResult>>>;
  public getProcessDefinitionStatistics(
    failedJobs?: boolean,
    incidents?: boolean,
    incidentsForType?: string,
    rootIncidents?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams();
    if (failedJobs !== undefined && failedJobs !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>failedJobs, 'failedJobs');
    }
    if (incidents !== undefined && incidents !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidents, 'incidents');
    }
    if (incidentsForType !== undefined && incidentsForType !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentsForType, 'incidentsForType');
    }
    if (rootIncidents !== undefined && rootIncidents !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>rootIncidents, 'rootIncidents');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<Array<CamundaProcessDefinitionStatisticsResult>>(
      `${this.endpoint}/statistics`,
      {
        params: queryParameters,
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get List
   * Queries for process definitions that fulfill given parameters. Parameters may be the properties of  process definitions, such as the name, key or version. The size of the result set can be retrieved by using the [Get Definition Count](https://docs.camunda.org/manual/7.14/reference/rest/get-query-count/) method.
   * @param processDefinitionId Filter by process definition id.
   * @param processDefinitionIdIn Filter by a comma-separated list of process definition ids.
   * @param name Filter by process definition name.
   * @param nameLike Filter by process definition names that the parameter is a substring of.
   * @param deploymentId Filter by the deployment the id belongs to.
   * @param deployedAfter Filter by the deploy time of the deployment the process definition belongs to. Only selects process definitions that have been deployed after (exclusive) a specific time. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.546+0200&#x60;.
   * @param deployedAt Filter by the deploy time of the deployment the process definition belongs to. Only selects process definitions that have been deployed at a specific time (exact match). By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.546+0200&#x60;.
   * @param key Filter by process definition key, i.e., the id in the BPMN 2.0 XML. Exact match.
   * @param keysIn Filter by a comma-separated list of process definition keys.
   * @param keyLike Filter by process definition keys that the parameter is a substring of.
   * @param category Filter by process definition category. Exact match.
   * @param categoryLike Filter by process definition categories that the parameter is a substring of.
   * @param version Filter by process definition version.
   * @param latestVersion Only include those process definitions that are latest versions. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param resourceName Filter by the name of the process definition resource. Exact match.
   * @param resourceNameLike Filter by names of those process definition resources that the parameter is a substring of.
   * @param startableBy Filter by a user name who is allowed to start the process.
   * @param active Only include active process definitions. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param suspended Only include suspended process definitions. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param incidentId Filter by the incident id.
   * @param incidentType Filter by the incident type. See the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/incidents/#incident-types) for a list of incident types.
   * @param incidentMessage Filter by the incident message. Exact match.
   * @param incidentMessageLike Filter by the incident message that the parameter is a substring of.
   * @param tenantIdIn Filter by a comma-separated list of tenant ids. A process definition must have one of the given tenant ids.
   * @param withoutTenantId Only include process definitions which belong to no tenant. Value may only be true, as false is the default behavior.
   * @param includeProcessDefinitionsWithoutTenantId Include process definitions which belong to no tenant. Can be used in combination with &#x60;tenantIdIn&#x60;. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param versionTag Filter by the version tag.
   * @param versionTagLike Filter by the version tag that the parameter is a substring of.
   * @param withoutVersionTag Only include process definitions without a &#x60;versionTag&#x60;.
   * @param startableInTasklist Filter by process definitions which are startable in Tasklist..
   * @param notStartableInTasklist Filter by process definitions which are not startable in Tasklist.
   * @param startablePermissionCheck Filter by process definitions which the user is allowed to start in Tasklist. If the user doesn\&#39;t have these permissions the result will be empty list. The permissions are: * &#x60;CREATE&#x60; permission for all Process instances * &#x60;CREATE_INSTANCE&#x60; and &#x60;READ&#x60; permission on Process definition level
   * @param sortBy Sort the results lexicographically by a given criterion. Must be used in conjunction with the sortOrder parameter.
   * @param sortOrder Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
   * @param firstResult Pagination of results. Specifies the index of the first result to return.
   * @param maxResults Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinitions(
    processDefinitionId?: string,
    processDefinitionIdIn?: string,
    name?: string,
    nameLike?: string,
    deploymentId?: string,
    deployedAfter?: string,
    deployedAt?: string,
    key?: string,
    keysIn?: string,
    keyLike?: string,
    category?: string,
    categoryLike?: string,
    version?: number,
    latestVersion?: boolean,
    resourceName?: string,
    resourceNameLike?: string,
    startableBy?: string,
    active?: boolean,
    suspended?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    includeProcessDefinitionsWithoutTenantId?: boolean,
    versionTag?: string,
    versionTagLike?: string,
    withoutVersionTag?: boolean,
    startableInTasklist?: boolean,
    notStartableInTasklist?: boolean,
    startablePermissionCheck?: boolean,
    sortBy?:
      | 'category'
      | 'key'
      | 'id'
      | 'name'
      | 'version'
      | 'deploymentId'
      | 'deployTime'
      | 'tenantId '
      | 'versionTag',
    sortOrder?: 'asc' | 'desc',
    firstResult?: number,
    maxResults?: number,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<Array<CamundaProcessDefinition>>;
  public getProcessDefinitions(
    processDefinitionId?: string,
    processDefinitionIdIn?: string,
    name?: string,
    nameLike?: string,
    deploymentId?: string,
    deployedAfter?: string,
    deployedAt?: string,
    key?: string,
    keysIn?: string,
    keyLike?: string,
    category?: string,
    categoryLike?: string,
    version?: number,
    latestVersion?: boolean,
    resourceName?: string,
    resourceNameLike?: string,
    startableBy?: string,
    active?: boolean,
    suspended?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    includeProcessDefinitionsWithoutTenantId?: boolean,
    versionTag?: string,
    versionTagLike?: string,
    withoutVersionTag?: boolean,
    startableInTasklist?: boolean,
    notStartableInTasklist?: boolean,
    startablePermissionCheck?: boolean,
    sortBy?:
      | 'category'
      | 'key'
      | 'id'
      | 'name'
      | 'version'
      | 'deploymentId'
      | 'deployTime'
      | 'tenantId '
      | 'versionTag',
    sortOrder?: 'asc' | 'desc',
    firstResult?: number,
    maxResults?: number,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<Array<CamundaProcessDefinition>>>;
  public getProcessDefinitions(
    processDefinitionId?: string,
    processDefinitionIdIn?: string,
    name?: string,
    nameLike?: string,
    deploymentId?: string,
    deployedAfter?: string,
    deployedAt?: string,
    key?: string,
    keysIn?: string,
    keyLike?: string,
    category?: string,
    categoryLike?: string,
    version?: number,
    latestVersion?: boolean,
    resourceName?: string,
    resourceNameLike?: string,
    startableBy?: string,
    active?: boolean,
    suspended?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    includeProcessDefinitionsWithoutTenantId?: boolean,
    versionTag?: string,
    versionTagLike?: string,
    withoutVersionTag?: boolean,
    startableInTasklist?: boolean,
    notStartableInTasklist?: boolean,
    startablePermissionCheck?: boolean,
    sortBy?:
      | 'category'
      | 'key'
      | 'id'
      | 'name'
      | 'version'
      | 'deploymentId'
      | 'deployTime'
      | 'tenantId '
      | 'versionTag',
    sortOrder?: 'asc' | 'desc',
    firstResult?: number,
    maxResults?: number,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<Array<CamundaProcessDefinition>>>;
  public getProcessDefinitions(
    processDefinitionId?: string,
    processDefinitionIdIn?: string,
    name?: string,
    nameLike?: string,
    deploymentId?: string,
    deployedAfter?: string,
    deployedAt?: string,
    key?: string,
    keysIn?: string,
    keyLike?: string,
    category?: string,
    categoryLike?: string,
    version?: number,
    latestVersion?: boolean,
    resourceName?: string,
    resourceNameLike?: string,
    startableBy?: string,
    active?: boolean,
    suspended?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    includeProcessDefinitionsWithoutTenantId?: boolean,
    versionTag?: string,
    versionTagLike?: string,
    withoutVersionTag?: boolean,
    startableInTasklist?: boolean,
    notStartableInTasklist?: boolean,
    startablePermissionCheck?: boolean,
    sortBy?:
      | 'category'
      | 'key'
      | 'id'
      | 'name'
      | 'version'
      | 'deploymentId'
      | 'deployTime'
      | 'tenantId '
      | 'versionTag',
    sortOrder?: 'asc' | 'desc',
    firstResult?: number,
    maxResults?: number,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams();
    if (processDefinitionId !== undefined && processDefinitionId !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionId,
        'processDefinitionId'
      );
    }
    if (processDefinitionIdIn !== undefined && processDefinitionIdIn !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionIdIn,
        'processDefinitionIdIn'
      );
    }
    if (name !== undefined && name !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>name, 'name');
    }
    if (nameLike !== undefined && nameLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>nameLike, 'nameLike');
    }
    if (deploymentId !== undefined && deploymentId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deploymentId, 'deploymentId');
    }
    if (deployedAfter !== undefined && deployedAfter !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deployedAfter, 'deployedAfter');
    }
    if (deployedAt !== undefined && deployedAt !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deployedAt, 'deployedAt');
    }
    if (key !== undefined && key !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>key, 'key');
    }
    if (keysIn !== undefined && keysIn !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>keysIn, 'keysIn');
    }
    if (keyLike !== undefined && keyLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>keyLike, 'keyLike');
    }
    if (category !== undefined && category !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>category, 'category');
    }
    if (categoryLike !== undefined && categoryLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>categoryLike, 'categoryLike');
    }
    if (version !== undefined && version !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>version, 'version');
    }
    if (latestVersion !== undefined && latestVersion !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>latestVersion, 'latestVersion');
    }
    if (resourceName !== undefined && resourceName !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>resourceName, 'resourceName');
    }
    if (resourceNameLike !== undefined && resourceNameLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>resourceNameLike, 'resourceNameLike');
    }
    if (startableBy !== undefined && startableBy !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>startableBy, 'startableBy');
    }
    if (active !== undefined && active !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>active, 'active');
    }
    if (suspended !== undefined && suspended !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>suspended, 'suspended');
    }
    if (incidentId !== undefined && incidentId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentId, 'incidentId');
    }
    if (incidentType !== undefined && incidentType !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentType, 'incidentType');
    }
    if (incidentMessage !== undefined && incidentMessage !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentMessage, 'incidentMessage');
    }
    if (incidentMessageLike !== undefined && incidentMessageLike !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>incidentMessageLike,
        'incidentMessageLike'
      );
    }
    if (tenantIdIn !== undefined && tenantIdIn !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>tenantIdIn, 'tenantIdIn');
    }
    if (withoutTenantId !== undefined && withoutTenantId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>withoutTenantId, 'withoutTenantId');
    }
    if (
      includeProcessDefinitionsWithoutTenantId !== undefined &&
      includeProcessDefinitionsWithoutTenantId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>includeProcessDefinitionsWithoutTenantId,
        'includeProcessDefinitionsWithoutTenantId'
      );
    }
    if (versionTag !== undefined && versionTag !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>versionTag, 'versionTag');
    }
    if (versionTagLike !== undefined && versionTagLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>versionTagLike, 'versionTagLike');
    }
    if (withoutVersionTag !== undefined && withoutVersionTag !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>withoutVersionTag,
        'withoutVersionTag'
      );
    }
    if (startableInTasklist !== undefined && startableInTasklist !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>startableInTasklist,
        'startableInTasklist'
      );
    }
    if (notStartableInTasklist !== undefined && notStartableInTasklist !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>notStartableInTasklist,
        'notStartableInTasklist'
      );
    }
    if (startablePermissionCheck !== undefined && startablePermissionCheck !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>startablePermissionCheck,
        'startablePermissionCheck'
      );
    }
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>sortBy, 'sortBy');
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>sortOrder, 'sortOrder');
    }
    if (firstResult !== undefined && firstResult !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>firstResult, 'firstResult');
    }
    if (maxResults !== undefined && maxResults !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>maxResults, 'maxResults');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<Array<CamundaProcessDefinition>>(
      `${this.endpoint}/process-definition`,
      {
        params: queryParameters,
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get List Count
   * Requests the number of process definitions that fulfill the query criteria. Takes the same filtering parameters as the [Get Definitions](https://docs.camunda.org/manual/7.14/reference/rest/get-query/) method.
   * @param processDefinitionId Filter by process definition id.
   * @param processDefinitionIdIn Filter by a comma-separated list of process definition ids.
   * @param name Filter by process definition name.
   * @param nameLike Filter by process definition names that the parameter is a substring of.
   * @param deploymentId Filter by the deployment the id belongs to.
   * @param deployedAfter Filter by the deploy time of the deployment the process definition belongs to. Only selects process definitions that have been deployed after (exclusive) a specific time. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.546+0200&#x60;.
   * @param deployedAt Filter by the deploy time of the deployment the process definition belongs to. Only selects process definitions that have been deployed at a specific time (exact match). By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.546+0200&#x60;.
   * @param key Filter by process definition key, i.e., the id in the BPMN 2.0 XML. Exact match.
   * @param keysIn Filter by a comma-separated list of process definition keys.
   * @param keyLike Filter by process definition keys that the parameter is a substring of.
   * @param category Filter by process definition category. Exact match.
   * @param categoryLike Filter by process definition categories that the parameter is a substring of.
   * @param version Filter by process definition version.
   * @param latestVersion Only include those process definitions that are latest versions. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param resourceName Filter by the name of the process definition resource. Exact match.
   * @param resourceNameLike Filter by names of those process definition resources that the parameter is a substring of.
   * @param startableBy Filter by a user name who is allowed to start the process.
   * @param active Only include active process definitions. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param suspended Only include suspended process definitions. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param incidentId Filter by the incident id.
   * @param incidentType Filter by the incident type. See the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/incidents/#incident-types) for a list of incident types.
   * @param incidentMessage Filter by the incident message. Exact match.
   * @param incidentMessageLike Filter by the incident message that the parameter is a substring of.
   * @param tenantIdIn Filter by a comma-separated list of tenant ids. A process definition must have one of the given tenant ids.
   * @param withoutTenantId Only include process definitions which belong to no tenant. Value may only be true, as false is the default behavior.
   * @param includeProcessDefinitionsWithoutTenantId Include process definitions which belong to no tenant. Can be used in combination with &#x60;tenantIdIn&#x60;. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param versionTag Filter by the version tag.
   * @param versionTagLike Filter by the version tag that the parameter is a substring of.
   * @param withoutVersionTag Only include process definitions without a &#x60;versionTag&#x60;.
   * @param startableInTasklist Filter by process definitions which are startable in Tasklist..
   * @param notStartableInTasklist Filter by process definitions which are not startable in Tasklist.
   * @param startablePermissionCheck Filter by process definitions which the user is allowed to start in Tasklist. If the user doesn\&#39;t have these permissions the result will be empty list. The permissions are: * &#x60;CREATE&#x60; permission for all Process instances * &#x60;CREATE_INSTANCE&#x60; and &#x60;READ&#x60; permission on Process definition level
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessDefinitionsCount(
    processDefinitionId?: string,
    processDefinitionIdIn?: string,
    name?: string,
    nameLike?: string,
    deploymentId?: string,
    deployedAfter?: string,
    deployedAt?: string,
    key?: string,
    keysIn?: string,
    keyLike?: string,
    category?: string,
    categoryLike?: string,
    version?: number,
    latestVersion?: boolean,
    resourceName?: string,
    resourceNameLike?: string,
    startableBy?: string,
    active?: boolean,
    suspended?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    includeProcessDefinitionsWithoutTenantId?: boolean,
    versionTag?: string,
    versionTagLike?: string,
    withoutVersionTag?: boolean,
    startableInTasklist?: boolean,
    notStartableInTasklist?: boolean,
    startablePermissionCheck?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaCountResult>;
  public getProcessDefinitionsCount(
    processDefinitionId?: string,
    processDefinitionIdIn?: string,
    name?: string,
    nameLike?: string,
    deploymentId?: string,
    deployedAfter?: string,
    deployedAt?: string,
    key?: string,
    keysIn?: string,
    keyLike?: string,
    category?: string,
    categoryLike?: string,
    version?: number,
    latestVersion?: boolean,
    resourceName?: string,
    resourceNameLike?: string,
    startableBy?: string,
    active?: boolean,
    suspended?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    includeProcessDefinitionsWithoutTenantId?: boolean,
    versionTag?: string,
    versionTagLike?: string,
    withoutVersionTag?: boolean,
    startableInTasklist?: boolean,
    notStartableInTasklist?: boolean,
    startablePermissionCheck?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaCountResult>>;
  public getProcessDefinitionsCount(
    processDefinitionId?: string,
    processDefinitionIdIn?: string,
    name?: string,
    nameLike?: string,
    deploymentId?: string,
    deployedAfter?: string,
    deployedAt?: string,
    key?: string,
    keysIn?: string,
    keyLike?: string,
    category?: string,
    categoryLike?: string,
    version?: number,
    latestVersion?: boolean,
    resourceName?: string,
    resourceNameLike?: string,
    startableBy?: string,
    active?: boolean,
    suspended?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    includeProcessDefinitionsWithoutTenantId?: boolean,
    versionTag?: string,
    versionTagLike?: string,
    withoutVersionTag?: boolean,
    startableInTasklist?: boolean,
    notStartableInTasklist?: boolean,
    startablePermissionCheck?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaCountResult>>;
  public getProcessDefinitionsCount(
    processDefinitionId?: string,
    processDefinitionIdIn?: string,
    name?: string,
    nameLike?: string,
    deploymentId?: string,
    deployedAfter?: string,
    deployedAt?: string,
    key?: string,
    keysIn?: string,
    keyLike?: string,
    category?: string,
    categoryLike?: string,
    version?: number,
    latestVersion?: boolean,
    resourceName?: string,
    resourceNameLike?: string,
    startableBy?: string,
    active?: boolean,
    suspended?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    includeProcessDefinitionsWithoutTenantId?: boolean,
    versionTag?: string,
    versionTagLike?: string,
    withoutVersionTag?: boolean,
    startableInTasklist?: boolean,
    notStartableInTasklist?: boolean,
    startablePermissionCheck?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams();
    if (processDefinitionId !== undefined && processDefinitionId !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionId,
        'processDefinitionId'
      );
    }
    if (processDefinitionIdIn !== undefined && processDefinitionIdIn !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionIdIn,
        'processDefinitionIdIn'
      );
    }
    if (name !== undefined && name !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>name, 'name');
    }
    if (nameLike !== undefined && nameLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>nameLike, 'nameLike');
    }
    if (deploymentId !== undefined && deploymentId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deploymentId, 'deploymentId');
    }
    if (deployedAfter !== undefined && deployedAfter !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deployedAfter, 'deployedAfter');
    }
    if (deployedAt !== undefined && deployedAt !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deployedAt, 'deployedAt');
    }
    if (key !== undefined && key !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>key, 'key');
    }
    if (keysIn !== undefined && keysIn !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>keysIn, 'keysIn');
    }
    if (keyLike !== undefined && keyLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>keyLike, 'keyLike');
    }
    if (category !== undefined && category !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>category, 'category');
    }
    if (categoryLike !== undefined && categoryLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>categoryLike, 'categoryLike');
    }
    if (version !== undefined && version !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>version, 'version');
    }
    if (latestVersion !== undefined && latestVersion !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>latestVersion, 'latestVersion');
    }
    if (resourceName !== undefined && resourceName !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>resourceName, 'resourceName');
    }
    if (resourceNameLike !== undefined && resourceNameLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>resourceNameLike, 'resourceNameLike');
    }
    if (startableBy !== undefined && startableBy !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>startableBy, 'startableBy');
    }
    if (active !== undefined && active !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>active, 'active');
    }
    if (suspended !== undefined && suspended !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>suspended, 'suspended');
    }
    if (incidentId !== undefined && incidentId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentId, 'incidentId');
    }
    if (incidentType !== undefined && incidentType !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentType, 'incidentType');
    }
    if (incidentMessage !== undefined && incidentMessage !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentMessage, 'incidentMessage');
    }
    if (incidentMessageLike !== undefined && incidentMessageLike !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>incidentMessageLike,
        'incidentMessageLike'
      );
    }
    if (tenantIdIn !== undefined && tenantIdIn !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>tenantIdIn, 'tenantIdIn');
    }
    if (withoutTenantId !== undefined && withoutTenantId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>withoutTenantId, 'withoutTenantId');
    }
    if (
      includeProcessDefinitionsWithoutTenantId !== undefined &&
      includeProcessDefinitionsWithoutTenantId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>includeProcessDefinitionsWithoutTenantId,
        'includeProcessDefinitionsWithoutTenantId'
      );
    }
    if (versionTag !== undefined && versionTag !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>versionTag, 'versionTag');
    }
    if (versionTagLike !== undefined && versionTagLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>versionTagLike, 'versionTagLike');
    }
    if (withoutVersionTag !== undefined && withoutVersionTag !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>withoutVersionTag,
        'withoutVersionTag'
      );
    }
    if (startableInTasklist !== undefined && startableInTasklist !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>startableInTasklist,
        'startableInTasklist'
      );
    }
    if (notStartableInTasklist !== undefined && notStartableInTasklist !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>notStartableInTasklist,
        'notStartableInTasklist'
      );
    }
    if (startablePermissionCheck !== undefined && startablePermissionCheck !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>startablePermissionCheck,
        'startablePermissionCheck'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaCountResult>(`${this.endpoint}/count`, {
      params: queryParameters,
      responseType: <any>responseType,

      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Rendered Start Form
   * Retrieves the rendered form for a process definition. This method can be used to get the HTML rendering of a [Generated Task Form](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#generated-task-forms).
   * @param id The id of the process definition to get the rendered start form for.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRenderedStartForm(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<Blob>;
  public getRenderedStartForm(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getRenderedStartForm(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getRenderedStartForm(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getRenderedStartForm.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(`${this.endpoint}/${encodeURIComponent(String(id))}/rendered-form`, {
      responseType: 'blob',

      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get Rendered Start Form
   * Retrieves  the rendered form for the latest version of the process definition which belongs to no tenant. This method can be used to get the HTML rendering of a [Generated Task Form](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#generated-task-forms).
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRenderedStartFormByKey(
    key: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<Blob>;
  public getRenderedStartFormByKey(
    key: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getRenderedStartFormByKey(
    key: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getRenderedStartFormByKey(
    key: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getRenderedStartFormByKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/rendered-form`,
      {
        responseType: 'blob',

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Rendered Start Form
   * Retrieves  the rendered form for the latest version of the process definition for a tenant. This method can be used to get the HTML rendering of a [Generated Task Form](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#generated-task-forms).
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRenderedStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<Blob>;
  public getRenderedStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getRenderedStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getRenderedStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getRenderedStartFormByKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling getRenderedStartFormByKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/rendered-form`,
      {
        responseType: 'blob',

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Start Form Key
   * Retrieves the key of the start form for a process definition. The form key corresponds to the &#x60;FormData#formKey&#x60; property in the engine.
   * @param id The id of the process definition to get the start form key for.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStartForm(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaForm>;
  public getStartForm(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaForm>>;
  public getStartForm(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaForm>>;
  public getStartForm(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getStartForm.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaForm>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/startForm`,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Start Form Key
   * Retrieves the key of the start form for the latest version of the process definition which belongs to no tenant. The form key corresponds to the &#x60;FormData#formKey&#x60; property in the engine.
   * @param key The key of the process definition (the latest version thereof) for which the form key is to be retrieved.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStartFormByKey(
    key: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaForm>;
  public getStartFormByKey(
    key: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaForm>>;
  public getStartFormByKey(
    key: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaForm>>;
  public getStartFormByKey(
    key: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getStartFormByKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaForm>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/startForm`,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Start Form Key
   * Retrieves the key of the start form for the latest version of the process definition for a tenant. The form key corresponds to the &#x60;FormData#formKey&#x60; property in the engine.
   * @param key The key of the process definition (the latest version thereof) for which the form key is to be retrieved.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaForm>;
  public getStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaForm>>;
  public getStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaForm>>;
  public getStartFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getStartFormByKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling getStartFormByKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaForm>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/startForm`,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Start Form Variables
   * Retrieves the start form variables for a process definition (only if they are defined via the  [Generated Task Form](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#generated-task-forms) approach). The start form variables take form data specified on the start event into account. If form fields are defined, the variable types and default values of the form fields are taken into account.
   * @param id The id of the process definition to retrieve the variables for.
   * @param variableNames A comma-separated list of variable names. Allows restricting the list of requested variables to the variable names in the list. It is best practice to restrict the list of variables to the variables actually required by the form in order to minimize fetching of data. If the query parameter is ommitted all variables are fetched. If the query parameter contains non-existent variable names, the variable names are ignored.
   * @param deserializeValues Determines whether serializable variable values (typically variables that store custom Java objects) should be deserialized on server side (default true).  If set to true, a serializable variable will be deserialized on server side and transformed to JSON using [Jackson\&#39;s](http://jackson.codehaus.org/) POJO/bean property introspection feature. Note that this requires the Java classes of the variable value to be on the REST API\&#39;s classpath.  If set to false, a serializable variable will be returned in its serialized format. For example, a variable that is serialized as XML will be returned as a JSON string containing XML.  **Note**: While true is the default value for reasons of backward compatibility, we recommend setting this parameter to false when developing web applications that are independent of the Java process applications deployed to the engine.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStartFormVariables(
    id: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<{ [key: string]: CamundaVariableValue }>;
  public getStartFormVariables(
    id: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<{ [key: string]: CamundaVariableValue }>>;
  public getStartFormVariables(
    id: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<{ [key: string]: CamundaVariableValue }>>;
  public getStartFormVariables(
    id: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getStartFormVariables.'
      );
    }

    let queryParameters = new HttpParams();
    if (variableNames !== undefined && variableNames !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>variableNames, 'variableNames');
    }
    if (deserializeValues !== undefined && deserializeValues !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>deserializeValues,
        'deserializeValues'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<{ [key: string]: CamundaVariableValue }>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/form-variables`,
      {
        params: queryParameters,
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Start Form Variables
   * Retrieves the start form variables for the latest process definition which belongs to no tenant (only if they are defined via the  [Generated Task Form](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#generated-task-forms) approach). The start form variables take form data specified on the start event into account. If form fields are defined, the variable types and default values of the form fields are taken into account.
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param variableNames A comma-separated list of variable names. Allows restricting the list of requested variables to the variable names in the list. It is best practice to restrict the list of variables to the variables actually required by the form in order to minimize fetching of data. If the query parameter is ommitted all variables are fetched. If the query parameter contains non-existent variable names, the variable names are ignored.
   * @param deserializeValues Determines whether serializable variable values (typically variables that store custom Java objects) should be deserialized on server side (default true).  If set to true, a serializable variable will be deserialized on server side and transformed to JSON using [Jackson\&#39;s](http://jackson.codehaus.org/) POJO/bean property introspection feature. Note that this requires the Java classes of the variable value to be on the REST API\&#39;s classpath.  If set to false, a serializable variable will be returned in its serialized format. For example, a variable that is serialized as XML will be returned as a JSON string containing XML.  **Note**: While true is the default value for reasons of backward compatibility, we recommend setting this parameter to false when developing web applications that are independent of the Java process applications deployed to the engine.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStartFormVariablesByKey(
    key: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<{ [key: string]: CamundaVariableValue }>;
  public getStartFormVariablesByKey(
    key: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<{ [key: string]: CamundaVariableValue }>>;
  public getStartFormVariablesByKey(
    key: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<{ [key: string]: CamundaVariableValue }>>;
  public getStartFormVariablesByKey(
    key: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getStartFormVariablesByKey.'
      );
    }

    let queryParameters = new HttpParams();
    if (variableNames !== undefined && variableNames !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>variableNames, 'variableNames');
    }
    if (deserializeValues !== undefined && deserializeValues !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>deserializeValues,
        'deserializeValues'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<{ [key: string]: CamundaVariableValue }>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/form-variables`,
      {
        params: queryParameters,
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get Start Form Variables
   * Retrieves the start form variables for the latest process definition for a tenant (only if they are defined via the  [Generated Task Form](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#generated-task-forms) approach). The start form variables take form data specified on the start event into account. If form fields are defined, the variable types and default values of the form fields are taken into account.
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param variableNames A comma-separated list of variable names. Allows restricting the list of requested variables to the variable names in the list. It is best practice to restrict the list of variables to the variables actually required by the form in order to minimize fetching of data. If the query parameter is ommitted all variables are fetched. If the query parameter contains non-existent variable names, the variable names are ignored.
   * @param deserializeValues Determines whether serializable variable values (typically variables that store custom Java objects) should be deserialized on server side (default true).  If set to true, a serializable variable will be deserialized on server side and transformed to JSON using [Jackson\&#39;s](http://jackson.codehaus.org/) POJO/bean property introspection feature. Note that this requires the Java classes of the variable value to be on the REST API\&#39;s classpath.  If set to false, a serializable variable will be returned in its serialized format. For example, a variable that is serialized as XML will be returned as a JSON string containing XML.  **Note**: While true is the default value for reasons of backward compatibility, we recommend setting this parameter to false when developing web applications that are independent of the Java process applications deployed to the engine.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getStartFormVariablesByKeyAndTenantId(
    key: string,
    tenantId: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<{ [key: string]: CamundaVariableValue }>;
  public getStartFormVariablesByKeyAndTenantId(
    key: string,
    tenantId: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<{ [key: string]: CamundaVariableValue }>>;
  public getStartFormVariablesByKeyAndTenantId(
    key: string,
    tenantId: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<{ [key: string]: CamundaVariableValue }>>;
  public getStartFormVariablesByKeyAndTenantId(
    key: string,
    tenantId: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling getStartFormVariablesByKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling getStartFormVariablesByKeyAndTenantId.'
      );
    }

    let queryParameters = new HttpParams();
    if (variableNames !== undefined && variableNames !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>variableNames, 'variableNames');
    }
    if (deserializeValues !== undefined && deserializeValues !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>deserializeValues,
        'deserializeValues'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<{ [key: string]: CamundaVariableValue }>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/form-variables`,
      {
        params: queryParameters,
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Restart Process Instance
   * Restarts process instances that were canceled or terminated synchronously. Can also restart completed process instances. It will create a new instance using the original instance information. To execute the restart asynchronously, use the [Restart Process Instance Async](https://docs.camunda.org/manual/7.14/reference/rest/post-restart-process-instance-async/) method.  For more information about the difference between synchronous and asynchronous execution, please refer to the related section of the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/process-instance-restart/#execution).
   * @param id The id of the process definition of the process instances to restart.
   * @param camundaRestartProcessInstanceDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public restartProcessInstance(
    id: string,
    camundaRestartProcessInstanceDto?: CamundaVariableValue,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public restartProcessInstance(
    id: string,
    camundaRestartProcessInstanceDto?: CamundaVariableValue,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public restartProcessInstance(
    id: string,
    camundaRestartProcessInstanceDto?: CamundaVariableValue,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public restartProcessInstance(
    id: string,
    camundaRestartProcessInstanceDto?: CamundaVariableValue,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling restartProcessInstance.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/restart`,
      camundaRestartProcessInstanceDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Restart Process Instance Async
   * Restarts process instances that were canceled or terminated asynchronously. Can also restart completed process instances. It will create a new instance using the original instance information. To execute the restart asynchronously, use the [Restart Process Instance](https://docs.camunda.org/manual/7.14/reference/rest/post-restart-process-instance-sync/) method.  For more information about the difference between synchronous and asynchronous execution, please refer to the related section of the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/process-instance-restart/#execution).
   * @param id The id of the process definition of the process instances to restart.
   * @param camundaRestartProcessInstanceDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public restartProcessInstanceAsyncOperation(
    id: string,
    camundaRestartProcessInstanceDto?: CamundaVariableValue,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaBatch>;
  public restartProcessInstanceAsyncOperation(
    id: string,
    camundaRestartProcessInstanceDto?: CamundaVariableValue,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaBatch>>;
  public restartProcessInstanceAsyncOperation(
    id: string,
    camundaRestartProcessInstanceDto?: CamundaVariableValue,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaBatch>>;
  public restartProcessInstanceAsyncOperation(
    id: string,
    camundaRestartProcessInstanceDto?: CamundaVariableValue,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling restartProcessInstanceAsyncOperation.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaBatch>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/restart-async`,
      camundaRestartProcessInstanceDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Start Instance
   * Instantiates a given process definition. Process variables and business key may be supplied in the request body.
   * @param id The id of the process definition to be retrieved.
   * @param camundaStartProcessInstance
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public startProcessInstance(
    id: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessInstanceWithVariables>;
  public startProcessInstance(
    id: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessInstanceWithVariables>>;
  public startProcessInstance(
    id: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessInstanceWithVariables>>;
  public startProcessInstance(
    id: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling startProcessInstance.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaProcessInstanceWithVariables>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/start`,
      camundaStartProcessInstance,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Start Instance
   * Instantiates a given process definition, starts the latest version of the process definition which belongs to no tenant. Process variables and business key may be supplied in the request body.
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param camundaStartProcessInstance
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public startProcessInstanceByKey(
    key: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessInstanceWithVariables>;
  public startProcessInstanceByKey(
    key: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessInstanceWithVariables>>;
  public startProcessInstanceByKey(
    key: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessInstanceWithVariables>>;
  public startProcessInstanceByKey(
    key: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling startProcessInstanceByKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaProcessInstanceWithVariables>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/start`,
      camundaStartProcessInstance,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Start Instance
   * Instantiates a given process definition, starts the latest version of the process definition for tenant. Process variables and business key may be supplied in the request body.
   * @param key The key of the process definition (the latest version thereof) to be retrieved.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param camundaStartProcessInstance
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public startProcessInstanceByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessInstanceWithVariables>;
  public startProcessInstanceByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessInstanceWithVariables>>;
  public startProcessInstanceByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessInstanceWithVariables>>;
  public startProcessInstanceByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaStartProcessInstance?: CamundaStartProcessInstance,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling startProcessInstanceByKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling startProcessInstanceByKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaProcessInstanceWithVariables>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/start`,
      camundaStartProcessInstance,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Submit Start Form
   * Starts a process instance using a set of process variables and the business key. If the start event has Form Field Metadata defined, the process engine will perform backend validation for any form fields which have validators defined. See [Documentation on Generated Task Forms](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#generated-task-forms).
   * @param id The id of the process definition to submit the form for.
   * @param camundaStartProcessInstanceFormDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public submitForm(
    id: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessInstance>;
  public submitForm(
    id: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessInstance>>;
  public submitForm(
    id: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessInstance>>;
  public submitForm(
    id: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling submitForm.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaProcessInstance>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/submit-form`,
      camundaStartProcessInstanceFormDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Submit Start Form
   * Starts the latest version of the process definition which belongs to no tenant using a set of process variables and the business key. If the start event has Form Field Metadata defined, the process engine will perform backend validation for any form fields which have validators defined. See [Documentation on Generated Task Forms](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#generated-task-forms).
   * @param key The key of the process definition to submit the form for.
   * @param camundaStartProcessInstanceFormDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public submitFormByKey(
    key: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessInstance>;
  public submitFormByKey(
    key: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessInstance>>;
  public submitFormByKey(
    key: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessInstance>>;
  public submitFormByKey(
    key: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error('Required parameter key was null or undefined when calling submitFormByKey.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaProcessInstance>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/submit-form`,
      camundaStartProcessInstanceFormDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Submit Start Form
   * Starts the latest version of the process definition for a tenant using a set of process variables and the business key. If the start event has Form Field Metadata defined, the process engine will perform backend validation for any form fields which have validators defined. See [Documentation on Generated Task Forms](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#generated-task-forms).
   * @param key The key of the process definition to submit the form for.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param camundaStartProcessInstanceFormDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public submitFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessInstance>;
  public submitFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessInstance>>;
  public submitFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessInstance>>;
  public submitFormByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaStartProcessInstanceFormDto?: CamundaStartProcessInstanceForm,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling submitFormByKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling submitFormByKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaProcessInstance>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/submit-form`,
      camundaStartProcessInstanceFormDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update History Time to Live
   * Updates history time to live for process definition. The field is used within [History cleanup](https://docs.camunda.org/manual/7.14/user-guide/process-engine/history/#history-cleanup).
   * @param id The id of the process definition to change history time to live.
   * @param camundaHistoryTimeToLiveDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateHistoryTimeToLiveByProcessDefinitionId(
    id: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public updateHistoryTimeToLiveByProcessDefinitionId(
    id: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public updateHistoryTimeToLiveByProcessDefinitionId(
    id: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public updateHistoryTimeToLiveByProcessDefinitionId(
    id: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateHistoryTimeToLiveByProcessDefinitionId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/history-time-to-live`,
      camundaHistoryTimeToLiveDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update History Time to Live
   * Updates history time to live for the latest version of the process definition which belongs to no tenant. The field is used within [History cleanup](https://docs.camunda.org/manual/7.14/user-guide/process-engine/history/#history-cleanup).
   * @param key The key of the process definition to change history time to live.
   * @param camundaHistoryTimeToLiveDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateHistoryTimeToLiveByProcessDefinitionKey(
    key: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public updateHistoryTimeToLiveByProcessDefinitionKey(
    key: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public updateHistoryTimeToLiveByProcessDefinitionKey(
    key: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public updateHistoryTimeToLiveByProcessDefinitionKey(
    key: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling updateHistoryTimeToLiveByProcessDefinitionKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/history-time-to-live`,
      camundaHistoryTimeToLiveDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update History Time to Live
   * Updates history time to live for the latest version of the process definition for a tenant. The field is used within [History cleanup](https://docs.camunda.org/manual/7.14/user-guide/process-engine/history/#history-cleanup).
   * @param key The key of the process definition to change history time to live.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param camundaHistoryTimeToLiveDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateHistoryTimeToLiveByProcessDefinitionKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public updateHistoryTimeToLiveByProcessDefinitionKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public updateHistoryTimeToLiveByProcessDefinitionKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public updateHistoryTimeToLiveByProcessDefinitionKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaHistoryTimeToLiveDto?: CamundaHistoryTimeToLive,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling updateHistoryTimeToLiveByProcessDefinitionKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling updateHistoryTimeToLiveByProcessDefinitionKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/history-time-to-live`,
      camundaHistoryTimeToLiveDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Activate/Suspend By Key
   * Activates or suspends process definitions with the given process definition key.
   * @param camundaProcessDefinitionSuspensionStateDto **Note**: Unallowed property is &#x60;processDefinitionId&#x60;.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProcessDefinitionSuspensionState(
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public updateProcessDefinitionSuspensionState(
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public updateProcessDefinitionSuspensionState(
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public updateProcessDefinitionSuspensionState(
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/suspended`,
      camundaProcessDefinitionSuspensionStateDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Activate/Suspend By Id
   * Activates or suspends a given process definition by id.
   * @param id The id of the process definition to activate or suspend.
   * @param camundaProcessDefinitionSuspensionStateDto **Note**: Unallowed properties are &#x60;processDefinitionId&#x60; and &#x60;processDefinitionKey&#x60;.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProcessDefinitionSuspensionStateById(
    id: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public updateProcessDefinitionSuspensionStateById(
    id: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public updateProcessDefinitionSuspensionStateById(
    id: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public updateProcessDefinitionSuspensionStateById(
    id: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateProcessDefinitionSuspensionStateById.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/suspended`,
      camundaProcessDefinitionSuspensionStateDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Activate/Suspend by Id
   * Activates or suspends a given process definition by latest version of process definition key which belongs to no tenant.
   * @param key The key of the process definition (the latest version thereof) to be activated/suspended.
   * @param camundaProcessDefinitionSuspensionStateDto **Note**: Unallowed properties are &#x60;processDefinitionId&#x60; and &#x60;processDefinitionKey&#x60;.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProcessDefinitionSuspensionStateByKey(
    key: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public updateProcessDefinitionSuspensionStateByKey(
    key: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public updateProcessDefinitionSuspensionStateByKey(
    key: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public updateProcessDefinitionSuspensionStateByKey(
    key: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling updateProcessDefinitionSuspensionStateByKey.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/suspended`,
      camundaProcessDefinitionSuspensionStateDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Activate/Suspend by Id
   * Activates or suspends a given process definition by the latest version of the process definition for tenant.
   * @param key The key of the process definition (the latest version thereof) to be activated/suspended.
   * @param tenantId The id of the tenant the process definition belongs to.
   * @param camundaProcessDefinitionSuspensionStateDto **Note**: Unallowed properties are &#x60;processDefinitionId&#x60; and &#x60;processDefinitionKey&#x60;.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProcessDefinitionSuspensionStateByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public updateProcessDefinitionSuspensionStateByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public updateProcessDefinitionSuspensionStateByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public updateProcessDefinitionSuspensionStateByKeyAndTenantId(
    key: string,
    tenantId: string,
    camundaProcessDefinitionSuspensionStateDto?: CamundaProcessDefinitionSuspensionState,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (key === null || key === undefined) {
      throw new Error(
        'Required parameter key was null or undefined when calling updateProcessDefinitionSuspensionStateByKeyAndTenantId.'
      );
    }
    if (tenantId === null || tenantId === undefined) {
      throw new Error(
        'Required parameter tenantId was null or undefined when calling updateProcessDefinitionSuspensionStateByKeyAndTenantId.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/key/${encodeURIComponent(String(key))}/tenant-id/${encodeURIComponent(
        String(tenantId)
      )}/suspended`,
      camundaProcessDefinitionSuspensionStateDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
