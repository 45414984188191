import { Component, OnInit } from '@angular/core';
import { combineLatest, distinct, takeUntil } from 'rxjs';

import { ApplicationStateService } from '@core/services/application-state.service';
import { AuthenticationService } from './../../../core/services/authentication.service';
import { BaseComponent } from '../../base/base-component.component';
import { IosBridgeService } from '@core/ios-bridge/ios-bridge.service';
import { LoadingService } from '@core/services/loading.service';
import { LogService } from '@core/services/log.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  providers: [LoadingService],
})
export class LoginComponent extends BaseComponent implements OnInit {
  isAuthenticated;
  isAuthenticating;
  isLoading = false;

  constructor(
    private applicationStateService: ApplicationStateService,
    private authenticationService: AuthenticationService,
    private iosBridgeService: IosBridgeService,
    private logService: LogService,
    public loadingService: LoadingService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    try {
      this.loadingService.isLoadingObservable().subscribe((value) => {
        this.isLoading = value;
      });
      combineLatest([
        this.authenticationService.isAuthenticating,
        this.authenticationService.isAuthenticated,
      ])
        .pipe(takeUntil(this.destroy$), distinct())
        .subscribe(async ([isAuthenticating, isAuthenticated]) => {
          if (!isAuthenticating && isAuthenticated) {
            await this.loginSucess();
          }
          this.isAuthenticated = isAuthenticated;
          this.isAuthenticating = isAuthenticating;
        });
    } catch (error) {
      this.logService.error(`[LoginComponent]ngOnInit : ${error}`, error);
    }
  }

  async login() {
    this.loadingService.startLoading();
    await this.authenticationService.login();
    this.loadingService.stopLoading();
  }

  async loginSucess() {
    this.loadingService.startLoading();
    await this.applicationStateService.initializeApplication();
    await this.navigateStart();
    this.loadingService.stopLoading();
  }
}
