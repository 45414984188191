import { Component, Input, OnInit } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { DialogSize } from 'common-web-ui';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../base/base-component.component';

@Component({
  selector: 'app-idling',
  templateUrl: './idling.component.html',
  styleUrls: ['./idling.component.less'],
})
export class IdlingComponent extends BaseComponent implements OnInit {
  @Input() public idle: Idle;
  @Input() public isShow: boolean;

  public countdown: number;
  public dialogSize = DialogSize;

  constructor() {
    super();
  }

  ngOnInit() {
    this.idle.onIdleStart.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isShow = true;
    });

    this.idle.onTimeout.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.end();
    });

    this.idle.onTimeoutWarning.pipe(takeUntil(this.destroy$)).subscribe((countdown) => {
      this.countdown = countdown;
    });
  }

  end() {
    this.protectedProcess$.next(async () => {
      await this.navigateExit();
    });
    this.isShow = false;
  }

  continue() {
    this.isShow = false;
  }
}
