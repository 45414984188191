import { AppRoutePaths, EndSessionRoutePaths } from '@core/constants';
import { Component, OnDestroy } from '@angular/core';
import { Subject, combineLatest, defer, lastValueFrom } from 'rxjs';
import { exhaustMap, take } from 'rxjs/operators';

import { AnalyticService } from '@data/services/analytic.service';
import { ApplicationStateService } from '@core/services/application-state.service';
import { CamundaTask } from '@data/models/camunda/camunda-task.model';
import { CamundaVariableValue } from '@data/models/camunda/camunda-variable-value.model';
import { EWorkflowVariable } from '@core/enums/workflow-variable.enum';
import { LoadingService } from '@core/services/loading.service';
import { LogService } from '@core/services/log.service';
import { NavigationService } from '@core/services/navigation.service';
import { Router } from '@angular/router';
import { ServiceInjector } from '@core/services/service-injector.module';
import { StorageService } from '@core/services/storage.service';
import { VisitsService } from '@data/services/visits.service';
import { WorkflowInformation } from '@core/model/checkin-process/workflow-information.model';
import { WorkflowService } from '@data/services/workflow.service';

@Component({ template: '' })
export abstract class BaseComponent implements OnDestroy {
  destroy$ = new Subject<void>();
  protectedProcess$: Subject<() => Promise<any>>;
  private _applicationStateService: ApplicationStateService;
  public _router: Router;
  private _navigationService: NavigationService;
  private _workflowService: WorkflowService;
  private _logService: LogService;
  private _storageService: StorageService;
  private _visitsService: VisitsService;
  private _analyticService: AnalyticService;
  public isRunningProcess = false;
  public _loadingService: LoadingService;

  constructor() {
    this._applicationStateService =
      ServiceInjector.get<ApplicationStateService>(ApplicationStateService);
    this._router = ServiceInjector.get<Router>(Router);
    this._navigationService = ServiceInjector.get<NavigationService>(NavigationService);
    this._workflowService = ServiceInjector.get<WorkflowService>(WorkflowService);
    this._logService = ServiceInjector.get<LogService>(LogService);
    this._storageService = ServiceInjector.get<StorageService>(StorageService);
    this._visitsService = ServiceInjector.get<VisitsService>(VisitsService);
    this._analyticService = ServiceInjector.get<AnalyticService>(AnalyticService);
    this._loadingService = ServiceInjector.get<LoadingService>(LoadingService);
    this.initNavigationClick();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initNavigationClick() {
    this.protectedProcess$ = new Subject();
    this.protectedProcess$
      .asObservable()
      .pipe(
        exhaustMap((functionToCall) =>
          defer(async () => {
            try {
              this.isRunningProcess = true;
              await functionToCall();
            } catch (error) {
              this._logService.error(`[BaseComponent]protectedClick()`, error);
            } finally {
              this.isRunningProcess = false;
            }
          })
        )
      )
      .subscribe();
  }

  protected async navigateNext(workflowInformation?: WorkflowInformation, skipLoader?: boolean) {
    try {
      if (!skipLoader) {
        this._loadingService.startLoading();
      }

      const [camundaProcessInstanceWithVariables, camundaTask] = await lastValueFrom(
        combineLatest([
          this._applicationStateService.camundaProcessInstanceWithVariables$,
          this._applicationStateService.camundaTask$,
        ]).pipe(take(1))
      );

      const nextCamundaTask = await this._navigationService.next(
        camundaProcessInstanceWithVariables,
        camundaTask,
        workflowInformation
      );

      await this.navigateToTask(nextCamundaTask);
    } catch (error) {
      this._logService.error(`[BaseComponent]navigateNext() error`, error);
      await this.navigateError(error);
    }
    this._loadingService.stopLoading();
  }

  protected async navigateBack(workflowInformation?: WorkflowInformation) {
    this._loadingService.startLoading();
    try {
      const [camundaProcessInstanceWithVariables, camundaTask] = await lastValueFrom(
        combineLatest([
          this._applicationStateService.camundaProcessInstanceWithVariables$,
          this._applicationStateService.camundaTask$,
        ]).pipe(take(1))
      );

      const nextCamundaTask = await this._navigationService.back(
        camundaProcessInstanceWithVariables,
        camundaTask,
        workflowInformation
      );

      await this.navigateToTask(nextCamundaTask);
    } catch (error) {
      this._logService.error(`[BaseComponent]navigateBack()`, error);
      await this.navigateError(error);
    }
    this._loadingService.stopLoading();
  }

  protected async navigateExit(workflowInformation?: WorkflowInformation) {
    try {
      const [camundaProcessInstanceWithVariables, camundaTask] = await lastValueFrom(
        combineLatest([
          this._applicationStateService.camundaProcessInstanceWithVariables$,
          this._applicationStateService.camundaTask$,
        ]).pipe(take(1))
      );

      const nextCamundaTask = await this._navigationService.exit(
        camundaProcessInstanceWithVariables,
        camundaTask
      );

      await this.navigateToTask(nextCamundaTask);
    } catch (error) {
      this._logService.error(`[BaseComponent]navigateExit()`, error);
      await this.navigateError(error);
    }
  }

  protected async navigateBypass(workflowInformation?: WorkflowInformation) {
    this._loadingService.startLoading();
    try {
      const [camundaProcessInstanceWithVariables, camundaTask] = await lastValueFrom(
        combineLatest([
          this._applicationStateService.camundaProcessInstanceWithVariables$,
          this._applicationStateService.camundaTask$,
        ]).pipe(take(1))
      );

      const nextCamundaTask = await this._navigationService.bypass(
        camundaProcessInstanceWithVariables,
        camundaTask
      );

      await this.navigateToTask(nextCamundaTask);
    } catch (error) {
      this._logService.error(`[BaseComponent]navigateBypass()`, error);
      await this.navigateError(error);
    }
    this._loadingService.stopLoading();
  }

  protected async navigateRefresh() {
    try {
      const camundaTask = await lastValueFrom(
        this._applicationStateService.camundaTask$.pipe(take(1))
      );

      await this.navigateToTask(camundaTask);
    } catch (error) {
      this._logService.error(`[BaseComponent]navigateRefresh()`, error);
      await this.navigateError(error);
    }
  }

  protected async navigateError(error?: string) {
    return await this.navigateTo(`${AppRoutePaths.Error}`);
  }

  protected async navigateNotFound() {
    return await this.navigateTo(`${AppRoutePaths.NotFound}`);
  }

  protected async navigateEndSessionConfirmation() {
    this._loadingService.stopLoading();
    return await this.navigateTo(
      `${AppRoutePaths.EndSession}/${EndSessionRoutePaths.ClosingSessionConfirmation}`
    );
  }

  protected async navigateEndSession() {
    return await this.navigateTo(
      `${AppRoutePaths.EndSession}/${EndSessionRoutePaths.ClosingSession}`
    );
  }

  protected async navigateStart() {
    return await this.navigateTo(`${AppRoutePaths.StartWorkflow}`);
  }

  protected async navigateToTask(camundaTask: CamundaTask) {
    if (!camundaTask) {
      return this.navigateEndSession();
    }

    const routePath = await this._navigationService.getRoutePath(camundaTask);

    if (!routePath) {
      this.navigateNotFound();
    }
    return await this.navigateTo(`${routePath}`);
  }

  protected async deleteTaskVariables(workflowVariables: EWorkflowVariable[]): Promise<boolean> {
    const camundaTask = await lastValueFrom(
      this._applicationStateService.camundaTask$.pipe(take(1))
    );
    if (!camundaTask) {
      await this.navigateEndSession();
      return false;
    }

    const variables = await this.getTaskVariables();
    if (!variables) {
      return true;
    }
    const variableNames = [];
    for (const workflowVariable of workflowVariables) {
      if (variables[workflowVariable] != null) {
        variableNames.push(workflowVariable);
      }
    }
    if (variableNames.length === 0) {
      return true;
    }

    await this._workflowService.deleteTaskVariables(camundaTask.id, variableNames);
    return true;
  }

  protected async getTaskVariables(): Promise<{ [key: string]: CamundaVariableValue }> {
    const camundaTask = await lastValueFrom(
      this._applicationStateService.camundaTask$.pipe(take(1))
    );

    if (!camundaTask) {
      await this.navigateEndSession();
      return;
    }

    return await this._workflowService.getTaskVariables(camundaTask.id);
  }

  public async navigateTo(routePath: string) {
    return await this._router.navigate([`${routePath}`]);
  }

  async deletePreviousDeviceInstances() {
    try {
      const drk = await lastValueFrom(this._applicationStateService.drk$.pipe(take(1)));
      if (drk) {
        await this._workflowService.deleteProcessInstancesByDrk(drk);
      } else {
        const camundaProcessInstanceId = this._storageService.get('camundaProcessInstanceId');
        if (camundaProcessInstanceId) {
          await this._workflowService.deleteProcessInstance(camundaProcessInstanceId);
        }
      }
    } catch (error) {
      this._logService.error(`[StartWorkflowComponent]deletePreviousDeviceInstances()`, error);
    }
  }

  async deletePreviousVisits() {
    try {
      const drk = await lastValueFrom(this._applicationStateService.drk$.pipe(take(1)));
      if (drk) {
        await this._visitsService.deleteProcessInstancesByDrk(drk);
      }
    } catch (error) {
      this._logService.error(`[StartWorkflowComponent]deletePreviousDeviceInstances()`, error);
    }
  }
}
