import { CamundaVariableValue } from '../../../data/models/camunda/camunda-variable-value.model';
import { CheckinProcessConfiguration } from '@core/model/training-center/checkin-process-configuration.model';
import { DocumentManagementStatus } from '@core/enums/document-management-status.enum';
import { ECamundaVariableValueType } from '@data/interfaces/camunda/camunda-variable-value-type.enum';
import { ExportControl } from '@core/model/entity/export-control.model';
import { FlowType } from '@core/enums/flow-type.enum';
import { IWorkflowInformation } from './workflow-information.interface';
import { RecordingType } from '@core/enums/recording-type.enum';
import { TSAStatus } from '@core/enums/tsa-status.enum';
import { TraineeType } from '@core/enums/trainee-type.enum';
import { VisitStatus } from '@core/enums/visit-status.enum';
import { VisitorType } from '@core/enums/visitor-type.enum';
import { createVariable } from '@core/utils/camunda.util';

export class WorkflowInformation {
  checkinProcessConfiguration?: CheckinProcessConfiguration;
  trainingCenterId?: string;
  deviceId?: string;
  flowType?: FlowType;
  visitorType?: VisitorType;
  traineeType?: TraineeType;
  recordingType?: RecordingType;
  hasConsentedIdentityVerification?: boolean;
  hasAcknowledgeCovidVerification?: boolean;
  identityDocumentType?: string;
  isEmailProvided?: boolean;
  isScanPassportSuccess?: boolean;
  isScanGovernmentIdSuccess?: boolean;
  isScanDocumentSuccess?: boolean;
  isIdentityComparaisonSuccess?: boolean;
  isCheckinSuccess?: boolean;
  isIdPictureSuccess?: boolean;
  isSelfiePictureSuccess?: boolean;
  isPrintBadgeSuccess?: boolean;
  isScanQrCodeValidationSuccess?: boolean;
  exportControl?: ExportControl;
  visitStatus?: VisitStatus;
  trainingId?: string;
  isTrainingPaid?: boolean;
  documentManagementStatus?: DocumentManagementStatus;
  tsaStatus?: TSAStatus;
  skipPrintBadge?: boolean;
  isVisitPrecheckedIn?: boolean;

  constructor(data?: IWorkflowInformation) {
    Object.assign(this, data);
  }

  toCamundaVariables(): { [key: string]: CamundaVariableValue } {
    const result = {};
    for (const [key, value] of Object.entries(this)) {
      if (value === undefined || value === null) {
        continue;
      }
      let type = ECamundaVariableValueType.JSON;
      switch (typeof value) {
        case 'boolean':
          type = ECamundaVariableValueType.BOOLEAN;
          break;
        case 'number':
          type = ECamundaVariableValueType.INTEGER;
          break;
        case 'string':
          type = ECamundaVariableValueType.STRING;
          break;
        case 'object':
          type = ECamundaVariableValueType.JSON;
          break;
        default:
          type = ECamundaVariableValueType.JSON;
          break;
      }
      result[key] = createVariable(value, type);
    }
    return result;
  }
}
