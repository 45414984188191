import { ExportControlCitizenship } from '@core/model/export-control//export-control-citizenship.model';
import { ExportControlCountry } from '@core/model/export-control//export-control-country.model';
import { ExportControlStatus } from '@core/model/export-control/export-control-status.enum';
import { IExportControl } from '@data/interfaces/export-control/export-control.interface';
import moment from 'moment';

export class ExportControl {
  status: ExportControlStatus;
  updatedAt: Date;
  countryOfResidence: ExportControlCountry;
  lastGrantedCitizenship: ExportControlCitizenship;

  constructor(data?: IExportControl) {
    if (!data) {
      return;
    }
    Object.assign(this, data);
    if (data.updatedAt) {
      this.updatedAt = moment(data.updatedAt).toDate();
    }
    if (data.countryOfResidence) {
      this.countryOfResidence = new ExportControlCountry(data.countryOfResidence);
    }
    if (data.lastGrantedCitizenship) {
      this.lastGrantedCitizenship = new ExportControlCitizenship(data.lastGrantedCitizenship);
    }
  }
}
