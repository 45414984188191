import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-footer-actions',
  templateUrl: './footer-actions.component.html',
  styleUrls: ['./footer-actions.component.less'],
})
export class FooterActionsComponent {
  @HostBinding('class') class = 'footer';
}
