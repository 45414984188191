import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { TrainingDateRoutingModule } from './training-date-routing.module';
import { TrainingDateComponent } from './training-date.component';

@NgModule({
  declarations: [TrainingDateComponent],
  imports: [CommonModule, SharedModule, TrainingDateRoutingModule, FormsModule],
})
export class TrainingDateModule {}
