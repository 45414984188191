import { Injector, NgModule } from '@angular/core';

import { AppCommonUIModule } from 'common-web-ui';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CameraDecoratorComponent } from './components/camera-decorator/camera-decorator.component';
import { CameraTimerComponent } from './components/camera-timer/camera-timer.component';
import { ClickProtectionDirective } from './directives/click-protection.directive';
import { CommonModule } from '@angular/common';
import { CountDownComponent } from './components/count-down/count-down.component';
import { FooterActionsComponent } from './components/footer-actions/footer-actions.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { IdlingComponent } from './components/idling/idling.component';
import { LoginComponent } from './components/login/login.component';
import { NgIdleModule } from '@ng-idle/core';
import { ReservationCardComponent } from './components/reservation-card/reservation-card.component';
import { RouterModule } from '@angular/router';
import { SafeHtmlPipe } from '@core/pipes/safe-html.pipe';
import { ScrollDirective } from './directives/scroll.directive';
import { ScrollShadowDirective } from './directives/scroll-shadow.directive';
import { StepperComponent } from './components/stepper/stepper.component';
import { TrainingCardComponent } from './components/training-card/training-card.component';
import { VisitReservationCardComponent } from './components/visit-reservation-card/visit-reservation-card.component';
import { WarningBoxComponent } from './components/warning-box/warning-box.component';
import { CameraIndicatorComponent } from './components/camera-indicator/camera-indicator.component';

const COMPONENTS = [
  CameraDecoratorComponent,
  CameraTimerComponent,
  HeaderComponent,
  FooterActionsComponent,
  ReservationCardComponent,
  VisitReservationCardComponent,
  CountDownComponent,
  StepperComponent,
  WarningBoxComponent,
  IdlingComponent,
  SafeHtmlPipe,
  LoginComponent,
  TrainingCardComponent,
  VisitReservationCardComponent,
  CameraIndicatorComponent
];
const DIRECTIVES = [ScrollShadowDirective, ScrollDirective, ClickProtectionDirective];
const EXTERNAL_LIB = [AppCommonUIModule, AutocompleteLibModule];

export let ServiceInjector: Injector;

@NgModule({
  declarations: [COMPONENTS, ...DIRECTIVES, CameraIndicatorComponent],
  exports: [COMPONENTS, ...DIRECTIVES, ...EXTERNAL_LIB],
  imports: [CommonModule, RouterModule, FormsModule, ...EXTERNAL_LIB, NgIdleModule.forRoot()],
  providers: [],
})
export class SharedModule {}
