import { Injectable } from '@angular/core';
import { ITrainingCenterConfiguration } from '@core/model/training-center/training-center-configuration.interface';
import { TrainingCenterConfiguration } from '@core/model/training-center/training-center-configuration.model';
import { ApiService } from '@data/services/api.service';
import { lastValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TrainingCentersConfigurationsService {
  private endpoint = '/trainingCenterConfigurations';

  constructor(private apiService: ApiService) {}

  public async getByTrainingCenterId(
    trainingCenterId: string
  ): Promise<TrainingCenterConfiguration> {
    if(!trainingCenterId) {
      return null;
    }
    return lastValueFrom(
      this.apiService
        .get<ITrainingCenterConfiguration>(`${this.endpoint}/${trainingCenterId}?isGeminiSite=true`)
        .pipe(
          catchError((e) => {
            if (e.status === 404) {
              return of({
                geminiSiteId: trainingCenterId,
                accessLevels: [],
                turnstiles: [],
              });
            }
            return throwError(() => e);
          }),
          map((response) => {
            return new TrainingCenterConfiguration(response);
          })
        )
    );
  }

  public async getByDevice(
    trainingCenterId: string,
    deviceConfigurationId: string
  ): Promise<TrainingCenterConfiguration> {
    return lastValueFrom(
      this.apiService
        .get<ITrainingCenterConfiguration>(`${this.endpoint}/location/${trainingCenterId}/device/${deviceConfigurationId}`)
        .pipe(
          catchError((e) => {
            if (e.status === 404) {
              return of({
                geminiSiteId: trainingCenterId,
                accessLevels: [],
                turnstiles: [],
              });
            }
            return throwError(() => e);
          }),
          map((response) => {
            return new TrainingCenterConfiguration(response);
          })
        )
    );
  }
}
