import { environment } from '@env';

export function isAppInsightActive() {
  if (environment?.appInsights?.instrumentationKey) {
    return true;
  }
}

export function isHeapActive() {
  if (environment?.heap?.apiKey) {
    return true;
  }
}
