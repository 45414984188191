export enum AnalyticCheckinEventTypes {
  Unknown = '',
  AppClosed = 'AppClosed',
  PopupPageLoaded = 'PopupPageLoaded',
  PopupPageUnloaded = 'PopupPageUnloaded',
  PageLoaded = 'PageLoaded',
  PageUnloaded = 'PageUnloaded',
  ButtonClicked = 'ButtonClicked',
  FacialRecognition = 'FacialRecognition',
  FacialRecognitionConsent = 'FacialRecognitionConsent',
  UserAction = 'UserAction',
  Coronavirus = 'Coronavirus',
  CheckinWarning = 'CheckinWarning',
  CheckedIn = 'CheckedIn',
  BadgeValidity = 'BadgeValidity',
  SubmitCheckin = 'Submit Checkin',
  QrCodeReadingFailure = 'QrCode Reading Failure',
  CheckinDeviceAlive = 'Checkin_Device_Alive',
  Survey = 'Survey',
  DocumentScanStarted = 'DocumentScanStarted',
  DocumentScanSucceeded = 'DocumentScanSucceeded',
  DocumentScanFailed = 'DocumentScanFailed',
  IdentityComparisonFailed = 'IdentityComparisonFailed',
  IdentityComparisonSucceeded = 'IdentityComparisonSucceeded',
}
