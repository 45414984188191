import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { first, lastValueFrom } from 'rxjs';

import { AppRoutePaths } from '@core/constants';
import { ApplicationStateService } from '@core/services/application-state.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceRegistrationGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private applicationStateService: ApplicationStateService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const drk = await lastValueFrom(this.applicationStateService.drk$.pipe(first()));

    if (!drk) {
      await this.router.navigate([`${AppRoutePaths.DeviceRegistration}`]);
      return false;
    }

    return true;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const drk = await lastValueFrom(this.applicationStateService.drk$.pipe(first()));

    if (!drk) {
      await this.router.navigate([`${AppRoutePaths.DeviceRegistration}`]);
      return false;
    }

    return true;
  }
}
