// ****************************************************
// Log Entry Class

import { LogLevel } from '@core/enums/log-level.enum';

// ****************************************************
export class LogEntry {
  // Public Properties
  entryDate: Date = new Date();
  message = '';
  level: LogLevel = LogLevel.Debug;
  extraInfo: any[] = [];
  logWithDate = true;

  // **************
  // Public Methods
  // **************
  buildLogString(): string {
    let value = '';

    if (this.logWithDate) {
      value = `${new Date().toLocaleString()} - `;
    }
    value += `Type: ${LogLevel[this.level]}`;
    value += ` - Message: ${this.message}`;
    if (this.extraInfo.length) {
      value += ` - Extra Info: ${this.formatParams(this.extraInfo)}`;
    }

    return value;
  }

  // ***************
  // Private Methods
  // ***************
  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    // Is there at least one object in the array?
    if (params.some((p) => typeof p === 'object')) {
      ret = '';
      // Build comma-delimited string
      for (const item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }

    return ret;
  }
}
