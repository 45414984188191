import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '@core/services/authentication.service';
import { isHostedInWin } from '@core/win-bridge/win.utils';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router, private ngZone: NgZone, private authService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => {
          return;
        },
        (error) => {
          switch (error.status) {
            case 401: {
              if(isHostedInWin() && this.authService.drk) {
                this.authService.drk = null;
              }
              this.navigateTo(`/login`);
              break;
            }
            case 403: {
              this.navigateTo(`/login`);
              break;
            }
          }
        }
      )
    );
  }

  navigateTo(url: string): void {
    this.ngZone.run(() => {
      this.router.navigate([url]);
    });
  }
}
