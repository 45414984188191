import { ByPassInformation } from '@core/model/bypass-information.model';
import { EVisitRecordType } from './visit-record-type.enum';
import { IVisitRecord } from '@data/interfaces/visits/visit-record.interface';
import moment from 'moment';

export class VisitRecord {
  id: string;
  visitId: string;
  recordType: EVisitRecordType;
  recordDate: Date;
  identityPolicyConsentDate: Date;
  covidAcknowledgementDate: Date;
  bypasses: ByPassInformation[];

  constructor(data?: IVisitRecord) {
    if (data) {
      if (data.recordDate) {
        this.recordDate = moment(data.recordDate).toDate();
      }
      if (data.identityPolicyConsentDate) {
        this.identityPolicyConsentDate = moment(data.identityPolicyConsentDate).toDate();
      }
      if (data.covidAcknowledgementDate) {
        this.covidAcknowledgementDate = moment(data.covidAcknowledgementDate).toDate();
      }

      if (data.bypasses) {
        this.bypasses = [];
        for (const bypasse of data.bypasses) {
          this.bypasses.push(new ByPassInformation(bypasse));
        }
      }
    }
  }
}
