import { ByPassInformation } from '@core/model/bypass-information.model';
import { EVisitRecordType } from '@core/model/visit/visit-record-type.enum';
import { FlowInformation } from '@core/state/flow-information.model';
import { Visit } from '@core/model/visit/visit.model';

export class VisitRecordPost {
  visitId: string;
  recordType: EVisitRecordType;
  recordDate: Date;
  identityPolicyConsentDate: Date;
  covidAcknowledgementDate: Date;
  bypasses: ByPassInformation[];

  constructor(data?: { recordType?: EVisitRecordType; recordDate?: Date }) {
    this.recordDate = new Date();
    if (data) {
      Object.assign(this, data);
    }
  }

  public addVisit(visit: Visit) {
    if (!visit) {
      return this;
    }
    if (visit.identityPolicyConsentDate) {
      this.identityPolicyConsentDate = visit.identityPolicyConsentDate;
    }

    return this;
  }

  public addFlowInformation(flowInformation: FlowInformation) {
    if (!flowInformation) {
      return this;
    }
    if (flowInformation.byPasses) {
      this.bypasses = flowInformation.byPasses;
    }

    if (flowInformation.covidAcknowledgementDate) {
      this.covidAcknowledgementDate = flowInformation.covidAcknowledgementDate;
    }

    return this;
  }
}
