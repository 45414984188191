import { Component, Input, OnInit } from '@angular/core';
import { Reservation } from '@core/model/reservation/reservation.model';
import { AnalyticService } from '@data/services/analytic.service';
import moment from 'moment';

@Component({
  selector: 'app-visit-reservation-card',
  templateUrl: './visit-reservation-card.component.html',
  styleUrls: ['./visit-reservation-card.component.less'],
})
export class VisitReservationCardComponent implements OnInit {
  @Input() public reservations: Reservation[];

  traineeName: string;
  dateRange: string;

  constructor(private analyticService: AnalyticService) {}

  ngOnInit(): void {
    try {
      if (this.reservations) {
        this.traineeName = this.reservations[0].trainee.name;
        this.formatDateRange();
      }
    } catch (error) {
      this.analyticService.trackException(error);
    }
  }

  formatDateRange() {
    const dates = this.reservations.map((reservation) => ({
      startDate: reservation.startDate,
      endDate: reservation.endDate,
    }));
    const minDate = new Date(
      Math.max.apply(
        null,
        dates.map((p) => p.startDate)
      )
    );
    const maxDate = new Date(
      Math.min.apply(
        null,
        dates.map((p) => p.endDate)
      )
    );

    this.dateRange = `${moment(minDate).format('ll')} - ${moment(maxDate).format('ll')}`;
  }
}
