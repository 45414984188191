import { ITrainee } from './trainee.interface';

export class Trainee {
  public name: string;
  public isCabinCrew: boolean;
  constructor(data?: ITrainee) {
    Object.assign(this, data);
  }

  public getFirstName() {
    if (!this.name) {
      return null;
    }
    const split = this.name.split(' ');
    const names = split.shift();
    const lastName = split.join(' ');
    return names;
  }

  public getLastName() {
    if (!this.name) {
      return null;
    }
    const split = this.name.split(' ');
    const names = split.shift();
    const lastName = split.join(' ');
    return lastName;
  }
}
