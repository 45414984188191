export const environment = {
  production: true,
  mock: false,
  buildNumber: '#{buildNumber}#',
  appInsights: {
    instrumentationKey: '2e59eca6-caa7-445f-882c-9b4f8391adcc',
  },
  baseUrl: 'https://kiosk.checkin.devcloud.cae.com',
  azureAd: {
    tenant: '006c1e48-e342-47e9-ab5d-0dd9ff89bd96',
    clientId: '4f44bea3-9851-4215-8753-725a944df04b',
    redirectPath: 'login',
    scopes: ['api://4f44bea3-9851-4215-8753-725a944df04b/Global.All'],
    loginUrl: null,
  },
  launchDarkly: {
    isEnabled: true,
    apiKey: '5cd15d2c6ebc550823318d47',
  },
  store: {
    featureFlagsEnabled: true,
  },
  api: {
    baseUrl: 'https://api.devcloud.cae.com/trainingxpapi/v1',
  },
  heap: {
    apiKey: '3746394984',
  },
  idleTime: 60,
  idleTimeout: 30,
  refreshTimer: '30000',
  byPassCode: 'H3C0T9',
  documentScanner: {
    apidId: 'checkin',
    apiKey: 'checkin',
  },
};
