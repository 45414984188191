import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IdentityComparaisonFailModule } from './identity-comparaison-fail/identity-comparaison-fail.module';
import { IdentityComparisonRoutingModule } from './identity-comparison-routing.module';
import { IdentityComparisonComponent } from './identity-comparison.component';
import { IdentityVerificationStepperComponent } from '@shared/components/identity-verification-stepper/identity-verification-stepper.component';

@NgModule({
  declarations: [IdentityComparisonComponent],
  imports: [CommonModule, IdentityComparisonRoutingModule, IdentityVerificationStepperComponent],
  exports: [IdentityComparaisonFailModule],
})
export class IdentityComparisonModule {}
