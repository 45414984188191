import { StorageKeys, StorageTypes } from '../constants';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public get(key: string, storageType: StorageTypes = StorageTypes.LocalStorage): any {
    let value = '';
    if (storageType === StorageTypes.LocalStorage) {
      value = localStorage.getItem(key);
    } else {
      value = sessionStorage.getItem(key);
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  public set(key: string, value: any, storageType: StorageTypes = StorageTypes.LocalStorage) {
    if (!value) {
      if (storageType === StorageTypes.LocalStorage) {
        localStorage.removeItem(key);
      } else {
        sessionStorage.removeItem(key);
      }
      return;
    }
    if (storageType === StorageTypes.LocalStorage) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  public get TrainingCenterGeminiId() {
    return this.get(StorageKeys.TrainingCenterGeminiId);
  }

  public set TrainingCenterGeminiId(id: number) {
    this.set(StorageKeys.TrainingCenterGeminiId, id);
  }

  public get DRK() {
    return this.get(StorageKeys.DRK);
  }

  public set DRK(token: string) {
    this.set(StorageKeys.DRK, token);
  }

  public get DocumentScannerIpAddress() {
    return this.get(StorageKeys.DocumentScannerIpAddress);
  }

  public set DocumentScannerIpAddress(ipAddress: string) {
    this.set(StorageKeys.DocumentScannerIpAddress, ipAddress);
  }

  public get byPassCode() {
    return this.get(StorageKeys.ByPassCode);
  }

  public set byPassCode(byPassCode: string) {
    this.set(StorageKeys.ByPassCode, byPassCode);
  }
}
