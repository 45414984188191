import { Observable, of } from 'rxjs';

import { AnalyticService } from '@data/services/analytic.service';
import { Injector } from '@angular/core';
import { LogEntry } from './log-entry.model';
import { LogLevel } from '@core/enums/log-level.enum';
import { LogPublisher } from './log-publishers';

// ****************************************************
// Console Logging Class







// ****************************************************
export class LogAnalytic extends LogPublisher {
  private analyticService: AnalyticService;

  constructor(private injector: Injector) {
    super();
    this.analyticService = this.injector.get(AnalyticService);
  }

  log(entry: LogEntry): Observable<boolean> {
    // Log to console

    switch (entry.level) {
      case LogLevel.All:
        break;
      case LogLevel.Debug:
        break;
      case LogLevel.Error:
        this.analyticService.trackException(new Error(entry.buildLogString()), {
          entry,
        });
        break;
      case LogLevel.Fatal:
        this.analyticService.trackException(new Error(entry.buildLogString()), {
          entry,
        });
        break;
      case LogLevel.Info:
        break;
      case LogLevel.Off:
        break;
      case LogLevel.Warn:
        break;
      default:
        break;
    }

    return of();
  }

  clear(): Observable<boolean> {
    console.clear();
    return of();
  }
}
