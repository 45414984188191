import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private running = false;
  private startTime: number;
  private endTime: number;

  get isRunning() {
    return this.running;
  }
  get elapsed(): number {
    if (!this.startTime) {
      return null;
    }
    if (!this.endTime) {
      return  Math.round(performance.now() - this.startTime);
    }
    return Math.round(this.endTime - this.startTime);
  }

  reset() {
    this.startTime = null;
    this.endTime = null;
    this.running = false;
  }

  start() {
    this.startTime = performance.now();
    this.running = true;
  }

  stop() {
    this.endTime = performance.now();
    this.running = false;
  }
}
