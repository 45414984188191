import { Component, OnInit } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';
import moment, { ISO_8601 } from 'moment';

import { ApplicationStateService } from '@core/services/application-state.service';
import { BaseComponent } from '@shared/base/base-component.component';
import { LogService } from '@core/services/log.service';
import { VisitStateService } from '@core/state/visit-state.service';

@Component({
  selector: 'app-training-date',
  templateUrl: './training-date.component.html',
  styleUrls: ['./training-date.component.less'],
})
export class TrainingDateComponent extends BaseComponent implements OnInit {
  public fixStartDate: string;
  public maxDate: string;
  public selectedEndDate: Date;
  public trainingEndDateString: string;

  private dateFormat = 'YYYY-MM-DD';

  constructor(
    public visitStateService: VisitStateService,
    public applicationStateService: ApplicationStateService,
    private logService: LogService
  ) {
    super();
  }

  async ngOnInit() {
    this.fixStartDate = moment().format(this.dateFormat);
    this.maxDate = moment().add(1, 'month').format(this.dateFormat);
    const visitEndDate = await lastValueFrom(this.visitStateService.visitEndDate$.pipe(take(1)));
    if (visitEndDate) {
      this.selectedEndDate = visitEndDate;
      this.trainingEndDateString = moment(visitEndDate, ISO_8601).format(this.dateFormat);
    }
  }

  onSelectDate(datesInterval: any) {
    this.selectedEndDate = moment(datesInterval.endDate, this.dateFormat).toDate();
  }

  async back() {
    this.protectedProcess$.next(async () => {
      await this.navigateBack();
    });
  }

  async next() {
    this.visitStateService.setVisitEndDate(moment(this.selectedEndDate).endOf('day').toDate());
    this.protectedProcess$.next(async () => {
      await this.nextProcess();
    });
  }

  async nextProcess() {
    try {
      await this.navigateNext();
    } catch (error) {
      this.logService.error(`[CoronaVirusQuestionComponent]nextProcess()`, error);
    }
  }
}
