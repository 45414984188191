export class ApiError {
  code: string;
  status: number;
  message: string;

  constructor(status, code?, message?) {
    this.status = status;
    this.code = code;
    this.message = message;
  }
}
