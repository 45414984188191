import { FlowType } from '@core/enums/flow-type.enum';
import { EIdentityDocumentType } from '@core/enums/identity-document-type.enum';
import { RecordingType } from '@core/enums/recording-type.enum';
import { BadgeValidity } from '@core/model/badge/badge-validity.model';
import { ByPassInformation } from '@core/model/bypass-information.model';
import { Customer } from '@core/model/customer/customer.model';
import { Aircraft } from '@core/model/entity/aircraft.model';
import { FacesCompareResult } from '@core/model/entity/FacesCompareResult';
import { Reservation } from '@core/model/reservation/reservation.model';
import { Training } from '@core/model/training/training.model';
import { CamundaProcessInstanceWithVariables } from '@data/models/camunda/camunda-process-instance-with-variables.model';
import { CamundaTaskCustomProperties } from '@data/models/camunda/camunda-task-custom-properties.model';
import { CamundaTask } from '@data/models/camunda/camunda-task.model';

export class FlowInformation {
  flowType: FlowType;
  facialComparisonResult: FacesCompareResult;
  badgeValidity: BadgeValidity;
  badgePrinted: boolean;
  badgePrintRequest: boolean;
  recordingType: RecordingType;
  byPasses: ByPassInformation[];
  camundaTask: CamundaTask;
  camundaProcessInstanceWithVariables: CamundaProcessInstanceWithVariables;
  camundaTaskCustomPropertiesMap: Map<string, CamundaTaskCustomProperties>;
  aircraft: Aircraft;
  customer: Customer;
  training: Training;
  reservations: Reservation[];
  covidAcknowledgementDate: Date;
  identityDocumentType: EIdentityDocumentType;
  backButtonDeactivated: boolean;

  constructor(data?: FlowInformation) {
    this.badgePrintRequest = undefined;
    this.badgePrinted = false;
    this.backButtonDeactivated = false;
    Object.assign(this, data);
  }
}
