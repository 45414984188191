import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { AuthenticationService } from './../services/authentication.service';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('Authorization')) {
      return next.handle(req);
    }

    return from(this.authenticationService.getAuthorizationHeader()).pipe(
      switchMap((header: string) => {
        if (header) {
          return next.handle(
            req.clone({
              headers: req.headers.set('Authorization', header),
            })
          );
        }
      })
    );
  }
}
