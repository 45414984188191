import {
  AfterContentInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appScrollShadow]'
})
export class ScrollShadowDirective implements AfterContentInit {
  @Input() public bottomOffset = 10;
  @Input() public topOffset = 10;

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {}

  // handle host scroll
  @HostListener('scroll', ['$event']) public scrolled($event: Event) {
    this.elementScrollEvent($event);
  }

  ngAfterContentInit(): void {
    const { isReachingTop, isReachingBottom } = this.getParameters();

    this.manageClasses(isReachingTop, isReachingBottom);
  }

  private getParameters() {
    const scrollPosition =
      this.hostElement.nativeElement.scrollHeight - this.hostElement.nativeElement.scrollTop;
    const offsetHeight = this.hostElement.nativeElement.offsetHeight;
    const isReachingTop = this.hostElement.nativeElement.scrollTop < this.topOffset;
    const isReachingBottom = scrollPosition - offsetHeight < this.bottomOffset;
    return { isReachingTop, isReachingBottom };
  }

  protected elementScrollEvent($event: Event) {
    const { isReachingTop, isReachingBottom } = this.getParameters();
    this.manageClasses(isReachingTop, isReachingBottom);
  }

  private manageClasses(isReachingTop: boolean, isReachingBottom: boolean) {
    if (!isReachingTop && !isReachingBottom) {
      this.renderer.addClass(this.hostElement.nativeElement, 'shadow-top-bot');
      return;
    }
    if (!isReachingTop) {
      this.renderer.addClass(this.hostElement.nativeElement, 'shadow-top');
    }
    if (!isReachingBottom) {
      this.renderer.addClass(this.hostElement.nativeElement, 'shadow-bot');
    }
    if (isReachingTop) {
      this.renderer.removeClass(this.hostElement.nativeElement, 'shadow-top');
      this.renderer.removeClass(this.hostElement.nativeElement, 'shadow-top-bot');
    }

    if (isReachingBottom) {
      this.renderer.removeClass(this.hostElement.nativeElement, 'shadow-bot');
      this.renderer.removeClass(this.hostElement.nativeElement, 'shadow-top-bot');
    }
  }
}
