import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IdentityComparaisonFailComponent } from './identity-comparaison-fail.component';

const routes: Routes = [{ path: '', component: IdentityComparaisonFailComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IdentityComparaisonFailRoutingModule {}
