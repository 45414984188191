/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeapService {
  get heap() {
    return (window as any).heap;
  }

  resetIdentity() {
    this.heap?.resetIdentity();
  }

  addUserProperties(properties: any) {
    this.heap?.addUserProperties(properties);
  }

  addEventProperties(properties: any) {
    this.heap?.addEventProperties(properties);
  }

  removeEventProperty(property: string) {
    this.heap?.removeEventProperty(property);
  }

  clearEventProperties() {
    this.heap?.clearEventProperties();
  }

  public initializeHeap(appId: string) {
    (window as any).heap = (window as any).heap || [];

    const heap = (window as any).heap;

    heap.load = function (appId, heapConfig) {
      heap.appid = appId;
      heap.config = heapConfig || {};

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.heapanalytics.com/js/heap-' + appId + '.js';

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);

      const cloneArray = (arrayLike) => Array.prototype.slice.call(arrayLike, 0);

      const createMethod = function (method) {
        return function (...args) {
          heap.heap?.push([method, ...cloneArray(args)]);
        };
      };

      const methods = [
        'addEventProperties',
        'addUserProperties',
        'clearEventProperties',
        'identify',
        'resetIdentity',
        'removeEventProperty',
        'setEventProperties',
        'track',
        'unsetEventProperty',
      ];

      for (const method of methods) {
        heap[method] = createMethod(method);
      }
    };

    heap.load(appId);
  }

  public track(eventName: string, eventObj: any) {
    (window as any).heap?.track(eventName, eventObj);
  }
}
