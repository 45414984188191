import { createVariable } from '@core/utils/camunda.util';
import { ICamundaCompleteTask } from '@data/interfaces/camunda/camunda-complete-task.interface';
import { ECamundaVariableValueType } from '@data/interfaces/camunda/camunda-variable-value-type.enum';
import { CamundaVariableValue } from './camunda-variable-value.model';

export class CamundaCompleteTask {
  /**
   * A JSON object containing variable key-value pairs.
   */
  variables?: { [key: string]: CamundaVariableValue };
  /**
   * Indicates whether the response should contain the process variables or not. The default is `false` with a response code of `204`. If set to `true` the response contains the process variables and has a response code of `200`. If the task is not associated with a process instance (e.g. if it\'s part of a case instance) no variables will be returned.
   */
  withVariablesInReturn?: boolean | null;

  constructor(camundaCompleteTask?: ICamundaCompleteTask) {
    Object.assign(this, camundaCompleteTask);
  }

  addVariable(
    variableName: string,
    variableValue: any,
    variableType: ECamundaVariableValueType
  ): CamundaCompleteTask {
    if (!this.variables) {
      this.variables = {};
    }
    this.variables[variableName] = createVariable(variableValue, variableType);
    return this;
  }
}
