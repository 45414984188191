/**
 * Camunda BPM REST API
 * OpenApi Spec for Camunda BPM REST API.
 *
 * The version of the OpenAPI document: 7.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CamundaHistoricProcessInstanceQueryDto } from './camunda-historic-process-instance-query-dto.model';
import { CamundaProcessInstanceQueryDto } from './camunda-process-instance-query-dto.model';

export class CamundaDeleteProcessInstancesDto {
  /**
   * A list process instance ids to delete.
   */
  processInstanceIds?: Array<string>;
  /**
   * A string with delete reason.
   */
  deleteReason?: string;
  /**
   * Skip execution listener invocation for activities that are started or ended as part of this request.
   */
  skipCustomListeners?: boolean | null;
  /**
   * Skip deletion of the subprocesses related to deleted processes as part of this request.
   */
  skipSubprocesses?: boolean | null;
  processInstanceQuery?: CamundaProcessInstanceQueryDto;
  historicProcessInstanceQuery?: CamundaHistoricProcessInstanceQueryDto;
}
