import { IDeviceConfiguration } from '@data/interfaces/device-configurations/device-configuration.interface';
import { Printer } from '../printers/printer.model';
import { DeviceCheckinProcessConfiguration } from './device-checkin-process-configuration.model';

export class DeviceConfiguration {
  id: string = undefined;
  deviceId: string = undefined;
  geminiSiteId: string = undefined;
  printerInformation: Printer = undefined;
  passportScannerIp: string = undefined;
  passportScannerConnected: boolean = undefined;
  provisionedBy: string = undefined;
  provisioningDate: Date = undefined;
  checkinProcessConfiguration?: DeviceCheckinProcessConfiguration = undefined;
  serialNumber: string = undefined;
  inventoryNumber: string = undefined;

  constructor(data?: DeviceConfiguration | IDeviceConfiguration) {
    if (data) {
      this.id = data.id;
      this.deviceId = data.deviceId;
      this.geminiSiteId = data.geminiSiteId;
      this.printerInformation = data.printerInformation;
      this.passportScannerIp = data.passportScannerIp;
      this.passportScannerConnected = data.passportScannerConnected;
      this.provisionedBy = data.provisionedBy;
      this.provisioningDate = new Date(data.provisioningDate);
      this.checkinProcessConfiguration = new DeviceCheckinProcessConfiguration(data?.checkinProcessConfiguration);
      this.serialNumber = data.serialNumber;
      this.inventoryNumber = data.inventoryNumber;
    }
  }
}
