import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent } from '@shared/base/base-component.component';
import { Observable } from 'rxjs';
import { VisitorType } from '@core/enums/visitor-type.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @Input() public checkinVisitorType$: Observable<VisitorType>;
  checkinVisitorType: VisitorType;
  @Input() public hideExit = false;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this._initState();
  }

  public async exit(): Promise<void> {
    await this.navigateEndSessionConfirmation();
  }

  private _initState(): void {
    if (this.checkinVisitorType$) {
      this.checkinVisitorType$.subscribe((checkinVisitorType) => {
        this.checkinVisitorType = checkinVisitorType;
      });
    }
  }
}
