import { ExportControl } from '@core/model/entity/export-control.model';

export class VisitorInformation {
  idFacePhoto: string;
  facePhoto: string;
  exportControl: ExportControl;

  constructor(data?: VisitorInformation) {
    Object.assign(this, data);
  }
}
