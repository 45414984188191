import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { IdentityComparaisonFailComponent } from './identity-comparaison-fail.component';
import { IdentityComparaisonFailRoutingModule } from './identity-comparison-fail-routing.module';

@NgModule({
  declarations: [IdentityComparaisonFailComponent],
  imports: [CommonModule, IdentityComparaisonFailRoutingModule, SharedModule],
})
export class IdentityComparaisonFailModule {}
