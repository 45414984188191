import { Observable, lastValueFrom, take } from 'rxjs';
import {
  ResetTraining,
  SetCheckinFormFields,
  SetCovidAcknowledgementDate,
  SetIdentityPolicyConsentDate,
  SetReservations,
  SetTraineeType,
  SetTraining,
  SetVisit,
  SetVisitBadgeNumber,
  SetVisitEndDate,
  SetVisitId,
  SetVisitReason,
  SetVisitStartDate,
  SetVisitorContactName,
  SetVisitorCountryOfResidency,
  SetVisitorCountryOfResidencyId,
  SetVisitorDateOfBirth,
  SetVisitorEmail,
  SetVisitorEmailConsentDate,
  SetVisitorFirstName,
  SetVisitorHostName,
  SetVisitorHotelName,
  SetVisitorHotelPhone,
  SetVisitorLastName,
  SetVisitorMiddleName,
  SetVisitorNationality,
  SetVisitorNationalityId,
  SetVisitorPhoneNumber,
  SetVisitorType,
} from './application.actions';
import { Select, Store } from '@ngxs/store';

import { AccessPassCheckinRequest } from '@data/interfaces/visits/access-pass-checkin-request.model';
import { ApplicationState } from './application.state';
import { CheckinFormFields } from '@core/model/form/checkin-form-fields.model';
import { Country } from '@core/model/entity/country.model';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Injectable } from '@angular/core';
import { Nationality } from '@core/model/entity/nationality.model';
import { Reservation } from '@core/model/reservation/reservation.model';
import { ReservationsService } from '@data/services/reservations.service';
import { TraineeType } from '@core/enums/trainee-type.enum';
import { Training } from '@core/model/training/training.model';
import { TrainingsService } from './../../data/services/trainings.service';
import { Visit } from '@core/model/visit/visit.model';
import { VisitorType } from '@core/enums/visitor-type.enum';
import { VisitsService } from '@data/services/visits.service';

@Injectable({
  providedIn: 'root',
})
export class VisitStateService {
  @Select(ApplicationState.getVisit)
  public visit$: Observable<Visit>;

  @Select(ApplicationState.getVisitStartDate)
  public visitStartDate$: Observable<Date>;

  @Select(ApplicationState.getVisitEndDate)
  public visitEndDate$: Observable<Date>;

  @Select(ApplicationState.getVisitorType)
  public visitorType$: Observable<VisitorType>;

  @Select(ApplicationState.getCheckinTraineeType)
  public traineeType$: Observable<TraineeType>;

  @Select(ApplicationState.getBadgeNumber)
  public badgeNumber$: Observable<string>;

  @Select(ApplicationState.getConsentDate)
  public identityPolicyConsentDate$: Observable<Date>;

  @Select(ApplicationState.getCovidAcknowledgementDate)
  public covidAcknowledgementDate$: Observable<Date>;

  @Select(ApplicationState.getTrainingId)
  public trainingId$: Observable<string[]>;

  @Select(ApplicationState.getVisitId)
  public visitId$: Observable<string>;

  @Select(ApplicationState.getTraining)
  public training$: Observable<Training>;

  @Select(ApplicationState.getReservations)
  public reservations$: Observable<Reservation[]>;

  constructor(
    private store: Store,
    private visitsService: VisitsService,
    private reservationsService: ReservationsService,
    private trainingsService: TrainingsService
  ) { }

  @Dispatch()
  public async setVisit(visit: Visit) {
    if (!!visit && visit.trainingId) {
      const training = await this.trainingsService.getTraining(visit.trainingId);
      await this.setTraining(training);
    }
    return new SetVisit(visit);
  }

  @Dispatch()
  public setVisitorType(checkinVisitorType: VisitorType) {
    return new SetVisitorType(checkinVisitorType);
  }

  @Dispatch()
  public setTraineeType(checkinTraineeType: TraineeType) {
    return new SetTraineeType(checkinTraineeType);
  }

  @Dispatch()
  public setVisitStartDate(date: Date) {
    return new SetVisitStartDate(date);
  }

  @Dispatch()
  public setVisitEndDate(date: Date) {
    return new SetVisitEndDate(date);
  }

  @Dispatch()
  public setVisitBadgeNumber(badgeNumber: string) {
    return new SetVisitBadgeNumber(badgeNumber);
  }

  @Dispatch()
  public setVisitReason(visitReason: string) {
    return new SetVisitReason(visitReason);
  }

  @Dispatch()
  public setVisitorHotelName(hotelName: string) {
    return new SetVisitorHotelName(hotelName);
  }

  @Dispatch()
  public setVisitorHotelPhone(hotelPhone: string) {
    return new SetVisitorHotelPhone(hotelPhone);
  }

  @Dispatch()
  public setVisitorHostName(hostName: string) {
    return new SetVisitorHostName(hostName);
  }

  @Dispatch()
  public setIdentityPolicyConsentDate(identityPolicyConsentDate: Date) {
    return new SetIdentityPolicyConsentDate(identityPolicyConsentDate);
  }

  @Dispatch()
  public setCovidAcknowledgementDate(value: Date) {
    return new SetCovidAcknowledgementDate(value);
  }

  @Dispatch()
  public setVisitorContactName(contactName: string) {
    return new SetVisitorContactName(contactName);
  }

  @Dispatch()
  public setVisitorFirstName(firstName: string) {
    return new SetVisitorFirstName(firstName);
  }

  @Dispatch()
  public setVisitorLastName(lastName: string) {
    return new SetVisitorLastName(lastName);
  }

  @Dispatch()
  public setVisitorMiddleName(middleName: string) {
    return new SetVisitorMiddleName(middleName);
  }

  @Dispatch()
  public setVisitorDateOfBirth(value: Date) {
    return new SetVisitorDateOfBirth(value);
  }

  @Dispatch()
  public setVisitorNationality(nationality: Nationality) {
    return new SetVisitorNationality(nationality);
  }

  @Dispatch()
  public setVisitorNationalityId(nationalityId: string) {
    return new SetVisitorNationalityId(nationalityId);
  }

  @Dispatch()
  public setVisitorCountryOfResidency(country: Country) {
    return new SetVisitorCountryOfResidency(country);
  }

  @Dispatch()
  public setVisitorCountryOfResidencyId(countryOfResidencyId: string) {
    return new SetVisitorCountryOfResidencyId(countryOfResidencyId);
  }

  @Dispatch()
  public setVisitorEmail(email: string) {
    return new SetVisitorEmail(email);
  }

  @Dispatch()
  public setVisitorEmailConsentDate(emailConsentDate: Date) {
    return new SetVisitorEmailConsentDate(emailConsentDate);
  }

  @Dispatch()
  public setVisitorPhoneNumber(phone: string) {
    return new SetVisitorPhoneNumber(phone);
  }

  @Dispatch()
  public setVisitId(visitId: string) {
    return new SetVisitId(visitId);
  }

  @Dispatch()
  public async setTraining(training: Training) {
    if (training) {
      const reservations = await this.reservationsService.getReservations(
        training.geminiReservationIds
      );
      await this.setReservations(reservations);
    } else {
      await this.setReservations(null);
    }
    return new SetTraining(training);
  }

  @Dispatch()
  public async setReservations(reservations: Reservation[]) {
    return new SetReservations(reservations);
  }

  @Dispatch()
  public async resetTraining() {
    return new ResetTraining();
  }

  public async saveVisit() {
    let visit = await lastValueFrom(this.visit$.pipe(take(1)));
    if (!visit) {
      return;
    }
    if (!visit.id) {
      visit = await this.visitsService.createVisit(visit);
      await this.visitsService.recordStartCheckin(visit);
    } else {
      visit = await this.visitsService.updateVisit(visit);
    }
    this.store.dispatch(new SetVisit(visit));
    return visit;
  }

  public async getCheckinFormFields(trainingCenterId: string): Promise<CheckinFormFields> {
    return await this.visitsService.getCheckinFormFields(trainingCenterId);
  }

    public async createAccessPassCheckin(accessPassCheckinRequest: AccessPassCheckinRequest): Promise<Visit> {
      const visit = await this.visitsService.createAccessPassCheckin(accessPassCheckinRequest);

      this.store.dispatch(new SetVisit(visit));
      return visit;
  }
}
