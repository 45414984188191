import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-camera-decorator',
  templateUrl: './camera-decorator.component.html',
  styleUrls: ['./camera-decorator.component.less'],
})
export class CameraDecoratorComponent implements OnChanges {
  /** Defines the max width of the webcam area in px */
  @Input() public width = 500;
  @Input() public height = 500;
  @Input() public spacing = 10;
  @Input() public errorMessage;
  @Input() public isFlash;

  frameStyle: { height: string; width: string };
  commonCornerStyle: { height: string; width: string };
  topLeftCornerStyle: { top: string; left: string };
  botLeftCornerStyle: { bottom: string; left: string };
  topRightCornerStyle: { top: string; right: string };
  botRightCornerStyle: { bottom: string; right: string };

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    this.init();
  }

  private init() {
    this.frameStyle = {
      height: `${this.height}px`,
      width: `${this.width}px`,
    };

    this.topLeftCornerStyle = {
      top: `${this.spacing}px`,
      left: `${this.spacing}px`,
    };

    this.botLeftCornerStyle = {
      bottom: `${this.spacing}px`,
      left: `${this.spacing}px`,
    };

    this.topRightCornerStyle = {
      top: `${this.spacing}px`,
      right: `${this.spacing}px`,
    };

    this.botRightCornerStyle = {
      bottom: `${this.spacing}px`,
      right: `${this.spacing}px`,
    };
  }
}
