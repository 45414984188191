import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { first, lastValueFrom } from 'rxjs';

import { AuthenticationService } from './../services/authentication.service';
import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = await lastValueFrom(
      this.authenticationService.isAuthenticated.pipe(first())
    );
    if (environment.mock || isAuthenticated) {
      return true;
    }

    this.authenticationService.returnUrl = state.url;
    // not logged in so redirect to login page with the return url
    await this.router.navigate(['/login']);
    return false;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = await lastValueFrom(
      this.authenticationService.isAuthenticated.pipe(first())
    );

    if (environment.mock || isAuthenticated) {
      return true;
    }

    this.authenticationService.returnUrl = state.url;
    // not logged in so redirect to login page with the return url
    await this.router.navigate(['/login']);
    return false;
  }
}
