import { FacesCompareResult } from '../entity/FacesCompareResult';
import { AnalyticBaseProperties } from './AnalyticBaseProperties';

export enum FacialRecognitionResult {
  Success = 'Success',
  Fail = 'Fail',
}

export class AnalyticCheckinFacialRecognitionProperties extends AnalyticBaseProperties {
  Result: string;
  Confidence: number;
  Threshold: number;

  constructor(faceCompareResult: FacesCompareResult) {
    super();

    if (faceCompareResult) {
      this.Result = faceCompareResult.isIdentical
        ? FacialRecognitionResult.Success
        : FacialRecognitionResult.Fail;
      this.Confidence = faceCompareResult.confidence;
      this.Threshold = faceCompareResult.threshold;
    }
  }
}
