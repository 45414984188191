import { Component, OnInit } from '@angular/core';
import { filter, take, takeUntil } from 'rxjs/operators';

import { AnalyticCheckinEventTypes } from '@core/model/analytic/AnalyticCheckinEventTypes';
import { AnalyticCheckinFacialRecognitionProperties } from '@core/model/analytic/AnalyticCheckinFacialRecognitionProperties';
import { AnalyticService } from '@data/services/analytic.service';
import { ApplicationStateService } from '@core/services/application-state.service';
import { BaseComponent } from '@shared/base/base-component.component';
import { EWorkflowVariable } from '@core/enums/workflow-variable.enum';
import { FacesCompareResult } from '@core/model/entity/FacesCompareResult';
import { VisitStateService } from '@core/state/visit-state.service';
import { WorkflowInformation } from '@core/model/checkin-process/workflow-information.model';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-identity-comparison',
  templateUrl: './identity-comparison.component.html',
  styleUrls: ['./identity-comparison.component.less'],
})
export class IdentityComparisonComponent extends BaseComponent implements OnInit {
  isIdentityComparisonSuccess = false;

  constructor(
    private applicationStateService: ApplicationStateService,
    public visitStateService: VisitStateService,
    private analyticService: AnalyticService
  ) {
    super();
  }

  async ngOnInit() {
    try {
      await this.initState();
      await this.analyticService.startTrackEvent(AnalyticCheckinEventTypes.FacialRecognition);
      await this.listenCompareResult();
      this.applicationStateService.doFaceCompare();
    } catch (error) {
      this.analyticService.trackException(error);
      await this.analyticService.trackEvent(AnalyticCheckinEventTypes.IdentityComparisonFailed, {});
      await this.navigateBack();
    }
  }

  async initState() {
    const facialComparisonResult = await lastValueFrom(
      this.applicationStateService.facialComparisonResult$.pipe(take(1))
    );

    if (facialComparisonResult) {
      this.applicationStateService.setCheckinFacialComparisonResult(undefined);
    }

    await this.deleteTaskVariables([EWorkflowVariable.isIdentityComparaisonSuccess]);
  }

  private async listenCompareResult() {
    this.applicationStateService.facialComparisonResult$
      .pipe(
        filter((value) => value !== undefined),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.manageCompareResult(result);
      });
  }

  public async manageCompareResult(faceCompare: FacesCompareResult) {
    this.analyticService.stopTrackEvent(
      AnalyticCheckinEventTypes.FacialRecognition,
      new AnalyticCheckinFacialRecognitionProperties(faceCompare)
    );

    if (faceCompare?.isIdentical === true) {
      if (faceCompare.facePicture) {
        this.applicationStateService.setVisitorFacePhoto(faceCompare.facePicture);
      }

      this.isIdentityComparisonSuccess = true;

      await this.analyticService.trackEvent(AnalyticCheckinEventTypes.IdentityComparisonSucceeded, {});

      const displaySuccessMessage = new Promise<void>(function (resolve) {
        setTimeout(function () {
          resolve();
        }, 1500);
      });

      await displaySuccessMessage;
      await this.navigateNext(new WorkflowInformation({ isIdentityComparaisonSuccess: true }));
    }
    else {
      await this.analyticService.trackEvent(AnalyticCheckinEventTypes.IdentityComparisonFailed, {});
      await this.navigateNext(new WorkflowInformation({ isIdentityComparaisonSuccess: false }));
    }
  }
}
