import { TraineeType } from '@core/enums/trainee-type.enum';
import { VisitorType } from '@core/enums/visitor-type.enum';
import { Visit } from '@core/model/visit/visit.model';
import { ExportControlPost } from '@data/interfaces/export-control/export-control-post.model';

export class VisitPost {
  startDate: Date;
  endDate: Date;
  geminiReservationIds: string[];
  trainingCenterId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  hotelName: string;
  companyName: string;
  phone: string;
  email: string;
  emailConsentDate: Date;
  contactName: string;
  identityPolicyConsentDate: Date;
  visitorType: VisitorType;
  traineeType: TraineeType;
  visitReason: string;
  exportControl: ExportControlPost;

  constructor(visit?: Visit) {
    if (!visit) {
      return;
    }
    Object.assign(this, visit);
    if (visit.exportControl) {
      this.exportControl = new ExportControlPost();
      if (visit.exportControl.countryOfResidence) {
        this.exportControl.countryOfResidenceId = visit.exportControl.countryOfResidence.id;
      }
      if (visit.exportControl.lastGrantedCitizenship) {
        this.exportControl.lastGrantedCitizenshipId = visit.exportControl.lastGrantedCitizenship.id;
      }
    }
  }
}
