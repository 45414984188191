import { FeatureService } from './store/feature.service';
import { Injectable } from '@angular/core';
import { LaunchDarklyService } from '@core/feature-toggle/launch-darkly.service';
import { ToggleFeatureKeys } from '@core/feature-toggle/toggle-feature-keys.enum';
import { environment } from '@env';

export function initializeAppFeatureFlags(appInitService: FeatureFlagService) {
  return (): Promise<any> => {
    return appInitService.initialize();
  };
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(
    private featureService: FeatureService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  public async initialize() {
    await this.launchDarklyService.initialize(
      environment.launchDarkly.apiKey,
      environment.launchDarkly.isEnabled,
      {
        key: 'anon',
        anonymous: true,
      }
    );
    Object.keys(ToggleFeatureKeys).forEach((featureKey) => {
      const featureName = ToggleFeatureKeys[featureKey];
      const featureValue = this.launchDarklyService.getFlag(featureName);
      this.featureService.updateFeature(featureName, featureValue);
    });

    this.launchDarklyService.featureValueChanged.subscribe((feature) => {
      this.featureService.updateFeature(feature.name, feature.isActive);
    });
  }

  public changeUser(user) {
    if (user !== 'Anonymous') {
      this.launchDarklyService.identify({
        key: user.key,
        name: user.name,
        email: user.email,
        anonymous: false,
      });
    } else {
      this.launchDarklyService.identify({ key: 'anon', anonymous: true });
    }
  }
}
