import { ApplicationState } from '@core/state/application.state';
import { CommonModule } from '@angular/common';
import { FeatureState } from '@core/feature-toggle/store/feature.state';
import { NgModule } from '@angular/core';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@env';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot([FeatureState, ApplicationState], {
      developmentMode: !environment.production,
    }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    NgxsDispatchPluginModule.forRoot(),
  ],
  exports: [NgxsModule],
})
export class StoreModule {}
