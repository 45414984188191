import { ApiService } from '@data/services/api.service';
import { DeviceRegistration } from './../../core/model/device-registration/device-registration.model';
import { DeviceRegistrationPost } from './../models/device-registrations/device-registration-post.model';
import { HttpParams } from '@angular/common/http';
import { IDeviceRegistration } from '@data/interfaces/device-registrations/device-registration.interface';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeviceRegistrationService {
  private endpoint = `/device-registrations`;

  constructor(private apiService: ApiService) {}

  register(deviceRegistrationPost: DeviceRegistrationPost) {
    return lastValueFrom(
      this.apiService
        .post<IDeviceRegistration>(`${this.endpoint}`, deviceRegistrationPost)
        .pipe(map((response) => new DeviceRegistration(response)))
    );
  }

  unRegister(deviceRegistration: DeviceRegistration) {
    return lastValueFrom(
      this.apiService.delete<IDeviceRegistration>(`${this.endpoint}/${deviceRegistration.id}`)
    );
  }

  public async getByDeviceId(id: string): Promise<DeviceRegistration> {
    const params = new HttpParams().set('deviceId', id);

    return lastValueFrom(
      this.apiService.get<IDeviceRegistration[]>(`${this.endpoint}`, { params }).pipe(
        map((response) => {
          if (response?.length > 0) {
            return new DeviceRegistration(response[0]);
          }
        })
      )
    );
  }
}
