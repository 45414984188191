import { BypassCode } from '@core/enums/bypass-code.enum';
import { IByPassInformation } from '@data/interfaces/visits/bypass-information.interface';
import moment from 'moment';

export class ByPassInformation {
  code: BypassCode;
  employeeName: string;
  date: Date;
  reason: string;

  constructor(data?: IByPassInformation) {
    Object.assign(this, data);
    if (data) {
      if (data.date) {
        this.date = moment(data.date).toDate();
      }
    }
  }
}
