import { ApplicationStateService } from '@core/services/application-state.service';
import { BaseComponent } from '@shared/base/base-component.component';
import { BypassCode } from '@core/enums/bypass-code.enum';
import { Component } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { VisitStateService } from '@core/state/visit-state.service';

@Component({
  selector: 'app-identity-comparaison-fail',
  templateUrl: './identity-comparaison-fail.component.html',
  styleUrls: ['./identity-comparaison-fail.component.less'],
})
export class IdentityComparaisonFailComponent extends BaseComponent {
  constructor(
    public applicationStateService: ApplicationStateService,
    public visitStateService: VisitStateService,
    private storageService: StorageService
  ) {
    super();
  }

  async onRetryScan() {
    this.protectedProcess$.next(async () => {
      this.applicationStateService.resetScan();
      await this.navigateBack();
    });
  }

  async exit() {
    this.protectedProcess$.next(async () => {
      await this.navigateExit();
    });
  }

  async byPass() {
    this.protectedProcess$.next(async () => {
      this.storageService.byPassCode = BypassCode.IDENTITY_COMPARISON_FAIL;
      await this.navigateBypass();
    });
  }
}
