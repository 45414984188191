import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AdministrationRoutePaths, AppRoutePaths } from '@core/constants';
import { first, lastValueFrom } from 'rxjs';

import { AdministrationDeviceRoutePaths } from './../constants';
import { ApplicationStateService } from '@core/services/application-state.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceConfigurationGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private applicationStateService: ApplicationStateService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const deviceConfiguration = await lastValueFrom(
      this.applicationStateService.deviceConfiguration$.pipe(first())
    );

    if (!deviceConfiguration?.id || !deviceConfiguration?.geminiSiteId) {
      await this.router.navigate([
        `${AppRoutePaths.Administration}/${AdministrationRoutePaths.Device}/${AdministrationDeviceRoutePaths.General}`,
      ]);
      return false;
    }

    return true;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const deviceConfiguration = await lastValueFrom(
      this.applicationStateService.deviceConfiguration$.pipe(first())
    );

    if (!deviceConfiguration?.id || !deviceConfiguration?.geminiSiteId) {
      await this.router.navigate([
        `${AppRoutePaths.Administration}/${AdministrationRoutePaths.Device}/${AdministrationDeviceRoutePaths.General}`,
      ]);
      return false;
    }

    return true;
  }
}
