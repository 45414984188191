export enum IosAppEventTypes {
  registrationKeyReceived = 'registrationKeyReceived',
  takeSelfiePicture = 'takeSelfiePicture',
  takeIdCardPicture = 'takeIdCardPicture',
  pictureReceived = 'pictureReceived',
  getDeviceId = 'getDeviceId',
  deviceIdReceived = 'deviceIdReceived',
  getPrinters = 'getPrinters',
  getPrintersComplete = 'getPrintersComplete',
  printersReceived = 'printersReceived',
  printBadge = 'printBadge',
  badgePrintedReceived = 'badgePrintedReceived',
  idCardCameraSelectionReceived = 'idCardCameraSelectionReceived',
  startQrReader = 'startQrReader',
  stopQrReader = 'stopQrReader',
  badgeNumberReceived = 'badgeNumberReceived',
  documentScannerReceived = 'documentScannerReceived',
  setDrk = 'setDrk',
  setDrkComplete = 'setDrkComplete',
  reloadPage = 'reloadPage',
}

export enum IosPictureTypes {
  idCard = 'idCard',
  selfie = 'selfie',
}
