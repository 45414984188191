import { ICheckinApp } from './checkin-app.model';

export function nativeWindow(): any {
  return _window();
}

export function _window(): any {
  // return the global native browser window object
  return window;
}

export function isHostedInIPad(): boolean {
  return !!nativeWindow().webkit;
}

export function checkinApp(): ICheckinApp {
  if (isHostedInIPad()) {
    return nativeWindow().checkinApp;
  }
  return null;
}

export function nativeProcess(): any {
  if (isHostedInIPad()) {
    return nativeWindow().webkit.messageHandlers.nativeProcess;
  }
  return null;
}
