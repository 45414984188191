import { ECamundaVariableValueType } from '@data/interfaces/camunda/camunda-variable-value-type.enum';
import { CamundaVariableValue } from '@data/models/camunda/camunda-variable-value.model';

export function createVariable(
  variableValue: any,
  variableType: ECamundaVariableValueType
): CamundaVariableValue {
  if (variableType === ECamundaVariableValueType.BOOLEAN && typeof variableValue !== 'boolean') {
    throw new Error(`invalid-type`);
  }
  if (variableType === ECamundaVariableValueType.INTEGER && typeof variableValue !== 'number') {
    throw new Error(`invalid-type`);
  }
  if (
    variableType === ECamundaVariableValueType.STRING &&
    typeof variableValue !== 'string' &&
    variableValue !== null
  ) {
    throw new Error(`invalid-type`);
  }
  if (variableType === ECamundaVariableValueType.JSON && typeof variableValue !== 'object') {
    throw new Error(`invalid-type`);
  }
  if (variableType === ECamundaVariableValueType.OBJECT && typeof variableValue !== 'object') {
    throw new Error(`invalid-type`);
  }

  if (
    variableValue != null &&
    (variableType === ECamundaVariableValueType.JSON ||
      variableType === ECamundaVariableValueType.OBJECT)
  ) {
    variableValue = JSON.stringify(variableValue);
  }

  return new CamundaVariableValue(variableValue, variableType);
}
