import { ICamundaStartProcessInstance } from '@data/interfaces/camunda/camunda-start-process-instance.interface';
import { ECamundaVariableValueType } from '@data/interfaces/camunda/camunda-variable-value-type.enum';
import { CamundaProcessInstanceModificationInstruction } from './camunda-process-instance-modification-instruction.model';
import { CamundaVariableValue } from './camunda-variable-value.model';

export class CamundaStartProcessInstance {
  /**
   * The business key of the process instance.
   */
  businessKey?: string;
  variables?: { [key: string]: CamundaVariableValue };
  /**
   * The case instance id the process instance is to be initialized with.
   */
  caseInstanceId?: string;
  /**
   * **Optional**. A JSON array of instructions that specify which activities to start the process instance at. If this property is omitted, the process instance starts at its default blank start event.
   */
  startInstructions?: Array<CamundaProcessInstanceModificationInstruction>;
  /**
   * Skip execution listener invocation for activities that are started or ended as part of this request. **Note**: This option is currently only respected when start instructions are submitted via the `startInstructions` property.
   */
  skipCustomListeners?: boolean | null;
  /**
   * Skip execution of [input/output variable mappings](https://docs.camunda.org/manual/7.14/user-guide/process-engine/variables/#input-output-variable-mapping) for activities that are started or ended as part of this request. **Note**: This option is currently only respected when start instructions are submitted via the `startInstructions` property.
   */
  skipIoMappings?: boolean | null;
  /**
   * Indicates if the variables, which was used by the process instance during execution, should be returned. Default value: `false`
   */
  withVariablesInReturn?: boolean | null;

  constructor(camundaStartProcessInstance?: ICamundaStartProcessInstance) {
    Object.assign(this, camundaStartProcessInstance);
  }

  addVariable(
    variableName: string,
    variableValue: any,
    variableType: ECamundaVariableValueType
  ): CamundaStartProcessInstance {
    if (!this.variables) {
      this.variables = {};
    }

    if (
      variableType === ECamundaVariableValueType.JSON ||
      variableType === ECamundaVariableValueType.OBJECT
    ) {
      variableValue = JSON.stringify(variableValue);
    }

    this.variables[variableName] = new CamundaVariableValue(variableValue, variableType);

    return this;
  }
}
