import { Validators } from "@angular/forms";
import { TraineeType } from "@core/enums/trainee-type.enum";
import { CheckinFieldState, CheckinFormFields, MappedCheckinFields } from "@core/model/form/checkin-form-fields.model";

export async function blobToBase64(blob: Blob): Promise<string> {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      const base64String = reader.result.toString().replace('data:', '').replace(/^.+,/, '');

      resolve(base64String);
    };
  });
}

export async function base64ToBlob(base64: string) {
  const byteString = window.atob(base64);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/png' });
  return blob;
}

export async function base64ToFile(fileName: string, base64: string) {
  const imageBlob = await base64ToBlob(base64);
  const imageFile = new File([imageBlob], fileName, { type: 'image/png' });
  return imageFile;
}

export async function getBase64ImageUrl(imageData: string): Promise<string> {
  return `data:image/jpeg;base64,${imageData}`;
}

export function getBase64ImageUrlSync(imageData: string): string {
  return `data:image/jpeg;base64,${imageData}`;
}


export function getFormFieldsFromTraineeType(
  checkinFormFields: CheckinFormFields,
  traineeType: TraineeType
): MappedCheckinFields {

  const mappedFields = checkinFormFields.checkinTrainees.find(
    (fields) => fields.trainee === traineeType
  ).checkinFields.reduce((acc, field) => ({
    ...acc,
    [field.name as string]: {
      isUnavailable: field.state === CheckinFieldState.Unavailable,
      control: {
        value: null, disabled: field.state === CheckinFieldState.Unavailable,

      },
      validators: field.state === CheckinFieldState.Mandatory ? [Validators.required] : []
    }
  }), {});

  return mappedFields as MappedCheckinFields;
}
