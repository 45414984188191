import moment from 'moment';
import { IReservation } from './reservation.interface';
import { Trainee } from './trainee.model';
import { Training } from './training.model';

export class Reservation {
  public geminiReservationId: string;
  public isDry: boolean;
  public startDate: Date;
  public endDate: Date;
  public training: Training;
  public trainee: Trainee;
  constructor(data?: IReservation) {
    Object.assign(this, data);
    if (data) {
      if (data.startDate) {
        this.startDate = moment(data.startDate).toDate();
      }
      if (data.endDate) {
        this.endDate = moment(data.endDate).toDate();
      }
      if (data.trainee) {
        this.trainee = new Trainee(data.trainee);
      }
    }
  }

  hasReservationTraineeName() {
    return !!this.trainee && !!this.trainee.name;
  }
}
