import { DeviceConfiguration } from '../model/devices-configuration/device-configuration.model';
import { DeviceRegistration } from './../model/device-registration/device-registration.model';
import { ExportControl } from '@core/model/export-control/export-control.model';
import { FlowInformation } from './flow-information.model';
import { TechnicalInformation } from './technical-information.model';
import { Visit } from '@core/model/visit/visit.model';
import { VisitorInformation } from '@core/state/visitor-information.model';
import { FeedbackInformation } from './feedback-information.model';

export class ApplicationStateModel {
  readonly feedbackInformation: FeedbackInformation;
  readonly flowInformation: FlowInformation;
  readonly technicalInformation: TechnicalInformation;
  readonly visitorInformation: VisitorInformation;
  readonly visit: Visit;
  readonly deviceConfiguration: DeviceConfiguration;
  readonly deviceRegistration: DeviceRegistration;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    } else {
      this.feedbackInformation = new FeedbackInformation();
      this.flowInformation = new FlowInformation();
      this.technicalInformation = new TechnicalInformation();
      this.visitorInformation = new VisitorInformation();
      this.deviceConfiguration = new DeviceConfiguration();
      this.visit = new Visit();
      this.visit.exportControl = new ExportControl();
    }
  }
}
