import { StateOperator } from '@ngxs/store';

import { CheckinCameraType } from '@core/enums/checkin-camera-type.enum';
import { FlowType } from '@core/enums/flow-type.enum';
import { EIdentityDocumentType } from '@core/enums/identity-document-type.enum';
import { RecordingType } from '@core/enums/recording-type.enum';
import { TraineeType } from '@core/enums/trainee-type.enum';
import { VisitorType } from '@core/enums/visitor-type.enum';
import { BadgeValidity } from '@core/model/badge/badge-validity.model';
import { ByPassInformation } from '@core/model/bypass-information.model';
import { Customer } from '@core/model/customer/customer.model';
import { DeviceRegistration } from '@core/model/device-registration/device-registration.model';
import { DeviceConfiguration } from '@core/model/devices-configuration/device-configuration.model';
import { Aircraft } from '@core/model/entity/aircraft.model';
import { Country } from '@core/model/entity/country.model';
import { ExportControl } from '@core/model/entity/export-control.model';
import { FacesCompareResult } from '@core/model/entity/FacesCompareResult';
import { Nationality } from '@core/model/entity/nationality.model';
import { Step } from '@core/model/entity/step.model';
import { TrainingCenter } from '@core/model/entity/training-center.model';
import { Reservation } from '@core/model/reservation/reservation.model';
import { Training } from '@core/model/training/training.model';
import { Visit } from '@core/model/visit/visit.model';
import { CamundaProcessInstanceWithVariables } from '@data/models/camunda/camunda-process-instance-with-variables.model';
import { CamundaTaskCustomProperties } from '@data/models/camunda/camunda-task-custom-properties.model';
import { CamundaTask } from '@data/models/camunda/camunda-task.model';
import { Printer } from './../model/printers/printer.model';
import { TrainingCenterConfiguration } from './../model/training-center/training-center-configuration.model';
import { ApplicationStateModel } from './application-state.model';
import { FeedbackInformation } from './feedback-information.model';
import { CheckinFormFields } from '@core/model/form/checkin-form-fields.model';

export enum ActionTypes {
  SetFacialCompareResult = '[SetFacialCompareResult] action',
  ResetFeedbackInformation = '[ResetResetFeedbackInformation] action',
  ResetScan = '[ResetScan] action',
  ResetTraining = '[ResetTraining] action',
  DoFaceCompare = '[DoFaceCompare] action',
  ResetSession = '[ResetSession] action',
  SetAircraft = '[SetAircraft] action',
  SetReservations = '[SetReservations] action',
  SetTraining = '[SetTraining] action',
  SetCustomer = '[SetCustomer] action',
  SetTrainingStartDate = '[SetTrainingStartDate] action',
  SetTrainingEndDate = '[SetTrainingEndDate] action',
  SetBadgePrinted = '[SetBadgePrinted] action',
  SetBadgePrintRequest = '[SetBadgePrintRequest] action',
  SetIdentityPolicyConsentDate = '[SetIdentityPolicyConsentDate] action',
  RestoreState = '[RestoreState] action',
  SetIDCardCameraSelection = '[SetIDCardCameraSelection] action',
  SetSkipBadgePrinting = '[SetSkipBadgePrinting] action',
  SetSkipTraining = '[SetSkipTraining] action',
  SetVisitor = '[SetVisitor] action',
  SetAppLoadedDateTime = '[SetAppLoadedDateTime] action',
  SetCheckinFlow = '[SetCheckinFlow] action',
  SetRecordingType = '[SetRecordingType] action',
  SetCovidAcknowledgementDate = '[SetCovidAcknowledgementDate] action',
  SetDeviceConfiguration = '[SetDeviceConfiguration] action',
  SetDeviceRegistration = '[SetDeviceRegistration] action',
  SetBackButtonDeactivated = '[SetBackButtonDeactivated] action',
  SetFeedbackInformation = '[SetFeedbackInformation] action',
}

export enum VisitorActionTypes {
  SetVisitorIdFacePhoto = '[SetVisitorIdFacePhoto] action',
  SetVisitorFacePhoto = '[SetVisitorFacePhoto] action',
  SetVisitorFirstName = '[SetVisitorFirstName] action',
  SetExportControl = '[SetExportControl] action',
}

export enum VisitActionTypes {
  SetVisitStartDate = '[SetVisitStartDate] action',
  SetVisitEndDate = '[SetVisitEndDate] action',
  SetVisitBadgeNumber = '[SetVisitBadgeNumber] action',
  SetVisitReason = '[SetVisitReason] action',
  SetVisitId = '[SetVisitId] action',
  SetVisit = '[SetVisit] action',
  SetVisitorType = '[SetVisitorType] action',
  SetVisitorLastName = '[SetVisitorLastName] action',
  SetVisitorMiddleName = '[SetVisitorMiddleName] action',
  SetVisitorDateOfBirth = '[SetVisitorDateOfBirth] action',
  SetVisitorNationality = '[SetVisitorNationality] action',
  SetVisitorCountryOfResidency = '[SetVisitorCountryOfResidency] action',
  SetVisitorHotelName = '[SetVisitorHotelName] action',
  SetVisitorHotelPhone = '[SetVisitorHotelPhone] action',
  SetVisitorEmail = '[SetVisitorEmail] action',
  SetVisitorEmailConsentDate = '[SetVisitorEmailConsentDate] action',
  SetVisitorContactName = '[SetVisitorContactName] action',
  SetTraineeType = '[SetTraineeType] action',
  SetVisitorPhoneNumber = '[SetVisitorPhoneNumber] action',
  SetVisitorHostName = '[SetVisitorHostName] action',
  SetVisitorNationalityId = '[SetVisitorNationalityId] action',
  SetVisitorCountryOfResidencyId = '[SetVisitorCountryOfResidencyId] action',
}

export enum FlowActionTypes {
  SetByPasses = '[SetByPasses] action',
  SetCamundaTask = '[SetCamundaTask] action',
  SetCamundaProcessInstanceWithVariables = '[SetCamundaProcessInstanceWithVariables] action',
  SetBadgeValidity = '[SetBadgeValidity] action',
  SetCamundaTaskCustomPropertiesMap = '[SetCamundaTaskCustomPropertiesMap] action',
  SetIdentityDocumentType = '[SetIdentityDocumentType] action',
  SetTraining = '[SetTraining] action',
}

export enum TechnicalActionTypes {
  SetTrainingCenter = '[SetTrainingCenter] action',
  SetTechnicalTrainingCenters = '[SetTechnicalTrainingCenters] action',
  SetTrainingCenterConfiguration = '[SetTrainingCenterConfiguration] action',
  SetPrinters = '[SetPrinters] action',
  SetSteps = '[SetSteps] action',
  SetIsAdministratorAuthenticated = '[SetIsAdministratorAuthenticated] action',
  SetAppVersionNumber = '[SetAppVersionNumber] action',
  SetCheckinFormFields = '[SetCheckinFormFields] action',
}

export enum DeviceConfigurationActionTypes {
  SetDeviceConfigurationCheckinProcessConfiguration = '[SetDeviceConfigurationCheckinProcessConfiguration] action',
  SetDrk = '[SetDrk] action',
  SetTrainingCenter = '[SetTrainingCenter] action',
  SetDeviceId = '[SetDeviceId] action',
  SetPrinter = '[SetPrinter] action',
  SetPassportScannerIp = '[SetPassportScannerIp] action',
  SetPassportScannerConnected = '[SetPassportScannerConnected] action',
  SetDeviceInventoryNumber = '[SetDeviceInventoryNumber] action',
  SetDeviceSerialNumber = '[SetDeviceSerialNumber] action',
}

export class SetFacialComparisonResult {
  public static readonly type = ActionTypes.SetFacialCompareResult;
  constructor(public payload: FacesCompareResult) { }
}

export class ResetFeedbackInformation {
  public static readonly type = ActionTypes.ResetFeedbackInformation;
}


export class ResetScan {
  public static readonly type = ActionTypes.ResetScan;
}

export class ResetTraining {
  public static readonly type = ActionTypes.ResetTraining;
}

export class DoFaceCompare {
  public static readonly type = ActionTypes.DoFaceCompare;
}

export class ResetSession {
  public static readonly type = ActionTypes.ResetSession;
}

export class SetAircraft {
  public static readonly type = ActionTypes.SetAircraft;
  constructor(public payload: Aircraft) { }
}

export class SetTraining {
  public static readonly type = ActionTypes.SetTraining;
  constructor(public payload: Training) { }
}

export class SetReservations {
  public static readonly type = ActionTypes.SetReservations;
  constructor(public payload: Reservation[]) { }
}

export class SetCustomer {
  public static readonly type = ActionTypes.SetCustomer;
  constructor(public payload: Customer) { }
}

export class SetTrainingCenter {
  public static readonly type = TechnicalActionTypes.SetTrainingCenter;
  constructor(public payload: TrainingCenter) { }
}

export class SetTechnicalTrainingCenters {
  public static readonly type = TechnicalActionTypes.SetTechnicalTrainingCenters;
  constructor(public payload: TrainingCenter[]) { }
}

export class SetTrainingCenterConfiguration {
  public static readonly type = TechnicalActionTypes.SetTrainingCenterConfiguration;
  constructor(public payload: TrainingCenterConfiguration) { }
}

export class SetPrinters {
  public static readonly type = TechnicalActionTypes.SetPrinters;
  constructor(public payload: Printer[]) { }
}

export class SetBadgePrinted {
  public static readonly type = ActionTypes.SetBadgePrinted;
  constructor(public payload: boolean) { }
}

export class SetIdentityPolicyConsentDate {
  public static readonly type = ActionTypes.SetIdentityPolicyConsentDate;
  constructor(public payload: Date) { }
}

export class SetBadgePrintRequest {
  public static readonly type = ActionTypes.SetBadgePrintRequest;
  constructor(public payload: boolean) { }
}

export class RestoreState {
  public static readonly type = ActionTypes.RestoreState;
  constructor(public payload: ApplicationStateModel | StateOperator<ApplicationStateModel>) { }
}

export class SetIDCardCameraSelection {
  public static readonly type = ActionTypes.SetIDCardCameraSelection;
  constructor(public payload: CheckinCameraType) { }
}

export class SetSkipBadgePrinting {
  public static readonly type = ActionTypes.SetSkipBadgePrinting;
  constructor(public payload: boolean) { }
}

export class SetSkipTraining {
  public static readonly type = ActionTypes.SetSkipTraining;
  constructor(public payload: boolean) { }
}

export class SetAppLoadedDateTime {
  public static readonly type = ActionTypes.SetAppLoadedDateTime;
  constructor(public payload: Date) { }
}

export class SetCheckinFlow {
  public static readonly type = ActionTypes.SetCheckinFlow;
  constructor(public payload: FlowType) { }
}

export class SetRecordingType {
  public static readonly type = ActionTypes.SetRecordingType;
  constructor(public payload: RecordingType) { }
}

export class SetCovidAcknowledgementDate {
  public static readonly type = ActionTypes.SetCovidAcknowledgementDate;
  constructor(public payload: Date) { }
}

export class SetVisit {
  public static readonly type = VisitActionTypes.SetVisit;
  constructor(public payload: Visit) { }
}

export class SetVisitorType {
  public static readonly type = VisitActionTypes.SetVisitorType;
  constructor(public payload: VisitorType) { }
}

export class SetTraineeType {
  public static readonly type = VisitActionTypes.SetTraineeType;
  constructor(public payload: TraineeType) { }
}

export class SetVisitorIdFacePhoto {
  public static readonly type = VisitorActionTypes.SetVisitorIdFacePhoto;
  constructor(public payload: string) { }
}

export class SetVisitorFacePhoto {
  public static readonly type = VisitorActionTypes.SetVisitorFacePhoto;
  constructor(public payload: string) { }
}

export class SetVisitorFirstName {
  public static readonly type = VisitorActionTypes.SetVisitorFirstName;
  constructor(public payload: string) { }
}

export class SetVisitorLastName {
  public static readonly type = VisitActionTypes.SetVisitorLastName;
  constructor(public payload: string) { }
}

export class SetVisitorMiddleName {
  public static readonly type = VisitActionTypes.SetVisitorMiddleName;
  constructor(public payload: string) { }
}

export class SetVisitorDateOfBirth {
  public static readonly type = VisitActionTypes.SetVisitorDateOfBirth;
  constructor(public payload: Date) { }
}

export class SetVisitorNationality {
  public static readonly type = VisitActionTypes.SetVisitorNationality;
  constructor(public payload: Nationality) { }
}
export class SetVisitorNationalityId {
  public static readonly type = VisitActionTypes.SetVisitorNationalityId;
  constructor(public payload: string) { }
}

export class SetVisitorCountryOfResidency {
  public static readonly type = VisitActionTypes.SetVisitorCountryOfResidency;
  constructor(public payload: Country) { }
}

export class SetVisitorCountryOfResidencyId {
  public static readonly type = VisitActionTypes.SetVisitorCountryOfResidency;
  constructor(public payload: string) { }
}

export class SetVisitorHotelName {
  public static readonly type = VisitActionTypes.SetVisitorHotelName;
  constructor(public payload: string) { }
}

export class SetVisitorHotelPhone {
  public static readonly type = VisitActionTypes.SetVisitorHotelPhone;
  constructor(public payload: string) { }
}

export class SetVisitorEmail {
  public static readonly type = VisitActionTypes.SetVisitorEmail;
  constructor(public payload: string) { }
}

export class SetVisitorEmailConsentDate {
  public static readonly type = VisitActionTypes.SetVisitorEmailConsentDate;
  constructor(public payload: Date) { }
}

export class SetVisitorPhoneNumber {
  public static readonly type = VisitActionTypes.SetVisitorPhoneNumber;
  constructor(public payload: string) { }
}

export class SetVisitorContactName {
  public static readonly type = VisitActionTypes.SetVisitorContactName;
  constructor(public payload: string) { }
}

export class SetVisitorHostName {
  public static readonly type = VisitActionTypes.SetVisitorContactName;
  constructor(public payload: string) { }
}

export class SetVisitStartDate {
  public static readonly type = VisitActionTypes.SetVisitStartDate;
  constructor(public payload: Date) { }
}

export class SetVisitEndDate {
  public static readonly type = VisitActionTypes.SetVisitEndDate;
  constructor(public payload: Date) { }
}

export class SetVisitBadgeNumber {
  public static readonly type = VisitActionTypes.SetVisitBadgeNumber;
  constructor(public payload: string) { }
}

export class SetByPasses {
  public static readonly type = FlowActionTypes.SetByPasses;
  constructor(public payload: ByPassInformation[]) { }
}
export class SetVisitReason {
  public static readonly type = VisitActionTypes.SetVisitReason;
  constructor(public payload: string) { }
}

export class SetCamundaTask {
  public static readonly type = FlowActionTypes.SetCamundaTask;
  constructor(public payload: CamundaTask) { }
}
export class SetCamundaProcessInstanceWithVariables {
  public static readonly type = FlowActionTypes.SetCamundaProcessInstanceWithVariables;
  constructor(public payload: CamundaProcessInstanceWithVariables) { }
}
export class SetVisitId {
  public static readonly type = VisitActionTypes.SetVisitId;
  constructor(public payload: string) { }
}

export class SetBadgeValidity {
  public static readonly type = FlowActionTypes.SetBadgeValidity;
  constructor(public payload: BadgeValidity) { }
}

export class SetCamundaTaskCustomPropertiesMap {
  public static readonly type = FlowActionTypes.SetCamundaTaskCustomPropertiesMap;
  constructor(public payload: Map<string, CamundaTaskCustomProperties>) { }
}

export class SetDeviceConfiguration {
  public static readonly type = ActionTypes.SetDeviceConfiguration;
  constructor(public payload: DeviceConfiguration) { }
}

export class SetDeviceRegistration {
  public static readonly type = ActionTypes.SetDeviceRegistration;
  constructor(public payload: DeviceRegistration) { }
}

export class SetDeviceId {
  public static readonly type = DeviceConfigurationActionTypes.SetDeviceId;
  constructor(public payload: string) { }
}


export class SetFeedbackInformation {
  public static readonly type = ActionTypes.SetFeedbackInformation;
  constructor(public payload: FeedbackInformation) { }
}

export class SetPrinter {
  public static readonly type = DeviceConfigurationActionTypes.SetPrinter;
  constructor(public payload: Printer) { }
}

export class SetExportControl {
  public static readonly type = VisitorActionTypes.SetExportControl;
  constructor(public payload: ExportControl) { }
}

export class SetSteps {
  public static readonly type = TechnicalActionTypes.SetSteps;
  constructor(public payload: Step[]) { }
}

export class SetIdentityDocumentType {
  public static readonly type = FlowActionTypes.SetIdentityDocumentType;
  constructor(public payload: EIdentityDocumentType) { }
}

export class SetPassportScannerIp {
  public static readonly type = DeviceConfigurationActionTypes.SetPassportScannerIp;
  constructor(public payload: string) { }
}

export class SetDeviceInventoryNumber {
  public static readonly type = DeviceConfigurationActionTypes.SetDeviceInventoryNumber;
  constructor(public payload: string) { }
}

export class SetDeviceSerialNumber {
  public static readonly type = DeviceConfigurationActionTypes.SetDeviceSerialNumber;
  constructor(public payload: string) { }
}

export class SetAppVersionNumber {
  public static readonly type = TechnicalActionTypes.SetAppVersionNumber;
  constructor(public payload: string) { }
}


export class SetPassportScannerConnected {
  public static readonly type = DeviceConfigurationActionTypes.SetPassportScannerConnected;
  constructor(public payload: boolean) { }
}

export class SetIsAdministratorAuthenticated {
  public static readonly type = TechnicalActionTypes.SetIsAdministratorAuthenticated;
  constructor(public payload: boolean) { }
}
export class SetBackButtonDeactivated {
  public static readonly type = ActionTypes.SetBackButtonDeactivated;
  constructor(public payload: boolean) { }
}

export class SetCheckinFormFields {
  public static readonly type = TechnicalActionTypes.SetCheckinFormFields;
  constructor(public payload: CheckinFormFields) { }
}


