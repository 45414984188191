import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import { ApiError } from '@core/model/entity/api-error.model';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private formatErrors(error: HttpErrorResponse) {
    if (error instanceof HttpErrorResponse && error.status === 404) {
      return of(null);
    }

    const finalError = error.error ? error.error : error;
    const apiError = new ApiError(error.status, finalError.code, finalError.message);
    return throwError(() => apiError);
  }

  get<T>(path: string, options: HttpOptions = {}): Observable<T> {
    return this.http
      .get<T>(`${environment.api.baseUrl}${path}`, options)
      .pipe(catchError(this.formatErrors));
  }

  put<T>(path: string, body: any = {}, options: HttpOptions = {}): Observable<T> {
    return this.http
      .put<T>(`${environment.api.baseUrl}${path}`, body, options)
      .pipe(catchError(this.formatErrors));
  }

  post<T>(path: string, body: any = {}, options: HttpOptions = {}): Observable<T> {
    return this.http
      .post<T>(`${environment.api.baseUrl}${path}`, body, options)
      .pipe(catchError(this.formatErrors));
  }

  delete<T>(path, options: HttpOptions = {}): Observable<any> {
    return this.http
      .delete(`${environment.api.baseUrl}${path}`, options)
      .pipe(catchError(this.formatErrors));
  }
}

export interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}
