import { Injectable, Injector } from '@angular/core';

import { LogAnalytic } from '@core/model/log/log-analytic.model';
import { LogConsole } from '@core/model/log/log-console.model';
import { LogPublisher } from '@core/model/log/log-publishers';
import { isAppInsightActive } from '@core/app-settings';

// ****************************************************
// Logging Publishers Service Class
// ****************************************************
@Injectable({
  providedIn: 'root',
})
export class LogPublishersService {
  constructor(private injector: Injector) {
    // Build publishers arrays
    this.buildPublishers();
  }

  // Public properties
  publishers: LogPublisher[] = [];

  // *************************
  // Public methods
  // *************************
  // Build publishers array
  buildPublishers(): void {
    // Create instance of LogConsole Class
    this.publishers.push(new LogConsole());
    if (isAppInsightActive()) {
      this.publishers.push(new LogAnalytic(this.injector));
    }
  }
}
