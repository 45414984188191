import { LDClient, LDOptions, LDUser, initialize } from 'ldclient-js';

import { Feature } from './feature.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LaunchDarklyService {
  ldClient: LDClient;

  public featureValueChanged = new Subject<Feature>();

  initialize(apiKey: string, isEnabled: boolean, user: LDUser, options?: LDOptions) {
    return new Promise<void>((resolve, reject) => {
      if (!isEnabled) {
        resolve();
      } else {
        this.ldClient = initialize(apiKey, user, options);
        this.ldClient.on('ready', () => {
          this.onReady();
          resolve();
        });
      }
    });
  }

  identify(user: LDUser) {
    if (this.ldClient) {
      this.ldClient.identify(user);
    }
  }

  getFlag(featureName: string) {
    if (this.ldClient) {
      return this.ldClient.variation(featureName);
    }
  }

  getAllFlags() {
    const features = new Array<Feature>();
    if (this.ldClient) {
      const allFlags = this.ldClient.allFlags();
      for (const feature of Object.keys(allFlags)) {
        features.push(new Feature(feature, allFlags[feature]));
      }
    }
    return features;
  }

  private onReady() {
    if (this.ldClient) {
      this.ldClient.on('change', (settings) => {
        this.onFeatureFlagsChanged(settings);
      });
    }
  }

  private onFeatureFlagsChanged(settings) {
    for (const feature of Object.keys(settings)) {
      this.featureValueChanged.next(new Feature(feature, settings[feature].current));
    }
  }
}
