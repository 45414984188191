import { CheckinCameraType } from '@core/enums/checkin-camera-type.enum';
import { Guid } from 'guid-typescript';
import { Printer } from '@core/model/printers/printer.model';
import { Step } from '@core/model/entity/step.model';
import { TrainingCenter } from '@core/model/entity/training-center.model';
import { TrainingCenterConfiguration } from '@core/model/training-center/training-center-configuration.model';
import { CheckinFormFields } from '@core/model/form/checkin-form-fields.model';

export class TechnicalInformation {
  trainingCenter: TrainingCenter;
  technicalTrainingCenters: TrainingCenter[];
  trainingCenterConfiguration: TrainingCenterConfiguration;
  checkinFormFields: CheckinFormFields;
  idCardCameraSelection: CheckinCameraType;
  skipBadgePrinting: boolean;
  skipTraining: boolean;
  sessionId: Guid;
  appLoadedDateTime: Date;
  steps: Step[];
  printers: Printer[];
  isAdministratorAuthenticated: boolean;
  appVersion: string;

  constructor(data?: TechnicalInformation) {
    this.sessionId = Guid.create();
    this.idCardCameraSelection = CheckinCameraType.Front;
    this.skipBadgePrinting = false;
    this.skipTraining = false;
    Object.assign(this, data);
  }
}
