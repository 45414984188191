import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppRoutePaths } from '@core/constants';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from '@env';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const baseUrl = environment.api.baseUrl;

        if (error.url.includes(baseUrl)) {
          if (error.status === 0) {
            this.router.navigate([`/${AppRoutePaths.Error}`]);
          }

          if (error.status >= 500 && error.status <= 511) {
            this.router.navigate([`/${AppRoutePaths.Error}`]);
          }
        }

        return throwError(() => error);
      })
    );
  }
}
