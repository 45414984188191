import { HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  addOptionsToHttpHeaders,
  addToHttpParams,
  canConsumeForm,
} from '@core/utils/http-params.util';
import { CamundaPatchVariables } from '@data/models/camunda/camunda-patch-variables.model';
import { CamundaVariableValue } from '@data/models/camunda/camunda-variable-value.model';
import { ApiService } from '@data/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskVariableService {
  private endpoint = '/camunda/task';
  public defaultHeaders = new HttpHeaders();
  constructor(private apiService: ApiService) {}

  /**
   * Removes a variable that is visible to a task. A variable is visible to a task if it is a local task variable or declared in a parent scope of the task. See documentation on [visiblity of variables](https://docs.camunda.org/manual/7.14/user-guide/process-engine/variables/).
   * @param id The id of the task to delete the variable from.
   * @param varName The name of the variable to be removed.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteTaskVariable(
    id: string,
    varName: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public deleteTaskVariable(
    id: string,
    varName: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public deleteTaskVariable(
    id: string,
    varName: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public deleteTaskVariable(
    id: string,
    varName: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteTaskVariable.'
      );
    }
    if (varName === null || varName === undefined) {
      throw new Error(
        'Required parameter varName was null or undefined when calling deleteTaskVariable.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.delete<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables/${encodeURIComponent(
        String(varName)
      )}`,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves a variable from the context of a given task. The variable must be visible from the task. It is visible from the task if it is a local task variable or declared in a parent scope of the task. See documentation on [visiblity of variables](https://docs.camunda.org/manual/7.14/user-guide/process-engine/variables/).
   * @param id The id of the task to retrieve the variable from.
   * @param varName The name of the variable to get.
   * @param deserializeValue Determines whether serializable variable values (typically variables that store custom Java objects) should be deserialized on the server side (default &#x60;true&#x60;).  If set to &#x60;true&#x60;, a serializable variable will be deserialized on server side and transformed to JSON using [Jackson\&#39;s](https://github.com/FasterXML/jackson) POJO/bean property introspection feature. Note that this requires the Java classes of the variable value to be on the REST API\&#39;s classpath.  If set to &#x60;false&#x60;, a serializable variable will be returned in its serialized format. For example, a variable that is serialized as XML will be returned as a JSON string containing XML.  Note: While &#x60;true&#x60; is the default value for reasons of backward compatibility, we recommend setting this parameter to &#x60;false&#x60; when developing web applications that are independent of the Java process applications deployed to the engine.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTaskVariable(
    id: string,
    varName: string,
    deserializeValue?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaVariableValue>;
  public getTaskVariable(
    id: string,
    varName: string,
    deserializeValue?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaVariableValue>>;
  public getTaskVariable(
    id: string,
    varName: string,
    deserializeValue?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaVariableValue>>;
  public getTaskVariable(
    id: string,
    varName: string,
    deserializeValue?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getTaskVariable.');
    }
    if (varName === null || varName === undefined) {
      throw new Error(
        'Required parameter varName was null or undefined when calling getTaskVariable.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let queryParameters = new HttpParams();
    if (deserializeValue !== undefined && deserializeValue !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deserializeValue, 'deserializeValue');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaVariableValue>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables/${encodeURIComponent(
        String(varName)
      )}`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves a binary variable from the context of a given task. Applicable for byte array and file variables. The variable must be visible from the task. It is visible from the task if it is a local task variable or declared in a parent scope of the task. See documentation on [visiblity of variables](https://docs.camunda.org/manual/7.14/user-guide/process-engine/variables/).
   * @param id The id of the task to retrieve the variable for.
   * @param varName The name of the variable to retrieve.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTaskVariableBinary(
    id: string,
    varName: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | 'text/plain' | 'application/json';
      headerContentType?: 'application/octet-stream' | 'text/plain' | 'application/json';
    }
  ): Observable<Blob>;
  public getTaskVariableBinary(
    id: string,
    varName: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | 'text/plain' | 'application/json';
      headerContentType?: 'application/octet-stream' | 'text/plain' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getTaskVariableBinary(
    id: string,
    varName: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | 'text/plain' | 'application/json';
      headerContentType?: 'application/octet-stream' | 'text/plain' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getTaskVariableBinary(
    id: string,
    varName: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/octet-stream' | 'text/plain' | 'application/json';
      headerContentType?: 'application/octet-stream' | 'text/plain' | 'application/json';
    }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getTaskVariableBinary.'
      );
    }
    if (varName === null || varName === undefined) {
      throw new Error(
        'Required parameter varName was null or undefined when calling getTaskVariableBinary.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables/${encodeURIComponent(
        String(varName)
      )}/data`,
      {
        responseType: 'blob',
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves all variables visible from the task. A variable is visible from the task if it is a local task variable or declared in a parent scope of the task. See documentation on [visiblity of variables](https://docs.camunda.org/manual/7.14/user-guide/process-engine/variables/).
   * @param id The id of the task to retrieve the variables from.
   * @param deserializeValue Determines whether serializable variable values (typically variables that store custom Java objects) should be deserialized on the server side (default &#x60;true&#x60;). If set to &#x60;true&#x60;, a serializable variable will be deserialized on server side and transformed to JSON using [Jackson\&#39;s](https://github.com/FasterXML/jackson) POJO/bean property introspection feature. Note that this requires the Java classes of the variable value to be on the REST API\&#39;s classpath.  If set to &#x60;false&#x60;, a serializable variable will be returned in its serialized format. For example, a variable that is serialized as XML will be returned as a JSON string containing XML.  Note: While &#x60;true&#x60; is the default value for reasons of backward compatibility, we recommend setting this parameter to &#x60;false&#x60; when developing web applications that are independent of the Java process applications deployed to the engine.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTaskVariables(
    id: string,
    deserializeValue?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<{ [key: string]: CamundaVariableValue }>;
  public getTaskVariables(
    id: string,
    deserializeValue?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<{ [key: string]: CamundaVariableValue }>>;
  public getTaskVariables(
    id: string,
    deserializeValue?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<{ [key: string]: CamundaVariableValue }>>;
  public getTaskVariables(
    id: string,
    deserializeValue?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getTaskVariables.');
    }

    let queryParameters = new HttpParams();
    if (deserializeValue !== undefined && deserializeValue !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deserializeValue, 'deserializeValue');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<{ [key: string]: CamundaVariableValue }>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables`,
      {
        params: queryParameters,
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Updates or deletes the variables visible from the task. Updates precede deletions. So, if a variable is updated AND deleted, the deletion overrides the update. A variable is visible from the task if it is a local task variable or declared in a parent scope of the task. See documentation on [visiblity of variables](https://docs.camunda.org/manual/7.14/user-guide/process-engine/variables/).
   * @param id The id of the task to set variables for.
   * @param camundaPatchVariablesDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public modifyTaskVariables(
    id: string,
    camundaPatchVariablesDto?: CamundaPatchVariables,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public modifyTaskVariables(
    id: string,
    camundaPatchVariablesDto?: CamundaPatchVariables,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public modifyTaskVariables(
    id: string,
    camundaPatchVariablesDto?: CamundaPatchVariables,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public modifyTaskVariables(
    id: string,
    camundaPatchVariablesDto?: CamundaPatchVariables,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling modifyTaskVariables.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables`,
      camundaPatchVariablesDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Updates a process variable that is visible from the Task scope. A variable is visible from the task if it is a local task variable, or declared in a parent scope of the task. See the documentation on [variable scopes and visibility](https://docs.camunda.org/manual/7.14/user-guide/process-engine/variables#variable-scopes-and-variable-visibility).  **Note**: If a variable doesn\&#39;t exist, the variable is created in the top-most scope visible from the task.
   * @param id The id of the task to set the variable for.
   * @param varName The name of the variable to set.
   * @param camundaVariableValueDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public putTaskVariable(
    id: string,
    varName: string,
    camundaVariableValueDto?: CamundaVariableValue,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public putTaskVariable(
    id: string,
    varName: string,
    camundaVariableValueDto?: CamundaVariableValue,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public putTaskVariable(
    id: string,
    varName: string,
    camundaVariableValueDto?: CamundaVariableValue,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public putTaskVariable(
    id: string,
    varName: string,
    camundaVariableValueDto?: CamundaVariableValue,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling putTaskVariable.');
    }
    if (varName === null || varName === undefined) {
      throw new Error(
        'Required parameter varName was null or undefined when calling putTaskVariable.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables/${encodeURIComponent(
        String(varName)
      )}`,
      camundaVariableValueDto,
      {
        responseType: <any>responseType,

        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Sets the serialized value for a binary variable or the binary value for a file variable visible from the task. A variable is visible from the task if it is a local task variable or declared in a parent scope of the task. See documentation on [visiblity of variables](https://docs.camunda.org/manual/7.14/user-guide/process-engine/variables/).
   * @param id The id of the task to retrieve the variable for.
   * @param varName The name of the variable to retrieve.
   * @param data The binary data to be set. For File variables, this multipart can contain the filename, binary value and MIME type of the file variable to be set Only the filename is mandatory.
   * @param valueType The name of the variable type. Either Bytes for a byte array variable or File for a file variable.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setBinaryTaskVariable(
    id: string,
    varName: string,
    data?: Blob,
    valueType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public setBinaryTaskVariable(
    id: string,
    varName: string,
    data?: Blob,
    valueType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public setBinaryTaskVariable(
    id: string,
    varName: string,
    data?: Blob,
    valueType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public setBinaryTaskVariable(
    id: string,
    varName: string,
    data?: Blob,
    valueType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling setBinaryTaskVariable.'
      );
    }
    if (varName === null || varName === undefined) {
      throw new Error(
        'Required parameter varName was null or undefined when calling setBinaryTaskVariable.'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const useForm = canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams();
    }

    if (data !== undefined) {
      formParams = (formParams.append('data', <any>data) as any) || formParams;
    }
    if (valueType !== undefined) {
      formParams = (formParams.append('valueType', <any>valueType) as any) || formParams;
    }

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables/${encodeURIComponent(
        String(varName)
      )}/data`,
      formParams,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
