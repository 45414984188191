import { HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addOptionsToHttpHeaders, addToHttpParams } from '@core/utils/http-params.util';
import { CamundaTaskEscalation } from '@data/models/camunda/camuda-task-escalation.model';
import { CamundaCompleteTask } from '@data/models/camunda/camunda-complete-task.model';
import { CamundaCountResult } from '@data/models/camunda/camunda-count-result.model';
import { CamundaForm } from '@data/models/camunda/camunda-form.model';
import { CamundaTaskBpmnError } from '@data/models/camunda/camunda-task-bpmn-error.model';
import { CamundaTaskQuery } from '@data/models/camunda/camunda-task-query.model';
import { CamundaTask } from '@data/models/camunda/camunda-task.model';
import { CamundaUserId } from '@data/models/camunda/camunda-user-id.model';
import { CamundaVariableValue } from '@data/models/camunda/camunda-variable-value.model';
import { ApiService } from '@data/services/api.service';
import { Observable } from 'rxjs';

type TasksCountQueryParams = {
  processInstanceId?: string;
  processInstanceIdIn?: string;
  processInstanceBusinessKey?: string;
  processInstanceBusinessKeyExpression?: string;
  processInstanceBusinessKeyIn?: string;
  processInstanceBusinessKeyLike?: string;
  processInstanceBusinessKeyLikeExpression?: string;
  processDefinitionId?: string;
  processDefinitionKey?: string;
  processDefinitionKeyIn?: string;
  processDefinitionName?: string;
  processDefinitionNameLike?: string;
  executionId?: string;
  caseInstanceId?: string;
  caseInstanceBusinessKey?: string;
  caseInstanceBusinessKeyLike?: string;
  caseDefinitionId?: string;
  caseDefinitionKey?: string;
  caseDefinitionName?: string;
  caseDefinitionNameLike?: string;
  caseExecutionId?: string;
  activityInstanceIdIn?: string;
  tenantIdIn?: string;
  withoutTenantId?: boolean;
  assignee?: string;
  assigneeExpression?: string;
  assigneeLike?: string;
  assigneeLikeExpression?: string;
  assigneeIn?: string;
  owner?: string;
  ownerExpression?: string;
  candidateGroup?: string;
  candidateGroupExpression?: string;
  candidateUser?: string;
  candidateUserExpression?: string;
  includeAssignedTasks?: boolean;
  involvedUser?: string;
  involvedUserExpression?: string;
  assigned?: boolean;
  unassigned?: boolean;
  taskDefinitionKey?: string;
  taskDefinitionKeyIn?: string;
  taskDefinitionKeyLike?: string;
  name?: string;
  nameNotEqual?: string;
  nameLike?: string;
  nameNotLike?: string;
  description?: string;
  descriptionLike?: string;
  priority?: number;
  maxPriority?: number;
  minPriority?: number;
  dueDate?: string;
  dueDateExpression?: string;
  dueAfter?: string;
  dueAfterExpression?: string;
  dueBefore?: string;
  dueBeforeExpression?: string;
  followUpDate?: string;
  followUpDateExpression?: string;
  followUpAfter?: string;
  followUpAfterExpression?: string;
  followUpBefore?: string;
  followUpBeforeExpression?: string;
  followUpBeforeOrNotExistent?: string;
  followUpBeforeOrNotExistentExpression?: string;
  createdOn?: string;
  createdOnExpression?: string;
  createdAfter?: string;
  createdAfterExpression?: string;
  createdBefore?: string;
  createdBeforeExpression?: string;
  delegationState?: 'PENDING' | 'RESOLVED';
  candidateGroups?: string;
  candidateGroupsExpression?: string;
  withCandidateGroups?: boolean;
  withoutCandidateGroups?: boolean;
  withCandidateUsers?: boolean;
  withoutCandidateUsers?: boolean;
  active?: boolean;
  suspended?: boolean;
  taskVariables?: string;
  processVariables?: string;
  caseInstanceVariables?: string;
  variableNamesIgnoreCase?: boolean;
  variableValuesIgnoreCase?: boolean;
  parentTaskId?: string;
};

type TasksQueryParams = {
  processInstanceId?: string;
  processInstanceIdIn?: string;
  processInstanceBusinessKey?: string;
  processInstanceBusinessKeyExpression?: string;
  processInstanceBusinessKeyIn?: string;
  processInstanceBusinessKeyLike?: string;
  processInstanceBusinessKeyLikeExpression?: string;
  processDefinitionId?: string;
  processDefinitionKey?: string;
  processDefinitionKeyIn?: string;
  processDefinitionName?: string;
  processDefinitionNameLike?: string;
  executionId?: string;
  caseInstanceId?: string;
  caseInstanceBusinessKey?: string;
  caseInstanceBusinessKeyLike?: string;
  caseDefinitionId?: string;
  caseDefinitionKey?: string;
  caseDefinitionName?: string;
  caseDefinitionNameLike?: string;
  caseExecutionId?: string;
  activityInstanceIdIn?: string;
  tenantIdIn?: string;
  withoutTenantId?: boolean;
  assignee?: string;
  assigneeExpression?: string;
  assigneeLike?: string;
  assigneeLikeExpression?: string;
  assigneeIn?: string;
  owner?: string;
  ownerExpression?: string;
  candidateGroup?: string;
  candidateGroupExpression?: string;
  candidateUser?: string;
  candidateUserExpression?: string;
  includeAssignedTasks?: boolean;
  involvedUser?: string;
  involvedUserExpression?: string;
  assigned?: boolean;
  unassigned?: boolean;
  taskDefinitionKey?: string;
  taskDefinitionKeyIn?: string;
  taskDefinitionKeyLike?: string;
  name?: string;
  nameNotEqual?: string;
  nameLike?: string;
  nameNotLike?: string;
  description?: string;
  descriptionLike?: string;
  priority?: number;
  maxPriority?: number;
  minPriority?: number;
  dueDate?: string;
  dueDateExpression?: string;
  dueAfter?: string;
  dueAfterExpression?: string;
  dueBefore?: string;
  dueBeforeExpression?: string;
  followUpDate?: string;
  followUpDateExpression?: string;
  followUpAfter?: string;
  followUpAfterExpression?: string;
  followUpBefore?: string;
  followUpBeforeExpression?: string;
  followUpBeforeOrNotExistent?: string;
  followUpBeforeOrNotExistentExpression?: string;
  createdOn?: string;
  createdOnExpression?: string;
  createdAfter?: string;
  createdAfterExpression?: string;
  createdBefore?: string;
  createdBeforeExpression?: string;
  delegationState?: 'PENDING' | 'RESOLVED';
  candidateGroups?: string;
  candidateGroupsExpression?: string;
  withCandidateGroups?: boolean;
  withoutCandidateGroups?: boolean;
  withCandidateUsers?: boolean;
  withoutCandidateUsers?: boolean;
  active?: boolean;
  suspended?: boolean;
  taskVariables?: string;
  processVariables?: string;
  caseInstanceVariables?: string;
  variableNamesIgnoreCase?: boolean;
  variableValuesIgnoreCase?: boolean;
  parentTaskId?: string;
  sortBy?:
    | 'instanceId'
    | 'caseInstanceId'
    | 'dueDate'
    | 'executionId'
    | 'caseExecutionId'
    | 'assignee'
    | 'created'
    | 'description'
    | 'id'
    | 'name'
    | 'nameCaseInsensitive'
    | 'priority'
    | 'processVariable'
    | 'executionVariable'
    | 'taskVariable'
    | 'caseExecutionVariable'
    | 'caseInstanceVariable';
  sortOrder?: 'asc' | 'desc';
  firstResult?: number;
  maxResults?: number;
};

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  private endpoint = '/camunda/task';
  public defaultHeaders = new HttpHeaders();
  constructor(private apiService: ApiService) {}

  /**
   * Claims a task for a specific user.  **Note:** The difference with the [Set Assignee](https://docs.camunda.org/manual/7.14/reference/rest/task/post-assignee/) method is that here a check is performed to see if the task already has a user assigned to it.
   * @param id The id of the task to claim.
   * @param camundaUserId Provide the id of the user that claims the task.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public claim(
    id: string,
    camundaUserId?: CamundaUserId,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public claim(
    id: string,
    camundaUserId?: CamundaUserId,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public claim(
    id: string,
    camundaUserId?: CamundaUserId,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public claim(
    id: string,
    camundaUserId?: CamundaUserId,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling claim.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/task/${encodeURIComponent(String(id))}/claim`,
      camundaUserId,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Completes a task and updates process variables.
   * @param id The id of the task to complete.
   * @param camundaCompleteTask
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public complete(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<{ [key: string]: CamundaVariableValue }>;
  public complete(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<{ [key: string]: CamundaVariableValue }>>;
  public complete(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<{ [key: string]: CamundaVariableValue }>>;
  public complete(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling complete.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<{ [key: string]: CamundaVariableValue }>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/complete`,
      camundaCompleteTask,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Creates a new task.
   * @param camundaTaskDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createTask(
    camundaTaskDto?: CamundaTask,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public createTask(
    camundaTaskDto?: CamundaTask,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public createTask(
    camundaTaskDto?: CamundaTask,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public createTask(
    camundaTaskDto?: CamundaTask,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(`${this.endpoint}/create`, camundaTaskDto, {
      responseType: <any>responseType,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Delegates a task to another user.
   * @param id The id of the task to delegate.
   * @param camundaUserId Provide the id of the user that the task should be delegated to.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public delegateTask(
    id: string,
    camundaUserId?: CamundaUserId,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public delegateTask(
    id: string,
    camundaUserId?: CamundaUserId,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public delegateTask(
    id: string,
    camundaUserId?: CamundaUserId,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public delegateTask(
    id: string,
    camundaUserId?: CamundaUserId,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling delegateTask.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/delegate`,
      camundaUserId,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Removes a task by id.
   * @param id The id of the task to be removed.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteTask(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public deleteTask(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public deleteTask(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public deleteTask(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteTask.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.delete(`${this.endpoint}/${encodeURIComponent(String(id))}`, {
      responseType: <any>responseType,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Retrieves the deployed form that is referenced from a given task. For further information please refer to the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#embedded-task-forms).
   * @param id The id of the task to get the deployed form for.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeployedForm(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<Blob>;
  public getDeployedForm(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getDeployedForm(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getDeployedForm(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getDeployedForm.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(`${this.endpoint}/${encodeURIComponent(String(id))}/deployed-form`, {
      responseType: 'blob',
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Retrieves the form key for a task. The form key corresponds to the &#x60;FormData#formKey&#x60; property in the engine. This key can be used to do task-specific form rendering in client applications. Additionally, the context path of the containing process application is returned.
   * @param id The id of the task to retrieve the form for.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getForm(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaForm>;
  public getForm(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaForm>>;
  public getForm(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaForm>>;
  public getForm(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getForm.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaForm>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/form`,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves the form variables for a task. The form variables take form data specified on the task into account. If form fields are defined, the variable types and default values of the form fields are taken into account.
   * @param id The id of the task to retrieve the variables for.
   * @param variableNames A comma-separated list of variable names. Allows restricting the list of requested variables to the variable names in the list. It is best practice to restrict the list of variables to the variables actually required by the form in order to minimize fetching of data. If the query parameter is ommitted all variables are fetched. If the query parameter contains non-existent variable names, the variable names are ignored.
   * @param deserializeValues Determines whether serializable variable values (typically variables that store custom Java objects) should be deserialized on server side (default true).  If set to true, a serializable variable will be deserialized on server side and transformed to JSON using [Jackson\&#39;s](http://jackson.codehaus.org/) POJO/bean property introspection feature. Note that this requires the Java classes of the variable value to be on the REST API\&#39;s classpath.  If set to false, a serializable variable will be returned in its serialized format. For example, a variable that is serialized as XML will be returned as a JSON string containing XML.  Note: While true is the default value for reasons of backward compatibility, we recommend setting this parameter to false when developing web applications that are independent of the Java process applications deployed to the engine.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFormVariables(
    id: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<{ [key: string]: CamundaVariableValue }>;
  public getFormVariables(
    id: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<{ [key: string]: CamundaVariableValue }>>;
  public getFormVariables(
    id: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<{ [key: string]: CamundaVariableValue }>>;
  public getFormVariables(
    id: string,
    variableNames?: string,
    deserializeValues?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getFormVariables.');
    }

    let queryParameters = new HttpParams();
    if (variableNames !== undefined && variableNames !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>variableNames, 'variableNames');
    }

    if (deserializeValues !== undefined && deserializeValues !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>deserializeValues,
        'deserializeValues'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<{ [key: string]: CamundaVariableValue }>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/form-variables`,
      {
        params: queryParameters,
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves the rendered form for a task. This method can be used to get the HTML rendering of a [Generated Task Form](https://docs.camunda.org/manual/7.14/user-guide/task-forms/#generated-task-forms).
   * @param id The id of the task to get the rendered form for.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRenderedForm(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,

    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<Blob>;
  public getRenderedForm(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getRenderedForm(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getRenderedForm(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/xhtml+xml' | 'application/json';
      headerContentType?: 'application/xhtml+xml' | 'application/json';
    }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getRenderedForm.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(`${this.endpoint}/${encodeURIComponent(String(id))}/rendered-form`, {
      responseType: 'blob',
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Retrieves a task by id.
   * @param id The id of the task to be retrieved.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTask(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaTask>;
  public getTask(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaTask>>;
  public getTask(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaTask>>;
  public getTask(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getTask.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaTask>(`${this.endpoint}/${encodeURIComponent(String(id))}`, {
      responseType: <any>responseType,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Queries for tasks that fulfill a given filter. The size of the result set can be retrieved by using the Get Task Count method.  **Security Consideration:** There are several query parameters (such as assigneeExpression) for specifying an EL expression. These are disabled by default to prevent remote code execution. See the section on [security considerations](https://docs.camunda.org/manual/7.14/user-guide/process-engine/securing-custom-code/) for custom code in the user guide for details.
   * @param tasksQueryParams.processInstanceId Restrict to tasks that belong to process instances with the given id.
   * @param tasksQueryParams.processInstanceIdIn Restrict to tasks that belong to process instances with the given ids.
   * @param tasksQueryParams.processInstanceBusinessKey Restrict to tasks that belong to process instances with the given business key.
   * @param tasksQueryParams.processInstanceBusinessKeyExpression Restrict to tasks that belong to process instances with the given business key which  is described by an expression. See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions.
   * @param tasksQueryParams.processInstanceBusinessKeyIn Restrict to tasks that belong to process instances with one of the give business keys.  The keys need to be in a comma-separated list.
   * @param tasksQueryParams.processInstanceBusinessKeyLike Restrict to tasks that have a process instance business key that has the parameter  value as a substring.
   * @param tasksQueryParams.processInstanceBusinessKeyLikeExpression Restrict to tasks that have a process instance business key that has the parameter  value as a substring and is described by an expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param tasksQueryParams.processDefinitionId Restrict to tasks that belong to a process definition with the given id.
   * @param tasksQueryParams.processDefinitionKey Restrict to tasks that belong to a process definition with the given key.
   * @param tasksQueryParams.processDefinitionKeyIn Restrict to tasks that belong to a process definition with one of the given keys. The  keys need to be in a comma-separated list.
   * @param tasksQueryParams.processDefinitionName Restrict to tasks that belong to a process definition with the given name.
   * @param tasksQueryParams.processDefinitionNameLike Restrict to tasks that have a process definition name that has the parameter value as  a substring.
   * @param tasksQueryParams.executionId Restrict to tasks that belong to an execution with the given id.
   * @param tasksQueryParams.caseInstanceId Restrict to tasks that belong to case instances with the given id.
   * @param tasksQueryParams.caseInstanceBusinessKey Restrict to tasks that belong to case instances with the given business key.
   * @param tasksQueryParams.caseInstanceBusinessKeyLike Restrict to tasks that have a case instance business key that has the parameter value  as a substring.
   * @param tasksQueryParams.caseDefinitionId Restrict to tasks that belong to a case definition with the given id.
   * @param tasksQueryParams.caseDefinitionKey Restrict to tasks that belong to a case definition with the given key.
   * @param tasksQueryParams.caseDefinitionName Restrict to tasks that belong to a case definition with the given name.
   * @param tasksQueryParams.caseDefinitionNameLike Restrict to tasks that have a case definition name that has the parameter value as a  substring.
   * @param tasksQueryParams.caseExecutionId Restrict to tasks that belong to a case execution with the given id.
   * @param tasksQueryParams.activityInstanceIdIn Only include tasks which belong to one of the passed and comma-separated activity  instance ids.
   * @param tasksQueryParams.tenantIdIn Only include tasks which belong to one of the passed and comma-separated  tenant ids.
   * @param tasksQueryParams.withoutTenantId Only include tasks which belong to no tenant. Value may only be &#x60;true&#x60;,  as &#x60;false&#x60; is the default behavior.
   * @param tasksQueryParams.assignee Restrict to tasks that the given user is assigned to.
   * @param tasksQueryParams.assigneeExpression Restrict to tasks that the user described by the given expression is assigned to.  See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param tasksQueryParams.assigneeLike Restrict to tasks that have an assignee that has the parameter  value as a substring.
   * @param tasksQueryParams.assigneeLikeExpression Restrict to tasks that have an assignee that has the parameter value described by the  given expression as a substring. See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param tasksQueryParams.assigneeIn Only include tasks which are assigned to one of the passed and  comma-separated user ids.
   * @param tasksQueryParams.owner Restrict to tasks that the given user owns.
   * @param tasksQueryParams.ownerExpression Restrict to tasks that the user described by the given expression owns. See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param tasksQueryParams.candidateGroup Only include tasks that are offered to the given group.
   * @param tasksQueryParams.candidateGroupExpression Only include tasks that are offered to the group described by the given expression.  See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param tasksQueryParams.candidateUser Only include tasks that are offered to the given user or to one of his groups.
   * @param tasksQueryParams.candidateUserExpression Only include tasks that are offered to the user described by the given expression.  See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param tasksQueryParams.includeAssignedTasks Also include tasks that are assigned to users in candidate queries. Default is to only  include tasks that are not assigned to any user if you query by candidate user or group(s).
   * @param tasksQueryParams.involvedUser Only include tasks that the given user is involved in. A user is involved in a task if  an identity link exists between task and user (e.g., the user is the assignee).
   * @param tasksQueryParams.involvedUserExpression Only include tasks that the user described by the given expression is involved in. A user is involved in a task if an identity link exists between task and user (e.g., the user is the assignee). See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions.
   * @param tasksQueryParams.assigned If set to &#x60;true&#x60;, restricts the query to all tasks that are assigned.
   * @param tasksQueryParams.unassigned If set to &#x60;true&#x60;, restricts the query to all tasks that are unassigned.
   * @param tasksQueryParams.taskDefinitionKey Restrict to tasks that have the given key.
   * @param tasksQueryParams.taskDefinitionKeyIn Restrict to tasks that have one of the given keys. The keys need to be in a comma-separated list.
   * @param tasksQueryParams.taskDefinitionKeyLike Restrict to tasks that have a key that has the parameter value as a substring.
   * @param tasksQueryParams.name Restrict to tasks that have the given name.
   * @param tasksQueryParams.nameNotEqual Restrict to tasks that do not have the given name.
   * @param tasksQueryParams.nameLike Restrict to tasks that have a name with the given parameter value as substring.
   * @param tasksQueryParams.nameNotLike Restrict to tasks that do not have a name with the given parameter value as substring.
   * @param tasksQueryParams.description Restrict to tasks that have the given description.
   * @param tasksQueryParams.descriptionLike Restrict to tasks that have a description that has the parameter value as a substring.
   * @param tasksQueryParams.priority Restrict to tasks that have the given priority.
   * @param tasksQueryParams.maxPriority Restrict to tasks that have a lower or equal priority.
   * @param tasksQueryParams.minPriority Restrict to tasks that have a higher or equal priority.
   * @param tasksQueryParams.dueDate Restrict to tasks that are due on the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.546+0200&#x60;.
   * @param tasksQueryParams.dueDateExpression Restrict to tasks that are due on the date described by the given expression. See the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param tasksQueryParams.dueAfter Restrict to tasks that are due after the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.435+0200&#x60;.
   * @param tasksQueryParams.dueAfterExpression Restrict to tasks that are due after the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param tasksQueryParams.dueBefore Restrict to tasks that are due before the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.243+0200&#x60;.
   * @param tasksQueryParams.dueBeforeExpression Restrict to tasks that are due before the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param tasksQueryParams.followUpDate Restrict to tasks that have a followUp date on the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.342+0200&#x60;.
   * @param tasksQueryParams.followUpDateExpression Restrict to tasks that have a followUp date on the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param tasksQueryParams.followUpAfter Restrict to tasks that have a followUp date after the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.542+0200&#x60;.
   * @param tasksQueryParams.followUpAfterExpression Restrict to tasks that have a followUp date after the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param tasksQueryParams.followUpBefore Restrict to tasks that have a followUp date before the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.234+0200&#x60;.
   * @param tasksQueryParams.followUpBeforeExpression Restrict to tasks that have a followUp date before the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param tasksQueryParams.followUpBeforeOrNotExistent Restrict to tasks that have no followUp date or a followUp date before the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.432+0200&#x60;. The typical use case is to query all &#x60;active&#x60; tasks for a user for a given date.
   * @param tasksQueryParams.followUpBeforeOrNotExistentExpression Restrict to tasks that have no followUp date or a followUp date before the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param tasksQueryParams.createdOn Restrict to tasks that were created on the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.324+0200&#x60;.
   * @param tasksQueryParams.createdOnExpression Restrict to tasks that were created on the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param tasksQueryParams.createdAfter Restrict to tasks that were created after the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.342+0200&#x60;.
   * @param tasksQueryParams.createdAfterExpression Restrict to tasks that were created after the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param tasksQueryParams.createdBefore Restrict to tasks that were created before the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.332+0200&#x60;.
   * @param tasksQueryParams.createdBeforeExpression Restrict to tasks that were created before the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param tasksQueryParams.delegationState Restrict to tasks that are in the given delegation state. Valid values are &#x60;PENDING&#x60; and &#x60;RESOLVED&#x60;.
   * @param tasksQueryParams.candidateGroups Restrict to tasks that are offered to any of the given candidate groups. Takes a comma-separated list of group names, so for example &#x60;developers,support,sales&#x60;.
   * @param tasksQueryParams.candidateGroupsExpression Restrict to tasks that are offered to any of the candidate groups described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to &#x60;java.util.List&#x60; of Strings.
   * @param tasksQueryParams.withCandidateGroups Only include tasks which have a candidate group. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param tasksQueryParams.withoutCandidateGroups Only include tasks which have no candidate group. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param tasksQueryParams.withCandidateUsers Only include tasks which have a candidate user. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param tasksQueryParams.withoutCandidateUsers Only include tasks which have no candidate users. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param tasksQueryParams.active Only include active tasks. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param tasksQueryParams.suspended Only include suspended tasks. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param tasksQueryParams.taskVariables Only include tasks that have variables with certain values. Variable filtering expressions are comma-separated and are structured as follows:  A valid parameter value has the form &#x60;key_operator_value&#x60;. &#x60;key&#x60; is the variable name, &#x60;operator&#x60; is the comparison operator to be used and &#x60;value&#x60; the variable value.  **Note**: Values are always treated as String objects on server side.  Valid &#x60;operator&#x60; values are: &#x60;eq&#x60; - equal to; &#x60;neq&#x60; - not equal to; &#x60;gt&#x60; - greater than; &#x60;gteq&#x60; - greater than or equal to; &#x60;lt&#x60; - lower than; &#x60;lteq&#x60; - lower than or equal to; &#x60;like&#x60;. &#x60;key&#x60; and &#x60;value&#x60; may not contain underscore or comma characters.
   * @param tasksQueryParams.processVariables Only include tasks that belong to process instances that have variables with certain  values. Variable filtering expressions are comma-separated and are structured as follows:  A valid parameter value has the form &#x60;key_operator_value&#x60;. &#x60;key&#x60; is the variable name, &#x60;operator&#x60; is the comparison operator to be used and &#x60;value&#x60; the variable value.  **Note**: Values are always treated as String objects on server side.  Valid &#x60;operator&#x60; values are: &#x60;eq&#x60; - equal to; &#x60;neq&#x60; - not equal to; &#x60;gt&#x60; - greater than; &#x60;gteq&#x60; - greater than or equal to; &#x60;lt&#x60; - lower than; &#x60;lteq&#x60; - lower than or equal to; &#x60;like&#x60;. &#x60;key&#x60; and &#x60;value&#x60; may not contain underscore or comma characters.
   * @param tasksQueryParams.caseInstanceVariables Only include tasks that belong to case instances that have variables with certain values. Variable filtering expressions are comma-separated and are structured as follows:  A valid parameter value has the form &#x60;key_operator_value&#x60;. &#x60;key&#x60; is the variable name, &#x60;operator&#x60; is the comparison operator to be used and &#x60;value&#x60; the variable value.  **Note**: Values are always treated as String objects on server side.  Valid &#x60;operator&#x60; values are: &#x60;eq&#x60; - equal to; &#x60;neq&#x60; - not equal to; &#x60;gt&#x60; - greater than; &#x60;gteq&#x60; - greater than or equal to; &#x60;lt&#x60; - lower than; &#x60;lteq&#x60; - lower than or equal to; &#x60;like&#x60;. &#x60;key&#x60; and &#x60;value&#x60; may not contain underscore or comma characters.
   * @param tasksQueryParams.variableNamesIgnoreCase Match all variable names in this query case-insensitively. If set &#x60;variableName&#x60; and &#x60;variablename&#x60; are treated as equal.
   * @param tasksQueryParams.variableValuesIgnoreCase Match all variable values in this query case-insensitively. If set &#x60;variableValue&#x60; and &#x60;variablevalue&#x60; are treated as equal.
   * @param tasksQueryParams.parentTaskId Restrict query to all tasks that are sub tasks of the given task. Takes a task id.
   * @param tasksQueryParams.sortBy Sort the results lexicographically by a given criterion. Must be used in conjunction with the sortOrder parameter.
   * @param tasksQueryParams.sortOrder Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
   * @param tasksQueryParams.firstResult Pagination of results. Specifies the index of the first result to return.
   * @param tasksQueryParams.maxResults Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTasks(
    tasksQueryParams: TasksQueryParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<Array<CamundaTask>>;
  public getTasks(
    tasksQueryParams: TasksQueryParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<Array<CamundaTask>>>;
  public getTasks(
    tasksQueryParams: TasksQueryParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams();
    if (
      tasksQueryParams.processInstanceId !== undefined &&
      tasksQueryParams.processInstanceId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        tasksQueryParams.processInstanceId,
        'processInstanceId'
      );
    }
    if (
      tasksQueryParams.processInstanceIdIn !== undefined &&
      tasksQueryParams.processInstanceIdIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processInstanceIdIn,
        'processInstanceIdIn'
      );
    }
    if (
      tasksQueryParams.processInstanceBusinessKey !== undefined &&
      tasksQueryParams.processInstanceBusinessKey !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processInstanceBusinessKey,
        'processInstanceBusinessKey'
      );
    }
    if (
      tasksQueryParams.processInstanceBusinessKeyExpression !== undefined &&
      tasksQueryParams.processInstanceBusinessKeyExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processInstanceBusinessKeyExpression,
        'processInstanceBusinessKeyExpression'
      );
    }
    if (
      tasksQueryParams.processInstanceBusinessKeyIn !== undefined &&
      tasksQueryParams.processInstanceBusinessKeyIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processInstanceBusinessKeyIn,
        'processInstanceBusinessKeyIn'
      );
    }
    if (
      tasksQueryParams.processInstanceBusinessKeyLike !== undefined &&
      tasksQueryParams.processInstanceBusinessKeyLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processInstanceBusinessKeyLike,
        'processInstanceBusinessKeyLike'
      );
    }
    if (
      tasksQueryParams.processInstanceBusinessKeyLikeExpression !== undefined &&
      tasksQueryParams.processInstanceBusinessKeyLikeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processInstanceBusinessKeyLikeExpression,
        'processInstanceBusinessKeyLikeExpression'
      );
    }
    if (
      tasksQueryParams.processDefinitionId !== undefined &&
      tasksQueryParams.processDefinitionId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processDefinitionId,
        'processDefinitionId'
      );
    }
    if (
      tasksQueryParams.processDefinitionKey !== undefined &&
      tasksQueryParams.processDefinitionKey !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processDefinitionKey,
        'processDefinitionKey'
      );
    }
    if (
      tasksQueryParams.processDefinitionKeyIn !== undefined &&
      tasksQueryParams.processDefinitionKeyIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processDefinitionKeyIn,
        'processDefinitionKeyIn'
      );
    }
    if (
      tasksQueryParams.processDefinitionName !== undefined &&
      tasksQueryParams.processDefinitionName !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processDefinitionName,
        'processDefinitionName'
      );
    }
    if (
      tasksQueryParams.processDefinitionNameLike !== undefined &&
      tasksQueryParams.processDefinitionNameLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processDefinitionNameLike,
        'processDefinitionNameLike'
      );
    }
    if (tasksQueryParams.executionId !== undefined && tasksQueryParams.executionId !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.executionId,
        'executionId'
      );
    }
    if (tasksQueryParams.caseInstanceId !== undefined && tasksQueryParams.caseInstanceId !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.caseInstanceId,
        'caseInstanceId'
      );
    }
    if (
      tasksQueryParams.caseInstanceBusinessKey !== undefined &&
      tasksQueryParams.caseInstanceBusinessKey !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.caseInstanceBusinessKey,
        'caseInstanceBusinessKey'
      );
    }
    if (
      tasksQueryParams.caseInstanceBusinessKeyLike !== undefined &&
      tasksQueryParams.caseInstanceBusinessKeyLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.caseInstanceBusinessKeyLike,
        'caseInstanceBusinessKeyLike'
      );
    }
    if (
      tasksQueryParams.caseDefinitionId !== undefined &&
      tasksQueryParams.caseDefinitionId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.caseDefinitionId,
        'caseDefinitionId'
      );
    }
    if (
      tasksQueryParams.caseDefinitionKey !== undefined &&
      tasksQueryParams.caseDefinitionKey !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.caseDefinitionKey,
        'caseDefinitionKey'
      );
    }
    if (
      tasksQueryParams.caseDefinitionName !== undefined &&
      tasksQueryParams.caseDefinitionName !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.caseDefinitionName,
        'caseDefinitionName'
      );
    }
    if (
      tasksQueryParams.caseDefinitionNameLike !== undefined &&
      tasksQueryParams.caseDefinitionNameLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.caseDefinitionNameLike,
        'caseDefinitionNameLike'
      );
    }
    if (
      tasksQueryParams.caseExecutionId !== undefined &&
      tasksQueryParams.caseExecutionId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.caseExecutionId,
        'caseExecutionId'
      );
    }
    if (
      tasksQueryParams.activityInstanceIdIn !== undefined &&
      tasksQueryParams.activityInstanceIdIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.activityInstanceIdIn,
        'activityInstanceIdIn'
      );
    }
    if (tasksQueryParams.tenantIdIn !== undefined && tasksQueryParams.tenantIdIn !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.tenantIdIn,
        'tenantIdIn'
      );
    }
    if (
      tasksQueryParams.withoutTenantId !== undefined &&
      tasksQueryParams.withoutTenantId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.withoutTenantId,
        'withoutTenantId'
      );
    }
    if (tasksQueryParams.assignee !== undefined && tasksQueryParams.assignee !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.assignee,
        'assignee'
      );
    }
    if (
      tasksQueryParams.assigneeExpression !== undefined &&
      tasksQueryParams.assigneeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.assigneeExpression,
        'assigneeExpression'
      );
    }
    if (tasksQueryParams.assigneeLike !== undefined && tasksQueryParams.assigneeLike !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.assigneeLike,
        'assigneeLike'
      );
    }
    if (
      tasksQueryParams.assigneeLikeExpression !== undefined &&
      tasksQueryParams.assigneeLikeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.assigneeLikeExpression,
        'assigneeLikeExpression'
      );
    }
    if (tasksQueryParams.assigneeIn !== undefined && tasksQueryParams.assigneeIn !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.assigneeIn,
        'assigneeIn'
      );
    }
    if (tasksQueryParams.owner !== undefined && tasksQueryParams.owner !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>tasksQueryParams.owner, 'owner');
    }
    if (
      tasksQueryParams.ownerExpression !== undefined &&
      tasksQueryParams.ownerExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.ownerExpression,
        'ownerExpression'
      );
    }
    if (tasksQueryParams.candidateGroup !== undefined && tasksQueryParams.candidateGroup !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.candidateGroup,
        'candidateGroup'
      );
    }
    if (
      tasksQueryParams.candidateGroupExpression !== undefined &&
      tasksQueryParams.candidateGroupExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.candidateGroupExpression,
        'candidateGroupExpression'
      );
    }
    if (tasksQueryParams.candidateUser !== undefined && tasksQueryParams.candidateUser !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.candidateUser,
        'candidateUser'
      );
    }
    if (
      tasksQueryParams.candidateUserExpression !== undefined &&
      tasksQueryParams.candidateUserExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.candidateUserExpression,
        'candidateUserExpression'
      );
    }
    if (
      tasksQueryParams.includeAssignedTasks !== undefined &&
      tasksQueryParams.includeAssignedTasks !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.includeAssignedTasks,
        'includeAssignedTasks'
      );
    }
    if (tasksQueryParams.involvedUser !== undefined && tasksQueryParams.involvedUser !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.involvedUser,
        'involvedUser'
      );
    }
    if (
      tasksQueryParams.involvedUserExpression !== undefined &&
      tasksQueryParams.involvedUserExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.involvedUserExpression,
        'involvedUserExpression'
      );
    }
    if (tasksQueryParams.assigned !== undefined && tasksQueryParams.assigned !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.assigned,
        'assigned'
      );
    }
    if (tasksQueryParams.unassigned !== undefined && tasksQueryParams.unassigned !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.unassigned,
        'unassigned'
      );
    }
    if (
      tasksQueryParams.taskDefinitionKey !== undefined &&
      tasksQueryParams.taskDefinitionKey !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.taskDefinitionKey,
        'taskDefinitionKey'
      );
    }
    if (
      tasksQueryParams.taskDefinitionKeyIn !== undefined &&
      tasksQueryParams.taskDefinitionKeyIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.taskDefinitionKeyIn,
        'taskDefinitionKeyIn'
      );
    }
    if (
      tasksQueryParams.taskDefinitionKeyLike !== undefined &&
      tasksQueryParams.taskDefinitionKeyLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.taskDefinitionKeyLike,
        'taskDefinitionKeyLike'
      );
    }
    if (tasksQueryParams.name !== undefined && tasksQueryParams.name !== null) {
      queryParameters = addToHttpParams(queryParameters, tasksQueryParams.name, 'name');
    }
    if (tasksQueryParams.nameNotEqual !== undefined && tasksQueryParams.nameNotEqual !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.nameNotEqual,
        'nameNotEqual'
      );
    }
    if (tasksQueryParams.nameLike !== undefined && tasksQueryParams.nameLike !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.nameLike,
        'nameLike'
      );
    }
    if (tasksQueryParams.nameNotLike !== undefined && tasksQueryParams.nameNotLike !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.nameNotLike,
        'nameNotLike'
      );
    }
    if (tasksQueryParams.description !== undefined && tasksQueryParams.description !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.description,
        'description'
      );
    }
    if (
      tasksQueryParams.descriptionLike !== undefined &&
      tasksQueryParams.descriptionLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.descriptionLike,
        'descriptionLike'
      );
    }
    if (tasksQueryParams.priority !== undefined && tasksQueryParams.priority !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.priority,
        'priority'
      );
    }
    if (tasksQueryParams.maxPriority !== undefined && tasksQueryParams.maxPriority !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.maxPriority,
        'maxPriority'
      );
    }
    if (tasksQueryParams.minPriority !== undefined && tasksQueryParams.minPriority !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.minPriority,
        'minPriority'
      );
    }
    if (tasksQueryParams.dueDate !== undefined && tasksQueryParams.dueDate !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>tasksQueryParams.dueDate, 'dueDate');
    }
    if (
      tasksQueryParams.dueDateExpression !== undefined &&
      tasksQueryParams.dueDateExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.dueDateExpression,
        'dueDateExpression'
      );
    }
    if (tasksQueryParams.dueAfter !== undefined && tasksQueryParams.dueAfter !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.dueAfter,
        'dueAfter'
      );
    }
    if (
      tasksQueryParams.dueAfterExpression !== undefined &&
      tasksQueryParams.dueAfterExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.dueAfterExpression,
        'dueAfterExpression'
      );
    }
    if (tasksQueryParams.dueBefore !== undefined && tasksQueryParams.dueBefore !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.dueBefore,
        'dueBefore'
      );
    }
    if (
      tasksQueryParams.dueBeforeExpression !== undefined &&
      tasksQueryParams.dueBeforeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.dueBeforeExpression,
        'dueBeforeExpression'
      );
    }
    if (tasksQueryParams.followUpDate !== undefined && tasksQueryParams.followUpDate !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.followUpDate,
        'followUpDate'
      );
    }
    if (
      tasksQueryParams.followUpDateExpression !== undefined &&
      tasksQueryParams.followUpDateExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.followUpDateExpression,
        'followUpDateExpression'
      );
    }
    if (tasksQueryParams.followUpAfter !== undefined && tasksQueryParams.followUpAfter !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.followUpAfter,
        'followUpAfter'
      );
    }
    if (
      tasksQueryParams.followUpAfterExpression !== undefined &&
      tasksQueryParams.followUpAfterExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.followUpAfterExpression,
        'followUpAfterExpression'
      );
    }
    if (tasksQueryParams.followUpBefore !== undefined && tasksQueryParams.followUpBefore !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.followUpBefore,
        'followUpBefore'
      );
    }
    if (
      tasksQueryParams.followUpBeforeExpression !== undefined &&
      tasksQueryParams.followUpBeforeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.followUpBeforeExpression,
        'followUpBeforeExpression'
      );
    }
    if (
      tasksQueryParams.followUpBeforeOrNotExistent !== undefined &&
      tasksQueryParams.followUpBeforeOrNotExistent !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.followUpBeforeOrNotExistent,
        'followUpBeforeOrNotExistent'
      );
    }
    if (
      tasksQueryParams.followUpBeforeOrNotExistentExpression !== undefined &&
      tasksQueryParams.followUpBeforeOrNotExistentExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.followUpBeforeOrNotExistentExpression,
        'followUpBeforeOrNotExistentExpression'
      );
    }
    if (tasksQueryParams.createdOn !== undefined && tasksQueryParams.createdOn !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.createdOn,
        'createdOn'
      );
    }
    if (
      tasksQueryParams.createdOnExpression !== undefined &&
      tasksQueryParams.createdOnExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.createdOnExpression,
        'createdOnExpression'
      );
    }
    if (tasksQueryParams.createdAfter !== undefined && tasksQueryParams.createdAfter !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.createdAfter,
        'createdAfter'
      );
    }
    if (
      tasksQueryParams.createdAfterExpression !== undefined &&
      tasksQueryParams.createdAfterExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.createdAfterExpression,
        'createdAfterExpression'
      );
    }
    if (tasksQueryParams.createdBefore !== undefined && tasksQueryParams.createdBefore !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.createdBefore,
        'createdBefore'
      );
    }
    if (
      tasksQueryParams.createdBeforeExpression !== undefined &&
      tasksQueryParams.createdBeforeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.createdBeforeExpression,
        'createdBeforeExpression'
      );
    }
    if (
      tasksQueryParams.delegationState !== undefined &&
      tasksQueryParams.delegationState !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.delegationState,
        'delegationState'
      );
    }
    if (
      tasksQueryParams.candidateGroups !== undefined &&
      tasksQueryParams.candidateGroups !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.candidateGroups,
        'candidateGroups'
      );
    }
    if (
      tasksQueryParams.candidateGroupsExpression !== undefined &&
      tasksQueryParams.candidateGroupsExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.candidateGroupsExpression,
        'candidateGroupsExpression'
      );
    }
    if (
      tasksQueryParams.withCandidateGroups !== undefined &&
      tasksQueryParams.withCandidateGroups !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.withCandidateGroups,
        'withCandidateGroups'
      );
    }
    if (
      tasksQueryParams.withoutCandidateGroups !== undefined &&
      tasksQueryParams.withoutCandidateGroups !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.withoutCandidateGroups,
        'withoutCandidateGroups'
      );
    }
    if (
      tasksQueryParams.withCandidateUsers !== undefined &&
      tasksQueryParams.withCandidateUsers !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.withCandidateUsers,
        'withCandidateUsers'
      );
    }
    if (
      tasksQueryParams.withoutCandidateUsers !== undefined &&
      tasksQueryParams.withoutCandidateUsers !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.withoutCandidateUsers,
        'withoutCandidateUsers'
      );
    }
    if (tasksQueryParams.active !== undefined && tasksQueryParams.active !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>tasksQueryParams.active, 'active');
    }
    if (tasksQueryParams.suspended !== undefined && tasksQueryParams.suspended !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.suspended,
        'suspended'
      );
    }
    if (tasksQueryParams.taskVariables !== undefined && tasksQueryParams.taskVariables !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.taskVariables,
        'taskVariables'
      );
    }
    if (
      tasksQueryParams.processVariables !== undefined &&
      tasksQueryParams.processVariables !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.processVariables,
        'processVariables'
      );
    }
    if (
      tasksQueryParams.caseInstanceVariables !== undefined &&
      tasksQueryParams.caseInstanceVariables !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.caseInstanceVariables,
        'caseInstanceVariables'
      );
    }
    if (
      tasksQueryParams.variableNamesIgnoreCase !== undefined &&
      tasksQueryParams.variableNamesIgnoreCase !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.variableNamesIgnoreCase,
        'variableNamesIgnoreCase'
      );
    }
    if (
      tasksQueryParams.variableValuesIgnoreCase !== undefined &&
      tasksQueryParams.variableValuesIgnoreCase !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.variableValuesIgnoreCase,
        'variableValuesIgnoreCase'
      );
    }
    if (tasksQueryParams.parentTaskId !== undefined && tasksQueryParams.parentTaskId !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.parentTaskId,
        'parentTaskId'
      );
    }
    if (tasksQueryParams.sortBy !== undefined && tasksQueryParams.sortBy !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>tasksQueryParams.sortBy, 'sortBy');
    }
    if (tasksQueryParams.sortOrder !== undefined && tasksQueryParams.sortOrder !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.sortOrder,
        'sortOrder'
      );
    }
    if (tasksQueryParams.firstResult !== undefined && tasksQueryParams.firstResult !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.firstResult,
        'firstResult'
      );
    }
    if (tasksQueryParams.maxResults !== undefined && tasksQueryParams.maxResults !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksQueryParams.maxResults,
        'maxResults'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<Array<CamundaTask>>(`${this.endpoint}`, {
      params: queryParameters,
      responseType: <any>responseType,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Retrieves the number of tasks that fulfill a provided filter. Corresponds to the size of the result set when using the [Get Tasks](https://docs.camunda.org/manual/7.14/reference/rest/task/) method.  **Security Consideration:** There are several query parameters (such as assigneeExpression) for specifying an EL expression. These are disabled by default to prevent remote code execution. See the section on [security considerations](https://docs.camunda.org/manual/7.14/user-guide/process-engine/securing-custom-code/) for custom code in the user guide for details.
   * @param processInstanceId Restrict to tasks that belong to process instances with the given id.
   * @param processInstanceIdIn Restrict to tasks that belong to process instances with the given ids.
   * @param processInstanceBusinessKey Restrict to tasks that belong to process instances with the given business key.
   * @param processInstanceBusinessKeyExpression Restrict to tasks that belong to process instances with the given business key which  is described by an expression. See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions.
   * @param processInstanceBusinessKeyIn Restrict to tasks that belong to process instances with one of the give business keys.  The keys need to be in a comma-separated list.
   * @param processInstanceBusinessKeyLike Restrict to tasks that have a process instance business key that has the parameter  value as a substring.
   * @param processInstanceBusinessKeyLikeExpression Restrict to tasks that have a process instance business key that has the parameter  value as a substring and is described by an expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param processDefinitionId Restrict to tasks that belong to a process definition with the given id.
   * @param processDefinitionKey Restrict to tasks that belong to a process definition with the given key.
   * @param processDefinitionKeyIn Restrict to tasks that belong to a process definition with one of the given keys. The  keys need to be in a comma-separated list.
   * @param processDefinitionName Restrict to tasks that belong to a process definition with the given name.
   * @param processDefinitionNameLike Restrict to tasks that have a process definition name that has the parameter value as  a substring.
   * @param executionId Restrict to tasks that belong to an execution with the given id.
   * @param caseInstanceId Restrict to tasks that belong to case instances with the given id.
   * @param caseInstanceBusinessKey Restrict to tasks that belong to case instances with the given business key.
   * @param caseInstanceBusinessKeyLike Restrict to tasks that have a case instance business key that has the parameter value  as a substring.
   * @param caseDefinitionId Restrict to tasks that belong to a case definition with the given id.
   * @param caseDefinitionKey Restrict to tasks that belong to a case definition with the given key.
   * @param caseDefinitionName Restrict to tasks that belong to a case definition with the given name.
   * @param caseDefinitionNameLike Restrict to tasks that have a case definition name that has the parameter value as a  substring.
   * @param caseExecutionId Restrict to tasks that belong to a case execution with the given id.
   * @param activityInstanceIdIn Only include tasks which belong to one of the passed and comma-separated activity  instance ids.
   * @param tenantIdIn Only include tasks which belong to one of the passed and comma-separated  tenant ids.
   * @param withoutTenantId Only include tasks which belong to no tenant. Value may only be &#x60;true&#x60;,  as &#x60;false&#x60; is the default behavior.
   * @param assignee Restrict to tasks that the given user is assigned to.
   * @param assigneeExpression Restrict to tasks that the user described by the given expression is assigned to.  See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param assigneeLike Restrict to tasks that have an assignee that has the parameter  value as a substring.
   * @param assigneeLikeExpression Restrict to tasks that have an assignee that has the parameter value described by the  given expression as a substring. See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param assigneeIn Only include tasks which are assigned to one of the passed and  comma-separated user ids.
   * @param owner Restrict to tasks that the given user owns.
   * @param ownerExpression Restrict to tasks that the user described by the given expression owns. See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param candidateGroup Only include tasks that are offered to the given group.
   * @param candidateGroupExpression Only include tasks that are offered to the group described by the given expression.  See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param candidateUser Only include tasks that are offered to the given user or to one of his groups.
   * @param candidateUserExpression Only include tasks that are offered to the user described by the given expression.  See the  [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions)  for more information on available functions.
   * @param includeAssignedTasks Also include tasks that are assigned to users in candidate queries. Default is to only  include tasks that are not assigned to any user if you query by candidate user or group(s).
   * @param involvedUser Only include tasks that the given user is involved in. A user is involved in a task if  an identity link exists between task and user (e.g., the user is the assignee).
   * @param involvedUserExpression Only include tasks that the user described by the given expression is involved in. A user is involved in a task if an identity link exists between task and user (e.g., the user is the assignee). See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions.
   * @param assigned If set to &#x60;true&#x60;, restricts the query to all tasks that are assigned.
   * @param unassigned If set to &#x60;true&#x60;, restricts the query to all tasks that are unassigned.
   * @param taskDefinitionKey Restrict to tasks that have the given key.
   * @param taskDefinitionKeyIn Restrict to tasks that have one of the given keys. The keys need to be in a comma-separated list.
   * @param taskDefinitionKeyLike Restrict to tasks that have a key that has the parameter value as a substring.
   * @param name Restrict to tasks that have the given name.
   * @param nameNotEqual Restrict to tasks that do not have the given name.
   * @param nameLike Restrict to tasks that have a name with the given parameter value as substring.
   * @param nameNotLike Restrict to tasks that do not have a name with the given parameter value as substring.
   * @param description Restrict to tasks that have the given description.
   * @param descriptionLike Restrict to tasks that have a description that has the parameter value as a substring.
   * @param priority Restrict to tasks that have the given priority.
   * @param maxPriority Restrict to tasks that have a lower or equal priority.
   * @param minPriority Restrict to tasks that have a higher or equal priority.
   * @param dueDate Restrict to tasks that are due on the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.546+0200&#x60;.
   * @param dueDateExpression Restrict to tasks that are due on the date described by the given expression. See the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param dueAfter Restrict to tasks that are due after the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.435+0200&#x60;.
   * @param dueAfterExpression Restrict to tasks that are due after the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param dueBefore Restrict to tasks that are due before the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.243+0200&#x60;.
   * @param dueBeforeExpression Restrict to tasks that are due before the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param followUpDate Restrict to tasks that have a followUp date on the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.342+0200&#x60;.
   * @param followUpDateExpression Restrict to tasks that have a followUp date on the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param followUpAfter Restrict to tasks that have a followUp date after the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.542+0200&#x60;.
   * @param followUpAfterExpression Restrict to tasks that have a followUp date after the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param followUpBefore Restrict to tasks that have a followUp date before the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.234+0200&#x60;.
   * @param followUpBeforeExpression Restrict to tasks that have a followUp date before the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param followUpBeforeOrNotExistent Restrict to tasks that have no followUp date or a followUp date before the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.432+0200&#x60;. The typical use case is to query all &#x60;active&#x60; tasks for a user for a given date.
   * @param followUpBeforeOrNotExistentExpression Restrict to tasks that have no followUp date or a followUp date before the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param createdOn Restrict to tasks that were created on the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.324+0200&#x60;.
   * @param createdOnExpression Restrict to tasks that were created on the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param createdAfter Restrict to tasks that were created after the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.342+0200&#x60;.
   * @param createdAfterExpression Restrict to tasks that were created after the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param createdBefore Restrict to tasks that were created before the given date. By [default](https://docs.camunda.org/manual/7.14/reference/rest/overview/date-format/), the date must have the format &#x60;yyyy-MM-dd\&#39;T\&#39;HH:mm:ss.SSSZ&#x60;, e.g., &#x60;2013-01-23T14:42:45.332+0200&#x60;.
   * @param createdBeforeExpression Restrict to tasks that were created before the date described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to a &#x60;java.util.Date&#x60; or &#x60;org.joda.time.DateTime&#x60; object.
   * @param delegationState Restrict to tasks that are in the given delegation state. Valid values are &#x60;PENDING&#x60; and &#x60;RESOLVED&#x60;.
   * @param candidateGroups Restrict to tasks that are offered to any of the given candidate groups. Takes a comma-separated list of group names, so for example &#x60;developers,support,sales&#x60;.
   * @param candidateGroupsExpression Restrict to tasks that are offered to any of the candidate groups described by the given expression. See the [user guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/expression-language/#internal-context-functions) for more information on available functions. The expression must evaluate to &#x60;java.util.List&#x60; of Strings.
   * @param withCandidateGroups Only include tasks which have a candidate group. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param withoutCandidateGroups Only include tasks which have no candidate group. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param withCandidateUsers Only include tasks which have a candidate user. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param withoutCandidateUsers Only include tasks which have no candidate users. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param active Only include active tasks. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param suspended Only include suspended tasks. Value may only be &#x60;true&#x60;, as &#x60;false&#x60; is the default behavior.
   * @param taskVariables Only include tasks that have variables with certain values. Variable filtering expressions are comma-separated and are structured as follows:  A valid parameter value has the form &#x60;key_operator_value&#x60;. &#x60;key&#x60; is the variable name, &#x60;operator&#x60; is the comparison operator to be used and &#x60;value&#x60; the variable value.  **Note**: Values are always treated as String objects on server side.  Valid &#x60;operator&#x60; values are: &#x60;eq&#x60; - equal to; &#x60;neq&#x60; - not equal to; &#x60;gt&#x60; - greater than; &#x60;gteq&#x60; - greater than or equal to; &#x60;lt&#x60; - lower than; &#x60;lteq&#x60; - lower than or equal to; &#x60;like&#x60;. &#x60;key&#x60; and &#x60;value&#x60; may not contain underscore or comma characters.
   * @param processVariables Only include tasks that belong to process instances that have variables with certain  values. Variable filtering expressions are comma-separated and are structured as follows:  A valid parameter value has the form &#x60;key_operator_value&#x60;. &#x60;key&#x60; is the variable name, &#x60;operator&#x60; is the comparison operator to be used and &#x60;value&#x60; the variable value.  **Note**: Values are always treated as String objects on server side.  Valid &#x60;operator&#x60; values are: &#x60;eq&#x60; - equal to; &#x60;neq&#x60; - not equal to; &#x60;gt&#x60; - greater than; &#x60;gteq&#x60; - greater than or equal to; &#x60;lt&#x60; - lower than; &#x60;lteq&#x60; - lower than or equal to; &#x60;like&#x60;. &#x60;key&#x60; and &#x60;value&#x60; may not contain underscore or comma characters.
   * @param caseInstanceVariables Only include tasks that belong to case instances that have variables with certain values. Variable filtering expressions are comma-separated and are structured as follows:  A valid parameter value has the form &#x60;key_operator_value&#x60;. &#x60;key&#x60; is the variable name, &#x60;operator&#x60; is the comparison operator to be used and &#x60;value&#x60; the variable value.  **Note**: Values are always treated as String objects on server side.  Valid &#x60;operator&#x60; values are: &#x60;eq&#x60; - equal to; &#x60;neq&#x60; - not equal to; &#x60;gt&#x60; - greater than; &#x60;gteq&#x60; - greater than or equal to; &#x60;lt&#x60; - lower than; &#x60;lteq&#x60; - lower than or equal to; &#x60;like&#x60;. &#x60;key&#x60; and &#x60;value&#x60; may not contain underscore or comma characters.
   * @param variableNamesIgnoreCase Match all variable names in this query case-insensitively. If set &#x60;variableName&#x60; and &#x60;variablename&#x60; are treated as equal.
   * @param variableValuesIgnoreCase Match all variable values in this query case-insensitively. If set &#x60;variableValue&#x60; and &#x60;variablevalue&#x60; are treated as equal.
   * @param parentTaskId Restrict query to all tasks that are sub tasks of the given task. Takes a task id.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTasksCount(
    tasksCountQueryParams: TasksCountQueryParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaCountResult>;
  public getTasksCount(
    tasksCountQueryParams: TasksCountQueryParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaCountResult>>;
  public getTasksCount(
    tasksCountQueryParams: TasksCountQueryParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaCountResult>>;
  public getTasksCount(
    tasksCountQueryParams: TasksCountQueryParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams();
    if (
      tasksCountQueryParams.processInstanceId !== undefined &&
      tasksCountQueryParams.processInstanceId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processInstanceId,
        'processInstanceId'
      );
    }
    if (
      tasksCountQueryParams.processInstanceIdIn !== undefined &&
      tasksCountQueryParams.processInstanceIdIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processInstanceIdIn,
        'processInstanceIdIn'
      );
    }
    if (
      tasksCountQueryParams.processInstanceBusinessKey !== undefined &&
      tasksCountQueryParams.processInstanceBusinessKey !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processInstanceBusinessKey,
        'processInstanceBusinessKey'
      );
    }
    if (
      tasksCountQueryParams.processInstanceBusinessKeyExpression !== undefined &&
      tasksCountQueryParams.processInstanceBusinessKeyExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processInstanceBusinessKeyExpression,
        'processInstanceBusinessKeyExpression'
      );
    }
    if (
      tasksCountQueryParams.processInstanceBusinessKeyIn !== undefined &&
      tasksCountQueryParams.processInstanceBusinessKeyIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processInstanceBusinessKeyIn,
        'processInstanceBusinessKeyIn'
      );
    }
    if (
      tasksCountQueryParams.processInstanceBusinessKeyLike !== undefined &&
      tasksCountQueryParams.processInstanceBusinessKeyLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processInstanceBusinessKeyLike,
        'processInstanceBusinessKeyLike'
      );
    }
    if (
      tasksCountQueryParams.processInstanceBusinessKeyLikeExpression !== undefined &&
      tasksCountQueryParams.processInstanceBusinessKeyLikeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processInstanceBusinessKeyLikeExpression,
        'processInstanceBusinessKeyLikeExpression'
      );
    }
    if (
      tasksCountQueryParams.processDefinitionId !== undefined &&
      tasksCountQueryParams.processDefinitionId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processDefinitionId,
        'processDefinitionId'
      );
    }
    if (
      tasksCountQueryParams.processDefinitionKey !== undefined &&
      tasksCountQueryParams.processDefinitionKey !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processDefinitionKey,
        'processDefinitionKey'
      );
    }
    if (
      tasksCountQueryParams.processDefinitionKeyIn !== undefined &&
      tasksCountQueryParams.processDefinitionKeyIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processDefinitionKeyIn,
        'processDefinitionKeyIn'
      );
    }
    if (
      tasksCountQueryParams.processDefinitionName !== undefined &&
      tasksCountQueryParams.processDefinitionName !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processDefinitionName,
        'processDefinitionName'
      );
    }
    if (
      tasksCountQueryParams.processDefinitionNameLike !== undefined &&
      tasksCountQueryParams.processDefinitionNameLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processDefinitionNameLike,
        'processDefinitionNameLike'
      );
    }
    if (
      tasksCountQueryParams.executionId !== undefined &&
      tasksCountQueryParams.executionId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.executionId,
        'executionId'
      );
    }
    if (
      tasksCountQueryParams.caseInstanceId !== undefined &&
      tasksCountQueryParams.caseInstanceId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.caseInstanceId,
        'caseInstanceId'
      );
    }
    if (
      tasksCountQueryParams.caseInstanceBusinessKey !== undefined &&
      tasksCountQueryParams.caseInstanceBusinessKey !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.caseInstanceBusinessKey,
        'caseInstanceBusinessKey'
      );
    }
    if (
      tasksCountQueryParams.caseInstanceBusinessKeyLike !== undefined &&
      tasksCountQueryParams.caseInstanceBusinessKeyLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.caseInstanceBusinessKeyLike,
        'caseInstanceBusinessKeyLike'
      );
    }
    if (
      tasksCountQueryParams.caseDefinitionId !== undefined &&
      tasksCountQueryParams.caseDefinitionId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.caseDefinitionId,
        'caseDefinitionId'
      );
    }
    if (
      tasksCountQueryParams.caseDefinitionKey !== undefined &&
      tasksCountQueryParams.caseDefinitionKey !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.caseDefinitionKey,
        'caseDefinitionKey'
      );
    }
    if (
      tasksCountQueryParams.caseDefinitionName !== undefined &&
      tasksCountQueryParams.caseDefinitionName !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.caseDefinitionName,
        'caseDefinitionName'
      );
    }
    if (
      tasksCountQueryParams.caseDefinitionNameLike !== undefined &&
      tasksCountQueryParams.caseDefinitionNameLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.caseDefinitionNameLike,
        'caseDefinitionNameLike'
      );
    }
    if (
      tasksCountQueryParams.caseExecutionId !== undefined &&
      tasksCountQueryParams.caseExecutionId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.caseExecutionId,
        'caseExecutionId'
      );
    }
    if (
      tasksCountQueryParams.activityInstanceIdIn !== undefined &&
      tasksCountQueryParams.activityInstanceIdIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.activityInstanceIdIn,
        'activityInstanceIdIn'
      );
    }
    if (
      tasksCountQueryParams.tenantIdIn !== undefined &&
      tasksCountQueryParams.tenantIdIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.tenantIdIn,
        'tenantIdIn'
      );
    }
    if (
      tasksCountQueryParams.withoutTenantId !== undefined &&
      tasksCountQueryParams.withoutTenantId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.withoutTenantId,
        'withoutTenantId'
      );
    }
    if (tasksCountQueryParams.assignee !== undefined && tasksCountQueryParams.assignee !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.assignee,
        'assignee'
      );
    }
    if (
      tasksCountQueryParams.assigneeExpression !== undefined &&
      tasksCountQueryParams.assigneeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.assigneeExpression,
        'assigneeExpression'
      );
    }
    if (
      tasksCountQueryParams.assigneeLike !== undefined &&
      tasksCountQueryParams.assigneeLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.assigneeLike,
        'assigneeLike'
      );
    }
    if (
      tasksCountQueryParams.assigneeLikeExpression !== undefined &&
      tasksCountQueryParams.assigneeLikeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.assigneeLikeExpression,
        'assigneeLikeExpression'
      );
    }
    if (
      tasksCountQueryParams.assigneeIn !== undefined &&
      tasksCountQueryParams.assigneeIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.assigneeIn,
        'assigneeIn'
      );
    }
    if (tasksCountQueryParams.owner !== undefined && tasksCountQueryParams.owner !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>tasksCountQueryParams.owner, 'owner');
    }
    if (
      tasksCountQueryParams.ownerExpression !== undefined &&
      tasksCountQueryParams.ownerExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.ownerExpression,
        'ownerExpression'
      );
    }
    if (
      tasksCountQueryParams.candidateGroup !== undefined &&
      tasksCountQueryParams.candidateGroup !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.candidateGroup,
        'candidateGroup'
      );
    }
    if (
      tasksCountQueryParams.candidateGroupExpression !== undefined &&
      tasksCountQueryParams.candidateGroupExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.candidateGroupExpression,
        'candidateGroupExpression'
      );
    }
    if (
      tasksCountQueryParams.candidateUser !== undefined &&
      tasksCountQueryParams.candidateUser !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.candidateUser,
        'candidateUser'
      );
    }
    if (
      tasksCountQueryParams.candidateUserExpression !== undefined &&
      tasksCountQueryParams.candidateUserExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.candidateUserExpression,
        'candidateUserExpression'
      );
    }
    if (
      tasksCountQueryParams.includeAssignedTasks !== undefined &&
      tasksCountQueryParams.includeAssignedTasks !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.includeAssignedTasks,
        'includeAssignedTasks'
      );
    }
    if (
      tasksCountQueryParams.involvedUser !== undefined &&
      tasksCountQueryParams.involvedUser !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.involvedUser,
        'involvedUser'
      );
    }
    if (
      tasksCountQueryParams.involvedUserExpression !== undefined &&
      tasksCountQueryParams.involvedUserExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.involvedUserExpression,
        'involvedUserExpression'
      );
    }
    if (tasksCountQueryParams.assigned !== undefined && tasksCountQueryParams.assigned !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.assigned,
        'assigned'
      );
    }
    if (
      tasksCountQueryParams.unassigned !== undefined &&
      tasksCountQueryParams.unassigned !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.unassigned,
        'unassigned'
      );
    }
    if (
      tasksCountQueryParams.taskDefinitionKey !== undefined &&
      tasksCountQueryParams.taskDefinitionKey !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.taskDefinitionKey,
        'taskDefinitionKey'
      );
    }
    if (
      tasksCountQueryParams.taskDefinitionKeyIn !== undefined &&
      tasksCountQueryParams.taskDefinitionKeyIn !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.taskDefinitionKeyIn,
        'taskDefinitionKeyIn'
      );
    }
    if (
      tasksCountQueryParams.taskDefinitionKeyLike !== undefined &&
      tasksCountQueryParams.taskDefinitionKeyLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.taskDefinitionKeyLike,
        'taskDefinitionKeyLike'
      );
    }
    if (name !== undefined && name !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>name, 'name');
    }
    if (
      tasksCountQueryParams.nameNotEqual !== undefined &&
      tasksCountQueryParams.nameNotEqual !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.nameNotEqual,
        'nameNotEqual'
      );
    }
    if (tasksCountQueryParams.nameLike !== undefined && tasksCountQueryParams.nameLike !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.nameLike,
        'nameLike'
      );
    }
    if (
      tasksCountQueryParams.nameNotLike !== undefined &&
      tasksCountQueryParams.nameNotLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.nameNotLike,
        'nameNotLike'
      );
    }
    if (
      tasksCountQueryParams.description !== undefined &&
      tasksCountQueryParams.description !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.description,
        'description'
      );
    }
    if (
      tasksCountQueryParams.descriptionLike !== undefined &&
      tasksCountQueryParams.descriptionLike !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.descriptionLike,
        'descriptionLike'
      );
    }
    if (tasksCountQueryParams.priority !== undefined && tasksCountQueryParams.priority !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.priority,
        'priority'
      );
    }
    if (
      tasksCountQueryParams.maxPriority !== undefined &&
      tasksCountQueryParams.maxPriority !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.maxPriority,
        'maxPriority'
      );
    }
    if (
      tasksCountQueryParams.minPriority !== undefined &&
      tasksCountQueryParams.minPriority !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.minPriority,
        'minPriority'
      );
    }
    if (tasksCountQueryParams.dueDate !== undefined && tasksCountQueryParams.dueDate !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.dueDate,
        'dueDate'
      );
    }
    if (
      tasksCountQueryParams.dueDateExpression !== undefined &&
      tasksCountQueryParams.dueDateExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.dueDateExpression,
        'dueDateExpression'
      );
    }
    if (tasksCountQueryParams.dueAfter !== undefined && tasksCountQueryParams.dueAfter !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.dueAfter,
        'dueAfter'
      );
    }
    if (
      tasksCountQueryParams.dueAfterExpression !== undefined &&
      tasksCountQueryParams.dueAfterExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.dueAfterExpression,
        'dueAfterExpression'
      );
    }
    if (tasksCountQueryParams.dueBefore !== undefined && tasksCountQueryParams.dueBefore !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.dueBefore,
        'dueBefore'
      );
    }
    if (
      tasksCountQueryParams.dueBeforeExpression !== undefined &&
      tasksCountQueryParams.dueBeforeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.dueBeforeExpression,
        'dueBeforeExpression'
      );
    }
    if (
      tasksCountQueryParams.followUpDate !== undefined &&
      tasksCountQueryParams.followUpDate !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.followUpDate,
        'followUpDate'
      );
    }
    if (
      tasksCountQueryParams.followUpDateExpression !== undefined &&
      tasksCountQueryParams.followUpDateExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.followUpDateExpression,
        'followUpDateExpression'
      );
    }
    if (
      tasksCountQueryParams.followUpAfter !== undefined &&
      tasksCountQueryParams.followUpAfter !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.followUpAfter,
        'followUpAfter'
      );
    }
    if (
      tasksCountQueryParams.followUpAfterExpression !== undefined &&
      tasksCountQueryParams.followUpAfterExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.followUpAfterExpression,
        'followUpAfterExpression'
      );
    }
    if (
      tasksCountQueryParams.followUpBefore !== undefined &&
      tasksCountQueryParams.followUpBefore !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.followUpBefore,
        'followUpBefore'
      );
    }
    if (
      tasksCountQueryParams.followUpBeforeExpression !== undefined &&
      tasksCountQueryParams.followUpBeforeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.followUpBeforeExpression,
        'followUpBeforeExpression'
      );
    }
    if (
      tasksCountQueryParams.followUpBeforeOrNotExistent !== undefined &&
      tasksCountQueryParams.followUpBeforeOrNotExistent !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.followUpBeforeOrNotExistent,
        'followUpBeforeOrNotExistent'
      );
    }
    if (
      tasksCountQueryParams.followUpBeforeOrNotExistentExpression !== undefined &&
      tasksCountQueryParams.followUpBeforeOrNotExistentExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.followUpBeforeOrNotExistentExpression,
        'followUpBeforeOrNotExistentExpression'
      );
    }
    if (tasksCountQueryParams.createdOn !== undefined && tasksCountQueryParams.createdOn !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.createdOn,
        'createdOn'
      );
    }
    if (
      tasksCountQueryParams.createdOnExpression !== undefined &&
      tasksCountQueryParams.createdOnExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.createdOnExpression,
        'createdOnExpression'
      );
    }
    if (
      tasksCountQueryParams.createdAfter !== undefined &&
      tasksCountQueryParams.createdAfter !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.createdAfter,
        'createdAfter'
      );
    }
    if (
      tasksCountQueryParams.createdAfterExpression !== undefined &&
      tasksCountQueryParams.createdAfterExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.createdAfterExpression,
        'createdAfterExpression'
      );
    }
    if (
      tasksCountQueryParams.createdBefore !== undefined &&
      tasksCountQueryParams.createdBefore !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.createdBefore,
        'createdBefore'
      );
    }
    if (
      tasksCountQueryParams.createdBeforeExpression !== undefined &&
      tasksCountQueryParams.createdBeforeExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.createdBeforeExpression,
        'createdBeforeExpression'
      );
    }
    if (
      tasksCountQueryParams.delegationState !== undefined &&
      tasksCountQueryParams.delegationState !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.delegationState,
        'delegationState'
      );
    }
    if (
      tasksCountQueryParams.candidateGroups !== undefined &&
      tasksCountQueryParams.candidateGroups !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.candidateGroups,
        'candidateGroups'
      );
    }
    if (
      tasksCountQueryParams.candidateGroupsExpression !== undefined &&
      tasksCountQueryParams.candidateGroupsExpression !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.candidateGroupsExpression,
        'candidateGroupsExpression'
      );
    }
    if (
      tasksCountQueryParams.withCandidateGroups !== undefined &&
      tasksCountQueryParams.withCandidateGroups !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.withCandidateGroups,
        'withCandidateGroups'
      );
    }
    if (
      tasksCountQueryParams.withoutCandidateGroups !== undefined &&
      tasksCountQueryParams.withoutCandidateGroups !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.withoutCandidateGroups,
        'withoutCandidateGroups'
      );
    }
    if (
      tasksCountQueryParams.withCandidateUsers !== undefined &&
      tasksCountQueryParams.withCandidateUsers !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.withCandidateUsers,
        'withCandidateUsers'
      );
    }
    if (
      tasksCountQueryParams.withoutCandidateUsers !== undefined &&
      tasksCountQueryParams.withoutCandidateUsers !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.withoutCandidateUsers,
        'withoutCandidateUsers'
      );
    }
    if (tasksCountQueryParams.active !== undefined && tasksCountQueryParams.active !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.active,
        'active'
      );
    }
    if (tasksCountQueryParams.suspended !== undefined && tasksCountQueryParams.suspended !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.suspended,
        'suspended'
      );
    }
    if (
      tasksCountQueryParams.taskVariables !== undefined &&
      tasksCountQueryParams.taskVariables !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.taskVariables,
        'taskVariables'
      );
    }
    if (
      tasksCountQueryParams.processVariables !== undefined &&
      tasksCountQueryParams.processVariables !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.processVariables,
        'processVariables'
      );
    }
    if (
      tasksCountQueryParams.caseInstanceVariables !== undefined &&
      tasksCountQueryParams.caseInstanceVariables !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.caseInstanceVariables,
        'caseInstanceVariables'
      );
    }
    if (
      tasksCountQueryParams.variableNamesIgnoreCase !== undefined &&
      tasksCountQueryParams.variableNamesIgnoreCase !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.variableNamesIgnoreCase,
        'variableNamesIgnoreCase'
      );
    }
    if (
      tasksCountQueryParams.variableValuesIgnoreCase !== undefined &&
      tasksCountQueryParams.variableValuesIgnoreCase !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.variableValuesIgnoreCase,
        'variableValuesIgnoreCase'
      );
    }
    if (
      tasksCountQueryParams.parentTaskId !== undefined &&
      tasksCountQueryParams.parentTaskId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>tasksCountQueryParams.parentTaskId,
        'parentTaskId'
      );
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaCountResult>(`${this.endpoint}/count`, {
      params: queryParameters,
      responseType: <any>responseType,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Reports a business error in the context of a running task by id. The error code must be specified to identify the BPMN error handler. See the documentation for [Reporting Bpmn Error](https://docs.camunda.org/manual/7.14/reference/bpmn20/tasks/user-task/#reporting-bpmn-error) in User Tasks.
   * @param id The id of the task a BPMN error is reported for.
   * @param camundaTaskBpmnErrorDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public handleBpmnError(
    id: string,
    camundaTaskBpmnErrorDto?: CamundaTaskBpmnError,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public handleBpmnError(
    id: string,
    camundaTaskBpmnErrorDto?: CamundaTaskBpmnError,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public handleBpmnError(
    id: string,
    camundaTaskBpmnErrorDto?: CamundaTaskBpmnError,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public handleBpmnError(
    id: string,
    camundaTaskBpmnErrorDto?: CamundaTaskBpmnError,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling handleBpmnError.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/bpmnError`,
      camundaTaskBpmnErrorDto,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Reports an escalation in the context of a running task by id. The escalation code must be specified to identify the escalation handler. See the documentation for [Reporting Bpmn Escalation](https://docs.camunda.org/manual/7.14/reference/bpmn20/tasks/user-task/#reporting-bpmn-escalation) in User Tasks.
   * @param id The id of the task in which context a BPMN escalation is reported.
   * @param camundaTaskEscalationDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public handleEscalation(
    id: string,
    camundaTaskEscalationDto?: CamundaTaskEscalation,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public handleEscalation(
    id: string,
    camundaTaskEscalationDto?: CamundaTaskEscalation,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public handleEscalation(
    id: string,
    camundaTaskEscalationDto?: CamundaTaskEscalation,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public handleEscalation(
    id: string,
    camundaTaskEscalationDto?: CamundaTaskEscalation,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling handleEscalation.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/bpmnEscalation`,
      camundaTaskEscalationDto,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Queries for tasks that fulfill a given filter. This method is slightly more powerful than the [Get Tasks](https://docs.camunda.org/manual/7.14/reference/rest/task/get-query/) method because it allows filtering by multiple process or task variables of types &#x60;String&#x60;, &#x60;Number&#x60; or &#x60;Boolean&#x60;. The size of the result set can be retrieved by using the [Get Task Count (POST)](https://docs.camunda.org/manual/7.14/reference/rest/task/post-query-count/) method.  **Security Consideration**: There are several parameters (such as &#x60;assigneeExpression&#x60;) for specifying an EL expression. These are disabled by default to prevent remote code execution. See the section on [security considerations for custom code](https://docs.camunda.org/manual/7.14/user-guide/process-engine/securing-custom-code/) in the user guide for details.
   * @param firstResult Pagination of results. Specifies the index of the first result to return.
   * @param maxResults Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
   * @param camundaTaskQueryDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryTasks(
    firstResult?: number,
    maxResults?: number,
    camundaTaskQueryDto?: CamundaTaskQuery,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<Array<CamundaTask>>;
  public queryTasks(
    firstResult?: number,
    maxResults?: number,
    camundaTaskQueryDto?: CamundaTaskQuery,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<Array<CamundaTask>>>;
  public queryTasks(
    firstResult?: number,
    maxResults?: number,
    camundaTaskQueryDto?: CamundaTaskQuery,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<Array<CamundaTask>>>;
  public queryTasks(
    firstResult?: number,
    maxResults?: number,
    camundaTaskQueryDto?: CamundaTaskQuery,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    let queryParameters = new HttpParams();
    if (firstResult !== undefined && firstResult !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>firstResult, 'firstResult');
    }
    if (maxResults !== undefined && maxResults !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>maxResults, 'maxResults');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<Array<CamundaTask>>(`${this.endpoint}`, camundaTaskQueryDto, {
      params: queryParameters,
      responseType: <any>responseType,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Retrieves the number of tasks that fulfill the given filter. Corresponds to the size of the result set of the [Get Tasks (POST)](https://docs.camunda.org/manual/7.14/reference/rest/task/post-query/) method and takes the same parameters.  **Security Consideration**: There are several parameters (such as &#x60;assigneeExpression&#x60;) for specifying an EL expression. These are disabled by default to prevent remote code execution. See the section on [security considerations for custom code](https://docs.camunda.org/manual/7.14/user-guide/process-engine/securing-custom-code/) in the user guide for details.
   * @param camundaTaskQueryDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryTasksCount(
    camundaTaskQueryDto?: CamundaTaskQuery,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaCountResult>;
  public queryTasksCount(
    camundaTaskQueryDto?: CamundaTaskQuery,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaCountResult>>;
  public queryTasksCount(
    camundaTaskQueryDto?: CamundaTaskQuery,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaCountResult>>;
  public queryTasksCount(
    camundaTaskQueryDto?: CamundaTaskQuery,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaCountResult>(`${this.endpoint}/count`, camundaTaskQueryDto, {
      responseType: <any>responseType,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Resolves a task and updates execution variables.  Resolving a task marks that the assignee is done with the task delegated to them, and that it can be sent back to the owner. Can only be executed when the task has been delegated. The assignee will be set to the owner, who performed the delegation.
   * @param id The id of the task to resolve.
   * @param camundaCompleteTask
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resolve(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public resolve(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public resolve(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public resolve(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling resolve.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/resolve`,
      camundaCompleteTask,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Changes the assignee of a task to a specific user.  **Note:** The difference with the [Claim Task](https://docs.camunda.org/manual/7.14/reference/rest/task/post-claim/) method is that this method does not check if the task already has a user assigned to it.
   * @param id The id of the task to set the assignee for.
   * @param camundaUserId Provide the id of the user that will be the assignee of the task.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setAssignee(
    id: string,
    camundaUserId?: CamundaUserId,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public setAssignee(
    id: string,
    camundaUserId?: CamundaUserId,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public setAssignee(
    id: string,
    camundaUserId?: CamundaUserId,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public setAssignee(
    id: string,
    camundaUserId?: CamundaUserId,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling setAssignee.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/assignee`,
      camundaUserId,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Completes a task and updates process variables using a form submit. There are two difference between this method and the &#x60;complete&#x60; method:  * If the task is in state &#x60;PENDING&#x60; - i.e., has been delegated before, it is not completed but resolved. Otherwise it will be completed. * If the task has Form Field Metadata defined, the process engine will perform backend validation for any form fields which have validators defined. See the [Generated Task Forms](https://docs.camunda.org/manual/7.14/user-guide/task-forms/_index/#generated-task-forms) section of the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/) for more information.
   * @param id The id of the task to submit the form for.
   * @param camundaCompleteTask
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public submit(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<{ [key: string]: CamundaVariableValue }>;
  public submit(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<{ [key: string]: CamundaVariableValue }>>;
  public submit(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<{ [key: string]: CamundaVariableValue }>>;
  public submit(
    id: string,
    camundaCompleteTask?: CamundaCompleteTask,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling submit.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<{ [key: string]: CamundaVariableValue }>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/submit-form`,
      camundaCompleteTask,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Resets a task\&#39;s assignee. If successful, the task is not assigned to a user.
   * @param id The id of the task to unclaim.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public unclaim(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public unclaim(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public unclaim(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public unclaim(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling unclaim.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/unclaim`,
      null,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Updates a task.
   * @param id The id of the task to be updated.
   * @param camundaTaskDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTask(
    id: string,
    camundaTaskDto?: CamundaTask,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public updateTask(
    id: string,
    camundaTaskDto?: CamundaTask,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public updateTask(
    id: string,
    camundaTaskDto?: CamundaTask,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public updateTask(
    id: string,
    camundaTaskDto?: CamundaTask,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateTask.');
    }
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }
    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}`,
      camundaTaskDto,
      {
        responseType: <any>responseType,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
