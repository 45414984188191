import { IDeviceRegistration } from '@data/interfaces/device-registrations/device-registration.interface';

export class DeviceRegistration {
  id: string;
  deviceId: string;
  drk: string;
  provisionedBy: string;

  constructor(data?: IDeviceRegistration) {
    this.id = data?.id;
    this.deviceId = data?.deviceId;
    this.drk = data?.drk;
    this.provisionedBy = data?.provisionedBy;
  }
}
