import { Customer } from '@core/model/customer/customer.model';
import { DocumentManagementStatus } from '@core/enums/document-management-status.enum';
import { ExportControl } from '../export-control/export-control.model';
import { IVisit } from '@data/interfaces/visits/visit.interface';
import { Payment } from '@core/model/visit/payment.model';
import { TSAStatus } from '@core/enums/tsa-status.enum';
import { TraineeType } from '@core/enums/trainee-type.enum';
import { VisitStatus } from '@core/enums/visit-status.enum';
import { VisitorType } from '@core/enums/visitor-type.enum';
import moment from 'moment';

export class Visit {
  id: string;
  userId: string;
  startDate: Date;
  endDate: Date;
  trainingId: string;
  trainingCenterId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: Date;
  hotelName: string;
  hotelPhone: string;
  phone: string;
  email: string;
  emailConsentDate: Date;
  contactName: string;
  identityPolicyConsentDate: Date;
  visitorType: VisitorType;
  traineeType: TraineeType;
  badgeNumber: string;
  visitReason: string;
  hostName: string;
  status: VisitStatus;
  drk: string;
  lastStatusChange: Date;
  isBadgeActivated: boolean;
  exportControl: ExportControl;
  customer: Customer;
  payment: Payment;
  documentManagementStatus?: DocumentManagementStatus;
  tsaStatus?: TSAStatus;

  constructor(data?: IVisit) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.userId = data.userId;
    this.trainingId = data.trainingId;
    this.trainingCenterId = data.trainingCenterId;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.middleName = data.middleName;
    this.hotelName = data.hotelName;
    this.hotelPhone = data.hotelPhone;
    this.phone = data.phone;
    this.email = data.email;
    this.contactName = data.contactName;
    this.visitorType = data.visitorType;
    this.traineeType = data.traineeType;
    this.badgeNumber = data.badgeNumber;
    this.visitReason = data.visitReason;
    this.hostName = data.hostName;
    this.status = data.checkinStatus;
    this.drk = data.drk;
    this.isBadgeActivated = data.isBadgeActivated;
    this.documentManagementStatus = data.documentManagementStatus;
    this.tsaStatus = data.tsaStatus;

    if (data.startDate) {
      this.startDate = moment(data.startDate).toDate();
    }

    if (data.endDate) {
      this.endDate = moment(data.endDate).toDate();
    }

    if (data.dateOfBirth) {
      this.dateOfBirth = moment(data.dateOfBirth).toDate();
    }

    if (data.emailConsentDate) {
      this.emailConsentDate = moment(data.emailConsentDate).toDate();
    }

    if (data.lastStatusChange) {
      this.lastStatusChange = moment(data.lastStatusChange).toDate();
    }

    if (data.exportControl) {
      this.exportControl = new ExportControl(data.exportControl);
    }
    if (data.customer) {
      this.customer = new Customer(data.customer);
    }
    if (data.payment) {
      this.payment = new Payment(data.payment);
    }
  }

  public getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public isVisitPrecheckedIn(includeActivePassRequests: boolean): boolean {
    if(includeActivePassRequests && this.status === VisitStatus.ACCESS_PASS_REQUESTED) {
      return !this.isBadgeActivated;
    }
 
    if(this.status === VisitStatus.PRECHECKED_IN) {
      return !this.isBadgeActivated;
    }
 
    return false;
  }
}
