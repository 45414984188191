import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private isLoading$ = new BehaviorSubject<boolean>(false);
  private loading = false;

  isLoading(): boolean {
    return this.loading;
  }

  startLoading() {
    this.loading = true;
    this.isLoading$.next(true);
  }

  stopLoading() {
    this.loading = false;
    this.isLoading$.next(false);
  }

  isLoadingObservable() {
    return this.isLoading$.asObservable();
  }
}
