import { ExportControlLevel } from '@core/model/export-control/export-control-level.enum';
import { IExportControlCitizenship } from '@data/interfaces/export-control/export-control-citizenship.interface';
import moment from 'moment';

export class ExportControlCitizenship {
  id: string;
  updatedAt: Date;
  restrictionLevel: ExportControlLevel;

  constructor(data?: IExportControlCitizenship) {
    if (!data) {
      return;
    }
    Object.assign(this, data);
    if (data.updatedAt) {
      this.updatedAt = moment(data.updatedAt).toDate();
    }
  }
}
