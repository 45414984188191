import { Injectable } from '@angular/core';
import { EWorkflowActionType } from '@core/enums/workflow-action-type.enum';
import { WorkflowInformation } from '@core/model/checkin-process/workflow-information.model';
import { ECamundaVariableValueType } from '@data/interfaces/camunda/camunda-variable-value-type.enum';
import { CamundaCompleteTask } from '@data/models/camunda/camunda-complete-task.model';
import { CamundaDeleteProcessInstancesDto } from '@data/models/camunda/camunda-delete-process-instances-dto.model';
import { CamundaPatchVariables } from '@data/models/camunda/camunda-patch-variables.model';
import { CamundaProcessInstanceDto } from '@data/models/camunda/camunda-process-instance-dto.model';
import { CamundaProcessInstanceModification } from '@data/models/camunda/camunda-process-instance-modification-dto.model';
import {
  CamundaProcessInstanceModificationInstruction,
  ProcessInstanceModificationInstructionDto,
} from '@data/models/camunda/camunda-process-instance-modification-instruction.model';
import { CamundaProcessInstanceQueryDto } from '@data/models/camunda/camunda-process-instance-query-dto.model';
import { CamundaProcessInstanceWithVariables } from '@data/models/camunda/camunda-process-instance-with-variables.model';
import { CamundaStartProcessInstance } from '@data/models/camunda/camunda-start-process-instance.model';
import { CamundaTask } from '@data/models/camunda/camunda-task.model';
import { CamundaVariableValue } from '@data/models/camunda/camunda-variable-value.model';
import { ProcessDefinitionService } from '@data/services/camunda/process-definition.service';
import { ProcessInstanceService } from '@data/services/camunda/process-instance.service';
import { TaskVariableService } from '@data/services/camunda/task-variable.service';
import { TaskService } from '@data/services/camunda/task.service';
import { lastValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  constructor(
    private processDefinitionService: ProcessDefinitionService,
    private taskService: TaskService,
    private taskVariableService: TaskVariableService,
    private processInstanceService: ProcessInstanceService
  ) {}

  async createProcessInstance(
    processDefinitionKey: string,
    workflowInformation?: WorkflowInformation
  ): Promise<CamundaProcessInstanceWithVariables> {
    const camundaStartProcessInstance = new CamundaStartProcessInstance();

    if (workflowInformation) {
      camundaStartProcessInstance.variables = workflowInformation.toCamundaVariables();
    }

    camundaStartProcessInstance.businessKey = uuidv4();
    return lastValueFrom(
      this.processDefinitionService.startProcessInstanceByKey(
        processDefinitionKey,
        camundaStartProcessInstance
      )
    );
  }

  async deleteProcessInstance(
    camundaProcessInstanceId: string
  ): Promise<CamundaProcessInstanceWithVariables> {
    return lastValueFrom(
      this.processInstanceService.deleteProcessInstance(camundaProcessInstanceId)
    );
  }

  async deleteProcessInstances(
    camundaDeleteProcessInstancesDto?: CamundaDeleteProcessInstancesDto
  ): Promise<CamundaProcessInstanceWithVariables> {
    return lastValueFrom(
      this.processInstanceService.deleteProcessInstancesAsyncOperation(
        camundaDeleteProcessInstancesDto
      )
    );
  }

  async cancelProcessInstance(
    camundaProcessInstanceId: string
  ): Promise<CamundaProcessInstanceWithVariables> {
    const camundaProcessInstanceModification = new CamundaProcessInstanceModification();
    const camundaProcessInstanceModificationInstruction =
      new CamundaProcessInstanceModificationInstruction();
    camundaProcessInstanceModification.skipCustomListeners = true;
    camundaProcessInstanceModification.skipIoMappings = true;
    camundaProcessInstanceModificationInstruction.type =
      ProcessInstanceModificationInstructionDto.TypeEnum.Cancel;

    camundaProcessInstanceModification.instructions = [
      camundaProcessInstanceModificationInstruction,
    ];
    return lastValueFrom(
      this.processInstanceService.modifyProcessInstance(
        camundaProcessInstanceId,
        camundaProcessInstanceModification
      )
    );
  }

  async getProcessInstance(
    camundaProcessInstanceId: string
  ): Promise<CamundaProcessInstanceWithVariables> {
    return lastValueFrom(this.processInstanceService.getProcessInstance(camundaProcessInstanceId));
  }

  async getProcessInstances(businessKey: string): Promise<CamundaProcessInstanceWithVariables[]> {
    return lastValueFrom(
      this.processInstanceService.getProcessInstances(null, null, null, null, null, businessKey)
    );
  }

  async queryProcessInstances(
    firstResult: number,
    maxResults: number,
    camundaProcessInstanceQueryDto: CamundaProcessInstanceQueryDto
  ): Promise<CamundaProcessInstanceDto[]> {
    return lastValueFrom(
      this.processInstanceService.queryProcessInstances(
        firstResult,
        maxResults,
        camundaProcessInstanceQueryDto
      )
    );
  }

  public async completeTask(
    taskId: string,
    workflowInformation?: WorkflowInformation
  ): Promise<void> {
    const camundaCompleteTask = new CamundaCompleteTask();
    if (workflowInformation) {
      camundaCompleteTask.variables = workflowInformation.toCamundaVariables();
    }
    camundaCompleteTask.addVariable(
      `action`,
      EWorkflowActionType.DEFAULT,
      ECamundaVariableValueType.STRING
    );
    await lastValueFrom(this.taskService.complete(taskId, camundaCompleteTask));
  }

  public async previousTask(
    taskId: string,
    workflowInformation?: WorkflowInformation
  ): Promise<void> {
    const camundaCompleteTask = new CamundaCompleteTask();
    if (workflowInformation) {
      camundaCompleteTask.variables = workflowInformation.toCamundaVariables();
    }
    camundaCompleteTask.addVariable(
      `action`,
      EWorkflowActionType.BACK,
      ECamundaVariableValueType.STRING
    );
    await lastValueFrom(this.taskService.complete(taskId, camundaCompleteTask));
  }

  public async bypassTask(taskId: string): Promise<void> {
    const camundaCompleteTask = new CamundaCompleteTask();
    camundaCompleteTask.addVariable(
      `action`,
      EWorkflowActionType.BYPASS,
      ECamundaVariableValueType.STRING
    );
    await lastValueFrom(this.taskService.complete(taskId, camundaCompleteTask));
  }

  public async exitTask(taskId: string): Promise<void> {
    const camundaCompleteTask = new CamundaCompleteTask();
    camundaCompleteTask.addVariable(
      `action`,
      EWorkflowActionType.EXIT,
      ECamundaVariableValueType.STRING
    );
    await lastValueFrom(this.taskService.complete(taskId, camundaCompleteTask));
  }

  public async getActiveTask(processInstanceBusinessKey: string): Promise<CamundaTask> {
    const tasks = await lastValueFrom(
      this.taskService.getTasks({ active: true, processInstanceBusinessKey })
    );
    if (tasks && tasks.length > 1) {
      throw new Error(`no-unique-active-task`);
    }
    return tasks.shift();
  }

  public async deleteTaskVariables(taskId: string, variableNames: string[]): Promise<void> {
    if (!variableNames || variableNames.length === 0) {
      return;
    }
    const camundaPatchVariables = new CamundaPatchVariables();
    camundaPatchVariables.deletions = variableNames;
    await lastValueFrom(
      this.taskVariableService.modifyTaskVariables(taskId, camundaPatchVariables)
    );
  }

  public async getTaskVariables(taskId: string): Promise<{ [key: string]: CamundaVariableValue }> {
    return lastValueFrom(this.taskVariableService.getTaskVariables(taskId));
  }

  async deleteProcessInstancesByDrk(drk: string) {
    if (!drk) {
      return;
    }
    const camundaProcessInstanceQueryDto = new CamundaProcessInstanceQueryDto();
    camundaProcessInstanceQueryDto.variables = [{ name: 'drk', operator: 'eq', value: drk as any }];
    const camundaProcessInstances = await this.queryProcessInstances(
      null,
      null,
      camundaProcessInstanceQueryDto
    );
    if (camundaProcessInstances && camundaProcessInstances.length > 0) {
      const camundaDeleteProcessInstancesDto = new CamundaDeleteProcessInstancesDto();
      camundaDeleteProcessInstancesDto.processInstanceIds = camundaProcessInstances.map(
        (instance) => instance.id
      );
      camundaDeleteProcessInstancesDto.deleteReason = `Cleaning previous instances for drk ${drk}`;
      await this.deleteProcessInstances(camundaDeleteProcessInstancesDto);
    }
  }
}
