/**
 * Camunda BPM REST API
 * OpenApi Spec for Camunda BPM REST API.
 *
 * The version of the OpenAPI document: 7.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addOptionsToHttpHeaders, addToHttpParams } from '@core/utils/http-params.util';
import { CamundaActivityInstanceDto } from '@data/models/camunda/camunda-activity-instance-dto.model';
import { CamundaBatchDto } from '@data/models/camunda/camunda-batch-dto.model';
import { CamundaCountResult } from '@data/models/camunda/camunda-count-result.model';
import { CamundaDeleteProcessInstancesDto } from '@data/models/camunda/camunda-delete-process-instances-dto.model';
import { CamundaPatchVariables } from '@data/models/camunda/camunda-patch-variables.model';
import { CamundaProcessInstanceDto } from '@data/models/camunda/camunda-process-instance-dto.model';
import { CamundaProcessInstanceModification } from '@data/models/camunda/camunda-process-instance-modification-dto.model';
import { CamundaProcessInstanceQueryDto } from '@data/models/camunda/camunda-process-instance-query-dto.model';
import { CamundaProcessInstanceSuspensionStateAsyncDto } from '@data/models/camunda/camunda-process-instance-suspension-state-async-dto.model';
import { CamundaProcessInstanceSuspensionStateDto } from '@data/models/camunda/camunda-process-instance-suspension-state-dto.model';
import { CamundaSetJobRetriesByProcessDto } from '@data/models/camunda/camunda-set-job-retries-by-process-dto.model';
import { CamundaSetVariablesAsyncDto } from '@data/models/camunda/camunda-set-variables-async-dto.model';
import { CamundaSuspensionStateDto } from '@data/models/camunda/camunda-suspension-state-dto.model';
import { CamundaVariableValue } from '@data/models/camunda/camunda-variable-value.model';
import { ApiService } from '@data/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProcessInstanceService {
  protected endpoint = '/camunda/process-instance';
  public defaultHeaders = new HttpHeaders();

  constructor(private apiService: ApiService) {}

  /**
   * Deletes a set of process instances asynchronously (batch) based on a historic process instance query.
   * @param camundaDeleteProcessInstancesDto **Unallowed property**: &#x60;processInstanceQuery&#x60;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAsyncHistoricQueryBased(
    camundaDeleteProcessInstancesDto?: CamundaDeleteProcessInstancesDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaBatchDto>;
  public deleteAsyncHistoricQueryBased(
    camundaDeleteProcessInstancesDto?: CamundaDeleteProcessInstancesDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaBatchDto>>;
  public deleteAsyncHistoricQueryBased(
    camundaDeleteProcessInstancesDto?: CamundaDeleteProcessInstancesDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaBatchDto>>;
  public deleteAsyncHistoricQueryBased(
    camundaDeleteProcessInstancesDto?: CamundaDeleteProcessInstancesDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaBatchDto>(
      `${this.endpoint}/delete-historic-query-based`,
      camundaDeleteProcessInstancesDto,
      {
        responseType: <any>responseType,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Deletes a running process instance by id.
   * @param id The id of the process instance to be deleted.
   * @param skipCustomListeners If set to true, the custom listeners will be skipped.
   * @param skipIoMappings If set to true, the input/output mappings will be skipped.
   * @param skipSubprocesses If set to true, subprocesses related to deleted processes will be skipped.
   * @param failIfNotExists If set to false, the request will still be successful if the process id is not found.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProcessInstance(
    id: string,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    skipSubprocesses?: boolean,
    failIfNotExists?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public deleteProcessInstance(
    id: string,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    skipSubprocesses?: boolean,
    failIfNotExists?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public deleteProcessInstance(
    id: string,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    skipSubprocesses?: boolean,
    failIfNotExists?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public deleteProcessInstance(
    id: string,
    skipCustomListeners?: boolean,
    skipIoMappings?: boolean,
    skipSubprocesses?: boolean,
    failIfNotExists?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteProcessInstance.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let queryParameters = new HttpParams();
    if (skipCustomListeners !== undefined && skipCustomListeners !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>skipCustomListeners,
        'skipCustomListeners'
      );
    }
    if (skipIoMappings !== undefined && skipIoMappings !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>skipIoMappings, 'skipIoMappings');
    }
    if (skipSubprocesses !== undefined && skipSubprocesses !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>skipSubprocesses, 'skipSubprocesses');
    }
    if (failIfNotExists !== undefined && failIfNotExists !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>failIfNotExists, 'failIfNotExists');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.delete<any>(`${this.endpoint}/${encodeURIComponent(String(id))}`, {
      params: queryParameters,
      responseType: <any>responseType,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Deletes a variable of a process instance by id.
   * @param id The id of the process instance to delete the variable from.
   * @param varName The name of the variable to delete.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProcessInstanceVariable(
    id: string,
    varName: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: undefined; headerContentType?: undefined }
  ): Observable<any>;
  public deleteProcessInstanceVariable(
    id: string,
    varName: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: undefined; headerContentType?: undefined }
  ): Observable<HttpResponse<any>>;
  public deleteProcessInstanceVariable(
    id: string,
    varName: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: undefined; headerContentType?: undefined }
  ): Observable<HttpEvent<any>>;
  public deleteProcessInstanceVariable(
    id: string,
    varName: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: undefined; headerContentType?: undefined }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteProcessInstanceVariable.'
      );
    }
    if (varName === null || varName === undefined) {
      throw new Error(
        'Required parameter varName was null or undefined when calling deleteProcessInstanceVariable.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = undefined;
    }
    if (!options.headerContentType) {
      options.headerContentType = undefined;
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);
    let responseType = 'json';

    return this.apiService.delete<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables/${encodeURIComponent(
        String(varName)
      )}`,
      {
        responseType,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Deletes multiple process instances asynchronously (batch).
   * @param camundaDeleteProcessInstancesDto **Unallowed property**: &#x60;historicProcessInstanceQuery&#x60;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProcessInstancesAsyncOperation(
    camundaDeleteProcessInstancesDto?: CamundaDeleteProcessInstancesDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaBatchDto>;
  public deleteProcessInstancesAsyncOperation(
    camundaDeleteProcessInstancesDto?: CamundaDeleteProcessInstancesDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaBatchDto>>;
  public deleteProcessInstancesAsyncOperation(
    camundaDeleteProcessInstancesDto?: CamundaDeleteProcessInstancesDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaBatchDto>>;
  public deleteProcessInstancesAsyncOperation(
    camundaDeleteProcessInstancesDto?: CamundaDeleteProcessInstancesDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaBatchDto>(
      `${this.endpoint}/delete`,
      camundaDeleteProcessInstancesDto,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Retrieves an Activity Instance (Tree) for a given process instance by id.
   * @param id The id of the process instance for which the activity instance should be retrieved.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getActivityInstanceTree(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaActivityInstanceDto>;
  public getActivityInstanceTree(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaActivityInstanceDto>>;
  public getActivityInstanceTree(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaActivityInstanceDto>>;
  public getActivityInstanceTree(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getActivityInstanceTree.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaActivityInstanceDto>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/activity-instances`,
      {
        responseType: <any>responseType,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Get Process Instance
   * Retrieves a process instance by id, according to the &#x60;ProcessInstance&#x60; interface in the engine.
   * @param id The id of the process instance to be retrieved.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessInstance(
    id: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaProcessInstanceDto>;
  public getProcessInstance(
    id: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaProcessInstanceDto>>;
  public getProcessInstance(
    id: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaProcessInstanceDto>>;
  public getProcessInstance(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProcessInstance.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaProcessInstanceDto>(
      `${this.endpoint}/${encodeURIComponent(String(id))}`,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Retrieves a variable of a given process instance by id.
   * @param id The id of the process instance to retrieve the variable for.
   * @param varName The name of the variable to retrieve.
   * @param deserializeValue Determines whether serializable variable values (typically variables that store custom Java objects) should be deserialized on server side (default true).  If set to true, a serializable variable will be deserialized on server side and transformed to JSON using [Jackson\&#39;s](https://github.com/FasterXML/jackson) POJO/bean property introspection feature. Note that this requires the Java classes of the variable value to be on the REST API\&#39;s classpath.  If set to false, a serializable variable will be returned in its serialized format. For example, a variable that is serialized as XML will be returned as a JSON string containing XML.  Note: While true is the default value for reasons of backward compatibility, we recommend setting this parameter to false when developing web applications that are independent of the Java process applications deployed to the engine.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessInstanceVariable(
    id: string,
    varName: string,
    deserializeValue?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaVariableValue>;
  public getProcessInstanceVariable(
    id: string,
    varName: string,
    deserializeValue?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaVariableValue>>;
  public getProcessInstanceVariable(
    id: string,
    varName: string,
    deserializeValue?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaVariableValue>>;
  public getProcessInstanceVariable(
    id: string,
    varName: string,
    deserializeValue?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProcessInstanceVariable.'
      );
    }
    if (varName === null || varName === undefined) {
      throw new Error(
        'Required parameter varName was null or undefined when calling getProcessInstanceVariable.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let queryParameters = new HttpParams();
    if (deserializeValue !== undefined && deserializeValue !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deserializeValue, 'deserializeValue');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaVariableValue>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables/${encodeURIComponent(
        String(varName)
      )}`,
      {
        params: queryParameters,
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Retrieves the content of a Process Variable by the Process Instance id and the Process Variable name. Applicable for byte array or file Process Variables.
   * @param id The id of the process instance to retrieve the variable for.
   * @param varName The name of the variable to retrieve.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessInstanceVariableBinary(
    id: string,
    varName: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | 'text/plain' | 'application/json';
      headerContentType?: 'application/octet-stream' | 'text/plain' | 'application/json';
    }
  ): Observable<Blob>;
  public getProcessInstanceVariableBinary(
    id: string,
    varName: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | 'text/plain' | 'application/json';
      headerContentType?: 'application/octet-stream' | 'text/plain' | 'application/json';
    }
  ): Observable<HttpResponse<Blob>>;
  public getProcessInstanceVariableBinary(
    id: string,
    varName: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      headerAccept?: 'application/octet-stream' | 'text/plain' | 'application/json';
      headerContentType?: 'application/octet-stream' | 'text/plain' | 'application/json';
    }
  ): Observable<HttpEvent<Blob>>;
  public getProcessInstanceVariableBinary(
    id: string,
    varName: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      headerAccept?: 'application/octet-stream' | 'text/plain' | 'application/json';
      headerContentType?: 'application/octet-stream' | 'text/plain' | 'application/json';
    }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProcessInstanceVariableBinary.'
      );
    }
    if (varName === null || varName === undefined) {
      throw new Error(
        'Required parameter varName was null or undefined when calling getProcessInstanceVariableBinary.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    return this.apiService.get(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables/${encodeURIComponent(
        String(varName)
      )}/data`,
      {
        responseType: 'blob',

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Retrieves all variables of a given process instance by id.
   * @param id The id of the process instance to retrieve the variables from.
   * @param deserializeValue Determines whether serializable variable values (typically variables that store custom Java objects) should be deserialized on server side (default true).  If set to true, a serializable variable will be deserialized on server side and transformed to JSON using [Jackson\&#39;s](https://github.com/FasterXML/jackson) POJO/bean property introspection feature. Note that this requires the Java classes of the variable value to be on the REST API\&#39;s classpath.  If set to false, a serializable variable will be returned in its serialized format. For example, a variable that is serialized as XML will be returned as a JSON string containing XML.  Note: While true is the default value for reasons of backward compatibility, we recommend setting this parameter to false when developing web applications that are independent of the Java process applications deployed to the engine.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessInstanceVariables(
    id: string,
    deserializeValue?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<{ [key: string]: CamundaVariableValue }>;
  public getProcessInstanceVariables(
    id: string,
    deserializeValue?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<{ [key: string]: CamundaVariableValue }>>;
  public getProcessInstanceVariables(
    id: string,
    deserializeValue?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<{ [key: string]: CamundaVariableValue }>>;
  public getProcessInstanceVariables(
    id: string,
    deserializeValue?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getProcessInstanceVariables.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let queryParameters = new HttpParams();
    if (deserializeValue !== undefined && deserializeValue !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deserializeValue, 'deserializeValue');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<{ [key: string]: CamundaVariableValue }>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables`,
      {
        params: queryParameters,
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Queries for process instances that fulfill given parameters. Parameters may be static as well as dynamic runtime properties of process instances. The size of the result set can be retrieved by using the Get Instance Count method.
   * @param sortBy Sort the results lexicographically by a given criterion. Must be used in conjunction with the sortOrder parameter.
   * @param sortOrder Sort the results in a given order. Values may be asc for ascending order or desc for descending order. Must be used in conjunction with the sortBy parameter.
   * @param firstResult Pagination of results. Specifies the index of the first result to return.
   * @param maxResults Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
   * @param processInstanceIds Filter by a comma-separated list of process instance ids.
   * @param businessKey Filter by process instance business key.
   * @param businessKeyLike Filter by process instance business key that the parameter is a substring of.
   * @param caseInstanceId Filter by case instance id.
   * @param processDefinitionId Filter by the deployment the id belongs to.
   * @param processDefinitionKey Filter by the key of the process definition the instances run on.
   * @param processDefinitionKeyIn Filter by a comma-separated list of process definition keys. A process instance must have one of the given process definition keys.
   * @param processDefinitionKeyNotIn Exclude instances by a comma-separated list of process definition keys. A process instance must not have one of the given process definition keys.
   * @param deploymentId Filter by the deployment the id belongs to.
   * @param superProcessInstance Restrict query to all process instances that are sub process instances of the given process instance. Takes a process instance id.
   * @param subProcessInstance Restrict query to all process instances that have the given process instance as a sub process instance. Takes a process instance id.
   * @param superCaseInstance Restrict query to all process instances that are sub process instances of the given case instance. Takes a case instance id.
   * @param subCaseInstance Restrict query to all process instances that have the given case instance as a sub case instance. Takes a case instance id.
   * @param active Only include active process instances. Value may only be true, as false is the default behavior.
   * @param suspended Only include suspended process instances. Value may only be true, as false is the default behavior.
   * @param withIncident Filter by presence of incidents. Selects only process instances that have an incident.
   * @param incidentId Filter by the incident id.
   * @param incidentType Filter by the incident type. See the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/incidents/#incident-types) for a list of incident types.
   * @param incidentMessage Filter by the incident message. Exact match.
   * @param incidentMessageLike Filter by the incident message that the parameter is a substring of.
   * @param tenantIdIn Filter by a comma-separated list of tenant ids. A process instance must have one of the given tenant ids.
   * @param withoutTenantId Only include process instances which belong to no tenant.
   * @param processDefinitionWithoutTenantId Only include process instances which process definition has no tenant id.
   * @param activityIdIn Filter by a comma-separated list of activity ids. A process instance must currently wait in a leaf activity with one of the given activity ids.
   * @param rootProcessInstances Restrict the query to all process instances that are top level process instances.
   * @param leafProcessInstances Restrict the query to all process instances that are leaf instances. (i.e. don\&#39;t have any sub instances).
   * @param variables Only include process instances that have variables with certain values. Variable filtering expressions are comma-separated and are structured as follows:  A valid parameter value has the form &#x60;key_operator_value&#x60;. &#x60;key&#x60; is the variable name, &#x60;operator&#x60; is the comparison operator to be used and &#x60;value&#x60; the variable value.  **Note**: Values are always treated as String objects on server side.  Valid &#x60;operator&#x60; values are: &#x60;eq&#x60; - equal to; &#x60;neq&#x60; - not equal to; &#x60;gt&#x60; - greater than; &#x60;gteq&#x60; - greater than or equal to; &#x60;lt&#x60; - lower than; &#x60;lteq&#x60; - lower than or equal to; &#x60;like&#x60;. &#x60;key&#x60; and &#x60;value&#x60; may not contain underscore or comma characters.
   * @param variableNamesIgnoreCase Match all variable names in this query case-insensitively. If set to true variableName and variablename are treated as equal.
   * @param variableValuesIgnoreCase Match all variable values in this query case-insensitively. If set to true variableValue and variablevalue are treated as equal.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessInstances(
    sortBy?: 'instanceId' | 'definitionKey' | 'definitionId' | 'tenantId' | 'businessKey',
    sortOrder?: 'asc' | 'desc',
    firstResult?: number,
    maxResults?: number,
    processInstanceIds?: string,
    businessKey?: string,
    businessKeyLike?: string,
    caseInstanceId?: string,
    processDefinitionId?: string,
    processDefinitionKey?: string,
    processDefinitionKeyIn?: string,
    processDefinitionKeyNotIn?: string,
    deploymentId?: string,
    superProcessInstance?: string,
    subProcessInstance?: string,
    superCaseInstance?: string,
    subCaseInstance?: string,
    active?: boolean,
    suspended?: boolean,
    withIncident?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    processDefinitionWithoutTenantId?: boolean,
    activityIdIn?: string,
    rootProcessInstances?: boolean,
    leafProcessInstances?: boolean,
    variables?: string,
    variableNamesIgnoreCase?: boolean,
    variableValuesIgnoreCase?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<Array<CamundaProcessInstanceDto>>;
  public getProcessInstances(
    sortBy?: 'instanceId' | 'definitionKey' | 'definitionId' | 'tenantId' | 'businessKey',
    sortOrder?: 'asc' | 'desc',
    firstResult?: number,
    maxResults?: number,
    processInstanceIds?: string,
    businessKey?: string,
    businessKeyLike?: string,
    caseInstanceId?: string,
    processDefinitionId?: string,
    processDefinitionKey?: string,
    processDefinitionKeyIn?: string,
    processDefinitionKeyNotIn?: string,
    deploymentId?: string,
    superProcessInstance?: string,
    subProcessInstance?: string,
    superCaseInstance?: string,
    subCaseInstance?: string,
    active?: boolean,
    suspended?: boolean,
    withIncident?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    processDefinitionWithoutTenantId?: boolean,
    activityIdIn?: string,
    rootProcessInstances?: boolean,
    leafProcessInstances?: boolean,
    variables?: string,
    variableNamesIgnoreCase?: boolean,
    variableValuesIgnoreCase?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<Array<CamundaProcessInstanceDto>>>;
  public getProcessInstances(
    sortBy?: 'instanceId' | 'definitionKey' | 'definitionId' | 'tenantId' | 'businessKey',
    sortOrder?: 'asc' | 'desc',
    firstResult?: number,
    maxResults?: number,
    processInstanceIds?: string,
    businessKey?: string,
    businessKeyLike?: string,
    caseInstanceId?: string,
    processDefinitionId?: string,
    processDefinitionKey?: string,
    processDefinitionKeyIn?: string,
    processDefinitionKeyNotIn?: string,
    deploymentId?: string,
    superProcessInstance?: string,
    subProcessInstance?: string,
    superCaseInstance?: string,
    subCaseInstance?: string,
    active?: boolean,
    suspended?: boolean,
    withIncident?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    processDefinitionWithoutTenantId?: boolean,
    activityIdIn?: string,
    rootProcessInstances?: boolean,
    leafProcessInstances?: boolean,
    variables?: string,
    variableNamesIgnoreCase?: boolean,
    variableValuesIgnoreCase?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<Array<CamundaProcessInstanceDto>>>;
  public getProcessInstances(
    sortBy?: 'instanceId' | 'definitionKey' | 'definitionId' | 'tenantId' | 'businessKey',
    sortOrder?: 'asc' | 'desc',
    firstResult?: number,
    maxResults?: number,
    processInstanceIds?: string,
    businessKey?: string,
    businessKeyLike?: string,
    caseInstanceId?: string,
    processDefinitionId?: string,
    processDefinitionKey?: string,
    processDefinitionKeyIn?: string,
    processDefinitionKeyNotIn?: string,
    deploymentId?: string,
    superProcessInstance?: string,
    subProcessInstance?: string,
    superCaseInstance?: string,
    subCaseInstance?: string,
    active?: boolean,
    suspended?: boolean,
    withIncident?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    processDefinitionWithoutTenantId?: boolean,
    activityIdIn?: string,
    rootProcessInstances?: boolean,
    leafProcessInstances?: boolean,
    variables?: string,
    variableNamesIgnoreCase?: boolean,
    variableValuesIgnoreCase?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let queryParameters = new HttpParams();
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>sortBy, 'sortBy');
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>sortOrder, 'sortOrder');
    }
    if (firstResult !== undefined && firstResult !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>firstResult, 'firstResult');
    }
    if (maxResults !== undefined && maxResults !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>maxResults, 'maxResults');
    }
    if (processInstanceIds !== undefined && processInstanceIds !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processInstanceIds,
        'processInstanceIds'
      );
    }
    if (businessKey !== undefined && businessKey !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>businessKey, 'businessKey');
    }
    if (businessKeyLike !== undefined && businessKeyLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>businessKeyLike, 'businessKeyLike');
    }
    if (caseInstanceId !== undefined && caseInstanceId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>caseInstanceId, 'caseInstanceId');
    }
    if (processDefinitionId !== undefined && processDefinitionId !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionId,
        'processDefinitionId'
      );
    }
    if (processDefinitionKey !== undefined && processDefinitionKey !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionKey,
        'processDefinitionKey'
      );
    }
    if (processDefinitionKeyIn !== undefined && processDefinitionKeyIn !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionKeyIn,
        'processDefinitionKeyIn'
      );
    }
    if (processDefinitionKeyNotIn !== undefined && processDefinitionKeyNotIn !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionKeyNotIn,
        'processDefinitionKeyNotIn'
      );
    }
    if (deploymentId !== undefined && deploymentId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deploymentId, 'deploymentId');
    }
    if (superProcessInstance !== undefined && superProcessInstance !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>superProcessInstance,
        'superProcessInstance'
      );
    }
    if (subProcessInstance !== undefined && subProcessInstance !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>subProcessInstance,
        'subProcessInstance'
      );
    }
    if (superCaseInstance !== undefined && superCaseInstance !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>superCaseInstance,
        'superCaseInstance'
      );
    }
    if (subCaseInstance !== undefined && subCaseInstance !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>subCaseInstance, 'subCaseInstance');
    }
    if (active !== undefined && active !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>active, 'active');
    }
    if (suspended !== undefined && suspended !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>suspended, 'suspended');
    }
    if (withIncident !== undefined && withIncident !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>withIncident, 'withIncident');
    }
    if (incidentId !== undefined && incidentId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentId, 'incidentId');
    }
    if (incidentType !== undefined && incidentType !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentType, 'incidentType');
    }
    if (incidentMessage !== undefined && incidentMessage !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentMessage, 'incidentMessage');
    }
    if (incidentMessageLike !== undefined && incidentMessageLike !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>incidentMessageLike,
        'incidentMessageLike'
      );
    }
    if (tenantIdIn !== undefined && tenantIdIn !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>tenantIdIn, 'tenantIdIn');
    }
    if (withoutTenantId !== undefined && withoutTenantId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>withoutTenantId, 'withoutTenantId');
    }
    if (
      processDefinitionWithoutTenantId !== undefined &&
      processDefinitionWithoutTenantId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionWithoutTenantId,
        'processDefinitionWithoutTenantId'
      );
    }
    if (activityIdIn !== undefined && activityIdIn !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>activityIdIn, 'activityIdIn');
    }
    if (rootProcessInstances !== undefined && rootProcessInstances !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>rootProcessInstances,
        'rootProcessInstances'
      );
    }
    if (leafProcessInstances !== undefined && leafProcessInstances !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>leafProcessInstances,
        'leafProcessInstances'
      );
    }
    if (variables !== undefined && variables !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>variables, 'variables');
    }
    if (variableNamesIgnoreCase !== undefined && variableNamesIgnoreCase !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>variableNamesIgnoreCase,
        'variableNamesIgnoreCase'
      );
    }
    if (variableValuesIgnoreCase !== undefined && variableValuesIgnoreCase !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>variableValuesIgnoreCase,
        'variableValuesIgnoreCase'
      );
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<Array<CamundaProcessInstanceDto>>(`${this.endpoint}`, {
      params: queryParameters,
      responseType: <any>responseType,

      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Queries for the number of process instances that fulfill given parameters.
   * @param processInstanceIds Filter by a comma-separated list of process instance ids.
   * @param businessKey Filter by process instance business key.
   * @param businessKeyLike Filter by process instance business key that the parameter is a substring of.
   * @param caseInstanceId Filter by case instance id.
   * @param processDefinitionId Filter by the deployment the id belongs to.
   * @param processDefinitionKey Filter by the key of the process definition the instances run on.
   * @param processDefinitionKeyIn Filter by a comma-separated list of process definition keys. A process instance must have one of the given process definition keys.
   * @param processDefinitionKeyNotIn Exclude instances by a comma-separated list of process definition keys. A process instance must not have one of the given process definition keys.
   * @param deploymentId Filter by the deployment the id belongs to.
   * @param superProcessInstance Restrict query to all process instances that are sub process instances of the given process instance. Takes a process instance id.
   * @param subProcessInstance Restrict query to all process instances that have the given process instance as a sub process instance. Takes a process instance id.
   * @param superCaseInstance Restrict query to all process instances that are sub process instances of the given case instance. Takes a case instance id.
   * @param subCaseInstance Restrict query to all process instances that have the given case instance as a sub case instance. Takes a case instance id.
   * @param active Only include active process instances. Value may only be true, as false is the default behavior.
   * @param suspended Only include suspended process instances. Value may only be true, as false is the default behavior.
   * @param withIncident Filter by presence of incidents. Selects only process instances that have an incident.
   * @param incidentId Filter by the incident id.
   * @param incidentType Filter by the incident type. See the [User Guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/incidents/#incident-types) for a list of incident types.
   * @param incidentMessage Filter by the incident message. Exact match.
   * @param incidentMessageLike Filter by the incident message that the parameter is a substring of.
   * @param tenantIdIn Filter by a comma-separated list of tenant ids. A process instance must have one of the given tenant ids.
   * @param withoutTenantId Only include process instances which belong to no tenant.
   * @param processDefinitionWithoutTenantId Only include process instances which process definition has no tenant id.
   * @param activityIdIn Filter by a comma-separated list of activity ids. A process instance must currently wait in a leaf activity with one of the given activity ids.
   * @param rootProcessInstances Restrict the query to all process instances that are top level process instances.
   * @param leafProcessInstances Restrict the query to all process instances that are leaf instances. (i.e. don\&#39;t have any sub instances).
   * @param variables Only include process instances that have variables with certain values. Variable filtering expressions are comma-separated and are structured as follows:  A valid parameter value has the form &#x60;key_operator_value&#x60;. &#x60;key&#x60; is the variable name, &#x60;operator&#x60; is the comparison operator to be used and &#x60;value&#x60; the variable value.  **Note**: Values are always treated as String objects on server side.  Valid &#x60;operator&#x60; values are: &#x60;eq&#x60; - equal to; &#x60;neq&#x60; - not equal to; &#x60;gt&#x60; - greater than; &#x60;gteq&#x60; - greater than or equal to; &#x60;lt&#x60; - lower than; &#x60;lteq&#x60; - lower than or equal to; &#x60;like&#x60;. &#x60;key&#x60; and &#x60;value&#x60; may not contain underscore or comma characters.
   * @param variableNamesIgnoreCase Match all variable names in this query case-insensitively. If set to true variableName and variablename are treated as equal.
   * @param variableValuesIgnoreCase Match all variable values in this query case-insensitively. If set to true variableValue and variablevalue are treated as equal.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProcessInstancesCount(
    processInstanceIds?: string,
    businessKey?: string,
    businessKeyLike?: string,
    caseInstanceId?: string,
    processDefinitionId?: string,
    processDefinitionKey?: string,
    processDefinitionKeyIn?: string,
    processDefinitionKeyNotIn?: string,
    deploymentId?: string,
    superProcessInstance?: string,
    subProcessInstance?: string,
    superCaseInstance?: string,
    subCaseInstance?: string,
    active?: boolean,
    suspended?: boolean,
    withIncident?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    processDefinitionWithoutTenantId?: boolean,
    activityIdIn?: string,
    rootProcessInstances?: boolean,
    leafProcessInstances?: boolean,
    variables?: string,
    variableNamesIgnoreCase?: boolean,
    variableValuesIgnoreCase?: boolean,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaCountResult>;
  public getProcessInstancesCount(
    processInstanceIds?: string,
    businessKey?: string,
    businessKeyLike?: string,
    caseInstanceId?: string,
    processDefinitionId?: string,
    processDefinitionKey?: string,
    processDefinitionKeyIn?: string,
    processDefinitionKeyNotIn?: string,
    deploymentId?: string,
    superProcessInstance?: string,
    subProcessInstance?: string,
    superCaseInstance?: string,
    subCaseInstance?: string,
    active?: boolean,
    suspended?: boolean,
    withIncident?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    processDefinitionWithoutTenantId?: boolean,
    activityIdIn?: string,
    rootProcessInstances?: boolean,
    leafProcessInstances?: boolean,
    variables?: string,
    variableNamesIgnoreCase?: boolean,
    variableValuesIgnoreCase?: boolean,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaCountResult>>;
  public getProcessInstancesCount(
    processInstanceIds?: string,
    businessKey?: string,
    businessKeyLike?: string,
    caseInstanceId?: string,
    processDefinitionId?: string,
    processDefinitionKey?: string,
    processDefinitionKeyIn?: string,
    processDefinitionKeyNotIn?: string,
    deploymentId?: string,
    superProcessInstance?: string,
    subProcessInstance?: string,
    superCaseInstance?: string,
    subCaseInstance?: string,
    active?: boolean,
    suspended?: boolean,
    withIncident?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    processDefinitionWithoutTenantId?: boolean,
    activityIdIn?: string,
    rootProcessInstances?: boolean,
    leafProcessInstances?: boolean,
    variables?: string,
    variableNamesIgnoreCase?: boolean,
    variableValuesIgnoreCase?: boolean,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaCountResult>>;
  public getProcessInstancesCount(
    processInstanceIds?: string,
    businessKey?: string,
    businessKeyLike?: string,
    caseInstanceId?: string,
    processDefinitionId?: string,
    processDefinitionKey?: string,
    processDefinitionKeyIn?: string,
    processDefinitionKeyNotIn?: string,
    deploymentId?: string,
    superProcessInstance?: string,
    subProcessInstance?: string,
    superCaseInstance?: string,
    subCaseInstance?: string,
    active?: boolean,
    suspended?: boolean,
    withIncident?: boolean,
    incidentId?: string,
    incidentType?: string,
    incidentMessage?: string,
    incidentMessageLike?: string,
    tenantIdIn?: string,
    withoutTenantId?: boolean,
    processDefinitionWithoutTenantId?: boolean,
    activityIdIn?: string,
    rootProcessInstances?: boolean,
    leafProcessInstances?: boolean,
    variables?: string,
    variableNamesIgnoreCase?: boolean,
    variableValuesIgnoreCase?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let queryParameters = new HttpParams();
    if (processInstanceIds !== undefined && processInstanceIds !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processInstanceIds,
        'processInstanceIds'
      );
    }
    if (businessKey !== undefined && businessKey !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>businessKey, 'businessKey');
    }
    if (businessKeyLike !== undefined && businessKeyLike !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>businessKeyLike, 'businessKeyLike');
    }
    if (caseInstanceId !== undefined && caseInstanceId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>caseInstanceId, 'caseInstanceId');
    }
    if (processDefinitionId !== undefined && processDefinitionId !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionId,
        'processDefinitionId'
      );
    }
    if (processDefinitionKey !== undefined && processDefinitionKey !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionKey,
        'processDefinitionKey'
      );
    }
    if (processDefinitionKeyIn !== undefined && processDefinitionKeyIn !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionKeyIn,
        'processDefinitionKeyIn'
      );
    }
    if (processDefinitionKeyNotIn !== undefined && processDefinitionKeyNotIn !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionKeyNotIn,
        'processDefinitionKeyNotIn'
      );
    }
    if (deploymentId !== undefined && deploymentId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>deploymentId, 'deploymentId');
    }
    if (superProcessInstance !== undefined && superProcessInstance !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>superProcessInstance,
        'superProcessInstance'
      );
    }
    if (subProcessInstance !== undefined && subProcessInstance !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>subProcessInstance,
        'subProcessInstance'
      );
    }
    if (superCaseInstance !== undefined && superCaseInstance !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>superCaseInstance,
        'superCaseInstance'
      );
    }
    if (subCaseInstance !== undefined && subCaseInstance !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>subCaseInstance, 'subCaseInstance');
    }
    if (active !== undefined && active !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>active, 'active');
    }
    if (suspended !== undefined && suspended !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>suspended, 'suspended');
    }
    if (withIncident !== undefined && withIncident !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>withIncident, 'withIncident');
    }
    if (incidentId !== undefined && incidentId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentId, 'incidentId');
    }
    if (incidentType !== undefined && incidentType !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentType, 'incidentType');
    }
    if (incidentMessage !== undefined && incidentMessage !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>incidentMessage, 'incidentMessage');
    }
    if (incidentMessageLike !== undefined && incidentMessageLike !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>incidentMessageLike,
        'incidentMessageLike'
      );
    }
    if (tenantIdIn !== undefined && tenantIdIn !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>tenantIdIn, 'tenantIdIn');
    }
    if (withoutTenantId !== undefined && withoutTenantId !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>withoutTenantId, 'withoutTenantId');
    }
    if (
      processDefinitionWithoutTenantId !== undefined &&
      processDefinitionWithoutTenantId !== null
    ) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>processDefinitionWithoutTenantId,
        'processDefinitionWithoutTenantId'
      );
    }
    if (activityIdIn !== undefined && activityIdIn !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>activityIdIn, 'activityIdIn');
    }
    if (rootProcessInstances !== undefined && rootProcessInstances !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>rootProcessInstances,
        'rootProcessInstances'
      );
    }
    if (leafProcessInstances !== undefined && leafProcessInstances !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>leafProcessInstances,
        'leafProcessInstances'
      );
    }
    if (variables !== undefined && variables !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>variables, 'variables');
    }
    if (variableNamesIgnoreCase !== undefined && variableNamesIgnoreCase !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>variableNamesIgnoreCase,
        'variableNamesIgnoreCase'
      );
    }
    if (variableValuesIgnoreCase !== undefined && variableValuesIgnoreCase !== null) {
      queryParameters = addToHttpParams(
        queryParameters,
        <any>variableValuesIgnoreCase,
        'variableValuesIgnoreCase'
      );
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.get<CamundaCountResult>(`${this.endpoint}/count`, {
      params: queryParameters,
      responseType: <any>responseType,

      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Submits a list of modification instructions to change a process instance\&#39;s execution state. A modification instruction is one of the following:  * Starting execution before an activity * Starting execution after an activity on its single outgoing sequence flow * Starting execution on a specific sequence flow * Canceling an activity instance, transition instance, or all instances (activity or transition) for an activity  Instructions are executed immediately and in the order they are provided in this request\&#39;s body. Variables can be provided with every starting instruction.  The exact semantics of modification can be read about in the [User guide](https://docs.camunda.org/manual/develop/user-guide/process-engine/process-instance-modification/).
   * @param id The id of the process instance to modify.
   * @param camundaProcessInstanceModification
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public modifyProcessInstance(
    id: string,
    camundaProcessInstanceModification?: CamundaProcessInstanceModification,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public modifyProcessInstance(
    id: string,
    camundaProcessInstanceModification?: CamundaProcessInstanceModification,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public modifyProcessInstance(
    id: string,
    camundaProcessInstanceModification?: CamundaProcessInstanceModification,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public modifyProcessInstance(
    id: string,
    camundaProcessInstanceModification?: CamundaProcessInstanceModification,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling modifyProcessInstance.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/modification`,
      camundaProcessInstanceModification,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Submits a list of modification instructions to change a process instance\&#39;s execution state async. A modification instruction is one of the following:  * Starting execution before an activity * Starting execution after an activity on its single outgoing sequence flow * Starting execution on a specific sequence flow * Cancelling an activity instance, transition instance, or all instances (activity or transition) for an activity  Instructions are executed asynchronous and in the order they are provided in this request\&#39;s body. Variables can be provided with every starting instruction.  The exact semantics of modification can be read about in the [User guide](https://docs.camunda.org/manual/7.14/user-guide/process-engine/process-instance-modification/).
   * @param id The id of the process instance to modify.
   * @param camundaProcessInstanceModification
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public modifyProcessInstanceAsyncOperation(
    id: string,
    camundaProcessInstanceModification?: CamundaProcessInstanceModification,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaBatchDto>;
  public modifyProcessInstanceAsyncOperation(
    id: string,
    camundaProcessInstanceModification?: CamundaProcessInstanceModification,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaBatchDto>>;
  public modifyProcessInstanceAsyncOperation(
    id: string,
    camundaProcessInstanceModification?: CamundaProcessInstanceModification,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaBatchDto>>;
  public modifyProcessInstanceAsyncOperation(
    id: string,
    camundaProcessInstanceModification?: CamundaProcessInstanceModification,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling modifyProcessInstanceAsyncOperation.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaBatchDto>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/modification-async`,
      camundaProcessInstanceModification,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Updates or deletes the variables of a process instance by id. Updates precede deletions. So, if a variable is updated AND deleted, the deletion overrides the update.
   * @param id The id of the process instance to set variables for.
   * @param camundaPatchVariablesDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public modifyProcessInstanceVariables(
    id: string,
    camundaPatchVariablesDto?: CamundaPatchVariables,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public modifyProcessInstanceVariables(
    id: string,
    camundaPatchVariablesDto?: CamundaPatchVariables,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public modifyProcessInstanceVariables(
    id: string,
    camundaPatchVariablesDto?: CamundaPatchVariables,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public modifyProcessInstanceVariables(
    id: string,
    camundaPatchVariablesDto?: CamundaPatchVariables,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling modifyProcessInstanceVariables.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables`,
      camundaPatchVariablesDto,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Queries for process instances that fulfill given parameters through a JSON object. This method is slightly more powerful than the Get Instances method because it allows filtering by multiple process variables of types &#x60;string&#x60;, &#x60;number&#x60; or &#x60;boolean&#x60;.
   * @param firstResult Pagination of results. Specifies the index of the first result to return.
   * @param maxResults Pagination of results. Specifies the maximum number of results to return. Will return less results if there are no more results left.
   * @param camundaProcessInstanceQueryDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryProcessInstances(
    firstResult?: number,
    maxResults?: number,
    camundaProcessInstanceQueryDto?: CamundaProcessInstanceQueryDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<Array<CamundaProcessInstanceDto>>;
  public queryProcessInstances(
    firstResult?: number,
    maxResults?: number,
    camundaProcessInstanceQueryDto?: CamundaProcessInstanceQueryDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<Array<CamundaProcessInstanceDto>>>;
  public queryProcessInstances(
    firstResult?: number,
    maxResults?: number,
    camundaProcessInstanceQueryDto?: CamundaProcessInstanceQueryDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<Array<CamundaProcessInstanceDto>>>;
  public queryProcessInstances(
    firstResult?: number,
    maxResults?: number,
    camundaProcessInstanceQueryDto?: CamundaProcessInstanceQueryDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let queryParameters = new HttpParams();
    if (firstResult !== undefined && firstResult !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>firstResult, 'firstResult');
    }
    if (maxResults !== undefined && maxResults !== null) {
      queryParameters = addToHttpParams(queryParameters, <any>maxResults, 'maxResults');
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<Array<CamundaProcessInstanceDto>>(
      `${this.endpoint}`,
      camundaProcessInstanceQueryDto,
      {
        params: queryParameters,
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Queries for the number of process instances that fulfill the given parameters. This method takes the same message body as the Get Instances (POST) method and therefore it is slightly more powerful than the Get Instance Count method.
   * @param camundaProcessInstanceQueryDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryProcessInstancesCount(
    camundaProcessInstanceQueryDto?: CamundaProcessInstanceQueryDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaCountResult>;
  public queryProcessInstancesCount(
    camundaProcessInstanceQueryDto?: CamundaProcessInstanceQueryDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaCountResult>>;
  public queryProcessInstancesCount(
    camundaProcessInstanceQueryDto?: CamundaProcessInstanceQueryDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaCountResult>>;
  public queryProcessInstancesCount(
    camundaProcessInstanceQueryDto?: CamundaProcessInstanceQueryDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaCountResult>(
      `${this.endpoint}/count`,
      camundaProcessInstanceQueryDto,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Sets a variable of a given process instance by id.
   * @param id The id of the process instance to set the variable for.
   * @param varName The name of the variable to set.
   * @param camundaVariableValueDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setProcessInstanceVariable(
    id: string,
    varName: string,
    camundaVariableValueDto?: CamundaVariableValue,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public setProcessInstanceVariable(
    id: string,
    varName: string,
    camundaVariableValueDto?: CamundaVariableValue,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public setProcessInstanceVariable(
    id: string,
    varName: string,
    camundaVariableValueDto?: CamundaVariableValue,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public setProcessInstanceVariable(
    id: string,
    varName: string,
    camundaVariableValueDto?: CamundaVariableValue,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling setProcessInstanceVariable.'
      );
    }
    if (varName === null || varName === undefined) {
      throw new Error(
        'Required parameter varName was null or undefined when calling setProcessInstanceVariable.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables/${encodeURIComponent(
        String(varName)
      )}`,
      camundaVariableValueDto,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Sets the serialized value for a binary variable or the binary value for a file variable.
   * @param id The id of the process instance to retrieve the variable for.
   * @param varName The name of the variable to retrieve.
   * @param data The binary data to be set. For File variables, this multipart can contain the filename, binary value and MIME type of the file variable to be set Only the filename is mandatory.
   * @param valueType The name of the variable type. Either Bytes for a byte array variable or File for a file variable.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setProcessInstanceVariableBinary(
    id: string,
    varName: string,
    data?: Blob,
    valueType?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public setProcessInstanceVariableBinary(
    id: string,
    varName: string,
    data?: Blob,
    valueType?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public setProcessInstanceVariableBinary(
    id: string,
    varName: string,
    data?: Blob,
    valueType?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public setProcessInstanceVariableBinary(
    id: string,
    varName: string,
    data?: Blob,
    valueType?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling setProcessInstanceVariableBinary.'
      );
    }
    if (varName === null || varName === undefined) {
      throw new Error(
        'Required parameter varName was null or undefined when calling setProcessInstanceVariableBinary.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let formParams: { append(param: string, value: any): any };
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data

    formParams = new FormData();

    if (data !== undefined) {
      formParams = (formParams.append('data', <any>data) as any) || formParams;
    }
    if (valueType !== undefined) {
      formParams = (formParams.append('valueType', <any>valueType) as any) || formParams;
    }

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/variables/${encodeURIComponent(
        String(varName)
      )}/data`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Create a batch to set retries of jobs associated with given processes asynchronously.
   * @param camundaSetJobRetriesByProcessDto Please note that if both processInstances and processInstanceQuery are provided, then the resulting execution will be performed on the union of these sets. **Unallowed property**: &#x60;historicProcessInstanceQuery&#x60;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setRetriesByProcess(
    camundaSetJobRetriesByProcessDto?: CamundaSetJobRetriesByProcessDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaBatchDto>;
  public setRetriesByProcess(
    camundaSetJobRetriesByProcessDto?: CamundaSetJobRetriesByProcessDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaBatchDto>>;
  public setRetriesByProcess(
    camundaSetJobRetriesByProcessDto?: CamundaSetJobRetriesByProcessDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaBatchDto>>;
  public setRetriesByProcess(
    camundaSetJobRetriesByProcessDto?: CamundaSetJobRetriesByProcessDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaBatchDto>(
      `${this.endpoint}/job-retries`,
      camundaSetJobRetriesByProcessDto,
      {
        responseType: <any>responseType,
        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Create a batch to set retries of jobs asynchronously based on a historic process instance query.
   * @param camundaSetJobRetriesByProcessDto Please note that if both processInstances and historicProcessInstanceQuery are provided, then the resulting execution will be performed on the union of these sets. **Unallowed property**: &#x60;processInstanceQuery&#x60;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setRetriesByProcessHistoricQueryBased(
    camundaSetJobRetriesByProcessDto?: CamundaSetJobRetriesByProcessDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaBatchDto>;
  public setRetriesByProcessHistoricQueryBased(
    camundaSetJobRetriesByProcessDto?: CamundaSetJobRetriesByProcessDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaBatchDto>>;
  public setRetriesByProcessHistoricQueryBased(
    camundaSetJobRetriesByProcessDto?: CamundaSetJobRetriesByProcessDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaBatchDto>>;
  public setRetriesByProcessHistoricQueryBased(
    camundaSetJobRetriesByProcessDto?: CamundaSetJobRetriesByProcessDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaBatchDto>(
      `${this.endpoint}/job-retries-historic-query-based`,
      camundaSetJobRetriesByProcessDto,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Update or create runtime process variables in the root scope of process instances.
   * @param camundaSetVariablesAsyncDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setVariablesAsyncOperation(
    camundaSetVariablesAsyncDto?: CamundaSetVariablesAsyncDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaBatchDto>;
  public setVariablesAsyncOperation(
    camundaSetVariablesAsyncDto?: CamundaSetVariablesAsyncDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaBatchDto>>;
  public setVariablesAsyncOperation(
    camundaSetVariablesAsyncDto?: CamundaSetVariablesAsyncDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaBatchDto>>;
  public setVariablesAsyncOperation(
    camundaSetVariablesAsyncDto?: CamundaSetVariablesAsyncDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaBatchDto>(
      `${this.endpoint}/variables-async`,
      camundaSetVariablesAsyncDto,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Activates or suspends process instances by providing certain criteria:  # Activate/Suspend Process Instance By Process Definition Id * &#x60;suspend&#x60; * &#x60;processDefinitionId&#x60;  # Activate/Suspend Process Instance By Process Definition Key  * &#x60;suspend&#x60; * &#x60;processDefinitionKey&#x60; * &#x60;processDefinitionTenantId&#x60; * &#x60;processDefinitionWithoutTenantId&#x60;  # Activate/Suspend Process Instance In Group * &#x60;suspend&#x60; * &#x60;processInstanceIds&#x60; * &#x60;processInstanceQuery&#x60; * &#x60;historicProcessInstanceQuery&#x60;
   * @param camundaProcessInstanceSuspensionStateDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSuspensionState(
    camundaProcessInstanceSuspensionStateDto?: CamundaProcessInstanceSuspensionStateDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any>;
  public updateSuspensionState(
    camundaProcessInstanceSuspensionStateDto?: CamundaProcessInstanceSuspensionStateDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<any>>;
  public updateSuspensionState(
    camundaProcessInstanceSuspensionStateDto?: CamundaProcessInstanceSuspensionStateDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<any>>;
  public updateSuspensionState(
    camundaProcessInstanceSuspensionStateDto?: CamundaProcessInstanceSuspensionStateDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.put<any>(
      `${this.endpoint}/suspended`,
      camundaProcessInstanceSuspensionStateDto,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Activates or suspends process instances asynchronously with a list of process instance ids, a process instance query, and/or a historical process instance query.
   * @param camundaProcessInstanceSuspensionStateAsyncDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSuspensionStateAsyncOperation(
    camundaProcessInstanceSuspensionStateAsyncDto?: CamundaProcessInstanceSuspensionStateAsyncDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<CamundaBatchDto>;
  public updateSuspensionStateAsyncOperation(
    camundaProcessInstanceSuspensionStateAsyncDto?: CamundaProcessInstanceSuspensionStateAsyncDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpResponse<CamundaBatchDto>>;
  public updateSuspensionStateAsyncOperation(
    camundaProcessInstanceSuspensionStateAsyncDto?: CamundaProcessInstanceSuspensionStateAsyncDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<HttpEvent<CamundaBatchDto>>;
  public updateSuspensionStateAsyncOperation(
    camundaProcessInstanceSuspensionStateAsyncDto?: CamundaProcessInstanceSuspensionStateAsyncDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: 'application/json'; headerContentType?: 'application/json' }
  ): Observable<any> {
    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = 'application/json';
    }
    if (!options.headerContentType) {
      options.headerContentType = 'application/json';
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType: 'text' | 'json' = 'json';
    if (options.headerAccept && options.headerAccept.startsWith('text')) {
      responseType = 'text';
    }

    return this.apiService.post<CamundaBatchDto>(
      `${this.endpoint}/suspended-async`,
      camundaProcessInstanceSuspensionStateAsyncDto,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }

  /**
   * Activates or suspends a given process instance by id.
   * @param id The id of the process instance to activate or suspend.
   * @param camundaSuspensionStateDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSuspensionStateById(
    id: string,
    camundaSuspensionStateDto?: CamundaSuspensionStateDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { headerAccept?: undefined; headerContentType?: undefined }
  ): Observable<any>;
  public updateSuspensionStateById(
    id: string,
    camundaSuspensionStateDto?: CamundaSuspensionStateDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { headerAccept?: undefined; headerContentType?: undefined }
  ): Observable<HttpResponse<any>>;
  public updateSuspensionStateById(
    id: string,
    camundaSuspensionStateDto?: CamundaSuspensionStateDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { headerAccept?: undefined; headerContentType?: undefined }
  ): Observable<HttpEvent<any>>;
  public updateSuspensionStateById(
    id: string,
    camundaSuspensionStateDto?: CamundaSuspensionStateDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { headerAccept?: undefined; headerContentType?: undefined }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling updateSuspensionStateById.'
      );
    }

    if (!options) {
      options = {};
    }
    if (!options.headerAccept) {
      options.headerAccept = undefined;
    }
    if (!options.headerContentType) {
      options.headerContentType = undefined;
    }

    let headers = addOptionsToHttpHeaders(this.defaultHeaders, options);

    let responseType = 'json';

    return this.apiService.put<any>(
      `${this.endpoint}/${encodeURIComponent(String(id))}/suspended`,
      camundaSuspensionStateDto,
      {
        responseType: <any>responseType,

        headers,
        observe,
        reportProgress,
      }
    );
  }
}
