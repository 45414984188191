import { HttpHeaders, HttpParams } from '@angular/common/http';

import { Aircraft } from '@core/model/entity/aircraft.model';
import { Customer } from '@core/model/customer/customer.model';
import { TrainingCenter } from '@core/model/entity/training-center.model';

export function stateToHttpParams(
  trainingCenters?: TrainingCenter[],
  trainingStartDate?: Date,
  aircraft?: Aircraft,
  customer?: Customer
) {
  let params = new HttpParams();

  if (trainingStartDate) {
    params = params.append('startDate', trainingStartDate.toISOString());
  }

  if (trainingCenters && trainingCenters.length > 0) {
    for (const trainingCenter of trainingCenters) {
      params = params.append('trainingCenterId', trainingCenter.geminiSiteId);
    }
  }

  if (aircraft && aircraft.externalId) {
    params = params.append('aircraftId', aircraft.externalId);
  }
  if (customer && customer.salesForceId) {
    params = params.append('customerId', customer.salesForceId);
  }

  return params;
}

export function addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
  if (typeof value === 'object' && value instanceof Date === false) {
    httpParams = addToHttpParamsRecursive(httpParams, value);
  } else {
    httpParams = addToHttpParamsRecursive(httpParams, value, key);
  }
  return httpParams;
}

export function addToHttpParamsRecursive(
  httpParams: HttpParams,
  value?: any,
  key?: string
): HttpParams {
  if (value == null) {
    return httpParams;
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      (value as any[]).forEach(
        (elem) => (httpParams = addToHttpParamsRecursive(httpParams, elem, key))
      );
    } else if (value instanceof Date) {
      if (key != null) {
        httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
      } else {
        throw Error('key may not be null if value is Date');
      }
    } else {
      Object.keys(value).forEach(
        (k) =>
          (httpParams = addToHttpParamsRecursive(
            httpParams,
            value[k],
            key != null ? `${key}.${k}` : k
          ))
      );
    }
  } else if (key != null) {
    httpParams = httpParams.append(key, value);
  } else {
    throw Error('key may not be null if value is not object or array');
  }
  return httpParams;
}

export function addOptionsToHttpHeaders(
  headers: HttpHeaders,
  options?: {
    headerAccept?: string;
    headerContentType?: string;
  }
) {
  if (options) {
    if (options.headerAccept) {
      headers = headers.set('Accept', options.headerAccept);
    }
    if (options.headerAccept) {
      headers = headers.set('Content-Type', options.headerContentType);
    }
  }
  return headers;
}

/**
 * @param consumes string[] mime-types
 * @return true: consumes contains 'multipart/form-data', false: otherwise
 */
export function canConsumeForm(consumes: string[]): boolean {
  const form = 'multipart/form-data';
  for (const consume of consumes) {
    if (form === consume) {
      return true;
    }
  }
  return false;
}
