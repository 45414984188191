export enum EWorkflowVariable {
  checkinProcessConfiguration = 'checkinProcessConfiguration',
  trainingCenterId = 'trainingCenterId',
  drk = 'drk',
  flowType = 'flowType',
  visitorType = 'visitorType',
  traineeType = 'traineeType',
  recordingType = 'recordingType',
  hasConsentedIdentityVerification = 'hasConsentedIdentityVerification',
  hasAcknowledgeCovidVerification = 'hasAcknowledgeCovidVerification',
  identityDocumentType = 'identityDocumentType',
  isScanPassportSuccess = 'isScanPassportSuccess',
  isScanGovernmentIdSuccess = 'isScanGovernmentIdSuccess',
  isIdentityComparaisonSuccess = 'isIdentityComparaisonSuccess',
  isCheckinSuccess = 'isCheckinSuccess',
  isIdPictureSuccess = 'isIdPictureSuccess',
  isSelfiePictureSuccess = 'isSelfiePictureSuccess',
  isPrintBadgeSuccess = 'isPrintBadgeSuccess',
  isScanQrCodeValidationSuccess = 'isScanQrCodeValidationSuccess',
  exportControl = 'exportControl',
  trainingId = 'trainingId',
  isTrainingPaid = 'isTrainingPaid',
  tsaStatus = 'tsaStatus',
  documentManagementStatus = 'documentManagementStatus',
}
