export enum PageKey {
  WELCOME = 'WELCOME',
  COVID = 'COVID',
  COVID_RESCHEDULE = 'COVID_RESCHEDULE',
  TRAINEE_TYPE = 'TRAINEE_TYPE',
  IDENTITY_POLICY = 'IDENTITY_POLICY',
  IDENTITY_POLICY_FAIL = 'IDENTITY_POLICY_FAIL',
  IDENTITY_COMPARISON = 'IDENTITY_COMPARISON',
  IDENTITY_COMPARISON_SUCCESS = 'IDENTITY_COMPARISON_SUCCESS',
  IDENTITY_COMPARISON_FAIL = 'IDENTITY_COMPARISON_FAIL',
  PREPARE_PHOTO_FACE = 'PREPARE_PHOTO_FACE',
  PHOTO_FACE = 'PHOTO_FACE',
  PHOTO_FACE_SUCCESS = 'PHOTO_FACE_SUCCESS',
  PREPARE_PHOTO_ID = 'PREPARE_PHOTO_ID',
  PHOTO_ID = 'PHOTO_ID',
  PHOTO_ID_SUCCESS = 'PHOTO_ID_SUCCESS',
  RESERVATIONS = 'RESERVATIONS',
  RESERVATION_CONFIRMATION = 'RESERVATION_CONFIRMATION',
  END_SESSION = 'END_SESSION',
  CUSTOMERS = 'CUSTOMERS',
  AIRCRAFT = 'AIRCRAFT',
  WHOOPS_RESERVATIONS = 'WHOOPS_RESERVATIONS',
  VISITOR_INFORMATION_ABOUTYOU = 'VISITOR_INFORMATION_ABOUTYOU',
  VISITOR_INFORMATION_MORE_ABOUTYOU = 'VISITOR_INFORMATION_MORE_ABOUTYOU',
  CHECKIN_WARNING = 'CHECKIN_WARNING',
  REPORT_RECEPTION = 'REPORT_RECEPTION',
  PRINT_BADGE = 'PRINT_BADGE',
  PRINT_BADGE_SUCCESS = 'PRINT_BADGE_SUCCESS',
  PRINT_BADGE_FAIL = 'PRINT_BADGE_FAIL',
  PRINT_BADGE_FAIL_EMAIL = 'PRINT_BADGE_FAIL_EMAIL',
  CHECK_IN = 'CHECK_IN',
  CHECK_IN_SUMMARY = 'CHECK_IN_SUMMARY',
  CHECK_IN_FAIL = 'CHECK_IN_FAIL',
  TRAINING_DATE = 'TRAINING_DATE',
  RETRIEVE_ACCESSCARD = 'RETRIEVE_ACCESSCARD',
  GREETINGS_WELCOME = 'GREETINGS_WELCOME',
  GREETINGS_GOODBYE = 'GREETINGS_GOODBYE',
  QR_CODE_READER = 'QR_CODE_READER',
  QR_CODE_READER_VALIDATION = 'QR_CODE_READER_VALIDATION',
  QR_CODE_READER_FAIL = 'QR_CODE_READER_FAIL',
  DAILY_CHECKIN_CHECKOUT = 'DAILY_CHECKIN_CHECKOUT',
  PRESENT_YOUR_ID = 'PRESENT_YOUR_ID',
  BYPASS = 'BYPASS',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  SCAN_PASSPORT = 'SCAN_PASSPORT',
  SCAN_PASSPORT_SUCCESS = 'SCAN_PASSPORT_SUCCESS',
  SCAN_PASSPORT_FAIL = 'SCAN_PASSPORT_FAIL',
  SCAN_ID = 'SCAN_ID',
  SCAN_ID_SUCCESS = 'SCAN_ID_SUCCESS',
  SCAN_ID_FAIL = 'SCAN_ID_FAIL',
  SCAN_GOVERNMENT_ID = 'SCAN_GOVERNMENT_ID',
  SCAN_GOVERNMENT_ID_SUCCESS = 'SCAN_GOVERNMENT_ID_SUCCESS',
  SCAN_GOVERNMENT_ID_FAIL = 'SCAN_GOVERNMENT_ID_FAIL',
  SCAN_DOCUMENT = 'SCAN_DOCUMENT',
  SURVEY = 'SURVEY',
  CLOSING_SESSION_CONFIRMATION = 'CLOSING_SESSION_CONFIRMATION',
  CLOSING_SESSION = 'CLOSING_SESSION',
  NO_IDENTITY_DOCUMENT = 'NO_IDENTITY_DOCUMENT',
  TRAINING = 'TRAINING',
  TRAINING_SEARCH = 'TRAINING_SEARCH',
  TRAINING_SUMMARY = 'TRAINING_SUMMARY',
  EXPORT_CONTROL_FAIL = 'EXPORT_CONTROL_FAIL',
  REASON_VISIT = 'REASON_VISIT',
  CHECKIN_TYPE = 'CHECKIN_TYPE',
  PRINT_BADGE_OPTIONS = 'PRINT_BADGE_OPTIONS',
  ALREADY_CHECKEDIN = 'ALREADY_CHECKEDIN',
  NOT_PRECHECKED_IN = 'NOT_PRECHECKED_IN',
  DAILY_CHECKIN = 'DAILY_CHECKIN',
  DAILY_CHECKOUT = 'DAILY_CHECKOUT',
  COVID_RECORD = 'COVID_RECORD'
}
