// ****************************************************
// Console Logging Class

import { Observable, of } from 'rxjs';
import { LogEntry } from './log-entry.model';
import { LogPublisher } from './log-publishers';

// ****************************************************
export class LogConsole extends LogPublisher {
  log(entry: LogEntry): Observable<boolean> {
    // Log to console
    console.log(entry.buildLogString());
    return of();
  }

  clear(): Observable<boolean> {
    console.clear();
    return of();
  }
}
