export abstract class AnalyticBaseProperties {
  AppName: string;
  ClientOS: string;
  DeviceType: string;
  Language: string;
  AppVersion: string;
  Platform: string;
  SessionId: string;
  VisitorType: string;
  TrainingCenterId: string;
  VisitId: string;
  TrainingId: string;
  TraineeType: string;
  flowType: string;

  constructor() {
    this.AppName = '';
    this.ClientOS = '';
    this.DeviceType = '';
    this.Language = '';
    this.AppVersion = '';
    this.Platform = '';
    this.SessionId = '';
    this.VisitorType = '';
    this.TrainingCenterId = '';
    this.VisitId = '';
    this.TrainingId = '';
    this.TraineeType = '';
    this.flowType = '';
  }
}
