import { AnalyticAppName, Platforms } from '@core/constants';
import { isAppInsightActive, isHeapActive } from '@core/app-settings';

import { AnalyticCheckinEventTypes } from '@core/model/analytic/AnalyticCheckinEventTypes';
import { ApplicationStateService } from '@core/services/application-state.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Guid } from 'guid-typescript';
import { HeapService } from './heap.service';
import { Injectable } from '@angular/core';
import { VisitStateService } from '@core/state/visit-state.service';
import { combineLatest } from 'rxjs';
import { environment } from '@env';
import { isHostedInIPad } from '@core/ios-bridge/ios.utils';
import { isHostedInWin } from '@core/win-bridge/win.utils';
import { AnalyticBaseProperties } from '@core/model/analytic/AnalyticBaseProperties';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  ICustomProperties,
  IEventTelemetry,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root',
})
export class AnalyticService {
  properties: AnalyticBaseProperties;
  private angularPlugin = new AngularPlugin();
  private applicationInsights: ApplicationInsights = null;

  constructor(
    private applicationStateService: ApplicationStateService,
    public visitStateService: VisitStateService,
    private deviceDetectorService: DeviceDetectorService,
    private heapService: HeapService,
    private router: Router
  ) {}

  public init() {
    this.initAppInsights();
    this.initProperties();
    this.initHeap();
  }

  public async trackEvent(
    event: AnalyticCheckinEventTypes,
    properties?: ICustomProperties
  ): Promise<void> {
    if (isAppInsightActive()) {
      const eventBody: IEventTelemetry = {
        name: event,
      };
      this.applicationInsights?.trackEvent(
        eventBody,
        Object.assign({}, properties, this.properties)
      );
    }

    if (isHeapActive()) {
      this.heapService.track(event, Object.assign({}, properties, this.properties));
    }
  }

  public async trackPageView(
    name: string,
    uri: string,
    properties?: ICustomProperties
  ): Promise<void> {
    if (isAppInsightActive()) {
      this.applicationInsights?.trackPageView({
        name,
        uri,
        properties: Object.assign({}, properties, this.properties),
      });
    }
  }

  public async trackException(error: Error, properties?: ICustomProperties): Promise<void> {
    if (isAppInsightActive()) {
      const exceptionBody: IExceptionTelemetry = {
        error,
      };
      this.applicationInsights?.trackException(
        exceptionBody,
        Object.assign({}, properties, this.properties)
      );
    }
  }

  public async startTrackPage(name: string): Promise<void> {
    if (isAppInsightActive()) {
      this.applicationInsights?.startTrackPage(name);
    }
  }

  public async stopTrackPage(
    name: string,
    uri: string,
    properties?: ICustomProperties
  ): Promise<void> {
    if (isAppInsightActive()) {
      this.applicationInsights?.stopTrackPage(name, uri, properties);
    }
  }

  public async startTrackEvent(name: string): Promise<void> {
    if (isAppInsightActive()) {
      this.applicationInsights?.startTrackEvent(name);
    }
  }

  public async stopTrackEvent(name: string, properties?: ICustomProperties): Promise<void> {
    if (isAppInsightActive()) {
      this.applicationInsights?.stopTrackEvent(name, properties);
    }
  }

  public async trackAliveEvent(properties?: ICustomProperties): Promise<void> {
    if (isAppInsightActive()) {
      const eventBody: IEventTelemetry = {
        name: AnalyticCheckinEventTypes.CheckinDeviceAlive,
      };
      this.applicationInsights?.trackEvent(
        eventBody,
        Object.assign({}, properties, {
          platform: this.properties.Platform,
          appVersion: this.properties.AppVersion,
          trainingCenterId: properties.trainingCenterId || this.properties.TrainingCenterId,
        })
      );
    }
  }

  public getPlatformType(): string {
    if (isHostedInIPad()) {
      return Platforms.IOS;
    }
    if (isHostedInWin()) {
      return Platforms.Windows;
    }

    return Platforms.Web;
  }

  private initAppInsights(): void {
    if (environment.appInsights?.instrumentationKey) {
      this.applicationInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights.instrumentationKey,
          extensions: [this.angularPlugin as any],
          extensionConfig: {
            [this.angularPlugin.identifier]: { router: this.router },
          },
        },
      });

      this.applicationInsights?.loadAppInsights();
    }
  }
  private initHeap(): void {
    if (environment.heap?.apiKey) {
      this.heapService.initializeHeap(environment.heap.apiKey);
    }
  }

  private initProperties() {
    this.properties = {} as AnalyticBaseProperties;
    this.properties.AppName = AnalyticAppName;
    this.properties.Platform = this.getPlatformType();
    this.initBuildNumber();
    this.initDeviceInformations();
    this.initSessionId();
    this.initStateInfos();
  }

  private initBuildNumber(): void {
    if (environment?.buildNumber) {
      this.properties.AppVersion = environment.buildNumber;
    }
  }

  private initSessionId() {
    this.applicationStateService.sessionId$.subscribe((guid: Guid) => {
      if (guid) {
        this.properties.SessionId = guid.toString();
        this.applicationInsights?.setAuthenticatedUserContext(guid.toString());
      }
    });
  }

  private initStateInfos() {
    combineLatest([
      this.visitStateService.visitorType$,
      this.visitStateService.traineeType$,
      this.applicationStateService.trainingCenter$,
      this.visitStateService.visit$,
      this.visitStateService.training$,
      this.applicationStateService.checkinFlow$,
    ]).subscribe(([visitorType, traineeType, trainingCenter, visit, training, checkinflow]) => {
      this.properties.VisitorType = visitorType?.toString();
      this.properties.TraineeType = traineeType?.toString();
      this.properties.TrainingCenterId = trainingCenter?.geminiSiteId;
      this.properties.VisitId = visit?.id;
      this.properties.TrainingId = training?.id;
      this.properties.flowType = checkinflow?.toString();
      if (isHeapActive()) {
        this.heapService.addEventProperties(this.properties);
      }
    });
  }

  private initDeviceInformations(): void {
    this.properties.DeviceType = this.deviceDetectorService.device;
    this.properties.ClientOS = this.deviceDetectorService.os;
  }
}
