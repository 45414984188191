import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { AuthInterceptor, CustomHttpInterceptor } from '@core/interceptors';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { NgxsHmrLifeCycle, NgxsHmrSnapshot as Snapshot } from '@ngxs/hmr-plugin';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApplicationStateService } from '@core/services/application-state.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { FeatureFlagService } from '@core/feature-toggle/feature-flag.service';
import { GlobalErrorHandler } from '@core/handlers/global-error.handler';
import { IdentityComparaisonFailModule } from '@pages/identity-comparison/identity-comparaison-fail/identity-comparaison-fail.module';
import { IdentityComparisonModule } from '@pages/identity-comparison/identity-comparison.module';
import { LaunchDarklyService } from '@core/feature-toggle/launch-darkly.service';
import { ServiceInjectorModule } from '@core/services/service-injector.module';
import { SharedModule } from '@shared/shared.module';
import { StateContext } from '@ngxs/store';
import { StoreModule } from '@app/store/store.module';
import { ThemeService } from 'common-web-ui';
import { TrainingDateModule } from '@pages/training-date/training-date.module';
import { WelcomeModule } from '@pages/welcome/welcome.module';
import { environment } from '@env';
import { initializeApplication } from './app.initializers';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureAd.clientId,
      authority: `https://login.microsoftonline.com/${environment.azureAd.tenant}`,
      redirectUri: `${environment.baseUrl}/${environment.azureAd.redirectPath}`,
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.azureAd?.scopes,
    },
    loginFailedRoute: '/login-failed',
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    WelcomeModule,
    StoreModule,
    IdentityComparaisonFailModule,
    IdentityComparisonModule,
    SharedModule,
    TrainingDateModule,
    ServiceInjectorModule,
    MsalModule,
    DeviceDetectorModule.forRoot(),
  ],
  providers: [
    LaunchDarklyService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    Meta,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [FeatureFlagService, ApplicationStateService, ThemeService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule implements NgxsHmrLifeCycle<Snapshot> {
  constructor(private injector: Injector) {}

  public hmrNgxsStoreOnInit(ctx: StateContext<Snapshot>, snapshot: Partial<Snapshot>) {
    ctx.patchState(snapshot);
  }

  public hmrNgxsStoreBeforeOnDestroy(ctx: StateContext<Snapshot>): Partial<Snapshot> {
    return ctx.getState();
  }
}

export let AppInjector: Injector;
