import { ValidatorFn } from "@angular/forms";
import { TraineeType } from "@core/enums/trainee-type.enum";

export type CheckinFormFields = {
  trainingCenterId: string;
  application: string;
  checkinTrainees: CheckinTrainee[];
};

export type CheckinTrainee = {
  trainee: TraineeType;
  checkinFields: CheckinField[];
};

export type CheckinField = {
  name: string;
  state: CheckinFieldState;
};

export enum CheckinFieldState {
  Mandatory = 'Mandatory',
  Optional = 'Optional',
  Unavailable = 'Unavailable',
}

export type MappedCheckinFields = {
  [key: string]: {
    isUnavailable: boolean;
    control: {
      value: string | null;
      disabled: boolean;
    };
    validators: ValidatorFn[];
  };
};
