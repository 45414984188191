import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutePaths } from '@core/constants';
import { AuthGuard } from './core/guards/auth.guard';
import { DeviceConfigurationGuard } from '@core/guards/device-configuration.guard';
import { DeviceNotAuthorizedComponent } from '@core/httpErrorPages';
import { DeviceRegistrationGuard } from '@core/guards/device-registration.guard';
import { LoginComponent } from '@shared/components/login/login.component';
import { NgModule } from '@angular/core';
import { WelcomeComponent } from '@pages/welcome/welcome.component';

export const mainRoutes: Routes = [
  {
    path: AppRoutePaths.Default,
    canActivateChild: [AuthGuard, DeviceConfigurationGuard, DeviceRegistrationGuard],
    children: [
      {
        path: AppRoutePaths.Default,
        pathMatch: 'full',
        redirectTo: AppRoutePaths.StartWorkflow,
      },
      {
        path: AppRoutePaths.Welcome,
        component: WelcomeComponent,
      },
      {
        path: AppRoutePaths.ReasonVisit,
        loadChildren: () =>
          import('@pages/reason-visit/reason-visit.module').then((m) => m.ReasonVisitModule),
      },
      {
        path: AppRoutePaths.CheckinType,
        loadChildren: () =>
          import('@pages/checkin-type/checkin-type.module').then((m) => m.CheckinTypeModule),
      },
      {
        path: AppRoutePaths.IdCheck,
        loadChildren: () => import('@pages/id-check/id-check.module').then((m) => m.IdCheckModule),
      },
      {
        path: AppRoutePaths.PrepareFaceCheck,
        loadChildren: () =>
          import('@pages/prepare-face-check/prepare-face-check.module').then(
            (m) => m.PrepareFaceCheckModule
          ),
      },
      {
        path: AppRoutePaths.FaceCheck,
        loadChildren: () =>
          import('@pages/face-check/face-check.module').then((m) => m.FaceCheckModule),
      },
      {
        path: AppRoutePaths.IdentityComparison,
        loadChildren: () =>
          import('@pages/identity-comparison/identity-comparison.module').then(
            (m) => m.IdentityComparisonModule
          ),
      },
      {
        path: AppRoutePaths.Training,
        loadChildren: () => import('@pages/training/training.module').then((m) => m.TrainingModule),
      },
      {
        path: AppRoutePaths.EndSession,
        loadChildren: () =>
          import('@pages/end-session/end-session.module').then((m) => m.EndSessionModule),
      },
      {
        path: AppRoutePaths.Customers,
        loadChildren: () =>
          import('@pages/customers/customers.module').then((m) => m.CustomersModule),
      },
      {
        path: AppRoutePaths.Aircraft,
        loadChildren: () =>
          import('@pages/aircraft-selection/aircraft-selection.module').then(
            (m) => m.AircraftSelectionModule
          ),
      },
      {
        path: AppRoutePaths.IdentityPolicy,
        loadChildren: () =>
          import('@pages/identity-policy/identity-policy.module').then(
            (m) => m.IdentityPolicyModule
          ),
      },
      {
        path: AppRoutePaths.WhoopsReservations,
        loadChildren: () =>
          import('@pages/whoops-reservation/whoops-reservation.module').then(
            (m) => m.WhoopsReservationModule
          ),
      },
      {
        path: AppRoutePaths.VisitorInformation,
        loadChildren: () =>
          import('@pages/visitor-information/visitor-information.module').then(
            (m) => m.VisitorInformationModule
          ),
      },
      {
        path: AppRoutePaths.ReportReception,
        loadChildren: () =>
          import('@pages/report-reception/report-reception.module').then(
            (m) => m.ReportReceptionModule
          ),
      },
      {
        path: AppRoutePaths.PrintBadgeOptions,
        loadChildren: () =>
          import('@pages/print-badge-options/print-badge-options.module').then(
            (m) => m.PrintBadgeOptionsModule
          ),
      },
      {
        path: AppRoutePaths.BadgePrint,
        loadChildren: () =>
          import('@pages/badge-print/badge-print.module').then((m) => m.BadgePrintModule),
      },
      {
        path: AppRoutePaths.Checkin,
        loadChildren: () => import('@pages/checkin/checkin.module').then((m) => m.CheckedInModule),
      },
      {
        path: AppRoutePaths.Error,
        loadChildren: () =>
          import('@pages/error-page/error-page.module').then((m) => m.ErrorPageModule),
      },
      {
        path: AppRoutePaths.PrepareIdCheck,
        loadChildren: () =>
          import('@pages/prepare-id-check/prepare-id-check.module').then(
            (m) => m.PrepareIdCheckModule
          ),
      },
      {
        path: AppRoutePaths.CoronaVirusQuestion,
        loadChildren: () =>
          import('@pages/corona-virus-question/corona-virus-question.module').then(
            (m) => m.CoronaVirusQuestionModule
          ),
      },
      {
        path: AppRoutePaths.TrainingDate,
        loadChildren: () =>
          import('@pages/training-date/training-date.module').then((m) => m.TrainingDateModule),
      },
      {
        path: AppRoutePaths.RetrieveAccessCard,
        loadChildren: () =>
          import('@pages/retrieve-access-card/retrieve-access-card.module').then(
            (m) => m.RetrieveAccessCardModule
          ),
      },
      {
        path: AppRoutePaths.PreparePhotoBadge,
        loadChildren: () =>
          import('@pages/prepare-photo-badge/prepare-photo-badge.module').then(
            (m) => m.PreparePhotoBadgeModule
          ),
      },
      {
        path: AppRoutePaths.Greetings,
        loadChildren: () =>
          import('@pages/greetings/greetings.module').then((m) => m.GreetingsModule),
      },
      {
        path: AppRoutePaths.QrCodeReader,
        loadChildren: () =>
          import('@pages/qrcode-reader/qrcode-reader.module').then((m) => m.QrcodeReaderModule),
      },
      {
        path: AppRoutePaths.DailyCheckinCheckout,
        loadChildren: () =>
          import('@pages/daily-checkin-checkout/daily-checkin-checkout.module').then(
            (m) => m.DailyCheckinCheckoutModule
          ),
      },
      {
        path: AppRoutePaths.PresentYourId,
        loadChildren: () =>
          import('@pages/present-your-id/present-your-id.module').then(
            (m) => m.PresentYourIdModule
          ),
      },
      {
        path: AppRoutePaths.TraineeTypes,
        loadChildren: () =>
          import('@pages/trainee-types/trainee-types.module').then((m) => m.TraineeTypesModule),
      },
      {
        path: AppRoutePaths.ByPass,
        loadChildren: () => import('@pages/bypass/bypass.module').then((m) => m.BypassModule),
      },
      {
        path: AppRoutePaths.ScanDocument,
        loadChildren: () =>
          import('@pages/scan-document/scan-document.module').then((m) => m.ScanDocumentModule),
      },
      {
        path: AppRoutePaths.ScanPassport,
        loadChildren: () =>
          import('@pages/scan-passport/scan-passport.module').then((m) => m.ScanPassportModule),
      },
      {
        path: AppRoutePaths.Survey,
        loadChildren: () => import('@pages/survey/survey.module').then((m) => m.SurveyModule),
      },
      {
        path: AppRoutePaths.StartWorkflow,
        loadChildren: () =>
          import('@pages/start-workflow/start-workflow.module').then((m) => m.StartWorkflowModule),
      },
      {
        path: AppRoutePaths.NotFound,
        loadChildren: () =>
          import('@pages/not-found/not-found..module').then((m) => m.NotFoundCheckModule),
      },
      {
        path: AppRoutePaths.ScanGovernmentId,
        loadChildren: () =>
          import('@pages/scan-government-id/scan-government-id.module').then(
            (m) => m.ScanGovernmentIdModule
          ),
      },
      {
        path: AppRoutePaths.DocumentType,
        loadChildren: () =>
          import('@pages/document-type/document-type.module').then((m) => m.DocumentTypeModule),
      },
      {
        path: AppRoutePaths.NoIdentityDocument,
        loadChildren: () =>
          import('@pages/no-identity-document/no-identity-document.module').then(
            (m) => m.NoIdentityDocumentModule
          ),
      },
      {
        path: AppRoutePaths.ExportControl,
        loadChildren: () =>
          import('@pages/export-control/export-control.module').then((m) => m.ExportControlModule),
      },
    ],
  },
  {
    path: AppRoutePaths.DeviceRegistration,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@pages/device-registration/device-registration.module').then(
        (m) => m.DeviceRegistrationModule
      ),
  },
  {
    path: AppRoutePaths.Administration,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@pages/administration/administration.module').then((m) => m.AdministrationModule),
  },
  { path: AppRoutePaths.Login, component: LoginComponent },
  { path: AppRoutePaths.DeviceNotAuthorized, component: DeviceNotAuthorizedComponent },
  { path: '**', redirectTo: AppRoutePaths.NotFound },
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [
    RouterModule.forRoot(mainRoutes, {
      relativeLinkResolution: 'legacy',
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
