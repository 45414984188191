export class TrainingCenter {
  public id: string;
  public geminiSiteId: string;
  public name: string;
  public description: string;
  public displayName: string;
  public city: string;
  public country: string;
  constructor(data) {
    Object.assign(this, data);
  }
}
