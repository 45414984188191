import { Customer } from '@core/model/customer/customer.model';
import { ETrainingStatus } from '@core/enums/training-status.enum';
import { ITraining } from '@data/interfaces/training/training.interface';
import { TSAStatus } from '@core/enums/tsa-status.enum';
import { TrainingClient } from '@core/model/training/training-client.model';

export class Training {
  id: string;
  visitId: string;
  client: TrainingClient;
  customer: Customer;
  startDate: Date;
  endDate: Date;
  geminiReservationIds: string[];
  trainingCenterId: string;
  isPaid: boolean;
  status: ETrainingStatus;
  tsaStatus?: TSAStatus;
  constructor(data: ITraining) {
    Object.assign(this, data);

    if (data) {
      if (data.startDate) {
        this.startDate = new Date(data.startDate);
      }

      if (data.endDate) {
        this.endDate = new Date(data.endDate);
      }

      if (data.client) {
        this.client = new TrainingClient(data.client);
      }

      if (data.customer) {
        this.customer = new Customer(data.customer);
      }
    }
  }
}
