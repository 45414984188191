import { TSAStatus } from '@core/enums/tsa-status.enum';
import { ITrainingClient } from '@data/interfaces/training/training-client.interface';

export class TrainingClient {
  userId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  constructor(data: ITrainingClient) {
    Object.assign(this, data);
  }
}
