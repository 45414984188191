import { ExportControlLevel } from '@core/model/export-control/export-control-level.enum';
import { IExportControlCountry } from '@data/interfaces/export-control/export-control-country.interface';
import moment from 'moment';
export class ExportControlCountry {
  id: string;
  updatedAt: Date;
  restrictionLevel: ExportControlLevel;

  constructor(data?: IExportControlCountry) {
    if (!data) {
      return;
    }
    Object.assign(this, data);
    if (data.updatedAt) {
      this.updatedAt = moment(data.updatedAt).toDate();
    }
  }
}
