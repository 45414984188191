import { AppModule } from './app/app.module';
import { BootstrapModuleFn as Bootstrap } from '@ngxs/hmr-plugin';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  enableProdMode();
}

const boostrap: Bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

boostrap().catch((err) => console.error(err));
