import { Action, Selector, State, StateContext } from '@ngxs/store';
import update from 'immutability-helper';
import { Feature } from '../feature.model';
import { RememberFeature, SetRestartApp, SetWebAppRefresh } from './feature.actions';
import { Injectable } from '@angular/core';
export class FeatureStateModel {
  readonly features: Feature[];
  readonly hasWebAppRefresh: boolean;
  readonly hasRestartApp: boolean;
}

@State<FeatureStateModel>({
  name: 'features',
  defaults: {
    features: [],
    hasWebAppRefresh: false,
    hasRestartApp: false,
  },
})
@Injectable()
export class FeatureState {
  @Selector()
  static getFeatureState(state: FeatureStateModel): FeatureStateModel {
    return state;
  }

  @Selector()
  static getFeatures(state: FeatureStateModel): Feature[] {
    return state.features;
  }

  @Selector()
  static isFeatureEnabled(state: FeatureStateModel) {
    return (name: string) => {
      const feature = state.features.find((f) => f.name === name);
      return !!feature && feature.isActive;
    };
  }

  @Selector()
  public static hasWebAppRefresh(state: FeatureStateModel): boolean {
    return state.hasWebAppRefresh;
  }

  @Selector()
  public static hasRestartApp(state: FeatureStateModel): boolean {
    return state.hasRestartApp;
  }

  @Action(SetWebAppRefresh)
  setWebAppRefresh(context: StateContext<FeatureStateModel>, action: SetWebAppRefresh) {
    const state = update(context.getState(), {
      hasWebAppRefresh: { $set: action.payload.isActive },
    });
    context.patchState(state);
  }

  @Action(SetRestartApp)
  setRestartApp(context: StateContext<FeatureStateModel>, action: SetRestartApp) {
    const state = update(context.getState(), {
      hasRestartApp: { $set: action.payload.isActive },
    });
    context.patchState(state);
  }

  @Action(RememberFeature)
  rememberFeature(context: StateContext<FeatureStateModel>, action: RememberFeature) {
    let currentFeatures = context.getState().features.slice(0);
    currentFeatures = currentFeatures.filter((f) => f.name !== action.payload.name);
    currentFeatures.push(action.payload);
    const state = update(context.getState(), {
      features: { $set: currentFeatures },
    });
    context.patchState(state);
  }
}
