import { ApiService } from '@data/services/api.service';
import { DeviceConfiguration } from '@core/model/devices-configuration/device-configuration.model';
import { HttpParams } from '@angular/common/http';
import { IDeviceConfiguration } from '../interfaces/device-configurations/device-configuration.interface';
import { IVisit } from '../interfaces/visits/visit.interface';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import update from 'immutability-helper';

@Injectable({
  providedIn: 'root',
})
export class DeviceConfigurationsService {
  private readonly endpoint = `/device-configurations`;

  constructor(private apiService: ApiService) {}

  public async post(devicePost: DeviceConfiguration): Promise<DeviceConfiguration> {
    return lastValueFrom(
      this.apiService
        .post<IDeviceConfiguration>(this.endpoint, devicePost)
        .pipe(map((response) => new DeviceConfiguration(response)))
    );
  }

  public async put(device: DeviceConfiguration): Promise<DeviceConfiguration> {
    return lastValueFrom(
      this.apiService
        .put<IDeviceConfiguration>(`${this.endpoint}/${device.id}`, device)
        .pipe(map((response) => new DeviceConfiguration(response)))
    );
  }

  public async get(id: string): Promise<DeviceConfiguration> {
    return lastValueFrom(
      this.apiService.get<IDeviceConfiguration>(`${this.endpoint}/${id}`).pipe(
        map((response) => {
          if (response) {
            return new DeviceConfiguration(response);
          }
        })
      )
    );
  }

  public async getByDeviceId(id: string): Promise<DeviceConfiguration> {
    const params = new HttpParams().set('deviceId', id);

    return lastValueFrom(
      this.apiService.get<IDeviceConfiguration[]>(`${this.endpoint}`, { params }).pipe(
        map((response) => {
          if (response?.length > 0) {
            return new DeviceConfiguration(response[0]);
          }
        })
      )
    );
  }

  public async delete(device: DeviceConfiguration): Promise<void> {
    return lastValueFrom(this.apiService.delete<IVisit>(`${this.endpoint}/${device.id}`));
  }

  public async save(deviceConfiguration: DeviceConfiguration): Promise<DeviceConfiguration> {
    if (!deviceConfiguration.id) {
      deviceConfiguration = update(deviceConfiguration, {
        provisioningDate: { $set: new Date() },
      });
      return this.post(deviceConfiguration);
    }
    return this.put(deviceConfiguration);
  }
}
