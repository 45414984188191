import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IdentityComparisonRoutePaths } from '@core/constants';
import { IdentityComparisonComponent } from './identity-comparison.component';

export const identityComparisonRoutes: Routes = [
  {
    path: IdentityComparisonRoutePaths.Default,
    pathMatch: 'full',
    component: IdentityComparisonComponent,
  },
  {
    path: IdentityComparisonRoutePaths.IdentityComparisonFail,
    loadChildren: () =>
      import('./identity-comparaison-fail/identity-comparaison-fail.module').then(
        (m) => m.IdentityComparaisonFailModule
      ),
  },
  {
    path: IdentityComparisonRoutePaths.IdentityComparisonSuccess,
    loadChildren: () =>
      import('./identity-comparison-success/identity-comparison-success.module').then(
        (m) => m.IdentityComparisonSuccessModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(identityComparisonRoutes)],
  exports: [RouterModule],
})
export class IdentityComparisonRoutingModule {}
