import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { AnalyticService } from '@data/services/analytic.service';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  properties;

  constructor(private router: Router, private analyticService: AnalyticService) {}

  public startTracking() {
    this.watchNavigation();
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private watchNavigation() {
    this.router.events.subscribe((event) => {
      if (event instanceof ResolveEnd) {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.analyticService.trackPageView(`${activatedComponent.name}`, event.urlAfterRedirects);
        }
      }
    });
  }
}
