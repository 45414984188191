/* eslint-disable @typescript-eslint/no-explicit-any */

export function nativeWindow(): any {
  return window as any;
}
export function webview(): any | undefined {
  return nativeWindow().chrome?.webview;
}

export function isHostedInWin(): boolean {
  return !!webview();
}

