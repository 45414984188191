import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

@Directive({
  selector: '[appClickProtection]',
})
export class ClickProtectionDirective implements OnDestroy {
  @Input() functionTocall;
  @Input() debounceTime = 500;
  @Output() readonly debouncedClick = new EventEmitter<MouseEvent>();
  private readonly clicks = new Subject<MouseEvent>();
  private subscription: Subscription;
  protected isBrowser = false;

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.subscription = this.clicks
      .pipe(
        exhaustMap(async () => {
          await this.functionTocall();
          return;
        })
      )
      .subscribe((event) => this.debouncedClick.emit());
  }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
