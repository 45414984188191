import { Component, Input } from '@angular/core';

export enum WarningColor {
  Orange,
  Red,
}

@Component({
  selector: 'app-warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.less'],
})
export class WarningBoxComponent {
  @Input() public warningColor: WarningColor;
  @Input() public title: string;
  @Input() public description: string;

  public get topColor(): object {
    return {
      'top-orange': this.warningColor === WarningColor.Orange,
      'top-red': this.warningColor === WarningColor.Red,
    };
  }

  public get imageIcon(): object {
    return {
      'warning-icon-orange': this.warningColor === WarningColor.Orange,
      'warning-icon-red': this.warningColor === WarningColor.Red,
    };
  }
}
