import { CamundaVariableValue } from './camunda-variable-value.model';
import { ECamundaVariableValueType } from '@data/interfaces/camunda/camunda-variable-value-type.enum';

export class CamundaPatchVariables {
  /**
   * A JSON object containing variable key-value pairs.
   */
  modifications?: { [key: string]: CamundaVariableValue };
  /**
   * An array of String keys of variables to be deleted.
   */
  deletions?: Array<string>;

  addVariable(
    variableName: string,
    variableValue: any,
    variableType: ECamundaVariableValueType
  ): CamundaPatchVariables {
    if (!this.modifications) {
      this.modifications = {};
    }

    if (
      variableType === ECamundaVariableValueType.JSON ||
      variableType === ECamundaVariableValueType.OBJECT
    ) {
      variableValue = JSON.stringify(variableValue);
    }

    this.modifications[variableName] = new CamundaVariableValue(variableValue, variableType);

    return this;
  }
}
