export enum ToggleFeatureKeys {
  CheckOutFeature = 'training-center-experience-web-app-checkout',
  CheckinPersistenceFeature = 'training-center-exp-visitor-info-persistence',
  AdminMenuFeature = 'training-center-exp-admin-menu',
  SessionManagementFeature = 'training-center-exp-session-management',
  CoronaVirusFeature = 'training-center-exp-coronavirus',
  MultiTrainingCenters = 'training-center-exp-multi_training_centers',
  DailyCheckinCheckoutFeature = 'training-center-experience-web-app-check-in-out',
  CheckInOut = 'training-center-experience-web-app-check-in-out',
  FacialRecognition = 'training-center-experience-web-app-face-comparison',
  TraineeFlow = 'training-center-experience-web-app-trainee-flow',
  InstructorFlow = 'training-center-experience-web-app-instructor-flow',
  GuestFlow = 'training-center-experience-web-app-guest-flow',
  FaceCrop = 'training-center-exp-api-picture-crop',
  QrCodeFlow = 'training-center-experience-web-app-qrcode-flow',
  WebAppRefresh = 'training-center-exp-api-webapp-refresh',
  MaxxessIntegration = 'training-center-exp-api-maxxess-integration',
  DocumentScanner = 'training-center-experience-web-app-document-scanner',
  RestartApplication = 'training-center-exp-api-webapp-restart-app',
}
