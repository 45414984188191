import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-identity-verification-stepper',
  templateUrl: './identity-verification-stepper.component.html',
  styleUrls: ['./identity-verification-stepper.component.less'],
})
export class IdentityVerificationStepperComponent {
  @Input() currentStep? = 1;
  @Input() validSteps = 0;
}
