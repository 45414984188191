import { Feature } from '../feature.model';

export enum ActionTypes {
  RememberFeature = '[RememberFeature] action',
  SetWebAppRefresh = '[SetWebAppRefresh] action',
  SetRestartApp = '[SetRestartApp] action',
}

export class RememberFeature {
  public static readonly type = ActionTypes.RememberFeature;
  constructor(public payload: Feature) {}
}

export class SetWebAppRefresh {
  public static readonly type = ActionTypes.SetWebAppRefresh;
  constructor(public payload: Feature) {}
}

export class SetRestartApp {
  public static readonly type = ActionTypes.SetRestartApp;
  constructor(public payload: Feature) {}
}
