import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '@core/model/customer/customer.model';
import { Aircraft } from '@core/model/entity/aircraft.model';
import { TrainingCenter } from '@core/model/entity/training-center.model';
import { IReservation } from '@core/model/reservation/reservation.interface';
import { Reservation } from '@core/model/reservation/reservation.model';
import { stateToHttpParams } from '@core/utils/http-params.util';
import { ApiService } from '@data/services/api.service';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReservationsService {
  private endpoint = `/reservations`;

  constructor(private apiService: ApiService) {}

  public get(
    trainingCenters: TrainingCenter[],
    trainingStartDate: Date,
    aircraft: Aircraft,
    customer: Customer
  ): Promise<Reservation[]> {
    const params = stateToHttpParams(trainingCenters, trainingStartDate, aircraft, customer);

    return lastValueFrom(
      this.apiService.get<IReservation[]>(`${this.endpoint}`, { params }).pipe(
        map((response) => {
          const reservations = response
            .filter((object) => object !== null)
            .map((data) => new Reservation(data));
          return reservations;
        })
      )
    );
  }

  public getReservation(id: string): Promise<Reservation> {
    return lastValueFrom(
      this.apiService
        .get<IReservation>(`${this.endpoint}/${id}`)
        .pipe(map((response) => new Reservation(response)))
    );
  }

  public getReservations(ids: string[]): Promise<Reservation[]> {
    let params = new HttpParams();
    if (ids) {
      for (const id of ids) {
        params = params.append('geminiReservationId', id);
      }
    }
    return lastValueFrom(
      this.apiService.get<IReservation[]>(`${this.endpoint}`, { params }).pipe(
        map((response) => {
          const reservations = response.map((data) => new Reservation(data));
          return reservations;
        })
      )
    );
  }
}
