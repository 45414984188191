import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import update from 'immutability-helper';

import { AppStoreKeys } from '@app/store/store-keys.module';
import { CheckinCameraType } from '@core/enums/checkin-camera-type.enum';
import { FlowType } from '@core/enums/flow-type.enum';
import { EIdentityDocumentType } from '@core/enums/identity-document-type.enum';
import { RecordingType } from '@core/enums/recording-type.enum';
import { TraineeType } from '@core/enums/trainee-type.enum';
import { VisitorType } from '@core/enums/visitor-type.enum';
import { FeatureFlagService } from '@core/feature-toggle/feature-flag.service';
import { BadgeValidity } from '@core/model/badge/badge-validity.model';
import { ByPassInformation } from '@core/model/bypass-information.model';
import { Customer } from '@core/model/customer/customer.model';
import { DeviceRegistration } from '@core/model/device-registration/device-registration.model';
import { DeviceConfiguration } from '@core/model/devices-configuration/device-configuration.model';
import { Aircraft } from '@core/model/entity/aircraft.model';
import { ExportControl } from '@core/model/entity/export-control.model';
import { FacesCompareResult } from '@core/model/entity/FacesCompareResult';
import { Step } from '@core/model/entity/step.model';
import { TrainingCenter } from '@core/model/entity/training-center.model';
import { ExportControlCitizenship } from '@core/model/export-control//export-control-citizenship.model';
import { ExportControlCountry } from '@core/model/export-control//export-control-country.model';
import { Printer } from '@core/model/printers/printer.model';
import { Reservation } from '@core/model/reservation/reservation.model';
import { CheckinProcessConfiguration } from '@core/model/training-center/checkin-process-configuration.model';
import { Training } from '@core/model/training/training.model';
import { Visit } from '@core/model/visit/visit.model';
import { VisitorInformation } from '@core/state/visitor-information.model';
import { CamundaProcessInstanceWithVariables } from '@data/models/camunda/camunda-process-instance-with-variables.model';
import { CamundaTaskCustomProperties } from '@data/models/camunda/camunda-task-custom-properties.model';
import { CamundaTask } from '@data/models/camunda/camunda-task.model';
import { FacialComparisonService } from '@data/services/facial-comparison.service';
import { StorageService } from '../services/storage.service';
import { TrainingCenterConfiguration } from './../model/training-center/training-center-configuration.model';
import { ApplicationStateModel } from './application-state.model';
import {
  DoFaceCompare,
  ResetScan,
  ResetSession,
  ResetTraining,
  RestoreState,
  SetAircraft,
  SetAppLoadedDateTime,
  SetBadgePrinted,
  SetBadgePrintRequest,
  SetBadgeValidity,
  SetByPasses,
  SetCamundaProcessInstanceWithVariables,
  SetCamundaTask,
  SetCamundaTaskCustomPropertiesMap,
  SetCheckinFlow,
  SetCovidAcknowledgementDate,
  SetCustomer,
  SetDeviceConfiguration,
  SetDeviceId,
  SetDeviceRegistration,
  SetExportControl,
  SetFacialComparisonResult,
  SetIDCardCameraSelection,
  SetIdentityDocumentType,
  SetIdentityPolicyConsentDate,
  SetIsAdministratorAuthenticated,
  SetPassportScannerIp,
  SetDeviceInventoryNumber,
  SetPrinter,
  SetRecordingType,
  SetReservations,
  SetSkipBadgePrinting,
  SetBackButtonDeactivated,
  SetSkipTraining,
  SetSteps,
  SetTechnicalTrainingCenters,
  SetTraineeType,
  SetTraining,
  SetTrainingCenter,
  SetTrainingCenterConfiguration,
  SetVisit,
  SetVisitBadgeNumber,
  SetVisitEndDate,
  SetVisitId,
  SetVisitorContactName,
  SetVisitorCountryOfResidency,
  SetVisitorDateOfBirth,
  SetVisitorEmail,
  SetVisitorEmailConsentDate,
  SetVisitorFacePhoto,
  SetVisitorFirstName,
  SetVisitorHostName,
  SetVisitorHotelName,
  SetVisitorHotelPhone,
  SetVisitorIdFacePhoto,
  SetVisitorLastName,
  SetVisitorMiddleName,
  SetVisitorNationality,
  SetVisitorPhoneNumber,
  SetVisitorType,
  SetVisitReason,
  SetVisitStartDate,
  SetPassportScannerConnected,
  SetDeviceSerialNumber,
  SetAppVersionNumber,
  SetFeedbackInformation,
  ResetFeedbackInformation,
  SetCheckinFormFields,
} from './application.actions';
import { FlowInformation } from './flow-information.model';
import { TechnicalInformation } from './technical-information.model';
import { FeedbackInformation } from './feedback-information.model';
import { CheckinFormFields } from '@core/model/form/checkin-form-fields.model';

@State<ApplicationStateModel>({
  name: AppStoreKeys.Session,
  defaults: new ApplicationStateModel(),
})
@Injectable()
export class ApplicationState {
  constructor(
    private featureFlagService: FeatureFlagService,
    private facialComparisonService: FacialComparisonService,
    private storageService: StorageService
  ) { }

  @Selector()
  public static getState(state: ApplicationStateModel): ApplicationStateModel {
    return state;
  }

  @Selector()
  public static getFlowInformation(state: ApplicationStateModel): FlowInformation {
    return state.flowInformation;
  }

  @Selector()
  public static getTechnicalInformation(state: ApplicationStateModel): TechnicalInformation {
    return state.technicalInformation;
  }

  @Selector()
  public static getDeviceConfiguration(state: ApplicationStateModel): DeviceConfiguration {
    return state.deviceConfiguration;
  }

  @Selector()
  public static getDeviceRegistration(state: ApplicationStateModel): DeviceRegistration {
    return state.deviceRegistration;
  }

  @Selector()
  public static getVisitorInformation(state: ApplicationStateModel): VisitorInformation {
    return state.visitorInformation;
  }

  @Selector()
  public static getVisit(state: ApplicationStateModel): Visit {
    return state.visit;
  }

  @Selector()
  public static getVisitorType(state: ApplicationStateModel): VisitorType {
    return state.visit.visitorType;
  }

  @Selector()
  public static getVisitorIdFacePhoto(state: ApplicationStateModel): string {
    return state.visitorInformation.idFacePhoto;
  }

  @Selector()
  public static getVisitorFacePhoto(state: ApplicationStateModel): string {
    return state.visitorInformation.facePhoto;
  }

  @Selector()
  public static getFacialComparisonResult(state: ApplicationStateModel): FacesCompareResult {
    return state.flowInformation.facialComparisonResult;
  }

  @Selector()
  public static getAircraft(state: ApplicationStateModel): Aircraft {
    return state.flowInformation.aircraft;
  }

  @Selector()
  public static getTrainingId(state: ApplicationStateModel): string {
    return state.visit.trainingId;
  }

  @Selector()
  public static getCustomer(state: ApplicationStateModel): Customer {
    return state.flowInformation.customer;
  }

  @Selector()
  public static getDeviceId(state: ApplicationStateModel): string {
    return state.deviceConfiguration.deviceId;
  }

  @Selector()
  public static getFeedbackInformation(state: ApplicationStateModel): FeedbackInformation {
    return state.feedbackInformation;
  }

  @Selector()
  public static getIsScannerConnected(state: ApplicationStateModel): boolean {
    return state.deviceConfiguration.passportScannerConnected;
  }

  @Selector()
  public static getPrinter(state: ApplicationStateModel): Printer {
    return state.deviceConfiguration.printerInformation;
  }

  @Selector()
  public static getTrainingCenter(state: ApplicationStateModel): TrainingCenter {
    return state.technicalInformation.trainingCenter;
  }

  @Selector()
  public static getTechnicalTrainingCenters(state: ApplicationStateModel): TrainingCenter[] {
    return state.technicalInformation.technicalTrainingCenters;
  }

  @Selector()
  public static getPrinters(state: ApplicationStateModel): Printer[] {
    return state.technicalInformation.printers;
  }

  @Selector()
  public static getBadgePrinted(state: ApplicationStateModel): boolean {
    return state.flowInformation.badgePrinted;
  }

  @Selector()
  public static getSessionId(state: ApplicationStateModel): Guid {
    return state.technicalInformation.sessionId;
  }

  @Selector()
  public static getConsentDate(state: ApplicationStateModel): Date {
    return state.visit.identityPolicyConsentDate;
  }

  @Selector()
  public static getBadgePrintRequest(state: ApplicationStateModel): boolean {
    return state.flowInformation.badgePrintRequest;
  }

  @Selector()
  public static getIDCardCameraSelection(state: ApplicationStateModel): CheckinCameraType {
    return state.technicalInformation.idCardCameraSelection;
  }

  @Selector()
  public static getSkipBadgePrinting(state: ApplicationStateModel): boolean {
    return state.technicalInformation.skipBadgePrinting;
  }

  @Selector()
  public static getSkipTraining(state: ApplicationStateModel): boolean {
    return state.technicalInformation.skipTraining;
  }

  @Selector()
  public static getappLoadedDateTime(state: ApplicationStateModel): Date {
    return state.technicalInformation.appLoadedDateTime;
  }

  @Selector()
  public static getBadgeNumber(state: ApplicationStateModel): string {
    return state.visit.badgeNumber;
  }

  @Selector()
  public static getFlowType(state: ApplicationStateModel): FlowType {
    return state.flowInformation.flowType;
  }

  @Selector()
  public static getRecordingType(state: ApplicationStateModel): RecordingType {
    return state.flowInformation.recordingType;
  }

  @Selector()
  public static getCovidAcknowledgementDate(state: ApplicationStateModel): Date {
    return state.flowInformation.covidAcknowledgementDate;
  }

  @Selector()
  public static getVisitStartDate(state: ApplicationStateModel): Date {
    return state.visit.startDate;
  }

  @Selector()
  public static getVisitEndDate(state: ApplicationStateModel): Date {
    return state.visit.endDate;
  }

  @Selector()
  public static getCheckinTraineeType(state: ApplicationStateModel): TraineeType {
    return state.visit.traineeType;
  }

  @Selector()
  public static getByPasses(state: ApplicationStateModel): ByPassInformation[] {
    return state.flowInformation.byPasses;
  }

  @Selector()
  public static getCamundaTask(state: ApplicationStateModel): CamundaTask {
    return state.flowInformation.camundaTask;
  }

  @Selector()
  public static getCamundaProcessInstanceWithVariables(
    state: ApplicationStateModel
  ): CamundaProcessInstanceWithVariables {
    return state.flowInformation.camundaProcessInstanceWithVariables;
  }

  @Selector()
  public static getVisitId(state: ApplicationStateModel): string {
    return state.visit.id;
  }

  @Selector()
  public static getAppVersion(state: ApplicationStateModel): string {
    return state.technicalInformation.appVersion;
  }

  @Selector()
  public static getBadgeValidity(state: ApplicationStateModel): BadgeValidity {
    return state.flowInformation.badgeValidity;
  }

  @Selector()
  public static getCamundaTaskCustomPropertiesMap(
    state: ApplicationStateModel
  ): Map<string, CamundaTaskCustomProperties> {
    return state.flowInformation.camundaTaskCustomPropertiesMap;
  }

  @Selector()
  public static getTrainingCenterConfiguration(
    state: ApplicationStateModel
  ): TrainingCenterConfiguration {
    return state.technicalInformation.trainingCenterConfiguration;
  }

  @Selector()
  public static getCheckinProcessConfiguration(
    state: ApplicationStateModel
  ): CheckinProcessConfiguration {
    return state.technicalInformation?.trainingCenterConfiguration?.checkinProcessConfiguration;
  }

  @Selector()
  public static getExportControl(state: ApplicationStateModel): ExportControl {
    return state.visitorInformation.exportControl;
  }

  @Selector()
  public static getDrk(state: ApplicationStateModel): string {
    return state.deviceRegistration.drk;
  }

  @Selector()
  public static getSteps(state: ApplicationStateModel): Step[] {
    return state.technicalInformation.steps;
  }

  @Selector()
  public static getIdentityDocumentType(state: ApplicationStateModel): EIdentityDocumentType {
    return state.flowInformation.identityDocumentType;
  }

  @Selector()
  public static getTraining(state: ApplicationStateModel): Training {
    return state.flowInformation.training;
  }

  @Selector()
  public static isAdministratorAuthenticated(state: ApplicationStateModel): boolean {
    return state.technicalInformation.isAdministratorAuthenticated;
  }

  @Selector()
  public static getReservations(state: ApplicationStateModel): Reservation[] {
    return state.flowInformation.reservations;
  }

  @Selector()
  public static getBackButtonDeactivated(state: ApplicationStateModel): boolean {
    return state.flowInformation.backButtonDeactivated;
  }

  @Selector()
  public static getCheckinFormFieldsState(state: ApplicationStateModel): CheckinFormFields {
    return state.technicalInformation.checkinFormFields;
  }

  @Action(SetAircraft)
  setAircraft(context: StateContext<ApplicationStateModel>, action: SetAircraft) {
    const state = update(context.getState(), {
      flowInformation: {
        aircraft: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetFacialComparisonResult)
  setCheckinFacialComparisonResult(
    context: StateContext<ApplicationStateModel>,
    action: SetFacialComparisonResult
  ) {
    const state = update(context.getState(), {
      flowInformation: {
        facialComparisonResult: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(ResetFeedbackInformation)
  resetFeedbackInformation(context: StateContext<ApplicationStateModel>) {
    const currentState = context.getState();

    const state = update(currentState, {
      feedbackInformation: { $set: new FeedbackInformation() },

    });
    context.patchState(state);
  }

  @Action(ResetSession)
  resetSession(context: StateContext<ApplicationStateModel>) {
    const currentState = context.getState();

    const state = update(currentState, {
      flowInformation: { $set: new FlowInformation() },
      visitorInformation: { $set: new VisitorInformation() },
      visit: {
        $set: new Visit({
          trainingCenterId: currentState.technicalInformation?.trainingCenter?.geminiSiteId,
          drk: currentState.deviceRegistration?.drk,
          exportControl: {},
          payment: { isPaid: false },
        }),
      },
      technicalInformation: { sessionId: { $set: Guid.create() } },
    });
    context.patchState(state);
  }

  @Action(ResetScan)
  resetScan(context: StateContext<ApplicationStateModel>, action: ResetScan) {
    const state = update(context.getState(), {
      visitorInformation: {
        facePhoto: { $set: undefined },
        idFacePhoto: { $set: undefined },
      },
      flowInformation: {
        facialComparisonResult: { $set: undefined },
      },
    });
    context.patchState(state);
  }

  @Action(ResetTraining)
  resetTraining(context: StateContext<ApplicationStateModel>, action: ResetTraining) {
    const state = update(context.getState(), {
      flowInformation: {
        training: {
          $set: undefined,
        },
      },
      visit: {
        trainingId: {
          $set: undefined,
        },
        userId: {
          $set: undefined,
        },
        customer: {
          $set: undefined,
        },
      },
    });
    context.patchState(state);
  }

  @Action(DoFaceCompare)
  async doFaceCompare(context: StateContext<ApplicationStateModel>, action: DoFaceCompare) {
    const currentState = context.getState();
    let result = null;
    try {
      result = await this.facialComparisonService.compareFaces({
        idScan: currentState.visitorInformation.idFacePhoto,
        photo: currentState.visitorInformation.facePhoto,
      });
    } catch (err) {
      result = null;
      console.error(err);
    }
    const state = update(context.getState(), {
      flowInformation: {
        facialComparisonResult: { $set: result },
      },
    });
    context.patchState(state);
  }

  @Action(SetTraining)
  setTraining(context: StateContext<ApplicationStateModel>, action: SetTraining) {
    const state = update(context.getState(), {
      flowInformation: {
        training: {
          $set: action.payload,
        },
      },
      visit: {
        trainingId: {
          $set: action.payload.id,
        },
        userId: {
          $set: action.payload.client.userId,
        },
        customer: {
          $set: action.payload.customer,
        },
        startDate: {
          $set: action.payload.startDate,
        },
        endDate: {
          $set: action.payload.endDate,
        },
      },
    });
    context.patchState(state);
  }

  @Action(SetReservations)
  setReservations(context: StateContext<ApplicationStateModel>, action: SetReservations) {
    const state = update(context.getState(), {
      flowInformation: {
        reservations: {
          $set: action.payload,
        },
      },
    });
    context.patchState(state);
  }

  @Action(SetCustomer)
  setCustomer(context: StateContext<ApplicationStateModel>, action: SetCustomer) {
    const state = update(context.getState(), {
      flowInformation: {
        customer: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetTrainingCenter)
  setTrainingCenter(context: StateContext<ApplicationStateModel>, action: SetTrainingCenter) {
    const trainingCenter = action.payload;

    let state = context.getState();
    if (trainingCenter) {
      if (trainingCenter?.geminiSiteId && trainingCenter?.name) {
        this.featureFlagService.changeUser({
          key: `tc-${action.payload.geminiSiteId}`,
          name: `${action.payload.name}'s Training Center`,
        });
      }

      state = update(state, {
        technicalInformation: {
          trainingCenter: { $set: trainingCenter },
        },
        deviceConfiguration: {
          geminiSiteId: { $set: trainingCenter.geminiSiteId },
        },
        visit: {
          trainingCenterId: { $set: trainingCenter.geminiSiteId },
        },
      });
    } else {
      state = update(state, {
        technicalInformation: {
          trainingCenter: { $set: null },
        },
        deviceConfiguration: {
          geminiSiteId: { $set: null },
        },
        visit: {
          trainingCenterId: { $set: null },
        },
      });
    }
    context.patchState(state);
  }

  @Action(SetDeviceId)
  setDeviceId(context: StateContext<ApplicationStateModel>, action: SetDeviceId) {
    let state = context.getState();

    state = update(context.getState(), {
      deviceConfiguration: {
        deviceId: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetFeedbackInformation)
  setFeedbackInformation(context: StateContext<ApplicationStateModel>, action: SetFeedbackInformation) {
    let state = context.getState();

    state = update(context.getState(), {
      feedbackInformation: { $set: action.payload },
    });
    context.patchState(state);
  }

  @Action(SetPrinter)
  setPrinterId(context: StateContext<ApplicationStateModel>, action: SetPrinter) {
    const state = update(context.getState(), {
      deviceConfiguration: {
        printerInformation: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetTechnicalTrainingCenters)
  setTechnicalTrainingCenters(
    context: StateContext<ApplicationStateModel>,
    action: SetTechnicalTrainingCenters
  ) {
    const state = update(context.getState(), {
      technicalInformation: {
        technicalTrainingCenters: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetBadgePrinted)
  setBadgePrinted(context: StateContext<ApplicationStateModel>, action: SetBadgePrinted) {
    const state = update(context.getState(), {
      flowInformation: {
        badgePrinted: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetIdentityPolicyConsentDate)
  setConsentDate(
    context: StateContext<ApplicationStateModel>,
    action: SetIdentityPolicyConsentDate
  ) {
    const state = update(context.getState(), {
      visit: {
        identityPolicyConsentDate: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetBadgePrintRequest)
  setBadgePrintRequest(context: StateContext<ApplicationStateModel>, action: SetBadgePrintRequest) {
    const state = update(context.getState(), {
      flowInformation: {
        badgePrintRequest: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetCovidAcknowledgementDate)
  setCovidAcknowledgementDate(
    context: StateContext<ApplicationStateModel>,
    action: SetCovidAcknowledgementDate
  ) {
    const state = update(context.getState(), {
      flowInformation: {
        covidAcknowledgementDate: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(RestoreState)
  restoreState(context: StateContext<ApplicationStateModel>, action: RestoreState) {
    context.setState(action.payload);
  }

  @Action(SetIDCardCameraSelection)
  setIDCardCameraSelection(
    context: StateContext<ApplicationStateModel>,
    action: SetIDCardCameraSelection
  ) {
    const state = update(context.getState(), {
      technicalInformation: {
        idCardCameraSelection: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetSkipBadgePrinting)
  setSkipBadgePrinting(context: StateContext<ApplicationStateModel>, action: SetSkipBadgePrinting) {
    const state = update(context.getState(), {
      technicalInformation: {
        skipBadgePrinting: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetSkipTraining)
  setSkipTraining(context: StateContext<ApplicationStateModel>, action: SetSkipTraining) {
    const state = update(context.getState(), {
      technicalInformation: {
        skipTraining: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetAppLoadedDateTime)
  setAppLoadedDateTime(context: StateContext<ApplicationStateModel>, action: SetAppLoadedDateTime) {
    const state = update(context.getState(), {
      technicalInformation: {
        appLoadedDateTime: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetCheckinFlow)
  setCheckinFlow(context: StateContext<ApplicationStateModel>, action: SetCheckinFlow) {
    const state = update(context.getState(), {
      flowInformation: {
        flowType: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetRecordingType)
  setRecordingType(context: StateContext<ApplicationStateModel>, action: SetRecordingType) {
    const state = update(context.getState(), {
      flowInformation: {
        recordingType: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorType)
  setVisitorType(context: StateContext<ApplicationStateModel>, action: SetVisitorType) {
    const state = update(context.getState(), {
      visit: {
        visitorType: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetTraineeType)
  setTraineeType(context: StateContext<ApplicationStateModel>, action: SetTraineeType) {
    const state = update(context.getState(), {
      visit: {
        traineeType: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorIdFacePhoto)
  setVisitorIdFacePhoto(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorIdFacePhoto
  ) {
    const state = update(context.getState(), {
      visitorInformation: {
        idFacePhoto: { $set: action.payload },
      },
      flowInformation: {
        facialComparisonResult: { $set: undefined },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorFacePhoto)
  async setVisitorFacePhoto(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorFacePhoto
  ) {
    const state = update(context.getState(), {
      visitorInformation: {
        facePhoto: { $set: action.payload },
      },
    });
    context.dispatch(new SetFacialComparisonResult(undefined));
    context.patchState(state);
  }

  @Action(SetVisitorFirstName)
  async setVisitorFirstName(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorFirstName
  ) {
    const state = update(context.getState(), {
      visit: {
        firstName: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorLastName)
  async setVisitorLastName(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorLastName
  ) {
    const state = update(context.getState(), {
      visit: {
        lastName: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorMiddleName)
  async setVisitorMiddleName(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorMiddleName
  ) {
    const state = update(context.getState(), {
      visit: {
        middleName: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorDateOfBirth)
  async setVisitorDateOfBirth(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorDateOfBirth
  ) {
    const state = update(context.getState(), {
      visit: {
        dateOfBirth: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorNationality)
  async setVisitorNationality(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorNationality
  ) {
    const exportControlCitizenship = new ExportControlCitizenship({
      id: action.payload.id,
      restrictionLevel: null,
      updatedAt: null,
    });

    const state = update(context.getState(), {
      visit: {
        exportControl: { lastGrantedCitizenship: { $set: exportControlCitizenship } },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorCountryOfResidency)
  async setVisitorCountryOfResidency(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorCountryOfResidency
  ) {
    const exportControlCountry = new ExportControlCountry({
      id: action.payload.id,
      restrictionLevel: null,
      updatedAt: null,
    });

    const state = update(context.getState(), {
      visit: {
        exportControl: { countryOfResidence: { $set: exportControlCountry } },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorHotelName)
  async setVisitorHotelName(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorHotelName
  ) {
    const state = update(context.getState(), {
      visit: {
        hotelName: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorHotelPhone)
  async setVisitorHotelPhone(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorHotelPhone
  ) {
    const state = update(context.getState(), {
      visit: {
        hotelPhone: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorEmail)
  async setVisitorEmail(context: StateContext<ApplicationStateModel>, action: SetVisitorEmail) {
    const state = update(context.getState(), {
      visit: {
        email: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorEmailConsentDate)
  async setVisitorEmailConsent(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorEmailConsentDate
  ) {
    const state = update(context.getState(), {
      visit: {
        emailConsentDate: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorPhoneNumber)
  async setVisitorPhoneNumber(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorPhoneNumber
  ) {
    const state = update(context.getState(), {
      visit: {
        phone: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorContactName)
  async setVisitorContactName(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorContactName
  ) {
    const state = update(context.getState(), {
      visit: {
        contactName: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitorHostName)
  async setVisitorHostName(
    context: StateContext<ApplicationStateModel>,
    action: SetVisitorHostName
  ) {
    const state = update(context.getState(), {
      visit: {
        hostName: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitStartDate)
  setVisitStartDate(context: StateContext<ApplicationStateModel>, action: SetVisitStartDate) {
    const state = update(context.getState(), {
      visit: {
        startDate: { $set: action.payload },
      },
    });
    return context.patchState(state);
  }

  @Action(SetVisitEndDate)
  setVisitEndDate(context: StateContext<ApplicationStateModel>, action: SetVisitEndDate) {
    const state = update(context.getState(), {
      visit: {
        endDate: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitBadgeNumber)
  setVisitBadgeNumber(context: StateContext<ApplicationStateModel>, action: SetVisitBadgeNumber) {
    const state = update(context.getState(), {
      visit: {
        badgeNumber: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetByPasses)
  setByPassFacialRecognition(context: StateContext<ApplicationStateModel>, action: SetByPasses) {
    const state = update(context.getState(), {
      flowInformation: {
        byPasses: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisitReason)
  setVisitReason(context: StateContext<ApplicationStateModel>, action: SetVisitReason) {
    const state = update(context.getState(), {
      visit: {
        visitReason: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetCamundaTask)
  setCamundaTask(context: StateContext<ApplicationStateModel>, action: SetCamundaTask) {
    const state = update(context.getState(), {
      flowInformation: {
        camundaTask: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetCamundaProcessInstanceWithVariables)
  setCamundaProcessInstanceWithVariables(
    context: StateContext<ApplicationStateModel>,
    action: SetCamundaProcessInstanceWithVariables
  ) {
    const state = update(context.getState(), {
      flowInformation: {
        camundaProcessInstanceWithVariables: { $set: action.payload },
      },
    });
    context.patchState(state);
    this.storageService.set('camundaProcessInstanceId', action.payload.id);
  }

  @Action(SetVisitId)
  setVisitid(context: StateContext<ApplicationStateModel>, action: SetVisitId) {
    const state = update(context.getState(), {
      visit: {
        id: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetBadgeValidity)
  setBadgeValidity(context: StateContext<ApplicationStateModel>, action: SetBadgeValidity) {
    const state = update(context.getState(), {
      flowInformation: {
        badgeValidity: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetCamundaTaskCustomPropertiesMap)
  setCamundaTaskCustomProperties(
    context: StateContext<ApplicationStateModel>,
    action: SetCamundaTaskCustomPropertiesMap
  ) {
    const state = update(context.getState(), {
      flowInformation: {
        camundaTaskCustomPropertiesMap: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetExportControl)
  setExportControl(context: StateContext<ApplicationStateModel>, action: SetExportControl) {
    const state = update(context.getState(), {
      visitorInformation: {
        exportControl: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetVisit)
  setVisit(context: StateContext<ApplicationStateModel>, action: SetVisit) {
    const state = update(context.getState(), {
      visit: { $set: action.payload },
    });
    context.patchState(state);
  }

  @Action(SetSteps)
  setSteps(context: StateContext<ApplicationStateModel>, action: SetSteps) {
    const state = update(context.getState(), {
      technicalInformation: { steps: { $set: action.payload } },
    });
    context.patchState(state);
  }

  @Action(SetIdentityDocumentType)
  setIdentityDocumentType(
    context: StateContext<ApplicationStateModel>,
    action: SetIdentityDocumentType
  ) {
    const state = update(context.getState(), {
      flowInformation: { identityDocumentType: { $set: action.payload } },
    });
    context.patchState(state);
  }

  @Action(SetDeviceConfiguration)
  setDeviceConfiguration(
    context: StateContext<ApplicationStateModel>,
    action: SetDeviceConfiguration
  ) {
    const state = update(context.getState(), {
      deviceConfiguration: { $set: action.payload },
    });
    context.patchState(state);
  }

  @Action(SetDeviceRegistration)
  setDeviceRegistration(
    context: StateContext<ApplicationStateModel>,
    action: SetDeviceRegistration
  ) {
    const state = update(context.getState(), {
      deviceRegistration: { $set: action.payload },
    });
    context.patchState(state);
  }

  @Action(SetTrainingCenterConfiguration)
  setTrainingCenterConfiguration(
    context: StateContext<ApplicationStateModel>,
    action: SetTrainingCenterConfiguration
  ) {
    const state = update(context.getState(), {
      technicalInformation: { trainingCenterConfiguration: { $set: action.payload } },
    });
    context.patchState(state);
  }

  @Action(SetPassportScannerIp)
  setPassportScannerIp(context: StateContext<ApplicationStateModel>, action: SetPassportScannerIp) {
    const state = update(context.getState(), {
      deviceConfiguration: { passportScannerIp: { $set: action.payload } },
    });
    context.patchState(state);
  }

  @Action(SetDeviceInventoryNumber)
  setDeviceInventoryNumber(context: StateContext<ApplicationStateModel>, action: SetDeviceInventoryNumber) {
    const state = update(context.getState(), {
      deviceConfiguration: { inventoryNumber: { $set: action.payload } },
    });
    context.patchState(state);
  }

  @Action(SetDeviceSerialNumber)
  setDeviceSerialNumber(context: StateContext<ApplicationStateModel>, action: SetDeviceSerialNumber) {
    const state = update(context.getState(), {
      deviceConfiguration: { serialNumber: { $set: action.payload } },
    });
    context.patchState(state);
  }

  @Action(SetAppVersionNumber)
  setAppVersionNumber(context: StateContext<ApplicationStateModel>, action: SetAppVersionNumber) {
    const state = update(context.getState(), {
      technicalInformation: { appVersion: { $set: action.payload } },
    });
    context.patchState(state);
  }

  @Action(SetPassportScannerConnected)
  setPassportScannerConnected(context: StateContext<ApplicationStateModel>, action: SetPassportScannerConnected) {
    const state = update(context.getState(), {
      deviceConfiguration: { passportScannerConnected: { $set: action.payload } },
    });
    context.patchState(state);
  }

  @Action(SetIsAdministratorAuthenticated)
  setIsAdministratorAuthenticated(
    context: StateContext<ApplicationStateModel>,
    action: SetIsAdministratorAuthenticated
  ) {
    const state = update(context.getState(), {
      technicalInformation: { isAdministratorAuthenticated: { $set: action.payload } },
    });
    context.patchState(state);
  }

  @Action(SetBackButtonDeactivated)
  setBackButtonDeactivated(
    context: StateContext<ApplicationStateModel>,
    action: SetBackButtonDeactivated
  ) {
    const state = update(context.getState(), {
      flowInformation: {
        backButtonDeactivated: { $set: action.payload },
      },
    });
    context.patchState(state);
  }

  @Action(SetCheckinFormFields)
  setCheckinFormFields(
    context: StateContext<ApplicationStateModel>,
    action: SetCheckinFormFields
  ) {
    const state = update(context.getState(), {
      technicalInformation: {
        checkinFormFields: { $set: action.payload },
      },
    });
    context.patchState(state);
  }
}
