import { fromEvent, map, Observable, of, Subject } from 'rxjs';
import { IosAppEventTypes, IosPictureTypes } from './ios-bridge-event-types';
import { isHostedInIPad, nativeProcess } from './ios.utils';

import { Injectable } from '@angular/core';
import { CheckinCameraType } from '@core/enums/checkin-camera-type.enum';
import { BadgeInformation } from '@core/model/badge/badge-information.model';
import { CameraPostion } from '@core/model/entity/cameraPosition.model';
import { Printer } from '@core/model/printers/printer.model';
import { blobToBase64 } from '@core/utils/utilities.util';

@Injectable({
  providedIn: 'root',
})
export class IosBridgeService {
  IdCardPictureSource = new Subject<string>();
  SelfiePictureSource = new Subject<string>();
  private onBadgeNumberReceived$ = new Subject<string>();
  private onBadgePrintedReceived$ = new Subject<boolean>();
  private onIdCardCameraSelectionReceived$ = new Subject<CheckinCameraType>();

  constructor() {
    this.bindToIosAppEvents();
  }

  public get onBadgeNumberReceived(): Observable<string> {
    return this.onBadgeNumberReceived$.asObservable();
  }
  public get onBadgePrintedReceived(): Observable<boolean> {
    return this.onBadgePrintedReceived$.asObservable();
  }
  public get onIdCardCameraSelectionReceived(): Observable<CheckinCameraType> {
    return this.onIdCardCameraSelectionReceived$.asObservable();
  }

  private bindToIosAppEvents() {
    window.addEventListener(
      IosAppEventTypes.pictureReceived,
      this.onPictureReceived.bind(this),
      false
    );

    window.addEventListener(
      IosAppEventTypes.badgePrintedReceived,
      this.onBadgePrintedReceivedCallback.bind(this),
      false
    );

    window.addEventListener(
      IosAppEventTypes.idCardCameraSelectionReceived,
      this.onIdCardCameraSelectionReceivedCallback.bind(this),
      false
    );

    window.addEventListener(
      IosAppEventTypes.badgeNumberReceived,
      this.onBadgeNumberReceivedCallback.bind(this),
      false
    );
  }

  public setDrk(drk: string): Observable<CustomEvent> {
    if (isHostedInIPad()) {
      nativeProcess().postMessage(
        JSON.stringify({
          type: IosAppEventTypes.setDrk,
          drk,
        })
      );
      return fromEvent<CustomEvent>(window, IosAppEventTypes.setDrkComplete);
    }
  }

  public getPrinters() {
    if (isHostedInIPad()) {
      nativeProcess().postMessage(
        JSON.stringify({
          type: IosAppEventTypes.getPrinters,
        })
      );
      return fromEvent<CustomEvent>(window, IosAppEventTypes.getPrintersComplete).pipe(
        map((customeEvent) => {
          if (!customeEvent?.detail?.printers) {
            return [];
          }
          return customeEvent?.detail?.printers as Printer[];
        })
      );
    } else {
      return of([]);
    }
  }

  public takeSelfiePicture(data: CameraPostion) {
    if (isHostedInIPad()) {
      nativeProcess().postMessage(
        JSON.stringify({
          type: IosAppEventTypes.takeSelfiePicture,
          position: data,
        })
      );
    }
  }

  public takeIdCardPicture(data: CameraPostion) {
    if (isHostedInIPad()) {
      nativeProcess().postMessage(
        JSON.stringify({
          type: IosAppEventTypes.takeIdCardPicture,
          position: data,
        })
      );
    }
  }

  public async printBadge(printer: Printer, badgeInformation: BadgeInformation, badgeBlob: Blob) {
    if (isHostedInIPad()) {
      const blobBase64 = await blobToBase64(badgeBlob);
      await nativeProcess().postMessage(
        JSON.stringify({
          type: IosAppEventTypes.printBadge,
          printer: printer,
          badgeInformation,
          blobBase64,
        })
      );
    }
  }

  public startQrReader(data: CameraPostion) {
    if (isHostedInIPad()) {
      nativeProcess().postMessage(
        JSON.stringify({
          type: IosAppEventTypes.startQrReader,
          position: data,
        })
      );
    }
  }

  public stopQrReader() {
    if (isHostedInIPad()) {
      nativeProcess().postMessage(
        JSON.stringify({
          type: IosAppEventTypes.stopQrReader,
        })
      );
    }
  }

  public reloadPage() {
    if (isHostedInIPad()) {
      nativeProcess().postMessage(
        JSON.stringify({
          type: IosAppEventTypes.reloadPage,
        })
      );
    }
  }

  private onPictureReceived(e) {
    if (e.detail.type === IosPictureTypes.idCard) {
      this.IdCardPictureSource.next(e.detail.image);
    } else if (e.detail.type === IosPictureTypes.selfie) {
      this.SelfiePictureSource.next(e.detail.image);
    }
  }

  private onBadgeNumberReceivedCallback(e) {
    const value = JSON.parse(e.detail) as string;
    this.onBadgeNumberReceived$.next(value);
  }

  private onBadgePrintedReceivedCallback(e) {
    const value = JSON.parse(e.detail) as boolean;
    this.onBadgePrintedReceived$.next(value);
  }

  private onIdCardCameraSelectionReceivedCallback(e) {
    const value: CheckinCameraType = e.detail as CheckinCameraType;
    this.onIdCardCameraSelectionReceived$.next(value);
  }
}
