import { ApiService } from '@data/services/api.service';
import { FaceImage } from '@core/model/entity/FaceImage';
import { FacesCompare } from '@core/model/entity/FacesCompare';
import { FacesCompareResult } from '@core/model/entity/FacesCompareResult';
import { Injectable } from '@angular/core';
import { base64ToFile } from '@core/utils/utilities.util';
import { lastValueFrom } from 'rxjs';
import { retry } from 'rxjs/operators';

export enum BackendRoutePaths {
  FaceCompare = '/faces/compare',
  PostProcess = '/faces/postprocess',
}

@Injectable({
  providedIn: 'root',
})
export class FacialComparisonService {
  constructor(private apiService: ApiService) {}

  public async compareFaces(faceCompare: FacesCompare): Promise<FacesCompareResult> {
    const formData = new FormData();
    if (faceCompare) {
      formData.append('idScan', await base64ToFile('idScan.png', faceCompare.idScan));
      formData.append('photo', await base64ToFile('photo.png', faceCompare.photo));
    }
    return lastValueFrom(
      this.apiService
        .post<FacesCompareResult>(BackendRoutePaths.FaceCompare, formData)
        .pipe(retry(2))
    );
  }

  public async postProcess(faceImage: FaceImage): Promise<FaceImage> {
    const formData = new FormData();
    if (faceImage) {
      formData.append('photo', await base64ToFile('photo.png', faceImage.photo));
    }
    return lastValueFrom(this.apiService.post<FaceImage>(BackendRoutePaths.PostProcess, formData));
  }
}
