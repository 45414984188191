import { ApplicationStateService } from '@core/services/application-state.service';
import { FeatureFlagService } from '@core/feature-toggle/feature-flag.service';
import { ThemeService } from 'common-web-ui';

export function initializeApplication(
  featureFlagsInitService: FeatureFlagService,
  applicationStateService: ApplicationStateService,
  themeService: ThemeService
) {
  return async () => {
    await featureFlagsInitService.initialize();
    await applicationStateService.initializeApplication();
    await themeService.InitThemeService();
    return true;
  };
}
