import { Component, OnInit } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { combineLatest, first, lastValueFrom, take } from 'rxjs';

import { AppRoutePaths } from '@core/constants';
import { EWorkflowVariable } from '@core/enums/workflow-variable.enum';
import { TrainingCenter } from '@core/model/entity/training-center.model';
import { ApplicationStateService } from '@core/services/application-state.service';
import { LogService } from '@core/services/log.service';
import { TimerService } from '@core/services/timer.service';
import { VisitStateService } from '@core/state/visit-state.service';
import { environment } from '@env';
import { BaseComponent } from '@shared/base/base-component.component';
import { isHostedInWin } from '@core/win-bridge/win.utils';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.less'],
})
export class WelcomeComponent extends BaseComponent implements OnInit {
  trainingCenter: TrainingCenter;
  buildNumber: string;
  trainingCenterDisplayName: string;

  constructor(
    private applicationStateService: ApplicationStateService,
    private idle: Idle,
    private logService: LogService,
    private timerService: TimerService,
    private visitStateService: VisitStateService
  ) {
    super();
    this.buildNumber = environment.buildNumber;
  }

  public async ngOnInit() {
    try {
      this.idle.stop();

      if(isHostedInWin()) {
        const shellAppVersion = await lastValueFrom(this.applicationStateService.appVersion$.pipe(take(1)));
        this.buildNumber = shellAppVersion? `${this.buildNumber} - ${shellAppVersion}`: this.buildNumber;
      }

      [this.trainingCenter] = await lastValueFrom(
        combineLatest([
          this.applicationStateService.trainingCenter$,
          this.applicationStateService.trainingCenterConfiguration$,
        ]).pipe(first())
      );

      if (this.trainingCenter) {
        this.trainingCenterDisplayName = this.trainingCenter.displayName
          ? this.trainingCenter.displayName
          : this.trainingCenter.name;
      }

      this.initState();
    } catch (error) {
      this.logService.error(`[WelcomeComponent]ngOnInit()`, error);
      this.navigateError(error);
    }
  }

  public async initState() {
    const [checkinVisitorType, flowType] = await lastValueFrom(
      combineLatest([
        this.visitStateService.visitorType$,
        this.applicationStateService.checkinFlow$,
      ]).pipe(first())
    );

    if (checkinVisitorType) {
      this.visitStateService.setVisitorType(null);
    }
    if (flowType) {
      this.applicationStateService.setCheckinFlow(null);
    }

    await this.deleteTaskVariables([EWorkflowVariable.visitorType, EWorkflowVariable.flowType]);
  }

  public async administration(): Promise<void> {
    await this.navigateTo(`${AppRoutePaths.Administration}`);
  }

  public next(): void {
    this.protectedProcess$.next(async () => {
      await this.nextProcess();
    });
  }

  private async nextProcess() {
    try {
      this.timerService.start();
      this.watchIdle();
      return await this.navigateNext();
    } catch (error) {
      this.logService.error(`[WelcomeComponent]nextProcess()`, error);
      this.navigateError(error);
    }
  }

  private watchIdle(): void {
    if (!this.idle.isRunning()) {
      this.idle.watch();
    }
  }
}
