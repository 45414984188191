import { Input, Component, OnInit, Output, EventEmitter, HostBinding, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountDownComponent implements OnInit {
  @Input() public durationInSeconds: number;
  @Input() public size: 'small' | 'medium' = 'medium';
  @Output() public timerEnded: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('class.count-down') public class = true;

  @HostBinding('class.count-down-medium') public get classCountDownMedium() {
    return this.size === 'medium';
  }

  @HostBinding('class.count-down-small') public get classCountDownSmall() {
    return this.size === 'small';
  }

  protected count: number;
  protected intervalId: NodeJS.Timer;

  constructor(private _changeDetectorRef: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.count = this.durationInSeconds;
    this.intervalId = setInterval(() => {
      this.count--;
      this._changeDetectorRef.markForCheck();
      if (this.count === 0) {
        clearInterval(this.intervalId);
        this.timerEnded.emit(true);
      }
    }, 1000);
  }
}
