import { PageKey } from '@core/enums/page-keys.enum';

export enum AppRoutePaths {
  Administration = 'administration',
  Aircraft = 'aircraft',
  Default = '',
  EndSession = 'end-session',
  FaceCheck = 'face-check',
  IdCheck = 'id-check',
  IdentityComparison = 'identity-comparison',
  Reservations = 'reservations',
  Login = 'login',
  NotFound = '404',
  Customers = 'customers',
  PrepareFaceCheck = 'prepare-face-check',
  Welcome = 'welcome',
  WhoopsReservations = 'whoops-reservations',
  VisitorInformation = 'visitor-information',
  IdentityPolicy = 'identity-policy',
  DeviceNotAuthorized = 'device-not-authorized',
  CheckInWarning = 'check-in-warning',
  ReportReception = 'report-reception',
  PrintBadgeOptions = 'print-badge-options',
  BadgePrint = 'badge-print',
  Error = 'error',
  Idling = 'idling',
  PrepareIdCheck = 'prepare-id-check',
  CoronaVirusQuestion = 'corona-virus-question',
  TrainingDate = 'training-date',
  RetrieveAccessCard = 'retrieve-access-card',
  PreparePhotoBadge = 'prepare-photo',
  Greetings = 'greetings',
  QrCodeReader = 'qrcode-reader',
  DailyCheckinCheckout = 'daily-checkin-checkout',
  PresentYourId = 'present-your-id',
  TraineeTypes = 'trainee-types',
  ByPass = 'bypass',
  DocumentReader = 'document-reader',
  ScanPassport = 'scan-passport',
  ScanDocument = 'scan-document',
  ScanId = 'scan-id',
  Checkin = 'checkin',
  Survey = 'survey',
  StartWorkflow = 'start-workflow',
  AppUpdate = 'app-update',
  ScanGovernmentId = 'scan-government-id',
  DocumentType = 'document-type',
  NoIdentityDocument = 'no-identity-document',
  Training = 'training',
  ExportControl = 'export-control',
  DeviceRegistration = 'device-registration',
  ReasonVisit = 'reason-visit',
  CheckinType = 'checkin-type',
}

export enum ExportControlRoutePaths {
  Default = '',
  ExportControlFail = 'export-control-fail',
}

export enum NoIdentityDocumentPaths {
  Default = '',
}

export enum DocumentTypePaths {
  Default = '',
}

export enum ScanGovernmentIdPaths {
  Default = '',
  ScanGovernmentIdSuccess = 'success',
  ScanGovernmentIdError = 'error',
}

export enum AppUpdatePaths {
  Default = '',
}

export enum IdentityPolicyPaths {
  Default = '',
  IdentityPolicyFail = 'error',
}

export enum BadgePrintRoutePaths {
  Default = '',
  BadgePrintSuccess = 'success',
  BadgePrintFail = 'error',
  BadgePrintFailEmail = 'error-email',
}

export enum IdCheckRoutePaths {
  Default = '',
  IdCheckSuccess = 'success',
}

export enum PrepareFaceCheckRoutePaths {
  Default = '',
}

export enum NotFoundRoutePaths {
  Default = '',
}

export enum FaceCheckRoutePaths {
  Default = '',
  FaceCheckSuccess = 'success',
}

export enum IdentityComparisonRoutePaths {
  Default = '',
  IdentityComparisonFail = 'error',
  IdentityComparisonSuccess = 'success',
}

export enum ReservationsRoutePaths {
  Default = '',
  Whoops_Default = '',
  Confirmation = 'confirmation',
}

export enum TrainingRoutePaths {
  Default = '',
  Search = 'search',
  Summary = 'summary',
}

export enum DailyCheckinCheckoutRoutePaths {
  Default = '',
  Greetings = 'greetings',
  Choice = 'choice',
  Passcode = 'passcode',
  DailyCheckin = 'daily-checkin',
  DailyCheckout = 'daily-checkout',
}

export enum VisitorInformationRoutePaths {
  Default = '',
  AboutYou = 'about-you',
  MoreAboutYou = 'more-about-you',
}
export enum EndSessionRoutePaths {
  Default = '',
  ClosingSession = 'closing-session',
  ClosingSessionConfirmation = 'confirmation',
}

export enum CoronaVirusRoutePaths {
  Default = '',
  Reschedule = 'reschedule',
  Record = 'record',
}

export const AnalyticAppName = 'TrainingCenterExperience';

export enum Platforms {
  IOS = 'IOS',
  Windows = 'Windows',
  Web = 'Web',
}

export enum StorageKeys {
  IpadMode = 'isIPadMode',
  DRK = 'drk',
  TrainingCenterGeminiId = 'TrainingCenterGeminiId',
  ApplicationState = 'ApplicationState',
  DocumentScannerIpAddress = 'DocumentScannerIpAddress',
  ByPassCode = 'ByPassCode',
  RedirectUrl = 'RedirectUrl',
}

export enum StorageTypes {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
}

export enum PreparePhotoBadgeRoutePaths {
  Default = '',
}

export enum GreetingsRoutePaths {
  Default = '',
  Welcome = 'welcome',
  Goodbye = 'goodbye',
}

export enum QrCodeReaderRoutePaths {
  Default = '',
  QrCodeReaderFail = 'fail',
  QrCodeReaderValidation = 'validation',
}

export enum ScanPassportRoutePaths {
  Default = '',
  ScanPassportFail = 'error',
  ScanPassportSuccess = 'success',
}

export enum ScanIdRoutePaths {
  Default = '',
  ScanIdFail = 'error',
  ScanIdSuccess = 'success',
}

export enum CheckinRoutePaths {
  Default = '',
  CheckinFail = 'error',
  CheckinSummary = 'summary',
  CheckInAlready = 'checkin-already',
  NotPreCheckedin = 'not-prechecked-in',
}

export enum AdministrationRoutePaths {
  Default = '',
  Device = 'device',
  Login = 'login',
}

export enum AdministrationDeviceRoutePaths {
  Default = '',
  General = 'general',
  Features = 'features',
  Hardware = 'hardware',
}

export enum AdministrationDeviceHardwareRoutePaths {
  Default = '',
}
export enum AdministrationDeviceFeaturesRoutePaths {
  Default = '',
}
export enum AdministrationDeviceGeneralRoutePaths {
  Default = '',
}
export enum AdministrationLoginRoutePaths {
  Default = '',
}

export enum DeviceRegistrationRoutePaths {
  Default = '',
  DeviceRegistrationSuccess = 'success',
  DeviceRegistrationFail = 'error',
}

export enum DeviceRegistrationSuccessRoutePaths {
  Default = '',
}
export enum DeviceRegistrationFailRoutePaths {
  Default = '',
}

export enum ReasonVisitPaths {
  Default = '',
}

export enum CheckinTypePaths {
  Default = '',
}

export const GuestBadgeDefaultDaysDuration = 1;
export const AppRefreshDelayInMilliseconds = 1000 * 60 * 60;

export const PageKeyRoutes = {
  [PageKey.WELCOME]: AppRoutePaths.Welcome,
  [PageKey.COVID]: AppRoutePaths.CoronaVirusQuestion,
  [PageKey.COVID_RESCHEDULE]: `${AppRoutePaths.CoronaVirusQuestion}/${CoronaVirusRoutePaths.Reschedule}`,
  [PageKey.TRAINEE_TYPE]: `${AppRoutePaths.TraineeTypes}`,
  [PageKey.IDENTITY_POLICY]: `${AppRoutePaths.IdentityPolicy}`,
  [PageKey.IDENTITY_POLICY_FAIL]: `${AppRoutePaths.IdentityPolicy}/${IdentityPolicyPaths.IdentityPolicyFail}`,
  [PageKey.IDENTITY_COMPARISON]: `${AppRoutePaths.IdentityComparison}`,
  [PageKey.IDENTITY_COMPARISON_SUCCESS]: `${AppRoutePaths.IdentityComparison}/${IdentityComparisonRoutePaths.IdentityComparisonSuccess}`,
  [PageKey.IDENTITY_COMPARISON_FAIL]: `${AppRoutePaths.IdentityComparison}/${IdentityComparisonRoutePaths.IdentityComparisonFail}`,
  [PageKey.PREPARE_PHOTO_FACE]: `${AppRoutePaths.PrepareFaceCheck}`,
  [PageKey.PHOTO_FACE]: `${AppRoutePaths.FaceCheck}`,
  [PageKey.PHOTO_FACE_SUCCESS]: `${AppRoutePaths.FaceCheck}/${FaceCheckRoutePaths.FaceCheckSuccess}`,
  [PageKey.PREPARE_PHOTO_ID]: `${AppRoutePaths.PrepareIdCheck}`,
  [PageKey.PHOTO_ID]: `${AppRoutePaths.IdCheck}`,
  [PageKey.PHOTO_ID_SUCCESS]: `${AppRoutePaths.IdCheck}/${IdCheckRoutePaths.IdCheckSuccess}`,
  [PageKey.RESERVATIONS]: `${AppRoutePaths.Reservations}`,
  [PageKey.RESERVATION_CONFIRMATION]: `${AppRoutePaths.Reservations}/${ReservationsRoutePaths.Confirmation}`,
  [PageKey.WHOOPS_RESERVATIONS]: `${AppRoutePaths.WhoopsReservations}`,
  [PageKey.END_SESSION]: `${AppRoutePaths.EndSession}`,
  [PageKey.CUSTOMERS]: `${AppRoutePaths.Customers}`,
  [PageKey.AIRCRAFT]: `${AppRoutePaths.Aircraft}`,
  [PageKey.VISITOR_INFORMATION_ABOUTYOU]: `${AppRoutePaths.VisitorInformation}/${VisitorInformationRoutePaths.AboutYou}`,
  [PageKey.VISITOR_INFORMATION_MORE_ABOUTYOU]: `${AppRoutePaths.VisitorInformation}/${VisitorInformationRoutePaths.MoreAboutYou}`,
  [PageKey.CHECKIN_WARNING]: `${AppRoutePaths.CheckInWarning}`,
  [PageKey.REPORT_RECEPTION]: `${AppRoutePaths.ReportReception}`,
  [PageKey.PRINT_BADGE]: `${AppRoutePaths.BadgePrint}`,
  [PageKey.PRINT_BADGE_SUCCESS]: `${AppRoutePaths.BadgePrint}/${BadgePrintRoutePaths.BadgePrintSuccess}`,
  [PageKey.PRINT_BADGE_FAIL_EMAIL]: `${AppRoutePaths.BadgePrint}/${BadgePrintRoutePaths.BadgePrintFailEmail}`,
  [PageKey.PRINT_BADGE_FAIL]: `${AppRoutePaths.BadgePrint}/${BadgePrintRoutePaths.BadgePrintFail}`,
  [PageKey.CHECK_IN]: `${AppRoutePaths.Checkin}`,
  [PageKey.CHECK_IN_SUMMARY]: `${AppRoutePaths.Checkin}/${CheckinRoutePaths.CheckinSummary}`,
  [PageKey.CHECK_IN_FAIL]: `${AppRoutePaths.Checkin}/${CheckinRoutePaths.CheckinFail}`,
  [PageKey.TRAINING_DATE]: `${AppRoutePaths.TrainingDate}`,
  [PageKey.RETRIEVE_ACCESSCARD]: `${AppRoutePaths.RetrieveAccessCard}`,
  [PageKey.GREETINGS_WELCOME]: `${AppRoutePaths.Greetings}/${GreetingsRoutePaths.Welcome}`,
  [PageKey.GREETINGS_GOODBYE]: `${AppRoutePaths.Greetings}/${GreetingsRoutePaths.Goodbye}`,
  [PageKey.QR_CODE_READER]: `${AppRoutePaths.QrCodeReader}`,
  [PageKey.QR_CODE_READER_VALIDATION]: `${AppRoutePaths.QrCodeReader}/${QrCodeReaderRoutePaths.QrCodeReaderValidation}`,
  [PageKey.QR_CODE_READER_FAIL]: `${AppRoutePaths.QrCodeReader}/${QrCodeReaderRoutePaths.QrCodeReaderFail}`,
  [PageKey.DAILY_CHECKIN_CHECKOUT]: `${AppRoutePaths.DailyCheckinCheckout}`,
  [PageKey.PRESENT_YOUR_ID]: `${AppRoutePaths.PresentYourId}`,
  [PageKey.BYPASS]: `${AppRoutePaths.ByPass}`,
  [PageKey.SCAN_PASSPORT]: `${AppRoutePaths.ScanPassport}`,
  [PageKey.SCAN_PASSPORT_SUCCESS]: `${AppRoutePaths.ScanPassport}/${ScanPassportRoutePaths.ScanPassportSuccess}`,
  [PageKey.SCAN_PASSPORT_FAIL]: `${AppRoutePaths.ScanPassport}/${ScanPassportRoutePaths.ScanPassportFail}`,
  [PageKey.SCAN_ID]: `${AppRoutePaths.ScanId}`,
  [PageKey.SCAN_ID_SUCCESS]: `${AppRoutePaths.ScanId}/${ScanIdRoutePaths.ScanIdSuccess}`,
  [PageKey.SCAN_ID_FAIL]: `${AppRoutePaths.ScanId}/${ScanIdRoutePaths.ScanIdFail}`,
  [PageKey.SCAN_GOVERNMENT_ID]: `${AppRoutePaths.ScanGovernmentId}`,
  [PageKey.SCAN_GOVERNMENT_ID_SUCCESS]: `${AppRoutePaths.ScanGovernmentId}/${ScanGovernmentIdPaths.ScanGovernmentIdSuccess}`,
  [PageKey.SCAN_GOVERNMENT_ID_FAIL]: `${AppRoutePaths.ScanGovernmentId}/${ScanGovernmentIdPaths.ScanGovernmentIdError}`,
  [PageKey.SCAN_DOCUMENT]: `${AppRoutePaths.ScanDocument}`,
  [PageKey.SURVEY]: `${AppRoutePaths.Survey}`,
  [PageKey.CLOSING_SESSION_CONFIRMATION]: `${AppRoutePaths.EndSession}/${EndSessionRoutePaths.ClosingSessionConfirmation}`,
  [PageKey.CLOSING_SESSION]: `${AppRoutePaths.EndSession}/${EndSessionRoutePaths.ClosingSession}`,
  [PageKey.DOCUMENT_TYPE]: `${AppRoutePaths.DocumentType}`,
  [PageKey.NO_IDENTITY_DOCUMENT]: `${AppRoutePaths.NoIdentityDocument}`,
  [PageKey.TRAINING]: `${AppRoutePaths.Reservations}`,
  [PageKey.TRAINING_SEARCH]: `${AppRoutePaths.Training}/${TrainingRoutePaths.Search}`,
  [PageKey.TRAINING_SUMMARY]: `${AppRoutePaths.Training}/${TrainingRoutePaths.Summary}`,
  [PageKey.EXPORT_CONTROL_FAIL]: `${AppRoutePaths.ExportControl}/${ExportControlRoutePaths.ExportControlFail}`,
  [PageKey.REASON_VISIT]: `${AppRoutePaths.ReasonVisit}`,
  [PageKey.CHECKIN_TYPE]: `${AppRoutePaths.CheckinType}`,
  [PageKey.PRINT_BADGE_OPTIONS]: `${AppRoutePaths.PrintBadgeOptions}`,
  [PageKey.ALREADY_CHECKEDIN]: `${AppRoutePaths.Checkin}/${CheckinRoutePaths.CheckInAlready}`,
  [PageKey.NOT_PRECHECKED_IN]: `${AppRoutePaths.Checkin}/${CheckinRoutePaths.NotPreCheckedin}`,
  [PageKey.DAILY_CHECKIN]: `${AppRoutePaths.DailyCheckinCheckout}/${DailyCheckinCheckoutRoutePaths.DailyCheckin}`,
  [PageKey.DAILY_CHECKOUT]: `${AppRoutePaths.DailyCheckinCheckout}/${DailyCheckinCheckoutRoutePaths.DailyCheckout}`,
  [PageKey.COVID_RECORD]: `${AppRoutePaths.CoronaVirusQuestion}/${CoronaVirusRoutePaths.Record}`,
};
