import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from '@core/services/log.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  chunkFailedMessage = /Loading chunk [\d]+ failed/;

  constructor(private logService: LogService) {
    super();
  }

  handleError(error: Error) {
    this.logService.fatal(error.message);

    if (this.chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    super.handleError(error);
  }
}
