/**
 * Camunda BPM REST API
 * OpenApi Spec for Camunda BPM REST API.
 *
 * The version of the OpenAPI document: 7.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CamundaProcessInstanceModificationInstruction } from './camunda-process-instance-modification-instruction.model';

export class CamundaProcessInstanceModification {
  /**
   * Skip execution listener invocation for activities that are started or ended as part of this request.
   */
  skipCustomListeners?: boolean | null;
  /**
   * Skip execution of [input/output variable mappings](https://docs.camunda.org/manual/7.14/user-guide/process-engine/variables/#input-output-variable-mapping) for activities that are started or ended as part of this request.
   */
  skipIoMappings?: boolean | null;
  /**
   * JSON array of modification instructions. The instructions are executed in the order they are in.
   */
  instructions?: Array<CamundaProcessInstanceModificationInstruction>;
  /**
   * An arbitrary text annotation set by a user for auditing reasons.
   */
  annotation?: string;
}
