import { Observable, of } from 'rxjs';
import { RememberFeature, SetRestartApp, SetWebAppRefresh } from './feature.actions';

import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Feature } from '../feature.model';
import { FeatureState } from './feature.state';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { ToggleFeatureKeys } from '../toggle-feature-keys.enum';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  @Select(FeatureState.getFeatures)
  public features$: Observable<Feature[]>;

  @Select(FeatureState.isFeatureEnabled)
  public featureEnabled$: Observable<(name: string) => boolean>;

  @Select(FeatureState.hasWebAppRefresh)
  public hasWebAppRefresh$: Observable<boolean>;

  @Select(FeatureState.hasRestartApp)
  public hasRestartApp$: Observable<boolean>;

  @Dispatch()
  public setRestartApp = (feature: Feature) => {
    return new SetRestartApp(feature);
  };

  @Dispatch()
  public setWebAppRefresh = (feature: Feature) => {
    return new SetWebAppRefresh(feature);
  };

  @Dispatch()
  public updateFeature = (
    featureName: string,
    featureValue: boolean
  ): Observable<Feature[]> | Observable<RememberFeature> => {
    if (!!environment.store.featureFlagsEnabled && !!featureName && featureName !== '') {
      const feature = new Feature(featureName, featureValue);
      switch (feature.name) {
        case ToggleFeatureKeys.WebAppRefresh:
          this.setWebAppRefresh(feature);
          break;
        case ToggleFeatureKeys.RestartApplication:
          this.setRestartApp(feature);
          break;
        default:
          break;
      }
      return of(new RememberFeature(new Feature(featureName, featureValue)));
    }

    return this.features$;
  };
}
