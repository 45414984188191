import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { ETrainingType } from '@core/enums/training-type.enum';
import { Reservation } from '@core/model/reservation/reservation.model';

@Component({
  selector: 'app-reservation-card',
  templateUrl: './reservation-card.component.html',
  styleUrls: ['./reservation-card.component.less'],
})
export class ReservationCardComponent {
  @HostBinding('class') class = 'reservation-card';
  @HostBinding('class.active') get active() {
    return this.isActive;
  }

  trainingType = ETrainingType;

  @Input() public reservation: Reservation;
  @Input() public isActive: boolean;
  @Output() eventSelected: EventEmitter<Reservation> = new EventEmitter();

  selected() {
    this.eventSelected.emit(this.reservation);
  }
}
