export enum WinAppEvents {
  getRegistrationKeyComplete = 'getRegKey_complete',
  getDeviceIdComplete = 'getDeviceId_complete',
  getSerialNumberComplete = 'getSerialNumber_complete',
  getPrintersComplete = 'getPrinters_complete',
  printBadgeComplete = 'printBadge_complete',
  scannerConnected = 'scannerIsConnected',
  scanComplete = 'scan_complete',
  getAppVersionComplete = 'getAppVersion_complete'
}
