import { AccessLevel } from './access-levels.model';
import { CheckinProcessConfiguration } from './checkin-process-configuration.model';
import { ITrainingCenterConfiguration } from './training-center-configuration.interface';
import { Turnstile } from './turnstile.model';

export class TrainingCenterConfiguration implements ITrainingCenterConfiguration {
  id: string;
  geminiSiteId: string;
  accessLevels: AccessLevel[];
  turnstiles: Turnstile[];
  checkinProcessConfiguration: CheckinProcessConfiguration;

  constructor(data?: ITrainingCenterConfiguration) {
    Object.assign(this, data);
  }
}
