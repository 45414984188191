import { Injectable } from '@angular/core';
import { AnalyticService } from '@data/services/analytic.service';
import { ApplicationStateService } from './application-state.service';
import { first, lastValueFrom, timeout } from 'rxjs';
import { Platforms } from '@core/constants';
import { WinBridgeService } from '@core/win-bridge/win-bridge.service';
import { isHostedInWin } from '@core/win-bridge/win.utils';

@Injectable({
  providedIn: 'root',
})
export class WatchdogService {

  public deviceId: string;
  public platform: string;

  constructor(
    private analyticService: AnalyticService,
    private appStateService: ApplicationStateService,
    private winBridgeService: WinBridgeService
  ) {
    this.platform = this.analyticService.getPlatformType();
  }

  public async initialize() {
    await this.sendAliveEvent();
    setInterval(() => this.sendAliveEvent(), 300000)
  }

  private async sendAliveEvent(): Promise<void> {
    const deviceConfiguration = await lastValueFrom(
      this.appStateService.deviceConfiguration$.pipe(first())
    );

    this.deviceId = deviceConfiguration?.deviceId;
    const trainingCenterId = deviceConfiguration?.geminiSiteId;

    if(!this.deviceId || this.platform == Platforms.Web || !trainingCenterId){
      return;
    }

    const serialNumber = isHostedInWin() ? deviceConfiguration?.serialNumber || await lastValueFrom(this.winBridgeService.getSerialNumber().pipe(first(), timeout(1000)), {defaultValue: ''}).catch(() => ''): '';
    const inventoryNumber = deviceConfiguration?.inventoryNumber;
    const winAppVersion = isHostedInWin() ? await lastValueFrom(
      this.appStateService.appVersion$.pipe(first())
    ): '';

    this.analyticService.trackAliveEvent({
      deviceId: this.deviceId,
      serialNumber: serialNumber,
      inventoryNumber: inventoryNumber,
      winAppVersion: winAppVersion,
      trainingCenterId: trainingCenterId
    });
  }
}
