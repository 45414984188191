import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { BehaviorSubject, filter, lastValueFrom, Observable, Subject, takeUntil } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { environment } from '@env';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly _destroy$ = new Subject<void>();
  private _isAuthenticated$: BehaviorSubject<boolean>;
  private _isAuthenticating$: BehaviorSubject<boolean>;
  private accessToken: string;
  drk: string;
  private activeAccout: AccountInfo;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private storageService: StorageService
  ) {
    this.init();
  }

  public init() {
    this._isAuthenticated$ = new BehaviorSubject(false);
    this._isAuthenticating$ = new BehaviorSubject(false);
    this.refreshAuthUser();

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe(async (result: EventMessage) => {
        const authenticationResult = result.payload as AuthenticationResult;
        this.accessToken = authenticationResult.accessToken;
        this.refreshAuthUser();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
      .subscribe(async (result: EventMessage) => {
        const authenticationResult = result.payload as AuthenticationResult;
        this.accessToken = authenticationResult.accessToken;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE))
      .subscribe(async (_result: EventMessage) => {
        this.logout();
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status !== InteractionStatus.None),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this._isAuthenticating$.next(true);
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this.refreshAuthUser();
        this._isAuthenticating$.next(false);
      });
  }

  public async acquireTokenSilent(): Promise<string> {
    if (this.activeAccout) {
      const scopes = environment.azureAd.scopes;

      const account = this.activeAccout
        ? this.activeAccout
        : this.msalService.instance.getAllAccounts()[0];

      const authRequest = this.msalGuardConfig.authRequest;
      const authenticationResult = await lastValueFrom(
        this.msalService.acquireTokenSilent({ ...authRequest, scopes, account })
      );
      const accessToken = authenticationResult.accessToken;
      return accessToken;
    }
    return null;
  }

  public async getAccessToken() {
    if (this.drk) {
      return this.drk;
    }
    this.accessToken = await this.acquireTokenSilent();
    return this.accessToken;
  }

  public async getAuthorizationHeader() {
    const token = await this.getAccessToken();
    return this.drk ? `DRK ${token}` : `bearer ${token}`;
  }

  public get isAuthenticated(): Observable<boolean> {
    return this._isAuthenticated$.asObservable();
  }

  public get isAuthenticating(): Observable<boolean> {
    return this._isAuthenticating$.asObservable();
  }

  public get userId(): string {
    if (this.isAuthenticated) {
      if (this.drk) {
        return `${this.drk}`;
      }
      if (this.activeAccout) {
        return `${this.activeAccout.username}`;
      }
    }
    return null;
  }

  public async login(): Promise<void> {
    await this.msalService.instance.handleRedirectPromise();
    if (this.activeAccout) {
      await this.acquireTokenSilent();
    } else {
      if (this.msalGuardConfig.authRequest) {
        await lastValueFrom(
          this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
        );
      } else {
        await lastValueFrom(this.msalService.loginRedirect());
      }
    }
  }

  public logout(): void {
    this.msalService.logout();
    const returnUrl = this.storageService.get('returnUrl');
    window.location.href = returnUrl || environment.azureAd.loginUrl;
  }

  private refreshAuthUser() {
    if (this.drk) {
      this._isAuthenticated$.next(true);
      return;
    }

    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      activeAccount = accounts[0];
      this.msalService.instance.setActiveAccount(activeAccount);
    }
    if (activeAccount) {
      this.activeAccout = activeAccount;
      this._isAuthenticated$.next(true);
    } else {
      this.activeAccout = null;
      this.accessToken = null;
      this._isAuthenticated$.next(false);
    }
  }

  public set returnUrl(returnUrl: string) {
    this.storageService.set('returnUrl', returnUrl);
  }

  public get returnUrl() {
    return this.storageService.get('returnUrl');
  }
}
