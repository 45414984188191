import { ApiService } from '@data/services/api.service';
import { EVisitRecordType } from '@core/model/visit/visit-record-type.enum';
import { FlowInformation } from '@core/state/flow-information.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IVisit } from '../interfaces/visits/visit.interface';
import { IVisitRecord } from '../interfaces/visits/visit-record.interface';
import { Injectable } from '@angular/core';
import { TechnicalInformation } from '@core/state/technical-information.model';
import { Visit } from '@core/model/visit/visit.model';
import { VisitPost } from '../interfaces/visits/visit-post.model';
import { VisitRecord } from '@core/model/visit/visit-record.model';
import { VisitRecordPost } from '../interfaces/visits/visit-record-post.model';
import { VisitStatus } from '@core/enums/visit-status.enum';
import { VisitorInformation } from '@core/state/visitor-information.model';
import { lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CheckinFormFields } from '@core/model/form/checkin-form-fields.model';

@Injectable({
  providedIn: 'root',
})
export class VisitsService {
  private readonly endpoint = `/visits`;

  constructor(private apiService: ApiService, public httpClient: HttpClient) { }

  public post(visitPost: VisitPost): Promise<Visit> {
    return lastValueFrom(
      this.apiService
        .post<IVisit>(this.endpoint, visitPost)
        .pipe(map((response) => new Visit(response)))
    );
  }

  public put(visit: Visit): Promise<Visit> {
    return lastValueFrom(
      this.apiService
        .put<IVisit>(`${this.endpoint}/${visit.id}`, visit)
        .pipe(map((response) => new Visit(response)))
    );
  }

  // Quality code
  public putPost(id: string, visit: VisitPost): Promise<Visit> {
    return lastValueFrom(
      this.apiService
        .put<IVisit>(`${this.endpoint}/${id}`, visit)
        .pipe(map((response) => new Visit(response)))
    );
  }

  public get(id: number): Promise<Visit> {
    return lastValueFrom(
      this.apiService
        .get<IVisit>(`${this.endpoint}/${id}`)
        .pipe(map((response) => new Visit(response)))
    );
  }

  public delete(visit: Visit): Promise<Visit> {
    return lastValueFrom(this.apiService.delete<IVisit>(`${this.endpoint}/${visit.id}`));
  }

  public async getByBadgeNumber(badgeNumber: string): Promise<Visit> {
    let params = new HttpParams();
    if (badgeNumber) {
      params = params.append('badgeNumber', badgeNumber);
    }
    const visits = await lastValueFrom(
      this.apiService.get<IVisit[]>(`${this.endpoint}`, { params })
    );

    if (!visits || visits.length === 0) {
      return null;
    }

    return new Visit(visits.shift());
  }

  public async getVisits(drk: string, visitStatus: VisitStatus): Promise<Visit[]> {
    const odataFilters = [];

    if (visitStatus) {
      odataFilters.push(`checkinStatus eq '${visitStatus}'`);
    }
    if (drk) {
      odataFilters.push(`drk eq '${drk}'`);
    }

    let params = new HttpParams();

    if (odataFilters.length > 0) {
      params = params.append('$filter', odataFilters.join(' and '));
    }

    return lastValueFrom(
      this.apiService
        .get<IVisit[]>(`${this.endpoint}/odata`, { params })
        .pipe(map((response) => response.map((visit) => new Visit(visit))))
    );
  }

  public async createVisit(visit: Visit): Promise<Visit> {
    const visitPost = new VisitPost(visit);
    return this.post(visitPost);
  }
  public async updateVisit(visit: Visit): Promise<Visit> {
    const visitPost = new VisitPost(visit);
    return this.putPost(visit.id, visitPost);
  }

  public async createVisitRecord(
    visitId: string,
    visitRecordPost: VisitRecordPost
  ): Promise<VisitRecord> {
    return lastValueFrom(
      this.apiService
        .post<IVisitRecord>(`${this.endpoint}/${visitId}/visitRecords`, visitRecordPost)
        .pipe(map((response) => new VisitRecord(response)))
    );
  }

  public async recordBadgeGeneration(
    visit: Visit,
    flowInformation: FlowInformation
  ): Promise<VisitRecord> {
    const visitRecordPost = new VisitRecordPost({
      recordType: EVisitRecordType.BADGE_GENERATION
    }).addVisit(visit);
    visitRecordPost.addFlowInformation(flowInformation);

    return lastValueFrom(
      this.apiService
        .post<IVisitRecord>(`${this.endpoint}/${visit.id}/badge-generation`, visitRecordPost)
        .pipe(map((response) => new VisitRecord(response)))
    );
  }

  public async recordDailyCheckin(
    visit: Visit,
    visitorInformation: VisitorInformation,
    flowInformation: FlowInformation,
    technicalInformation: TechnicalInformation
  ): Promise<VisitRecord> {
    const visitRecordPost = new VisitRecordPost({
      recordType: EVisitRecordType.DAILY_CHECKIN,
    }).addVisit(visit);
    visitRecordPost.addFlowInformation(flowInformation);

    return lastValueFrom(
      this.apiService
        .post<IVisitRecord>(`${this.endpoint}/${visit.id}/daily-checkin`, visitRecordPost)
        .pipe(map((response) => new VisitRecord(response)))
    );
  }

  public async recordDailyCheckout(
    visit: Visit,
    visitInformation: VisitorInformation,
    flowInformation: FlowInformation,
    technicalInformation: TechnicalInformation
  ): Promise<VisitRecord> {
    const visitRecordPost = new VisitRecordPost({
      recordType: EVisitRecordType.DAILY_CHECKOUT,
    }).addVisit(visit);
    visitRecordPost.addFlowInformation(flowInformation);

    return lastValueFrom(
      this.apiService
        .post<IVisitRecord>(`${this.endpoint}/${visit.id}/daily-checkout`, visitRecordPost)
        .pipe(map((response) => new VisitRecord(response)))
    );
  }

  public async recordCovidAcknowledgement(
    visit: Visit,
    visitInformation: VisitorInformation,
    flowInformation: FlowInformation,
    technicalInformation: TechnicalInformation
  ): Promise<VisitRecord> {
    const visitRecordPost = new VisitRecordPost({
      recordType: EVisitRecordType.COVID_ACKNOWLEDGEMENT,
    }).addVisit(visit);
    visitRecordPost.addFlowInformation(flowInformation);

    return lastValueFrom(
      this.apiService
        .post<IVisitRecord>(`${this.endpoint}/${visit.id}/covid-acknowledgement`, visitRecordPost)
        .pipe(map((response) => new VisitRecord(response)))
    );
  }

  public async recordStartCheckin(visit: Visit): Promise<VisitRecord> {
    const visitRecordPost = new VisitRecordPost({
      recordType: EVisitRecordType.START_CHECKIN,
    }).addVisit(visit);

    return lastValueFrom(
      this.apiService
        .post<IVisitRecord>(`${this.endpoint}/${visit.id}/start-checkin`, visitRecordPost)
        .pipe(map((response) => new VisitRecord(response)))
    );
  }

  async deleteProcessInstancesByDrk(drk: string) {
    if (!drk) {
      return;
    }

    const visits = await this.getVisits(drk, VisitStatus.CHECKIN_IN_PROGRESS);

    if (visits && visits.length > 0) {
      const promises = [];
      for (const visit of visits) {
        promises.push(this.delete(visit));
      }
      await Promise.all(promises);
    }
  }

  public async isVisitPrecheckedIn(
    visitId: string): Promise<boolean> {
    return lastValueFrom(
      this.apiService
        .get<boolean>(`${this.endpoint}/${visitId}/is-prechecked-in`)
        .pipe(map((response) => response))
    );
  }

  public async getCheckinFormFields(
    trainingCenterId: string
  ): Promise<CheckinFormFields> {
    return lastValueFrom(
      this.apiService
        .get<CheckinFormFields>(
          `${this.endpoint}/checkin-form-fields?trainingCenterId=${trainingCenterId}`
        ).pipe(take(1))
    );
  }
}
