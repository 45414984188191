import { map, take } from 'rxjs/operators';

import { ApiService } from '@data/services/api.service';
import { FeatureService } from '@core/feature-toggle/store/feature.service';
import { ITrainingCenter } from '@data/interfaces/training-centers/training-center.interface';
import { Injectable } from '@angular/core';
import { ToggleFeatureKeys } from '@core/feature-toggle/toggle-feature-keys.enum';
import { TrainingCenter } from '@core/model/entity/training-center.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrainingCentersService {
  private endpoint = `/TrainingCenters`;

  constructor(private apiService: ApiService, private featureService: FeatureService) {}

  public async getTrainingCenters(): Promise<TrainingCenter[]> {
    return lastValueFrom(
      this.apiService.get<ITrainingCenter[]>(`${this.endpoint}`).pipe(
        map((response) => {
          const trainingCenters = response.map((data) => new TrainingCenter(data));
          return trainingCenters;
        })
      )
    );
  }

  public async getTrainingCenter(id: string): Promise<TrainingCenter> {
    return lastValueFrom(
      this.apiService
        .get<ITrainingCenter>(`${this.endpoint}/${id}`)
        .pipe(map((response) => new TrainingCenter(response)))
    );
  }

  public async getTechnicalTrainingCenters(
    trainingCenterGeminiId: string
  ): Promise<TrainingCenter[]> {
    const available = await lastValueFrom(
      this.featureService.featureEnabled$
        .pipe(take(1))
        .pipe(map((f) => f(ToggleFeatureKeys.MultiTrainingCenters)))
    );

    if (!available) {
      return [];
    }

    return lastValueFrom(
      this.apiService
        .get<ITrainingCenter[]>(`${this.endpoint}/${trainingCenterGeminiId}/sub-centers`)
        .pipe(
          map((response) => {
            const trainingCenters = response.map((data) => new TrainingCenter(data));
            return trainingCenters;
          })
        )
    );
  }
}
